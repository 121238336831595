import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AddResourceUserInput,
  ConnectionType,
  EntityType,
  ResourceAccessLevel,
  ResourceDropdownPreviewFragment,
  ResourceType,
  useAddResourceUsersMutation,
  useMultipleResourceAccessLevelsQuery,
  usePaginatedResourceDropdownLazyQuery,
  useSearchResourcesQuery,
  useUserAddResourcesQuery,
} from "api/generated/graphql";
import FullscreenViewTitle from "components/fullscreen_modals/FullscreenViewTitle";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { Banner, Divider, Input } from "components/ui";
import List from "components/ui/list/ListV3";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import { useState } from "react";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import {
  resourceRequiresAtLeastOneRole,
  resourceTypeCanBeAccessed,
} from "utils/directory/resources";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";

import * as styles from "./GroupAddResources.css";
import { ResourceCard } from "./GroupAddResourcesView";

const PAGE_SIZE = 100;

interface ListItem {
  id: string;
  icon?: IconData;
  name: string;
  connectionType?: ConnectionType;
  resourceType?: ResourceType;

  isEmpty?: boolean;
}

const UserAddResourcesView = () => {
  const transitionBack = useTransitionBack();
  const logEvent = useLogEvent();
  const { userId } = useParams<{ userId: string }>();
  const startPushTaskPoll = usePushTaskLoader();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  // TODO: Remove this mess of useState in favor of context
  const [resourceById, setResourceById] = useState<{
    [resourceId: string]: ResourceDropdownPreviewFragment;
  }>({});
  const [resourcesByConnectionId, setResourcesByConnectionId] = useState<{
    [connectionId: string]: ResourceDropdownPreviewFragment[];
  }>({});
  const [resourcesByParentId, setResourcesByParentId] = useState<{
    [resourceId: string]: ResourceDropdownPreviewFragment[];
  }>({});
  const [roleByResourceIdToAdd, setRoleByResourceIdToAdd] = useState<
    Record<string, ResourceAccessLevel[]>
  >({});
  const [
    accessDurationByResourceIdToAdd,
    setAccessDurationByResourceIdToAdd,
  ] = useState<Record<string, ExpirationValue>>({});
  const [addError, setAddError] = useState("");

  // Get User and Connection data
  const { data, loading, error } = useUserAddResourcesQuery({
    variables: {
      userId,
    },
  });
  const user =
    data?.user.__typename === "UserResult" ? data.user.user : undefined;
  const allConnections = data?.connections.connections ?? [];
  const connections = allConnections.filter((connection) =>
    Boolean(connection.numResources)
  );

  // Fetch all non-remote roles for resources that the user has access to
  // Used to determine if the user already has full-access to the resource
  const resourceIDs = new Set(user?.userResources.map((ur) => ur.resourceId));
  const {
    data: rolesData,
    previousData: rolesPreviousData,
    error: rolesError,
  } = useMultipleResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceIds: Array.from(resourceIDs),
        // Don't query end systems for roles upfront to load page faster
        ignoreRemoteAccessLevels: true,
      },
    },
    skip: resourceIDs.size === 0,
  });
  const allNonRemoteRolesByResourceId: Record<
    string,
    ResourceAccessLevel[]
  > = {};
  switch (rolesPreviousData?.multipleAccessLevels.__typename) {
    case "MultipleResourceAccessLevelsResult":
      rolesPreviousData.multipleAccessLevels.results.forEach((role) => {
        allNonRemoteRolesByResourceId[role.resourceId] = role.accessLevels;
      });
  }
  switch (rolesData?.multipleAccessLevels.__typename) {
    case "MultipleResourceAccessLevelsResult":
      rolesData.multipleAccessLevels.results.forEach((role) => {
        allNonRemoteRolesByResourceId[role.resourceId] = role.accessLevels;
      });
  }

  // Allow searching directly for resources
  const {
    data: searchResourcesData,
    loading: searchResourcesLoading,
    error: searchResourcesError,
  } = useSearchResourcesQuery({
    variables: {
      query: debouncedSearchQuery,
      maxNumEntries: PAGE_SIZE,
    },
    skip: debouncedSearchQuery === "",
  });

  const [getResources] = usePaginatedResourceDropdownLazyQuery();
  const [
    addResourceUsers,
    { loading: addLoading },
  ] = useAddResourceUsersMutation();

  if (loading) {
    return <FullscreenSkeleton />;
  }
  if (!user || error || rolesError) {
    return <UnexpectedErrorPage error={error} />;
  }

  // Get the roles that the user already has on each resource
  const directRolesByResourceId: Record<string, ResourceAccessLevel[]> = {};
  user.userResources.forEach((userResource) => {
    if (!userResource.access?.directAccessPoint) {
      return;
    }
    if (!directRolesByResourceId[userResource.resourceId]) {
      directRolesByResourceId[userResource.resourceId] = [];
    }
    directRolesByResourceId[userResource.resourceId] = _.uniqBy(
      [
        ...directRolesByResourceId[userResource.resourceId],
        userResource.accessLevel,
      ],
      "accessLevelRemoteId"
    );
  });

  const handleClose = () => {
    transitionBack(`/users/${userId}/#resources`);
  };

  const handleFetchResources = async (
    connectionId: string,
    connectionType?: ConnectionType,
    parentResourceId?: string
  ) => {
    let resourceTypes: ResourceType[] | undefined;
    if (connectionType === ConnectionType.AwsSso) {
      resourceTypes = [ResourceType.AwsAccount];
    }
    try {
      const { data } = await getResources({
        variables: {
          input: {
            connectionIds: parentResourceId ? undefined : [connectionId],
            resourceTypes,
            parentResourceId: parentResourceId
              ? {
                  parentResourceId,
                }
              : undefined,
            maxNumEntries: PAGE_SIZE,
          },
        },
      });

      if (parentResourceId) {
        setResourcesByParentId((resourcesByParentId) => {
          return {
            ...resourcesByParentId,
            [parentResourceId]: data?.resources.resources ?? [],
          };
        });
      } else {
        setResourcesByConnectionId((resourcesByConnectionId) => {
          return {
            ...resourcesByConnectionId,
            [connectionId]: data?.resources.resources ?? [],
          };
        });
      }
      setResourceById((resourceById) => {
        return {
          ...resourceById,
          ...data?.resources.resources.reduce((acc, resource) => {
            acc[resource.id] = resource;
            return acc;
          }, {} as typeof resourceById),
        };
      });
    } catch (err) {
      logError(err, "Failed to fetch resources for connection " + connectionId);
    }
  };

  const numResourcesToAdd = Object.keys(roleByResourceIdToAdd).length;

  /*
    Disable any resources that the user already has full access to.
    If the user has no direct access then keep the resource enabled.
    Otherwise, if numNonRemoteRoles != 0 and numDirectRoles === numNonRemoteRoles
    then the user has all custom/static roles available on the resource so disable it.
    This will not disable a resource if a user has all remote roles, so a user
    can still select it, but will be shown they have no roles to add later.
    This is done because fetching remote roles upfront is expensive.
  */
  const disabledResourceIds = new Set();
  user.userResources.forEach((userResource) => {
    const numDirectRoles =
      directRolesByResourceId[userResource.resourceId]?.length ?? 0;
    const numNonRemoteRoles =
      allNonRemoteRolesByResourceId[userResource.resourceId]?.length ?? 0;
    if (numDirectRoles != 0 && numDirectRoles === numNonRemoteRoles) {
      disabledResourceIds.add(userResource.resourceId);
    }
  });

  const handleSubmit = async () => {
    if (!user) {
      return;
    }
    logEvent({
      name: "apps_add_user_to_resources",
      properties: {
        numResourcesAddedTo: Object.entries(roleByResourceIdToAdd).length,
      },
    });
    try {
      const resourceUsersToAdd: AddResourceUserInput[] = [];
      for (const [resourceId, roles] of Object.entries(roleByResourceIdToAdd)) {
        const resource = resourceById[resourceId];

        if (!resource) {
          setAddError("failed to add resources to bundle");
          return;
        }

        // Convert expiration value to duration in minutes, default to undefined.
        const expirationVal =
          accessDurationByResourceIdToAdd[resourceId] ||
          ExpirationValue.Indefinite;
        const accessDurationInMinutes = expirationValueToDurationInMinutes(
          expirationVal
        )?.asMinutes();

        if (roles.length === 0) {
          // If resource requires a role , but none are selected,
          // show an error.
          if (
            resourceRequiresAtLeastOneRole(resource) ||
            allNonRemoteRolesByResourceId[resourceId]?.length > 0
          ) {
            setAddError(
              "Please select at least one role for resources that have roles."
            );
            return;
          } else {
            // If resource does not require roles,
            // add an empty role to add the resource directly.
            resourceUsersToAdd.push({
              userId: user.id,
              resourceId,
              accessLevel: {
                accessLevelName: "",
                accessLevelRemoteId: "",
              },
              durationInMinutes: accessDurationInMinutes,
            });
          }
        }

        roles.forEach((role) => {
          resourceUsersToAdd.push({
            userId: user?.id ?? "",
            resourceId,
            accessLevel: {
              accessLevelName: role.accessLevelName,
              accessLevelRemoteId: role.accessLevelRemoteId,
            },
            durationInMinutes: accessDurationInMinutes,
          });
        });
      }

      const { data } = await addResourceUsers({
        variables: {
          input: {
            resourceUsers: resourceUsersToAdd,
          },
        },
        refetchQueries: ["User"],
      });
      switch (data?.addResourceUsers.__typename) {
        case "AddResourceUsersResult":
          if (data.addResourceUsers.taskId) {
            startPushTaskPoll(data.addResourceUsers.taskId);
          }
          handleClose();
          break;
        case "ResourceNotFoundError":
        case "ResourceUserAlreadyExists":
          setAddError(data.addResourceUsers.message);
          break;
        default:
          logError(new Error(`failed to add resources to user`));
          setAddError("Error: failed to add resources to user");
      }
    } catch (error) {
      logError(error, "failed to add resources to user");
      setAddError(
        getModifiedErrorMessage("Error: failed to add resources to user", error)
      );
    }
  };

  const renderConnectionsList = () => {
    const listItems: ListItem[] = connections.map((connection) => {
      return {
        id: connection.id,
        icon: {
          type: "src",
          icon: getConnectionTypeInfo(connection.connectionType)?.icon,
        },
        name: connection.name,
        connectionType: connection.connectionType,
      };
    });

    return (
      <List
        items={listItems}
        loading={loading || searchResourcesLoading}
        getItemKey={(item) => item.id}
        getItemLabel={(item) => item.name}
        getItemSublabel={(item) => {
          if (item.isEmpty) {
            return "No resources";
          }
          if (disabledResourceIds.has(item.id)) {
            return "[User already has access]";
          }
          return "";
        }}
        getItemDisabled={(item) => {
          if (item.isEmpty) {
            return true;
          }
          return disabledResourceIds.has(item.id);
        }}
        getIcon={(item) => item.icon}
        getActionLabel={(item) => {
          if (
            item.resourceType &&
            resourceTypeCanBeAccessed(item.resourceType)
          ) {
            return roleByResourceIdToAdd[item.id] ? "Remove" : "Add";
          }
          return "";
        }}
        getActionIcon={(item) => {
          if (
            item.resourceType &&
            resourceTypeCanBeAccessed(item.resourceType)
          ) {
            return roleByResourceIdToAdd[item.id] ? "x" : "plus";
          }
        }}
        onSelectItem={(item) => {
          if (
            item.connectionType ||
            item.resourceType === ResourceType.AwsAccount
          ) {
            setExpandedItems((expandedItems) => {
              if (expandedItems.includes(item.id)) {
                return expandedItems.filter((id) => id !== item.id);
              } else {
                return [...expandedItems, item.id];
              }
            });
            if (item.connectionType && !resourcesByConnectionId[item.id]) {
              handleFetchResources(item.id, item.connectionType);
            }
            if (
              item.resourceType === ResourceType.AwsAccount &&
              !resourcesByParentId[item.id]
            ) {
              handleFetchResources("", item.connectionType, item.id);
            }
          } else {
            setRoleByResourceIdToAdd((prev) => {
              const newRoles = { ...prev };
              if (item.id in newRoles) {
                delete newRoles[item.id];
                return newRoles;
              } else {
                return {
                  ...prev,
                  [item.id]: [],
                };
              }
            });
          }
        }}
        hasNestedItems={(item) =>
          Boolean(item.connectionType) ||
          item.resourceType === ResourceType.AwsAccount
        }
        getNestedItems={(item) => {
          const resources = item.connectionType
            ? resourcesByConnectionId[item.id]
            : resourcesByParentId[item.id];
          if (resources && resources.length === 0) {
            return [
              {
                id: `${item.id}-empty`,
                name: "",
                isEmpty: true,
              },
            ];
          }
          return resources?.map((resource) => {
            const iconData: IconData = {
              type: "entity",
              entityType: resource.resourceType,
            };
            return {
              id: resource.id,
              icon: iconData,
              name: resource.name,
              resourceType: resource.resourceType,
            };
          });
        }}
        expandedItems={expandedItems}
      />
    );
  };

  const renderSearchList = () => {
    if (searchResourcesError) {
      return <ModalErrorMessage errorMessage={searchResourcesError.message} />;
    }
    return (
      <List
        items={searchResourcesData?.resources.resources ?? []}
        loading={loading || searchResourcesLoading}
        getItemKey={(item) => item.id}
        getItemLabel={(item) => item.name}
        getIcon={(item) => {
          return {
            type: "entity",
            entityType: item.resourceType,
          };
        }}
        getItemSublabel={(item) => {
          let sublabel = item.parentResource?.name
            ? `${item.parentResource.name} `
            : "";
          if (!resourceTypeCanBeAccessed(item.resourceType)) {
            sublabel += "[This resource cannot be accessed directly]";
          }
          if (disabledResourceIds.has(item.id)) {
            sublabel += "[User already has access]";
          }
          return sublabel;
        }}
        getItemDisabled={(item) => {
          return (
            disabledResourceIds.has(item.id) ||
            !resourceTypeCanBeAccessed(item.resourceType)
          );
        }}
        noItemsMessage="No search results"
        onSelectItem={(item) => {
          setRoleByResourceIdToAdd((prev) => {
            const newRoles = { ...prev };
            if (item.id in newRoles) {
              delete newRoles[item.id];
              return newRoles;
            } else {
              return {
                ...prev,
                [item.id]: [],
              };
            }
          });
          setResourceById((resourceById) => {
            return {
              ...resourceById,
              [item.id]: item,
            };
          });
        }}
        getActionLabel={(item) => {
          if (
            item.resourceType &&
            resourceTypeCanBeAccessed(item.resourceType)
          ) {
            return roleByResourceIdToAdd[item.id] ? "Remove" : "Add";
          }
          return "";
        }}
        getActionIcon={(item) => {
          if (
            item.resourceType &&
            resourceTypeCanBeAccessed(item.resourceType)
          ) {
            return roleByResourceIdToAdd[item.id] ? "x" : "plus";
          }
        }}
      />
    );
  };

  return (
    <FullscreenView
      title={
        <FullscreenViewTitle
          entityType={EntityType.User}
          entityName={user.fullName}
          targetEntityName="Resources"
          action="add"
        />
      }
      onCancel={handleClose}
      onPrimaryButtonClick={handleSubmit}
      primaryButtonDisabled={numResourcesToAdd === 0}
      primaryButtonLabel={`Add ${
        numResourcesToAdd ? numResourcesToAdd : ""
      } ${pluralize("resource", numResourcesToAdd)}`}
      primaryButtonLoading={addLoading}
    >
      <FullscreenView.Content fullWidth>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "md",
            })}
          >
            Select resources to add to the user:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Search by name"
            />
          </div>
          <div className={sprinkles({ color: "gray600", fontSize: "textXs" })}>
            {debouncedSearchQuery === ""
              ? "Showing first 100 resources in each app. Use search to find more results."
              : "Showing first 100 search results. Refine your search to find more."}
          </div>
          <Divider />
          {debouncedSearchQuery === ""
            ? renderConnectionsList()
            : renderSearchList()}
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addError && (
          <Banner message={addError} type="error" marginBottom="lg" />
        )}
        <div
          className={sprinkles({
            fontSize: "textLg",
            fontWeight: "medium",
            marginBottom: "lg",
          })}
        >
          Adding {numResourcesToAdd} {pluralize("Resource", numResourcesToAdd)}
        </div>
        {Object.keys(roleByResourceIdToAdd).map((resourceId) => {
          const resource = resourceById[resourceId];
          if (!resource) {
            return null;
          }

          return (
            <ResourceCard
              key={resource.id}
              resource={resource}
              existingRoles={directRolesByResourceId[resourceId] ?? []}
              selectedRoles={roleByResourceIdToAdd[resourceId] ?? []}
              onRemove={() => {
                setRoleByResourceIdToAdd((prev) => {
                  const newRoles = { ...prev };
                  delete newRoles[resourceId];
                  return newRoles;
                });
              }}
              onUpdateSelectedRoles={(roles) => {
                setRoleByResourceIdToAdd((prev) => {
                  return {
                    ...prev,
                    [resourceId]: roles,
                  };
                });
              }}
              onUpdateAllRoles={(roles) => {
                allNonRemoteRolesByResourceId[resource.id] = roles;
              }}
              accessDuration={
                accessDurationByResourceIdToAdd[resourceId] ??
                ExpirationValue.Indefinite
              }
              setAccessDuration={(access) => {
                setAccessDurationByResourceIdToAdd((prev) => {
                  return {
                    ...prev,
                    [resourceId]: access,
                  };
                });
              }}
            />
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

export default UserAddResourcesView;
