import { Column, ColumnContainer } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeaderV3";
import { Divider } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { usePageTitle } from "utils/hooks";
import RecommendationFilterSection, {
  isEmptyFilter,
  RecommendationsFilter,
  RiskScoreRange,
  useRecommendationsFilterState,
} from "views/recommendations/RecommendationFilters";
import RiskyApps from "views/recommendations/RiskyApps";
import * as styles from "views/recommendations/ThreatCenter.css";
import ThreatResolutionNew from "views/recommendations/ThreatResolutionNew";

import ThreatCenter from "./ThreatCenter";

const Recommendations = () => {
  usePageTitle("Homepage");
  const [
    recommendationsFilter,
    setRecommendationsFilter,
  ] = useState<RecommendationsFilter>(useRecommendationsFilterState());

  const handleSetRiskScoreFilter = (
    riskScoreRange: RiskScoreRange[] | undefined
  ): void => {
    if (!riskScoreRange) {
      setRecommendationsFilter((prevFilter) => ({
        ...prevFilter,
        riskScoreFilter: undefined,
      }));
      return;
    }

    const findMinMaxScores = (ranges: RiskScoreRange[]) => {
      return ranges.reduce(
        (acc, { minScore, maxScore }) => ({
          minScore: Math.min(acc.minScore, minScore),
          maxScore: Math.max(acc.maxScore, maxScore),
        }),
        { minScore: Infinity, maxScore: -Infinity }
      );
    };

    const filteredRange = findMinMaxScores(riskScoreRange);
    setRecommendationsFilter((prevFilter) => ({
      ...prevFilter,
      riskScoreFilter: [filteredRange],
    }));
  };

  return (
    <ColumnContainer>
      <Column isContent maxWidth="none">
        <ColumnHeader
          title="Home"
          icon={{ type: "name", icon: "home-2" }}
          includeDefaultActions
        />
        <ColumnContent>
          <ThreatCenter setRiskScoreFilter={handleSetRiskScoreFilter} />
          <Divider margin="lg" />

          <div className={styles.threatContainer}>
            <div
              className={sprinkles({
                display: "flex",
                flexDirection: "column",
              })}
            >
              <div className={styles.header}>Top Suggested Remediations</div>
              <div
                className={sprinkles({
                  paddingX: "lg",
                })}
              >
                <RecommendationFilterSection
                  filter={recommendationsFilter}
                  setFilter={setRecommendationsFilter}
                />
                <ThreatResolutionNew filter={recommendationsFilter} />
              </div>
              {isEmptyFilter(recommendationsFilter) && (
                <>
                  <Divider margin="lg" />
                  <div className={styles.header}>Most Sensitive Apps</div>
                  <div
                    className={sprinkles({
                      paddingX: "lg",
                    })}
                  >
                    <RiskyApps />
                  </div>
                </>
              )}
            </div>
          </div>
        </ColumnContent>
      </Column>
    </ColumnContainer>
  );
};

export default Recommendations;
