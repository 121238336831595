import {
  Maybe,
  PropagationTaskType,
  SupportTicketPreviewFragment,
} from "api/generated/graphql";
import { GenericEntityViewerRow } from "components/entity_viewer/EntityViewer";
import { ErrorMessageLabel } from "components/label/MessageLabel";
import PropagationStatusCodeLabel from "components/propagation/PropagationStatusCodeLabel";
import { PropagationStatusLabel } from "components/propagation/PropagationStatusLabel";
import { PropagationStatus } from "components/propagation/PropagationStatusLabelWithModal";
import { SelfRefreshingTimestamp } from "components/time/time";
import { Button, Modal } from "components/ui";
import {
  Section,
  SectionDivider,
  SectionEntry,
  SectionRow,
} from "components/viewer/Viewer";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import React, { useState } from "react";
import {
  PropagatedEntityInfo,
  PropagationType,
  useRemediations,
} from "utils/useRemediations";

import * as styles from "./PropagationStatusModal.css";

export type PropagationStatusInfo = {
  propagationType: PropagationType;
  propagationStatus: Maybe<PropagationStatus> | undefined;
  propagationTickets?: SupportTicketPreviewFragment[];
  isAccessReview: boolean;
  entityInfo: PropagatedEntityInfo;
};

export type PropagationStatusModalProps = {
  propagationStatusInfos: PropagationStatusInfo[];
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

export const PropagationStatusModal = (props: PropagationStatusModalProps) => {
  return (
    <Modal
      isOpen={props.showModal}
      onClose={() => {
        props.setShowModal(false);
      }}
      title="Sync Details"
      maxWidth={false}
    >
      <Modal.Body>
        <div className={styles.container}>
          {props.propagationStatusInfos.map((propagationStatusInfo) => {
            return (
              <PropagationStatusSection
                key={propagationStatusInfo?.entityInfo?.resource?.id}
                propagationType={propagationStatusInfo.propagationType}
                propagationStatus={propagationStatusInfo.propagationStatus}
                propagationTickets={propagationStatusInfo.propagationTickets}
                isAccessReview={propagationStatusInfo.isAccessReview}
                entityInfo={propagationStatusInfo.entityInfo}
                setShowModal={props.setShowModal}
              />
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer
        onPrimaryButtonClick={() => {
          props.setShowModal(false);
        }}
        primaryButtonLabel="Close"
      />
    </Modal>
  );
};

export type PropagationStatusSectionProps = {
  propagationType: PropagationType;
  propagationStatus: Maybe<PropagationStatus> | undefined;
  propagationTickets?: SupportTicketPreviewFragment[];
  isAccessReview: boolean;
  entityInfo: PropagatedEntityInfo;
  setShowModal: (show: boolean) => void;
};

export const PropagationStatusSection = (
  props: PropagationStatusSectionProps
) => {
  const lastSynced = props.propagationStatus
    ? moment(props.propagationStatus.lastSynced)
    : null;

  const [
    remediationButtonLoadingIndex,
    setRemediationButtonLoadingIndex,
  ] = useState(-1);
  const [remediationErrorMessage, setRemediationErrorMessage] = useState("");
  const errorMessage = props.propagationStatus?.errorMessage;

  const remediations = useRemediations(
    props.isAccessReview,
    props.entityInfo,
    setRemediationErrorMessage,
    props.propagationStatus
  );

  let entityTypeName = "item";
  let entity1, entity2, role;
  switch (props.propagationType) {
    case PropagationType.ResourceUser:
      entityTypeName = "resource user";
      entity1 = props.entityInfo.user;
      entity2 = props.entityInfo.resource;
      role = props.entityInfo.role;
      break;
    case PropagationType.GroupUser:
      entityTypeName = "group user";
      entity1 = props.entityInfo.user;
      entity2 = props.entityInfo.group;
      break;
    case PropagationType.GroupResource:
      entityTypeName = "group resource";
      entity1 = props.entityInfo.group;
      entity2 = props.entityInfo.resource;
      role = props.entityInfo.role;
      break;
    case PropagationType.ConnectionUser:
      entityTypeName = "app user";
      entity1 = props.entityInfo.user;
      entity2 = props.entityInfo.connection;
      break;
    case PropagationType.RoleAssignment:
      entityTypeName = "role assignment";
      entity1 = props.entityInfo.principal;
      entity2 = props.entityInfo.entity;
      role = props.entityInfo.role;
      break;
  }

  let title = "Sync status";
  if (props.propagationTickets) {
    title = `${pluralize("Ticket", props.propagationTickets.length)} to `;
    switch (props.propagationStatus?.taskType) {
      case (PropagationTaskType.GroupsCreateUsers,
      PropagationTaskType.ResourcesCreateUsers):
        title = title + "propagate access";
        break;
      case (PropagationTaskType.GroupsDeleteUsers,
      PropagationTaskType.ResourcesDeleteUsers):
        title = title + "revoke access";
        break;
      default:
        title = title + "sync";
    }
  }

  return (
    <Section>
      <SectionRow>
        {entity1 && (
          <GenericEntityViewerRow title="Principal" entity={entity1} />
        )}
        {entity2 && <GenericEntityViewerRow entity={entity2} />}
        {role ? (
          <SectionEntry title={"Role"}>
            {role.accessLevelName === "" ? "--" : role.accessLevelName}
          </SectionEntry>
        ) : (
          // This section is to align propagation statuses without roles
          <SectionEntry title={""} />
        )}
      </SectionRow>

      <SectionDivider />
      <SectionRow>
        <SectionEntry title={title}>
          {props.propagationTickets ? (
            <div
              className={sprinkles({
                display: "flex",
                flexDirection: "column",
                gap: "sm",
              })}
            >
              {props.propagationTickets.map((propagationTicket) => (
                <PropagationStatusLabel
                  propagationStatusCode={props.propagationStatus?.statusCode}
                  propagationTicket={propagationTicket}
                  taskType={props.propagationStatus?.taskType}
                  showText
                />
              ))}
            </div>
          ) : (
            <PropagationStatusLabel
              propagationStatusCode={props.propagationStatus?.statusCode}
              propagationTicket={props.propagationTickets}
              taskType={props.propagationStatus?.taskType}
              showText
            />
          )}
        </SectionEntry>
        <SectionEntry title={"Status code"}>
          <PropagationStatusCodeLabel
            statusCode={props.propagationStatus?.statusCode}
            entityTypeName={entityTypeName}
          />
        </SectionEntry>
        <SectionEntry title={"Last synced"}>
          {lastSynced ? (
            <SelfRefreshingTimestamp timestamp={lastSynced} showTooltip />
          ) : (
            "--"
          )}
        </SectionEntry>
      </SectionRow>
      {errorMessage && (
        <SectionRow>
          <SectionEntry title={"Error message"}>
            <ErrorMessageLabel errorMessage={errorMessage} />
          </SectionEntry>
        </SectionRow>
      )}
      <SectionRow>
        <div>
          {remediations && remediations.length > 0 && (
            <SectionEntry title={"Remediation"}>
              <div className={styles.remediationButtonsContainer}>
                {remediations.map((remediation, index) => (
                  <Button
                    label={remediation.label}
                    loading={remediationButtonLoadingIndex === index}
                    disabled={
                      remediationButtonLoadingIndex !== -1 &&
                      remediationButtonLoadingIndex !== index
                    }
                    onClick={async () => {
                      setRemediationButtonLoadingIndex(index);
                      setRemediationErrorMessage("");
                      await remediation.onRemediate({
                        onSuccess: () => props.setShowModal(false),
                      });
                      setRemediationButtonLoadingIndex(-1);
                    }}
                    key={index}
                    outline
                  />
                ))}
              </div>
            </SectionEntry>
          )}
        </div>
      </SectionRow>
      {remediationErrorMessage && (
        <SectionRow>
          <SectionEntry title={"Remediation error"}>
            <ErrorMessageLabel errorMessage={remediationErrorMessage} />
          </SectionEntry>
        </SectionRow>
      )}
    </Section>
  );
};

export default PropagationStatusModal;
