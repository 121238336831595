import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AddGroupResourceInput,
  ConnectionType,
  GroupDropdownPreviewFragment,
  GroupType,
  ResourceAccessLevel,
  ResourcePreviewWithGroupsFragment,
  useAddGroupResourcesMutation,
  usePaginatedGroupDropdownLazyQuery,
  useResourceAddGroupsQuery,
  useSearchGroupsQuery,
} from "api/generated/graphql";
import FullscreenViewTitle from "components/fullscreen_modals/FullscreenViewTitle";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import {
  Banner,
  Divider,
  EntityIcon,
  Icon,
  Input,
  Label,
  Loader,
  Select,
} from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import {
  resourceRequiresAtLeastOneRole,
  serviceTypeHasMaxOneRole,
} from "utils/directory/resources";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";

import * as styles from "./GroupAddResources.css";

const PAGE_SIZE = 100;

interface ResourceGroupRow {
  id: string;
  icon?: IconData;
  name: string;
  connectionType?: ConnectionType;
  connectionId?: string;
  groupType?: GroupType;
  isEmpty?: boolean;
}

const ResourceAddGroupsView = () => {
  const transitionBack = useTransitionBack();
  const logEvent = useLogEvent();
  const { resourceId } = useParams<{ resourceId: string }>();
  const startPushTaskPoll = usePushTaskLoader();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const [groupById, setGroupById] = useState<{
    [groupId: string]: GroupDropdownPreviewFragment;
  }>({});
  const [groupsByConnectionId, setGroupsByConnectionId] = useState<{
    [connectionId: string]: GroupDropdownPreviewFragment[];
  }>({});
  const [roleByGroupIdToAdd, setRoleByGroupIdToAdd] = useState<
    Record<string, ResourceAccessLevel[]>
  >({});
  const [
    accessDurationByGroupIdToAdd,
    setAccessDurationByGroupIdToAdd,
  ] = useState<Record<string, ExpirationValue>>({});
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [addError, setAddError] = useState("");
  const rowsById: Record<string, ResourceGroupRow> = {};
  const [itemsLoadingSubRows, setItemsLoadingSubRows] = useState<string[]>([]);

  const [getGroups] = usePaginatedGroupDropdownLazyQuery();
  const { data, loading, error } = useResourceAddGroupsQuery({
    variables: {
      resourceId,
    },
  });
  const {
    data: searchGroupsData,
    loading: searchGroupsLoading,
    error: searchGroupsError,
  } = useSearchGroupsQuery({
    variables: {
      query: debouncedSearchQuery,
      maxNumEntries: PAGE_SIZE,
    },

    skip: debouncedSearchQuery === "",
  });

  useEffect(() => {
    setGroupById((groupById) => {
      return {
        ...groupById,
        ...searchGroupsData?.groups.groups.reduce((acc, group) => {
          acc[group.id] = group;
          return acc;
        }, {} as typeof groupById),
      };
    });
  }, [searchGroupsData]);

  const [
    addGroupResources,
    { loading: addLoading },
  ] = useAddGroupResourcesMutation();

  let resource: ResourcePreviewWithGroupsFragment | undefined;
  if (data?.resource.__typename === "ResourceResult") {
    resource = data.resource.resource;
  }
  const allConnections = data?.connections.connections ?? [];
  const connections = allConnections.filter((connection) =>
    Boolean(connection.numGroups)
  );
  const allRoles = resource?.accessLevels ?? [];

  if (loading) {
    return <FullscreenSkeleton />;
  }
  if (!resource?.authorizedActions?.includes(AuthorizedActionManage)) {
    return <ForbiddenPage />;
  }
  if (!resource || error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const resourceHasRoles = resourceRequiresAtLeastOneRole(resource);
  const directRolesByGroupId: Record<string, ResourceAccessLevel[]> = {};
  resource.containingGroups.forEach((groupResource) => {
    if (!groupResource.access.directAccessPoint) {
      return;
    }
    if (!directRolesByGroupId[groupResource.groupId]) {
      directRolesByGroupId[groupResource.groupId] = [];
    }
    directRolesByGroupId[groupResource.groupId] = _.uniqBy(
      [
        ...directRolesByGroupId[groupResource.groupId],
        groupResource.accessLevel,
      ],
      "accessLevelRemoteId"
    );
  });

  const handleClose = () => {
    transitionBack(`/resources/${resourceId}#groups`);
  };

  const handleFetchGroups = async (connectionId: string) => {
    try {
      setItemsLoadingSubRows((prev) => [...prev, connectionId]);
      const { data } = await getGroups({
        variables: {
          input: {
            connectionIds: [connectionId],
            maxNumEntries: PAGE_SIZE,
          },
        },
      });

      setGroupsByConnectionId((resourcesByConnectionId) => {
        return {
          ...resourcesByConnectionId,
          [connectionId]: data?.groups.groups ?? [],
        };
      });

      setGroupById((groupById) => {
        return {
          ...groupById,
          ...data?.groups.groups.reduce((acc, group) => {
            acc[group.id] = group;
            return acc;
          }, {} as typeof groupById),
        };
      });
      setItemsLoadingSubRows((prev) =>
        prev.filter((id) => id !== connectionId)
      );
      return data?.groups.groups ?? [];
    } catch (err) {
      logError(err, "Failed to fetch groups for connection " + connectionId);
    }
  };

  const numGroupsToAdd = Object.keys(roleByGroupIdToAdd).length;
  const disabledGroupIds = new Set();
  resource.containingGroups.forEach((groupResource) => {
    const allRolesAdded =
      allRoles.length === directRolesByGroupId[groupResource.groupId]?.length;
    if (allRolesAdded) {
      disabledGroupIds.add(groupResource.groupId);
    }
  });

  const hasNestedRows = (row: ResourceGroupRow) => {
    return Boolean(row.connectionType);
  };

  const getNestedRows = (row: ResourceGroupRow) => {
    const groups = groupsByConnectionId[row.id]?.filter(
      (group) => !disabledGroupIds.has(group.id)
    );
    if (groups && groups.length === 0) {
      return [
        {
          id: `${row.id}-empty`,
          name: "No groups",
          isEmpty: true,
        },
      ];
    }
    return groups?.map((group) => {
      const iconData: IconData = {
        type: "entity",
        entityType: group.groupType,
      };
      const row: ResourceGroupRow = {
        id: group.id,
        icon: iconData,
        name: group.name,
        groupType: group.groupType,
        connectionId: group?.connection?.id,
      };
      rowsById[row.id] = row;
      return row;
    });
  };

  const handleSubmit = async () => {
    logEvent({
      name: "apps_add_resource_to_groups",
      properties: {
        numGroupsAddedTo: Object.entries(roleByGroupIdToAdd).length,
      },
    });
    try {
      const groupResourcesToAdd: AddGroupResourceInput[] = [];
      for (const [groupId, roles] of Object.entries(roleByGroupIdToAdd)) {
        // Convert expiration value to duration in minutes, default to undefined.
        const expirationVal =
          accessDurationByGroupIdToAdd[groupId] || ExpirationValue.Indefinite;
        const accessDurationInMinutes = expirationValueToDurationInMinutes(
          expirationVal
        )?.asMinutes();
        if (roles.length === 0) {
          // If resource requires a role, but none are selected,
          // show an error.
          if (resourceHasRoles) {
            setAddError("Please select at least one role for each group.");
            return;
          } else {
            // If resource does not require roles,
            // add an empty role to add the resource directly.
            groupResourcesToAdd.push({
              groupId,
              resourceId,
              accessLevel: {
                accessLevelName: "",
                accessLevelRemoteId: "",
              },
              durationInMinutes: accessDurationInMinutes,
            });
          }
        }

        roles.forEach((role) => {
          groupResourcesToAdd.push({
            groupId,
            resourceId,
            accessLevel: {
              accessLevelName: role.accessLevelName,
              accessLevelRemoteId: role.accessLevelRemoteId,
            },
            durationInMinutes: accessDurationInMinutes,
          });
        });
      }

      const { data } = await addGroupResources({
        variables: {
          input: {
            groupResources: groupResourcesToAdd,
          },
        },
        refetchQueries: ["ResourceDetailView", "AppsListColumn"],
      });
      switch (data?.addGroupResources.__typename) {
        case "AddGroupResourcesResult":
          if (data.addGroupResources.taskId) {
            startPushTaskPoll(data.addGroupResources.taskId);
          }
          handleClose();
          break;
        case "GroupNotFoundError":
        case "GroupResourceAlreadyExists":
          setAddError(data.addGroupResources.message);
          break;
        default:
          logError(new Error(`failed to add resource to groups`));
          setAddError("Error: failed to add resource to groups");
      }
    } catch (error) {
      logError(error, "failed to add resource to groups");
      setAddError(
        getModifiedErrorMessage(
          "Error: failed to add resource to groups",
          error
        )
      );
    }
  };

  const COLUMNS: Header<ResourceGroupRow>[] = [
    {
      id: "name",
      label: "Name",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <div
            className={sprinkles({
              display: "flex",
              alignItems: "center",
              gap: "md",
            })}
          >
            <Label label={row.name} icon={row.icon} />
            {itemsLoadingSubRows.includes(row.id) && <Loader size="xs" />}
          </div>
        );
      },
      width: 500,
    },
  ];

  const getCheckboxDisabledReason = (row: ResourceGroupRow) => {
    if (row?.isEmpty) {
      return "No groups";
    }
    if (disabledGroupIds.has(row.id)) {
      return "Already in group";
    }
  };

  const onCheckedRowsChange = async (
    checkedRowIds: string[],
    checked: boolean
  ) => {
    if (checked) {
      setSelectedRowIds((prev) => [...prev, ...checkedRowIds]);
    } else {
      setSelectedRowIds((prev) =>
        prev.filter((id) => !checkedRowIds.includes(id))
      );
    }
    checkedRowIds.forEach((id) => {
      const row = rowsById[id];
      if (hasNestedRows(row)) {
        onCheckConnection(row, checked);
      } else {
        onCheckGroup(row);
      }
    });
  };

  const onCheckGroup = async (row: ResourceGroupRow) => {
    if (row.id in roleByGroupIdToAdd) {
      setSelectedRowIds((prev) =>
        prev.filter((id) => id !== row.id && id !== row.connectionId)
      );
      setRoleByGroupIdToAdd((prev) => {
        const newRoles = { ...prev };
        delete newRoles[row.id];
        return newRoles;
      });
      setAccessDurationByGroupIdToAdd((prev) => {
        const newDurations = { ...prev };
        delete newDurations[row.id];
        return newDurations;
      });
    } else {
      setSelectedRowIds((prev) => [...prev, row.id]);
      setRoleByGroupIdToAdd((prev) => {
        return {
          ...prev,
          [row.id]: [],
        };
      });
      setAccessDurationByGroupIdToAdd((prev) => {
        return {
          ...prev,
          [row.id]: ExpirationValue.Indefinite,
        };
      });
    }
  };

  const onCheckConnection = async (row: ResourceGroupRow, checked: Boolean) => {
    if (checked) {
      if (selectedRowIds.includes(row.id)) {
        return;
      }
      try {
        setSelectedRowIds((prev) => [...prev, row.id]);
        let items: GroupDropdownPreviewFragment[] | undefined;
        if (groupsByConnectionId[row.id]) {
          items = groupsByConnectionId[row.id];
        } else {
          if (row.connectionType) {
            items = await handleFetchGroups(row.id);
          }
        }
        ReactDOM.unstable_batchedUpdates(() => {
          setRoleByGroupIdToAdd((prev) => {
            const newRoles = { ...prev };
            items?.forEach((item) => {
              if (!(item.id in newRoles) && !disabledGroupIds.has(item.id)) {
                newRoles[item.id] = [];
                setSelectedRowIds((prev) => [...prev, item.id]);
                setAccessDurationByGroupIdToAdd((prev) => {
                  return {
                    ...prev,
                    [item.id]: ExpirationValue.Indefinite,
                  };
                });
              }
            });
            return newRoles;
          });
        });
      } catch (err) {
        logError(err, "Failed to fetch groups for connection");
      }
    } else {
      const items = getNestedRows(row);
      setSelectedRowIds((prev) =>
        prev.filter((id) => !items.map((row) => row.id).includes(id))
      );
      setAccessDurationByGroupIdToAdd((prev) => {
        const newDurations = { ...prev };
        items.forEach((row) => {
          if (row.id in newDurations) {
            delete newDurations[row.id];
            return newDurations;
          }
        });
        return newDurations;
      });
      setRoleByGroupIdToAdd((prev) => {
        const newRoles = { ...prev };
        items.forEach((row) => {
          if (row.id in newRoles) {
            delete newRoles[row.id];
            return newRoles;
          }
        });
        return newRoles;
      });
    }
  };

  const onRowClick = async (row: ResourceGroupRow) => {
    if (row.isEmpty || disabledGroupIds.has(row.id)) {
      return;
    }
    if (hasNestedRows(row)) {
      if (row.connectionType && !groupsByConnectionId[row.id]) {
        handleFetchGroups(row.id);
      }
    } else {
      onCheckGroup(row);
    }
  };

  const renderConnectionsList = () => {
    const rows: ResourceGroupRow[] = connections.map((connection) => {
      const row: ResourceGroupRow = {
        id: connection.id,
        icon: {
          type: "src",
          icon: getConnectionTypeInfo(connection.connectionType)?.icon,
        },
        name: connection.name,
        connectionType: connection.connectionType,
      };
      rowsById[row.id] = row;
      return row;
    });

    return (
      <Table
        columns={COLUMNS}
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        getRowCanExpand={(row) => hasNestedRows(row.original)}
        loadingRows={loading || searchGroupsLoading}
        defaultSortBy="name"
        checkedRowIds={new Set(selectedRowIds)}
        onCheckedRowsChange={onCheckedRowsChange}
        getCheckboxDisabledReason={getCheckboxDisabledReason}
        onRowClick={onRowClick}
        onExpandRow={(row) => handleFetchGroups(row.id)}
        getChildRows={getNestedRows}
        expandOnChecked={true}
        expandOnRowClick={true}
      />
    );
  };

  const renderSearchList = () => {
    if (searchGroupsError) {
      return <ModalErrorMessage errorMessage={searchGroupsError.message} />;
    }

    const filteredGroups = (searchGroupsData?.groups.groups ?? []).filter(
      (group) => !disabledGroupIds.has(group.id)
    );

    const rows: ResourceGroupRow[] = filteredGroups.map((group) => {
      const iconData: IconData = {
        type: "entity",
        entityType: group.groupType,
      };
      const row: ResourceGroupRow = {
        id: group.id,
        icon: iconData,
        name: group.name,
        groupType: group.groupType,
        connectionId: group?.connection?.id,
      };
      rowsById[row.id] = row;
      return row;
    });

    return (
      <Table
        columns={COLUMNS}
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        getRowCanExpand={(row) => hasNestedRows(row.original)}
        loadingRows={loading || searchGroupsLoading}
        defaultSortBy="name"
        checkedRowIds={new Set(selectedRowIds)}
        onCheckedRowsChange={onCheckedRowsChange}
        getCheckboxDisabledReason={getCheckboxDisabledReason}
        onRowClick={onRowClick}
        getChildRows={getNestedRows}
      />
    );
  };

  return (
    <FullscreenView
      title={
        <FullscreenViewTitle
          entityType={resource.resourceType}
          entityName={resource.name}
          targetEntityName="groups"
          action="add"
        />
      }
      onCancel={handleClose}
      onPrimaryButtonClick={handleSubmit}
      primaryButtonDisabled={numGroupsToAdd === 0}
      primaryButtonLabel={`Add ${
        numGroupsToAdd ? numGroupsToAdd : ""
      } ${pluralize("group", numGroupsToAdd)}`}
      primaryButtonLoading={addLoading}
    >
      <FullscreenView.Content fullWidth>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "md",
            })}
          >
            Select groups to add the resource to:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Search by name"
            />
          </div>
          <div className={sprinkles({ color: "gray600", fontSize: "textXs" })}>
            {debouncedSearchQuery === ""
              ? "Showing first 100 groups in each app. Use search to find more results."
              : "Showing first 100 search results. Refine your search to find more."}
          </div>
          <Divider />
          {debouncedSearchQuery === ""
            ? renderConnectionsList()
            : renderSearchList()}
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addError && (
          <Banner message={addError} type="error" marginBottom="lg" />
        )}
        <div
          className={sprinkles({
            fontSize: "textLg",
            fontWeight: "medium",
            marginBottom: "lg",
          })}
        >
          Adding {numGroupsToAdd} {pluralize("Group", numGroupsToAdd)}
        </div>
        {Object.keys(roleByGroupIdToAdd).map((groupId) => {
          const group = groupById[groupId];
          if (!group || !group.connection) {
            return null;
          }
          const existingRoles = directRolesByGroupId[groupId] || [];
          const rolesToAdd = roleByGroupIdToAdd[groupId] || [];
          const roleOptions = allRoles.filter((role) => {
            return ![...existingRoles, ...rolesToAdd].some(
              (existingRole) =>
                existingRole.accessLevelRemoteId === role.accessLevelRemoteId
            );
          });

          return (
            <div key={group.id} className={styles.resourceCard}>
              <div
                className={sprinkles({
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "sm",
                })}
              >
                <div
                  className={sprinkles({
                    flexShrink: 0,
                  })}
                >
                  <EntityIcon
                    type={group.connection.connectionType}
                    iconStyle="rounded"
                  />
                </div>
                <div className={styles.resourceInfoSection}>
                  <div className={styles.resourceCardHeader}>{group.name}</div>
                  <div className={styles.resourceCardSubtitle}>
                    {group.connection.name}
                  </div>
                  <div className={styles.resourceCardType}>
                    <EntityIcon type={group.groupType} includeBrand={false} />
                    {groupTypeInfoByType[group.groupType].name}
                  </div>
                </div>
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon
                    name="trash"
                    color="red600V3"
                    onClick={() => {
                      setRoleByGroupIdToAdd((prev) => {
                        const newRoles = { ...prev };
                        delete newRoles[group.id];
                        return newRoles;
                      });
                      setAccessDurationByGroupIdToAdd((prev) => {
                        const newAccessDurations = { ...prev };
                        delete newAccessDurations[group.id];
                        return newAccessDurations;
                      });
                      setSelectedRowIds((prev) =>
                        prev.filter(
                          (id) => id !== group.id && id !== group.connection?.id
                        )
                      );
                    }}
                  />
                </div>
              </div>
              <div className={sprinkles({ marginTop: "md" })}>
                <Select
                  key={group.id}
                  options={Object.values(ExpirationValue)}
                  value={accessDurationByGroupIdToAdd[group.id]}
                  onChange={(val) => {
                    if (val) {
                      setAccessDurationByGroupIdToAdd((prev) => {
                        return {
                          ...prev,
                          [group.id]: val,
                        };
                      });
                    }
                  }}
                  disableBuiltInFiltering
                  getOptionLabel={(expirationVal) =>
                    expirationVal === ExpirationValue.Indefinite
                      ? "Indefinite access"
                      : `Access for ${expirationVal}`
                  }
                />
              </div>
              {resourceHasRoles && (
                <div className={sprinkles({ marginTop: "md" })}>
                  <Select
                    options={roleOptions}
                    loading={loading}
                    placeholder="Select role"
                    getOptionLabel={(role) => role.accessLevelName}
                    onChange={(role) => {
                      if (role) {
                        setRoleByGroupIdToAdd((prev) => {
                          const newRoles = { ...prev };
                          if (serviceTypeHasMaxOneRole(resource?.serviceType)) {
                            newRoles[groupId] = [role];
                          } else {
                            newRoles[groupId] = [
                              ...(newRoles[groupId] || []),
                              role,
                            ];
                          }
                          return newRoles;
                        });
                      }
                    }}
                    selectOnly
                  />
                  {rolesToAdd.map((role) => {
                    return (
                      <div
                        key={role.accessLevelRemoteId}
                        className={sprinkles({
                          paddingX: "sm",
                          marginTop: "sm",
                          fontSize: "textSm",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        })}
                      >
                        {role.accessLevelName}
                        <Icon
                          name="x"
                          size="xs"
                          onClick={() => {
                            setRoleByGroupIdToAdd((prev) => {
                              const newRoles = { ...prev };
                              newRoles[groupId] = newRoles[groupId].filter(
                                (existingRole) =>
                                  existingRole.accessLevelRemoteId !==
                                  role.accessLevelRemoteId
                              );
                              return newRoles;
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

export default ResourceAddGroupsView;
