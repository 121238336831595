import {
  EntityType,
  GroupType,
  OwnersSortByField,
  SortDirection,
  useOwnersTableQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeaderV3";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import { Input, Label } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import TableHeader from "components/ui/table/TableHeader";
import moment from "moment";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { useParams } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionTo, useURLSearchParam } from "utils/router/hooks";
import { SEARCH_QUERY_URL_KEY } from "views/apps/AppsContext";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import OwnerCreateModal from "./OwnerCreateModal";
import OwnerDetailV3 from "./OwnerDetailV3";
import * as styles from "./OwnersColumnV3.css";

const CREATED_AT_COL_ID = OwnersSortByField.CreatedAt;
const NAME_COL_ID = OwnersSortByField.Name;

function isSortableField(str: string): str is OwnersSortByField {
  return Object.values<string>(OwnersSortByField).includes(str);
}

type SortValue = {
  field: OwnersSortByField;
  direction: SortDirection;
};

interface OwnerRow {
  id: string;
  [NAME_COL_ID]: string;
  numUsers: number;
  numResourcesAsAdmin: number;
  numResourcesAsReviewer: number;
  sourceGroup?: {
    name: string;
    groupType: GroupType;
  };
  [CREATED_AT_COL_ID]: string;
}

const OWNER_COLUMNS: Header<OwnerRow>[] = [
  {
    id: NAME_COL_ID,
    label: "Name",
    sortable: true,
  },
  {
    id: "numUsers",
    label: "Users",
    customCellRenderer: (row) => {
      return <div>{`${pluralize("User", row.numUsers, true)}`}</div>;
    },
    sortable: false,
  },
  {
    id: "numResourcesAsAdmin",
    label: "Assigned Resources as Admin",
    customCellRenderer: (row) => {
      return (
        <div>{`${pluralize("Resource", row.numResourcesAsAdmin, true)}`}</div>
      );
    },
    sortable: false,
  },
  {
    id: "numResourcesAsReviewer",
    label: "Assigned Resources as Reviewer",
    customCellRenderer: (row) => {
      return (
        <div>{`${pluralize(
          "Resource",
          row.numResourcesAsReviewer,
          true
        )}`}</div>
      );
    },
    sortable: false,
  },
  {
    id: "sourceGroup",
    label: "Source Group",
    customCellRenderer: (row) => {
      if (!row.sourceGroup) {
        return <div>—</div>;
      }
      return (
        <Label
          label={row.sourceGroup.name}
          iconSize="sm"
          icon={{
            type: "entity",
            entityType: row.sourceGroup.groupType,
          }}
        />
      );
    },
    sortable: false,
  },
  {
    id: CREATED_AT_COL_ID,
    label: "Created",
    customCellRenderer: (row) => {
      return <div>{moment(row[CREATED_AT_COL_ID]).fromNow()}</div>;
    },
    sortable: true,
  },
];

const OwnersColumnV3 = () => {
  const transitionTo = useTransitionTo();
  const { ownerId } = useParams<Record<string, string>>();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [searchQueryParam, setSearchQuery] = useURLSearchParam(
    SEARCH_QUERY_URL_KEY
  );
  const searchQuery = searchQueryParam || "";
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 300);
  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: OwnersSortByField.Name,
    direction: SortDirection.Asc,
  });

  const { data, previousData, error, loading, fetchMore } = useOwnersTableQuery(
    {
      fetchPolicy: "cache-and-network",
      variables: {
        input: {
          searchQuery: debouncedSearchQuery ? debouncedSearchQuery : undefined,
          sortBy: sortBy,
        },
      },
    }
  );

  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user?.isAdmin ?? false;

  const cursor = data?.owners.cursor;
  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              cursor,
              searchQuery:
                debouncedSearchQuery.length > 0
                  ? debouncedSearchQuery
                  : undefined,
              sortBy,
            },
          },
        });
      }
    : undefined;

  const owners = data?.owners.owners ?? previousData?.owners.owners ?? [];
  const totalNumOwners =
    data?.owners.totalNumOwners || previousData?.owners.totalNumOwners;

  const rows: OwnerRow[] = owners.map((owner) => {
    let numResourcesAsAdmin = 0;
    let numResourcesAsReviewer = 0;
    owner.ownedResources.forEach((resource) => {
      if (resource.isAdmin) {
        numResourcesAsAdmin++;
      }
      if (resource.isReviewer) {
        numResourcesAsReviewer++;
      }
    });
    owner.ownedGroups.forEach((group) => {
      if (group.isAdmin) {
        numResourcesAsAdmin++;
      }
      if (group.isReviewer) {
        numResourcesAsReviewer++;
      }
    });

    return {
      id: owner.id,
      [NAME_COL_ID]: owner.name,
      numUsers: owner.ownerUsers.length,
      numResourcesAsAdmin,
      numResourcesAsReviewer,
      sourceGroup: owner.sourceGroup
        ? {
            name: owner.sourceGroup.name,
            groupType: owner.sourceGroup.groupType,
          }
        : undefined,
      [CREATED_AT_COL_ID]: owner.createdAt,
    };
  });

  if (error) {
    logError(error, "failed to list owners");
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  if (ownerId) {
    return <OwnerDetailV3 />;
  }

  return (
    <>
      <Column isContent maxWidth="none">
        <ColumnHeader
          icon={{ type: "name", icon: "user-square" }}
          title="Owners"
          includeDefaultActions
        />

        <TableFilters>
          <TableFilters.Left>
            <div className={styles.searchInput}>
              <Input
                leftIconName="search"
                type="search"
                style="search"
                value={searchQuery}
                onChange={(query) => {
                  query.length > 0
                    ? setSearchQuery(query)
                    : setSearchQuery(null);
                }}
                placeholder="Filter by name"
              />
            </div>
          </TableFilters.Left>
        </TableFilters>

        <TableHeader
          entityType={EntityType.Owner}
          totalNumRows={totalNumOwners ?? rows.length}
          loading={loading}
          defaultRightActions={
            isAdmin
              ? [
                  {
                    label: "Owner",
                    type: "main",
                    iconName: "plus",
                    onClick: () => {
                      setShowCreateModal(true);
                    },
                  },
                ]
              : undefined
          }
        />
        <>
          {loading && !data && !previousData ? (
            <ColumnListItemsSkeleton />
          ) : (
            <Table
              rows={rows}
              totalNumRows={cursor ? totalNumOwners || 0 : rows.length}
              emptyState={{ title: "No owners to display." }}
              getRowId={(ru) => ru.id}
              columns={OWNER_COLUMNS}
              onRowClick={(row, event) => {
                transitionTo(
                  {
                    pathname: getResourceUrlNew({
                      entityId: row.id,
                      entityType: EntityType.Owner,
                    }),
                  },
                  event
                );
              }}
              onLoadMoreRows={loadMoreRows}
              manualSortDirection={
                sortBy && {
                  sortBy: sortBy.field,
                  sortDirection: sortBy.direction,
                }
              }
              handleManualSort={(sortBy, sortDirection) => {
                if (!sortDirection) {
                  setSortBy(undefined);
                  return;
                }
                if (!isSortableField(sortBy)) {
                  return;
                }
                const direction: SortDirection =
                  sortDirection === "DESC"
                    ? SortDirection.Desc
                    : SortDirection.Asc;

                setSortBy({
                  field: sortBy,
                  direction,
                });
              }}
            />
          )}
        </>
      </Column>
      {showCreateModal ? (
        <OwnerCreateModal onClose={() => setShowCreateModal(false)} />
      ) : null}
    </>
  );
};

export default OwnersColumnV3;
