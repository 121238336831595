import { openInNewTab } from "api/common/common";
import {
  EntityType,
  GeneralSettingType,
  UserOverviewFragment,
  UserTagFragment,
  useUpdateUserMutation,
  useUserDetailQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import { Banner, Button, Divider, Tabs } from "components/ui";
import { IconName } from "components/ui/icon/Icon";
import { makeURLForEntityViz } from "components/viz/contexts/FilterContext";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { NotFoundPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import AuthContext from "../../components/auth/AuthContext";
import ColumnContent from "../../components/column/ColumnContent";
import { FormMode, UserConfig } from "../../components/forms/common";
import { UsersConfigForm } from "../../components/forms/UsersConfigForm";
import { makeConfigForUser } from "../../components/forms/utils";
import { useToast } from "../../components/toast/Toast";
import { AppsContext } from "../apps/AppsContext";
import BulkImportColumnV2 from "../apps/BulkImportColumnV2";
import OrgContext from "../settings/OrgContext";
import UserDeleteModal from "./UserDeleteModal";
import UserResetMFAModal from "./UserResetMFAModal";
import { getUserAvatarIcon } from "./utils";
import DirectReportsRow from "./viewer/rows/UserDirectReportsRow";
import UserEventsRow from "./viewer/rows/UserEventsRow";
import UserGroupsRow from "./viewer/rows/UserGroupsRow";
import UserResourcesRow from "./viewer/rows/UserResourcesRow";

interface UserView {
  key: string;
  title: string;
  iconName: IconName;
  content: JSX.Element;
  count?: number;
}

const UserDetailColumn = () => {
  const history = useHistory();
  const location = useLocation();
  const { userId } = useParams<Record<string, string>>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showResetMFAModal, setShowResetMFAModal] = useState(false);
  const [formMode, setFormMode] = useState<FormMode>("view");
  const [config, setConfig] = useState<Partial<UserConfig>>({});
  const [initialConfig, setInitialConfig] = useState<Partial<UserConfig>>({});
  const { orgState } = useContext(OrgContext);
  const { authState } = useContext(AuthContext);
  const { displaySuccessToast } = useToast();
  const [errors, setErrors] = useState<string[]>([]);

  const hasResetUserMFA = useFeatureFlag(FeatureFlag.OpalMFAAllowUserMFAReset);
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);

  const { selectedUnmanagedItems } = useContext(AppsContext);

  const selectedView = location.hash.slice(1) || "overview";
  const canEditManager =
    (authState.user?.isAdmin && !orgState.isIdpEnabled) ?? false;

  const { data, error, loading } = useUserDetailQuery({
    variables: {
      id: userId!,
    },
    skip: !userId,
  });

  let user: UserOverviewFragment | null = null;
  let userAttributes: UserTagFragment[] | null = null;
  if (data) {
    switch (data.user.__typename) {
      case "UserResult":
        user = data.user.user;
        break;
      case "UserNotFoundError":
        break;
      default:
        logError(new Error(`failed to list users`));
    }
    switch (data.userAttributes.__typename) {
      case "UserTagsResult":
        userAttributes = data.userAttributes.userTags;
        break;
      default:
        logError(new Error(`failed to list users`));
    }
  } else if (error) {
    logError(error, `failed to fetch user detail ${userId}`);
  }

  const [update, { loading: updateLoading }] = useUpdateUserMutation();

  useEffect(() => {
    if (user) {
      const _config = makeConfigForUser(user, userAttributes, canEditManager);
      setConfig(_config);
      setInitialConfig(_config);
    }
  }, [userId, user, userAttributes, canEditManager]);

  if (loading) {
    return (
      <>
        <Column isContent maxWidth={hasV3Nav ? "none" : "lg"}>
          <ColumnContentSkeleton />
        </Column>
      </>
    );
  }

  if (!user) {
    return (
      <Column isContent>
        <NotFoundPage />
      </Column>
    );
  }

  if (selectedUnmanagedItems.length === 1) {
    return <BulkImportColumnV2 />;
  }

  const handleSave = async () => {
    const errors = [];
    if (!canEditManager && !_.isEqual(config.manager, initialConfig.manager)) {
      errors.push(
        "The manager field is imported from your IDP/HRIS and can't be edited from Opal."
      );
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    try {
      setErrors([]);
      const { data } = await update({
        variables: {
          input: {
            id: userId,
            // don't attempt to update the manager if it hasn't changed
            managerId: _.isEqual(config.manager?.id, initialConfig.manager?.id)
              ? null
              : { userId: config.manager?.id },
            position: config.position,
          },
        },
        refetchQueries: ["UserDetail"],
      });
      switch (data?.updateUser.__typename) {
        case "UpdateUserResult":
          displaySuccessToast("User updated");
          setFormMode("view");
          break;
        case "UserNotFoundError":
        case "SystemUserIsImmutableError":
        case "ManagedHRDataIsImmutableError":
          setErrors([data.updateUser.message]);
          break;
        default:
          logError(new Error(`failed to to update user title`));
          setErrors(["Error: failed to update user title"]);
      }
    } catch (e) {
      setErrors(["Failed to save user"]);
      logError(e, "failed to update owner");
    }
  };

  const userViews: UserView[] = [
    {
      key: "overview",
      title: "Overview",
      iconName: "list",
      content: (
        <UsersConfigForm mode={formMode} config={config} onChange={setConfig} />
      ),
    },
    {
      key: "resources",
      title: "Resources",
      iconName: "cube",
      count: user.numResources,
      content: <UserResourcesRow userId={user.id} />,
    },
    {
      key: "groups",
      title: "Groups",
      iconName: "users",
      count: user.numGroups,
      content: <UserGroupsRow userId={user.id} />,
    },
    {
      key: "events",
      title: "Events",
      iconName: "events",
      content: <UserEventsRow userId={user.id} />,
    },
  ];

  if (user.directReports.length) {
    userViews.push({
      key: "direct-reports",
      title: "Direct Reports",
      iconName: "users",
      count: user.directReports.length,
      content: <DirectReportsRow user={user} />,
    });
  }

  const selectedViewInfo = userViews.find((view) => view.key === selectedView);
  const content = selectedViewInfo?.content;

  const overviewButtons = (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      {formMode === "edit" ? (
        <>
          <Button
            label="Cancel"
            onClick={() => {
              setFormMode("view");
              setErrors([]);
              if (initialConfig) {
                setConfig(initialConfig);
              }
            }}
            size="md"
            borderless
          />
          <Button
            label={updateLoading ? "Saving..." : "Save"}
            leftIconName="check"
            type="primary"
            size="md"
            onClick={handleSave}
            disabled={updateLoading || _.isEqual(config, initialConfig)}
          />
        </>
      ) : (
        <Button
          label="Edit"
          onClick={() => setFormMode("edit")}
          leftIconName="edit-2"
          size="md"
          borderless
        />
      )}
    </div>
  );

  const tabInfos = userViews.map((view) => ({
    title: view.title,
    onClick: () => history.push({ hash: view.key }),
    isSelected: selectedView === view.key,
    badgeCount: view.count,
  }));

  let menuOptions: PropsFor<typeof ColumnHeader>["menuOptions"] = [
    {
      label: "Remove from Opal",
      onClick: () => setShowDeleteModal(true),
      type: "danger",
      icon: { type: "name", icon: "trash" },
    },
  ];

  const orgUsesAuth0MFA =
    // RequireOpalMfaForLogins indicates that an org uses Auth0 for logins.
    orgState.orgSettings?.generalSettings.some(
      (setting) => setting === GeneralSettingType.RequireOpalMfaForLogins
    ) ||
    // The presence of either of these settings indicates that an org does not
    // use Auth0 MFA for Opal actions.
    !orgState.orgSettings?.generalSettings.some(
      (setting) =>
        setting === GeneralSettingType.UseOktaMfaForGatingOpalActions ||
        setting === GeneralSettingType.UseOidcMfaForGatingOpalActions
    ) ||
    false;

  if (orgUsesAuth0MFA && hasResetUserMFA) {
    menuOptions.push({
      label: "Reset MFA for User",
      onClick: () => setShowResetMFAModal(true),
      type: "warning",
      icon: { type: "name", icon: "lock" },
    });
  }
  return (
    <>
      {content ? (
        <Column isContent maxWidth={hasV3Nav ? "none" : "lg"}>
          <ColumnHeader
            breadcrumbs={
              hasV3Nav ? [{ name: "Users", to: "/users" }] : undefined
            }
            subtitle={selectedViewInfo.title}
            title={user.fullName}
            icon={getUserAvatarIcon(user)}
            rightActions={
              selectedViewInfo.key === "overview" ? overviewButtons : undefined
            }
            menuOptions={menuOptions}
            onClickInsights={() => {
              const hash = makeURLForEntityViz(userId, EntityType.User);
              openInNewTab("/insights" + hash);
            }}
          />
          <Divider margin="md" />
          {formMode !== "edit" && (
            <div
              className={sprinkles({
                display: "flex",
                justifyContent: "center",
              })}
            >
              <Tabs tabInfos={tabInfos} round />
            </div>
          )}
          {errors.map((error) => (
            <Banner message={error} type="error" />
          ))}
          <ColumnContent>{content}</ColumnContent>
        </Column>
      ) : null}
      {showDeleteModal ? (
        <UserDeleteModal
          user={user}
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
        />
      ) : null}
      {showResetMFAModal ? (
        <UserResetMFAModal
          user={user}
          showModal={showResetMFAModal}
          setShowModal={setShowResetMFAModal}
        />
      ) : null}
    </>
  );
};

export default UserDetailColumn;
