import * as Apollo from "@apollo/client";
import {
  AccessOption,
  EntityType,
  GroupsHomeQuery,
  GroupsHomeQueryVariables,
  OwnersListQuery,
  OwnersListQueryVariables,
  PaginatedAppDropdownQuery,
  ResourcePreviewTinyFragment,
  ResourceType,
  TagsQuery,
  TagsQueryVariables,
  useGroupsHomeQuery,
  useOwnersListQuery,
  usePaginatedAppDropdownQuery,
  useResourceAncestorsQuery,
  UsersQuery,
  UsersQueryVariables,
  useTagsQuery,
  useUsersQuery,
} from "api/generated/graphql";
import {
  EffectCallback,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { getResourceUrlNew } from "./common";
import { logError } from "./logging";

export function usePageTitle(title?: string) {
  useEffect(() => {
    const prevTitle = document.title;
    if (title && title !== "") {
      document.title = title + " • Opal";
    }
    return () => {
      document.title = prevTitle;
    };
  }, [title]);
}

export const useMountEffect = (effect: EffectCallback) => {
  // Here we execute a callback only on mount, i.e. once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};

type AllUsersQueryHookState = {
  data: UsersQuery | undefined;
  error: Apollo.ApolloError | undefined;
  loading: boolean;
};

/**
 * `useAllUsersQuery` repeatedly fetches our paginated users endpoint in chunks
 * until it hydrates all users in the org. **This is very expensive for larger
 * orgs -- do not use this hook unless you absolutely need to.** Alternatively,
 * consider adding a paginated users endpoint.
 */
export const useAllUsersQuery = (
  baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>
) => {
  const [
    allUsersQueryHookState,
    setAllUsersQueryHookState,
  ] = useState<AllUsersQueryHookState>({
    data: undefined,
    error: undefined,
    loading: true,
  });

  const { data, error, loading, fetchMore } = useUsersQuery({
    ...baseOptions,
    variables: {
      input: {
        ...baseOptions?.variables?.input,
        maxNumEntries: 1000,
      },
    },
  });
  useEffect(() => {
    const cursor = data?.users.cursor;
    if (error || !cursor) {
      setAllUsersQueryHookState({
        data: data,
        error: error,
        loading: loading,
      });
      return;
    }
    fetchMore({
      ...baseOptions,
      variables: {
        input: {
          ...baseOptions?.variables?.input,
          maxNumEntries: 1000,
          cursor: cursor,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, loading, fetchMore]);

  return allUsersQueryHookState;
};

type AllGroupsQueryHookState = {
  data: GroupsHomeQuery | undefined;
  error: Apollo.ApolloError | undefined;
  loading: boolean;
};

export const useAllGroupsQuery = (
  baseOptions?: Apollo.QueryHookOptions<
    GroupsHomeQuery,
    GroupsHomeQueryVariables
  >
) => {
  const [
    allGroupsQueryHookState,
    setAllGroupsQueryHookState,
  ] = useState<AllGroupsQueryHookState>({
    data: undefined,
    error: undefined,
    loading: true,
  });
  const { data, error, loading, fetchMore } = useGroupsHomeQuery({
    ...baseOptions,
    variables: {
      input: {
        ...baseOptions?.variables?.input,
        maxNumEntries: 1000,
      },
    },
  });
  useEffect(() => {
    const cursor = data?.groups.cursor;
    if (error || !cursor) {
      setAllGroupsQueryHookState({
        data: data,
        error: error,
        loading: loading,
      });
      return;
    }
    fetchMore({
      ...baseOptions,
      variables: {
        input: {
          ...baseOptions?.variables?.input,
          maxNumEntries: 1000,
          cursor: cursor,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, loading, fetchMore]);

  return allGroupsQueryHookState;
};

type AllTagsQueryHookState = {
  data: TagsQuery | undefined;
  error: Apollo.ApolloError | undefined;
  loading: boolean;
};

export const useAutoPaginatingTagsQuery = (
  baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>
) => {
  const [
    allTagsQueryHookState,
    setAllTagsQueryHookState,
  ] = useState<AllTagsQueryHookState>({
    data: undefined,
    error: undefined,
    loading: true,
  });
  const { data, error, loading, fetchMore } = useTagsQuery({
    ...baseOptions,
    variables: {
      input: {
        ...baseOptions?.variables?.input,
        limit: 1000,
      },
    },
  });
  useEffect(() => {
    const cursor = data?.tags.cursor;
    if (error || !cursor) {
      setAllTagsQueryHookState({
        data: data,
        error: error,
        loading: loading,
      });
      return;
    }
    fetchMore({
      ...baseOptions,
      variables: {
        input: {
          ...baseOptions?.variables?.input,
          limit: 1000,
          cursor: cursor,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, loading, fetchMore]);

  return allTagsQueryHookState;
};

type AllOwnersQueryHookState = {
  data: OwnersListQuery | undefined;
  error: Apollo.ApolloError | undefined;
  loading: boolean;
};

/**
 * `useAllOwnersQuery` repeatedly fetches our paginated owners endpoint in chunks
 * until it hydrates all owners in the org.
 *
 * This operation potentially requires many page fetches, so be sure you need to populate
 * all owners before using.
 */
export const useAllOwnersQuery = (
  baseOptions?: Apollo.QueryHookOptions<
    OwnersListQuery,
    OwnersListQueryVariables
  >
) => {
  const [
    allOwnersQueryHookState,
    setAllOwnersQueryHookState,
  ] = useState<AllOwnersQueryHookState>({
    data: undefined,
    error: undefined,
    loading: true,
  });

  const { data, error, loading, fetchMore } = useOwnersListQuery({
    ...baseOptions,
    variables: {
      input: {
        ...baseOptions?.variables?.input,
        maxNumEntries: 1000,
      },
    },
  });

  useEffect(() => {
    const cursor = data?.owners.cursor;
    if (error || !cursor) {
      setAllOwnersQueryHookState({
        data: data,
        error: error,
        loading: loading,
      });
      return;
    }

    fetchMore({
      ...baseOptions,
      variables: {
        input: {
          ...baseOptions?.variables?.input,
          maxNumEntries: 1000,
          cursor: cursor,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, loading, fetchMore]);

  return allOwnersQueryHookState;
};

type UseAllAppsQueryHookState = {
  data: PaginatedAppDropdownQuery | undefined;
  error: Apollo.ApolloError | undefined;
  loading: boolean;
};
/**
 * `useAllOwnersQuery` repeatedly fetches our paginated apps endpoint in chunks
 * until it hydrates all owners in the org.
 *
 * This operation potentially requires many page fetches, so be sure you need to populate
 * all owners before using.
 */
export const useAllAppsQuery = (baseOptions?: { searchQuery?: string }) => {
  const [
    allAppsQueryHookState,
    setAllAppsQueryHookState,
  ] = useState<UseAllAppsQueryHookState>({
    data: undefined,
    error: undefined,
    loading: true,
  });

  const { data, error, loading, fetchMore } = usePaginatedAppDropdownQuery({
    ...baseOptions,
    variables: {
      access: AccessOption.All,
      searchQuery: baseOptions?.searchQuery || undefined,
      limit: 10000,
    },
  });

  useEffect(() => {
    const cursor = data?.apps.cursor;
    if (error || !cursor) {
      setAllAppsQueryHookState({
        data: data,
        error: error,
        loading: false,
      });
      return;
    }

    fetchMore({
      ...baseOptions,
      variables: {
        access: AccessOption.All,
        searchQuery: baseOptions?.searchQuery || undefined,
        limit: 10000,
        cursor: cursor,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, loading, fetchMore]);
  return allAppsQueryHookState;
};

/**
 * Debounces a fast-changing value.
 *
 * See: https://css-tricks.com/debouncing-throttling-explained-examples/
 *
 * @param value Any fast-changing value.
 * @param delayMs Duration `value` needs to hold without changing for debounced value to be updated.
 */
export const useDebouncedValue = <T,>(value: T, delayMs?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delayMs || 250);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delayMs]);

  return debouncedValue;
};

/**
 * See: https://usehooks.com/usePrevious/
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

/**
 * See: https://usehooks.com/useLocalStorage/
 */
export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, (value: T) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that persists the new value to localStorage.
  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // Ignore the error
    }
  };
  return [storedValue, setValue];
}

/**
 * Use in a component to pop a warning confirmation modal on before page leave/reload.
 * Useful to prevent users from accidentally losing changes when editing.
 *
 * @param shouldShow Whether or not should show confirm modal.
 * (e.g. pass in false if form has no changes, don't need to warn)
 */
export function useWarnBeforeUnload(shouldShow: boolean) {
  const warnUserRefresh = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    if (shouldShow) {
      window.addEventListener("beforeunload", warnUserRefresh);
    } else {
      window.removeEventListener("beforeunload", warnUserRefresh);
    }

    return () => {
      window.removeEventListener("beforeunload", warnUserRefresh);
    };
  }, [shouldShow]);
}

export type BreadcrumbEntityKey = {
  id?: string;
  name?: string;
  resourceType?: ResourceType;
  connection?: {
    id?: string;
    name?: string;
  } | null;
};

/**
 * @param entity base entity to get breadcrumbs for, if null, returns empty array
 * @returns array of breadcrumbs for the entity
 */
export const useGetResourceBreadcrumbs = (
  entity?: BreadcrumbEntityKey | null,
  includeBase: boolean = true
) => {
  const { data: ancestorsData, error, loading } = useResourceAncestorsQuery({
    variables: {
      id: entity?.id,
    },
    skip: !entity?.id,
  });

  const ancestors: Array<ResourcePreviewTinyFragment> = [];

  if (!entity) {
    return { data: [], error, loading };
  }

  switch (ancestorsData?.resourceAncestors?.__typename) {
    case "ResourcesResult": {
      const rootAncestorKey = "";
      const childResourceByParentId = new Map(
        ancestorsData.resourceAncestors.resources.map((ancestor) => [
          ancestor.parentResource?.id ?? rootAncestorKey,
          ancestor,
        ])
      );
      // The results are ordered by name + id so we need to put them in order from root to leaf
      let ancestor = childResourceByParentId.get(rootAncestorKey);
      while (ancestor != null) {
        ancestors.push(ancestor);
        ancestor = childResourceByParentId.get(ancestor.id);
      }
      break;
    }
  }

  if (error) {
    logError(new Error(`failed to get resource ancestors`));
  }

  const breadcrumbs = [];
  if (includeBase) {
    breadcrumbs.push({ name: "Catalog", to: "/apps" });
    breadcrumbs.push({ name: "Apps", to: "/apps" });
  }

  const connection = entity.connection;
  const isOktaApp = entity.resourceType === ResourceType.OktaApp;

  if (!isOktaApp && connection && connection.id && connection.name) {
    breadcrumbs.push({
      name: connection.name,
      to: `/apps/${connection.id}`,
    });
  }

  if (!loading && !error && ancestors.length > 0) {
    ancestors.forEach((ancestor) => {
      breadcrumbs.push({
        name: ancestor.name,
        to: `/resources/${ancestor.id}`,
      });
    });
  }

  if (entity.id && entity.name) {
    breadcrumbs.push({
      name: entity.name,
      to: getResourceUrlNew(
        {
          entityId: entity.id,
          entityType: EntityType.Resource,
        },
        EntityType.Resource
      ),
    });
  }

  return { data: breadcrumbs, error, loading };
};

// Make any component scroll by dragging in any direction
// https://phuoc.ng/collection/react-drag-drop/scroll-by-dragging/
export const useDragScroll = () => {
  const [node, setNode] = useState<HTMLElement>();

  const ref = useCallback((nodeEle) => {
    setNode(nodeEle);
  }, []);

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      if (!node) {
        return;
      }
      const startPos = {
        left: node.scrollLeft,
        top: node.scrollTop,
        x: e.clientX,
        y: e.clientY,
      };

      const handleMouseMove = (e: MouseEvent) => {
        const dx = e.clientX - startPos.x;
        const dy = e.clientY - startPos.y;
        node.scrollTop = startPos.top - dy;
        node.scrollLeft = startPos.left - dx;
        updateCursor(node);
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        resetCursor(node);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [node]
  );

  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      if (!node) {
        return;
      }
      const touch = e.touches[0];
      const startPos = {
        left: node.scrollLeft,
        top: node.scrollTop,
        x: touch.clientX,
        y: touch.clientY,
      };

      const handleTouchMove = (e: TouchEvent) => {
        const touch = e.touches[0];
        const dx = touch.clientX - startPos.x;
        const dy = touch.clientY - startPos.y;
        node.scrollTop = startPos.top - dy;
        node.scrollLeft = startPos.left - dx;
        updateCursor(node);
      };

      const handleTouchEnd = () => {
        document.removeEventListener("touchmove", handleTouchMove);
        document.removeEventListener("touchend", handleTouchEnd);
        resetCursor(node);
      };

      document.addEventListener("touchmove", handleTouchMove);
      document.addEventListener("touchend", handleTouchEnd);
    },
    [node]
  );

  const updateCursor = (ele: HTMLElement) => {
    ele.style.cursor = "grabbing";
    ele.style.userSelect = "none";
  };

  const resetCursor = (ele: HTMLElement) => {
    ele.style.cursor = "grab";
    ele.style.removeProperty("user-select");
  };

  useEffect(() => {
    if (!node) {
      return;
    }
    node.addEventListener("mousedown", handleMouseDown);
    node.addEventListener("touchstart", handleTouchStart);
    return () => {
      node.removeEventListener("mousedown", handleMouseDown);
      node.removeEventListener("touchstart", handleTouchStart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node]);

  return [ref];
};
