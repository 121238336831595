import { AuthContextState } from "components/auth/AuthContext";
import { LDContext } from "launchdarkly-js-sdk-common";
import {
  asyncWithLDProvider,
  useLDClient,
} from "launchdarkly-react-client-sdk";
import moment from "moment";
import { usePrevious } from "utils/hooks";
import { AppContextState } from "views/app/AppContext";

export enum FeatureFlag {
  ActiveDirectory = "active-directory-create-app",
  Vault = "vault-create-app",
  QueryOktaRoleAssignmentsCache = "query-okta-role-assignments-cache",
  DemoDashboard = "dashboard",
  VisualizationUsageFilter = "visualization-usage-filter",
  DatadogRumTracing = "datadog-rum-tracing",
  VisualizationGroupBasedVsBirthrightText = "visualization-group-based-vs-birthright-text",
  ServiceNowIntegration = "service-now-integration",
  Bundles = "bundles",
  AWSLegacy = "aws-legacy",
  ShowDeleteOrganization = "show-org-delete",
  RevocationRemediation = "kraken-revocation-remediation",
  OidcMFAForOpalActions = "oidc-mfa-for-opal-actions",
  ConfigurationTemplates = "configuration-templates",
  ConnectionWebhookToggle = "connection-webhook-toggle",
  OpalRequesterRole = "enable-opal-requester-role",
  OpalMFAAllowUserMFAReset = "opal-mfa-allow-user-mfa-reset",
  ShowSyncErrorStackTrace = "show-sync-error-stack-trace",
  ShowItemUserUploader = "show-item-user-uploader",
  ConnectionAutoImportConfigTemplate = "connection-auto-import-config-template",
  MetricsDashboard = "metrics-dashboard",
  AppLevelVisibility = "app-level-visibility",
  UseParentConfig = "explicit-config-template-inheritance",
  ResourceHierarchyUI = "resource-hierarchy-ui",
  SetupWizard = "setup-wizard",
  GroupBindings = "group-bindings",
  V3Nav = "v-3-nav",
  ReasonOptional = "reason-optional",
  LPPMShowHomepage = "recommendations",
  AzureEDS = "azure-eds",
  ResourceTicketPropagation = "resource-ticket-propagation",
  GcpEDS = "gcp-eds",
  AwsEds = "aws-eds",
  GroupProjects = "group-projects",
  EnableSnowflakeConnectionType = "enable-snowflake-connection-type",
  EnableWorkdayConnectionType = "enable-workday-connection-type",
  DisableDatabaseAppSetup = "disable-database-app-setup",
  EndUserExperience = "end-user-ux",
  NonHumanIdentities = "non-human-identities",
  ShowJITGraph = "show-jit-graph",
  RiskSensitivity = "risk-sensitivity",
  ServiceNowAccessAndPropagationTickets = "service-now-access-and-propagation-tickets",
  EventStreaming = "event-streaming",
  CustomRequestNotifications = "custom-request-notifications",
  OktaGroupAlias = "okta-group-alias",
}

export const LOCAL_STORAGE_FF_OVERRIDES_KEY = "ff-overrides";
export const addFFOverride = (key: FeatureFlag, value: boolean) => {
  const overridesRaw = localStorage.getItem(LOCAL_STORAGE_FF_OVERRIDES_KEY);
  let overrides: Record<string, string> = {};
  if (overridesRaw) {
    try {
      overrides = JSON.parse(overridesRaw);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error parsing overrides: ${overridesRaw}`, error);
    }
  }
  overrides[key] = value.toString();
  localStorage.setItem(
    LOCAL_STORAGE_FF_OVERRIDES_KEY,
    JSON.stringify(overrides)
  );
};

export const useFeatureFlagNoCast = (key: FeatureFlag): boolean | undefined => {
  const client = useLDClient();

  if (!client) {
    return false;
  }

  // Parse any override flags
  const overridesRaw = localStorage.getItem(LOCAL_STORAGE_FF_OVERRIDES_KEY);
  let overrides: Record<string, string> = {};
  if (overridesRaw) {
    try {
      overrides = JSON.parse(overridesRaw);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error parsing overrides: ${overridesRaw}`, error);
    }
  }
  switch (overrides[key]) {
    case "true":
      return true;
    case "false":
      return false;
  }
  return client.variation(key);
};

// Example usage:
// const hasNewFeature = useFeatureFlag(FeatureFlag.AWSOrganization);
export const useFeatureFlag = (key: FeatureFlag): boolean => {
  const client = useLDClient();
  if (!client) return false;

  // Parse any override flags
  const overridesRaw = localStorage.getItem(LOCAL_STORAGE_FF_OVERRIDES_KEY);
  let overrides: Record<string, string> = {};
  if (overridesRaw) {
    try {
      overrides = JSON.parse(overridesRaw);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error parsing overrides: ${overridesRaw}`, error);
    }
  }
  switch (overrides[key]) {
    case "true":
      return true;
    case "false":
      return false;
  }

  return Boolean(client.variation(key));
};

export const useIdentify = (
  authState: AuthContextState,
  appState: AppContextState
) => {
  const user = authState.user?.user;
  const impersonatorUserID = authState.impersonatingUser?.id || "";
  const prevUser = usePrevious(user);

  const client = useLDClient();
  if (!client) return;

  if (!user || user.id === prevUser?.id) return;

  // LaunchDarkly supports comparison by semantic versions like X.Y.Z, but does not understand our commit suffix.
  const numericalVersion = appState.version.split("-")[0];

  // We want to express the orgCreatedAt as a Unix timestamp for easier comparison
  const orgCreatedAt = user.organization.createdAt
    ? moment(user.organization.createdAt).unix()
    : undefined;

  const ldUser: LDContext = {
    kind: "user",
    key: user.email,
    name: user.fullName,
    email: user.email,

    // Custom attributes
    // TODO: Remove orgId in favor of orgID, which is the same value we push from the backend
    orgId: user.organizationId,
    orgID: user.organizationId,
    orgName: user.organization.name,
    orgCreatedAt,
    impersonatorUserID,
    environment: appState.environment,
    version: numericalVersion,
  };
  client.identify(ldUser);
};

export const getLDProvider = async () => {
  return asyncWithLDProvider({
    clientSideID: "63080de37d1fb611345d75ee",
    context: {
      kind: "user",
      user: {
        key: "anonymous",
        anonymous: true,
      },
    },
    options: {
      bootstrap: "localStorage",
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });
};
