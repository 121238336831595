import { ReactComponent as Account } from "./pro/account.svg";
import { ReactComponent as Add } from "./pro/add.svg";
import { ReactComponent as AlarmClockOff } from "./pro/alarm-clock-off.svg";
import { ReactComponent as AlarmClockPlus } from "./pro/alarm-clock-plus.svg";
import { ReactComponent as AlarmClock } from "./pro/alarm-clock.svg";
import { ReactComponent as AlertTriangle } from "./pro/alert-triangle.svg";
import { ReactComponent as Amazon } from "./pro/amazon.svg";
import { ReactComponent as Apps } from "./pro/apps.svg";
import { ReactComponent as ArrowDown } from "./pro/arrow-down.svg";
import { ReactComponent as ArrowLeft } from "./pro/arrow-left.svg";
import { ReactComponent as ArrowRight } from "./pro/arrow-right.svg";
import { ReactComponent as ArrowSquareDown } from "./pro/arrow-square-down.svg";
import { ReactComponent as ArrowSwitch3 } from "./pro/arrow-switch-3.svg";
import { ReactComponent as ArrowUp } from "./pro/arrow-up.svg";
import { ReactComponent as Asterisk } from "./pro/asterisk.svg";
import { ReactComponent as AtSign } from "./pro/at-sign.svg";
import { ReactComponent as BellNotification } from "./pro/bell-notification.svg";
import { ReactComponent as BellOff } from "./pro/bell-off.svg";
import { ReactComponent as BellRinging } from "./pro/bell-ringing.svg";
import { ReactComponent as Bell } from "./pro/bell.svg";
import { ReactComponent as Bookmark } from "./pro/bookmark.svg";
import { ReactComponent as Bucket } from "./pro/bucket.svg";
import { ReactComponent as Buildings } from "./pro/buildings.svg";
import { ReactComponent as Bundle } from "./pro/bundle.svg";
import { ReactComponent as CalendarCheck } from "./pro/calendar-check.svg";
import { ReactComponent as CalendarPlus } from "./pro/calendar-plus.svg";
import { ReactComponent as Calendar } from "./pro/calendar.svg";
import { ReactComponent as CheckCircle } from "./pro/check-circle.svg";
import { ReactComponent as CheckList } from "./pro/check-list.svg";
import { ReactComponent as Check } from "./pro/check.svg";
import { ReactComponent as ChevronDown } from "./pro/chevron-down.svg";
import { ReactComponent as ChevronLeft } from "./pro/chevron-left.svg";
import { ReactComponent as ChevronRight } from "./pro/chevron-right.svg";
import { ReactComponent as ChevronUp } from "./pro/chevron-up.svg";
import { ReactComponent as ClockPlus } from "./pro/clock-plus.svg";
import { ReactComponent as Clock } from "./pro/clock.svg";
import { ReactComponent as CloudBlank } from "./pro/cloud-blank.svg";
import { ReactComponent as Code } from "./pro/code.svg";
import { ReactComponent as Compare } from "./pro/compare.svg";
import { ReactComponent as Copy } from "./pro/copy.svg";
import { ReactComponent as CubeOutline } from "./pro/cube-outline.svg";
import { ReactComponent as Cube } from "./pro/cube.svg";
import { ReactComponent as Data } from "./pro/data.svg";
import { ReactComponent as Database2 } from "./pro/database-2.svg";
import { ReactComponent as Database } from "./pro/database.svg";
import { ReactComponent as Dataflow2 } from "./pro/dataflow-2.svg";
import { ReactComponent as Dataflow } from "./pro/dataflow.svg";
import { ReactComponent as Department } from "./pro/department.svg";
import { ReactComponent as Disconnect } from "./pro/disconnect.svg";
import { ReactComponent as DotsGrid2 } from "./pro/dots-grid-2.svg";
import { ReactComponent as DotsGrid } from "./pro/dots-grid.svg";
import { ReactComponent as DotsHorizontal } from "./pro/dots-horizontal.svg";
import { ReactComponent as Download } from "./pro/download.svg";
import { ReactComponent as Edit } from "./pro/edit.svg";
import { ReactComponent as Events } from "./pro/events.svg";
import { ReactComponent as EyeOff } from "./pro/eye-off.svg";
import { ReactComponent as Eye } from "./pro/eye.svg";
import { ReactComponent as FileCode } from "./pro/file-code.svg";
import { ReactComponent as FilterFunnel } from "./pro/filter-funnel.svg";
import { ReactComponent as Filter } from "./pro/filter.svg";
import { ReactComponent as FolderCode } from "./pro/folder-code.svg";
import { ReactComponent as Folder } from "./pro/folder.svg";
import { ReactComponent as GcpProject } from "./pro/gcp-project.svg";
import { ReactComponent as GitMerge } from "./pro/git-merge.svg";
import { ReactComponent as GitlabProject } from "./pro/gitlab-project.svg";
import { ReactComponent as Google } from "./pro/google.svg";
import { ReactComponent as HardDrive } from "./pro/hard-drive.svg";
import { ReactComponent as HelpCircle } from "./pro/help-circle.svg";
import { ReactComponent as Home } from "./pro/home.svg";
import { ReactComponent as Hourglass } from "./pro/hourglass.svg";
import { ReactComponent as Inbox } from "./pro/inbox.svg";
import { ReactComponent as Incomplete } from "./pro/incomplete.svg";
import { ReactComponent as Infinity } from "./pro/infinity.svg";
import { ReactComponent as InfoCircle } from "./pro/info-circle.svg";
import { ReactComponent as Insights2 } from "./pro/insights-2.svg";
import { ReactComponent as Insights } from "./pro/insights.svg";
import { ReactComponent as Key } from "./pro/key.svg";
import { ReactComponent as Kubernetes } from "./pro/kubernetes.svg";
import { ReactComponent as LayoutLeft } from "./pro/layout-left.svg";
import { ReactComponent as LineChartUp } from "./pro/line-chart-up.svg";
import { ReactComponent as LinkBroken } from "./pro/link-broken.svg";
import { ReactComponent as LinkExternal } from "./pro/link-external.svg";
import { ReactComponent as Link } from "./pro/link.svg";
import { ReactComponent as List } from "./pro/list.svg";
import { ReactComponent as LockUnlocked } from "./pro/lock-unlocked.svg";
import { ReactComponent as Lock } from "./pro/lock.svg";
import { ReactComponent as LogIn } from "./pro/log-in.svg";
import { ReactComponent as LogOut } from "./pro/log-out.svg";
import { ReactComponent as ManagementGroup } from "./pro/management-group.svg";
import { ReactComponent as Message } from "./pro/message.svg";
import { ReactComponent as Minus } from "./pro/minus.svg";
import { ReactComponent as None } from "./pro/none.svg";
import { ReactComponent as Odometer } from "./pro/odometer.svg";
import { ReactComponent as Package } from "./pro/package.svg";
import { ReactComponent as Placeholder } from "./pro/placeholder.svg";
import { ReactComponent as RaisedHand } from "./pro/raised-hand.svg";
import { ReactComponent as Refresh } from "./pro/refresh.svg";
import { ReactComponent as ResourceGroup } from "./pro/resource-group.svg";
import { ReactComponent as Role } from "./pro/role.svg";
import { ReactComponent as Salesforce } from "./pro/salesforce.svg";
import { ReactComponent as Search } from "./pro/search.svg";
import { ReactComponent as Send } from "./pro/send.svg";
import { ReactComponent as Server2 } from "./pro/server-2.svg";
import { ReactComponent as Server3 } from "./pro/server-3.svg";
import { ReactComponent as Server } from "./pro/server.svg";
import { ReactComponent as ServiceAccount } from "./pro/service-account.svg";
import { ReactComponent as Settings } from "./pro/settings.svg";
import { ReactComponent as Share } from "./pro/share.svg";
import { ReactComponent as SlashDivider } from "./pro/slash-divider.svg";
import { ReactComponent as Sort } from "./pro/sort.svg";
import { ReactComponent as Speedometer } from "./pro/speedometer.svg";
import { ReactComponent as Star } from "./pro/star.svg";
import { ReactComponent as Stars } from "./pro/stars.svg";
import { ReactComponent as Table } from "./pro/table.svg";
import { ReactComponent as Tag } from "./pro/tag.svg";
import { ReactComponent as Template } from "./pro/template.svg";
import { ReactComponent as Terminal } from "./pro/terminal.svg";
import { ReactComponent as ThumbsDown } from "./pro/thumbs-down.svg";
import { ReactComponent as ThumbsUp } from "./pro/thumbs-up.svg";
import { ReactComponent as Trash } from "./pro/trash.svg";
import { ReactComponent as Undo } from "./pro/undo.svg";
import { ReactComponent as Unlock } from "./pro/unlock.svg";
import { ReactComponent as Upload } from "./pro/upload.svg";
import { ReactComponent as UserCheck } from "./pro/user-check.svg";
import { ReactComponent as UserClock } from "./pro/user-clock.svg";
import { ReactComponent as UserEdit } from "./pro/user-edit.svg";
import { ReactComponent as UserMinus } from "./pro/user-minus.svg";
import { ReactComponent as UserPlus } from "./pro/user-plus.svg";
import { ReactComponent as UserRight } from "./pro/user-right.svg";
import { ReactComponent as UserSquare } from "./pro/user-square.svg";
import { ReactComponent as UserX } from "./pro/user-x.svg";
import { ReactComponent as User } from "./pro/user.svg";
import { ReactComponent as UsersCheck } from "./pro/users-check.svg";
import { ReactComponent as UsersEdit } from "./pro/users-edit.svg";
import { ReactComponent as UsersMinus } from "./pro/users-minus.svg";
import { ReactComponent as UsersPlus } from "./pro/users-plus.svg";
import { ReactComponent as UsersRight } from "./pro/users-right.svg";
import { ReactComponent as UsersX } from "./pro/users-x.svg";
import { ReactComponent as Users } from "./pro/users.svg";
import { ReactComponent as XClose } from "./pro/x-close.svg";
import { ReactComponent as Zap } from "./pro/zap.svg";

export const proIconSet = {
  account: Account,
  add: Add,
  "alarm-clock": AlarmClock,
  "alarm-clock-off": AlarmClockOff,
  "alarm-clock-plus": AlarmClockPlus,
  "alert-triangle": AlertTriangle,
  amazon: Amazon,
  apps: Apps,
  "arrow-down": ArrowDown,
  "arrow-left": ArrowLeft,
  "arrow-right": ArrowRight,
  "arrow-square-down": ArrowSquareDown,
  "arrow-switch-3": ArrowSwitch3,
  "arrow-up": ArrowUp,
  asterisk: Asterisk,
  "at-sign": AtSign,
  "bell-off": BellOff,
  "bell-ringing": BellRinging,
  "bell-notification": BellNotification,
  bell: Bell,
  bookmark: Bookmark,
  bucket: Bucket,
  buildings: Buildings,
  bundle: Bundle,
  calendar: Calendar,
  "calendar-check": CalendarCheck,
  "calendar-plus": CalendarPlus,
  check: Check,
  "check-circle": CheckCircle,
  "check-list": CheckList,
  "chevron-down": ChevronDown,
  "chevron-left": ChevronLeft,
  "chevron-right": ChevronRight,
  "chevron-up": ChevronUp,
  clock: Clock,
  "clock-plus": ClockPlus,
  "cloud-blank": CloudBlank,
  code: Code,
  compare: Compare,
  copy: Copy,
  cube: Cube,
  "cube-outline": CubeOutline,
  data: Data,
  "database-2": Database2,
  database: Database,
  dataflow: Dataflow,
  "dataflow-2": Dataflow2,
  department: Department,
  disconnect: Disconnect,
  "dots-grid": DotsGrid,
  "dots-grid-2": DotsGrid2,
  "dots-horizontal": DotsHorizontal,
  download: Download,
  edit: Edit,
  events: Events,
  eye: Eye,
  "eye-off": EyeOff,
  "file-code": FileCode,
  filter: Filter,
  "filter-funnel": FilterFunnel,
  folder: Folder,
  "folder-code": FolderCode,
  "gcp-project": GcpProject,
  "git-merge": GitMerge,
  "gitlab-project": GitlabProject,
  google: Google,
  "hard-drive": HardDrive,
  "help-circle": HelpCircle,
  "home-2": Home,
  hourglass: Hourglass,
  inbox: Inbox,
  incomplete: Incomplete,
  infinity: Infinity,
  "info-circle": InfoCircle,
  insights: Insights,
  "insights-2": Insights2,
  key: Key,
  kubernetes: Kubernetes,
  "layout-left": LayoutLeft,
  "line-chart-up": LineChartUp,
  link: Link,
  list: List,
  "link-broken": LinkBroken,
  "link-external": LinkExternal,
  lock: Lock,
  "lock-unlocked": LockUnlocked,
  "log-in": LogIn,
  "log-out": LogOut,
  "management-group": ManagementGroup,
  message: Message,
  minus: Minus,
  none: None,
  odometer: Odometer,
  package: Package,
  placeholder: Placeholder,
  "raised-hand": RaisedHand,
  refresh: Refresh,
  "resource-group": ResourceGroup,
  role: Role,
  salesforce: Salesforce,
  search: Search,
  send: Send,
  server: Server,
  "server-2": Server2,
  "server-3": Server3,
  "service-account": ServiceAccount,
  settings: Settings,
  share: Share,
  "slash-divider": SlashDivider,
  star: Star,
  stars: Stars,
  sort: Sort,
  speedometer: Speedometer,
  table: Table,
  tag: Tag,
  template: Template,
  terminal: Terminal,
  "thumbs-down": ThumbsDown,
  "thumbs-up": ThumbsUp,
  trash: Trash,
  undo: Undo,
  unlock: Unlock,
  upload: Upload,
  user: User,
  "user-check": UserCheck,
  "user-clock": UserClock,
  "user-edit": UserEdit,
  "user-minus": UserMinus,
  "user-plus": UserPlus,
  "user-right": UserRight,
  "user-square": UserSquare,
  "user-x": UserX,
  users: Users,
  "users-check": UsersCheck,
  "users-edit": UsersEdit,
  "users-minus": UsersMinus,
  "users-plus": UsersPlus,
  "users-right": UsersRight,
  "users-x": UsersX,
  "x-close": XClose,
  zap: Zap,
};
