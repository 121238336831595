import { getModifiedErrorMessage } from "api/ApiContext";
import {
  Maybe,
  OrganizationSamlInfoFragment,
  SamlConnectionDocument,
  SamlConnectionQuery,
  useCreateSamlConnectionMutation,
  useDeleteSamlConnectionMutation,
  UserPreviewSmallFragment,
  useSamlConnectionQuery,
  useUpdateSamlConnectionMutation,
} from "api/generated/graphql";
import clsx from "clsx";
import AuthContext from "components/auth/AuthContext";
import styles from "components/auth/SamlApp.module.scss";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import SamlBreakGlassUsersModal from "components/modals/SamlBreakGlassUsersModal";
import { FieldLabel } from "components/modals/SessionDetailsModal";
import { MoreInfo } from "components/more_info/MoreInfo";
import { useToast } from "components/toast/Toast";
import {
  Button,
  ButtonV3,
  FileUpload,
  FormGroup,
  Input,
  Modal,
  Switch,
} from "components/ui";
import { CERTIFICATE_FILE_ACCEPT } from "components/ui/file_upload/FileUpload";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import forge from "node-forge";
import pluralize from "pluralize";
import React, { useContext, useState } from "react";
import { isNonProd } from "utils/environment";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import AppContext from "views/app/AppContext";
import integrationsStyles from "views/settings/OrgIntegrations.module.scss";
import orgSettingsStyles from "views/settings/OrgSettings.module.scss";
import * as stylesV3 from "views/settings/OrgSettingsV3.css";

export const SamlApp = () => {
  const { authState } = useContext(AuthContext);
  const orgId = authState.user?.user.organizationId;

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const [
    deleteSamlConnection,
    { loading: deleteSamlConnectionLoading },
  ] = useDeleteSamlConnectionMutation();

  const [
    updateSamlConnection,
    { loading: updateSamlConnectionLoading },
  ] = useUpdateSamlConnectionMutation();

  const [orgSamlInfo, setOrgSamlInfo] = useState<
    Maybe<OrganizationSamlInfoFragment>
  >(null);

  const [
    showSamlBreakGlassUsersModal,
    setShowSamlBreakGlassUsersModal,
  ] = useState(false);

  const [showSamlSettingsModal, setShowSamlSettingsModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [signInEndpointURL, setSignInEndpointURL] = useState("");
  const [certExpirationDate, setCertExpirationDate] = useState<
    Maybe<string | Date>
  >(null);
  const [certFile, setCertFile] = useState<Maybe<File>>(null);
  const [endpointHostname, setEndpointHostname] = useState("");
  const [restrictLoginToSaml, setRestrictLoginToSaml] = useState(false);
  const [samlBreakGlassUsers, setSamlBreakGlassUsers] = useState<
    UserPreviewSmallFragment[]
  >([]);
  const [
    createSamlConnection,
    { loading: createSamlConnectionLoading },
  ] = useCreateSamlConnectionMutation();
  const { displaySuccessToast, displayErrorToast } = useToast();

  useSamlConnectionQuery({
    onCompleted: (data) => {
      if (data) {
        if (data.samlConnection.organizationSamlInfo) {
          setOrgSamlInfo(data.samlConnection.organizationSamlInfo);
          setSignInEndpointURL(
            data.samlConnection.organizationSamlInfo.endpoint
          );
          setCertExpirationDate(
            data.samlConnection.organizationSamlInfo.certExpiration
          );
          const endPointURL = new URL(
            data.samlConnection.organizationSamlInfo.endpoint
          );
          setEndpointHostname(endPointURL.hostname);
          setRestrictLoginToSaml(
            data.samlConnection.organizationSamlInfo.restrictLoginToSaml
          );
        } else {
          setSignInEndpointURL("");
          setCertExpirationDate(null);
          setEndpointHostname("");
          setRestrictLoginToSaml(false);
        }

        if (data.samlConnection.organizationSamlInfo?.samlBreakGlassUsers) {
          setSamlBreakGlassUsers(
            data.samlConnection.organizationSamlInfo.samlBreakGlassUsers
          );
        }
      }
    },
    onError: (error) => {
      logError(error, `failed to get saml connection`);
    },
  });

  const samlBreakGlassUserModalEntries = samlBreakGlassUsers.map((user) => {
    return {
      id: user.id,
      name: user.fullName || "",
      avatarUrl: user.avatarUrl || "",
      canBeRemoved: user.isSystemUser || false,
    };
  });

  // Delete SAML connection modal
  const confirmDeleteModal = (
    <Modal
      title={`Disconnect SAML connection`}
      isOpen={showConfirmModal}
      onClose={() => {
        setShowConfirmModal(false);
        setErrorMessage("");
      }}
    >
      <Modal.Body>
        {errorMessage}
        <p>Are you sure you want to disconnect your SAML connection?</p>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel={"Disconnect"}
        onPrimaryButtonClick={async () => {
          try {
            const { data: deleteData } = await deleteSamlConnection({
              update: (cache, { data }) => {
                switch (data?.deleteSamlConnection.__typename) {
                  case "DeleteSamlConnectionResult":
                    cache.evict({
                      id: cache.identify(
                        data?.deleteSamlConnection.organizationSamlInfo
                      ),
                    });
                    break;
                }
              },
            });
            switch (deleteData?.deleteSamlConnection.__typename) {
              case "DeleteSamlConnectionResult":
                setOrgSamlInfo(null);
                setSignInEndpointURL("");
                setCertExpirationDate(null);
                setCertFile(null);
                setShowConfirmModal(false);
                setErrorMessage(null);
                setShowSamlSettingsModal(false);
                displaySuccessToast(`SAML connection disconnected`);
                break;
              default:
                logError(new Error(`failed to disconnect SAML connection`));
                setErrorMessage("Error: failed to disconnect SAML connection");
            }
          } catch (error) {
            logError(error, `failed to disconnect SAML connection`);
            setErrorMessage("Error: failed to disconnect SAML connection");
          }
        }}
        primaryButtonLoading={deleteSamlConnectionLoading}
      />
    </Modal>
  );

  const formIsNotFilledOut = !certFile || signInEndpointURL === "";
  const formHasNotChanged =
    !certFile &&
    signInEndpointURL === orgSamlInfo?.endpoint &&
    restrictLoginToSaml === orgSamlInfo?.restrictLoginToSaml;

  const samlEndpointForm = (
    <FormGroup
      label="Identity Provider SAML 2.0 SSO URL"
      infoTooltip="Enter your Identity Provider SAML 2.0 Single Sign-On URL. This is where you go when you try to log in."
    >
      <Input
        value={signInEndpointURL}
        onChange={setSignInEndpointURL}
        placeholder={"https://..."}
      />
    </FormGroup>
  );

  const certificateUploadPanel = (
    <div
      className={sprinkles({
        backgroundColor: "gray100",
        padding: "lg",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "md",
        marginBottom: "md",
      })}
    >
      <div>
        <div
          className={sprinkles({
            fontSize: "labelLg",
            fontWeight: "semibold",
          })}
        >
          Identity Provider Public Certificate
        </div>
        <div
          className={sprinkles({
            fontSize: "labelMd",
            color: "gray600",
          })}
        >
          {certExpirationDate
            ? "Expiring " + moment(certExpirationDate).format("MMM DD, YYYY")
            : "None"}
        </div>
      </div>
      <FileUpload
        renderButton={(onClick) => (
          <Button
            label="Upload Certificate"
            leftIconName="edit-2"
            type="primary"
            onClick={onClick}
          />
        )}
        handleUpload={async (certificateFile) => {
          if (!certificateFile || certificateFile.size === 0) {
            // Indicates cancel
            return;
          }

          try {
            const text = await certificateFile.text();
            const cert = forge.pki.certificateFromPem(text);
            setCertExpirationDate(cert.validity.notAfter);
            setCertFile(certificateFile);
            setErrorMessage("");
          } catch (error) {
            logError(error, `failed to parse SAML certificate`);
            setErrorMessage("Error: failed to parse SAML certificate");
          }
        }}
        accept={CERTIFICATE_FILE_ACCEPT}
      />
    </div>
  );

  const { appState } = useContext(AppContext);
  let auth0Audience = "opal-security";
  if (isNonProd(appState.environment)) {
    auth0Audience = "opal-security-dev";
  }

  const identityProviderConfigurationPanel = (
    <div>
      <p>
        After entering the above, configure your SAML Identity Provider as
        follows:
      </p>
      <ol style={{ paddingInlineStart: "16px" }}>
        <li>
          In the service provider section, input the following ACS URL:
          <FieldLabel
            value={
              `https://` +
              authState.authClient?.config.domain +
              `/login/callback?connection=saml-` +
              orgId
            }
          />
        </li>

        <li>
          In the service provider section, input the following Entity ID:
          <FieldLabel value={`urn:auth0:` + auth0Audience + `:saml-` + orgId} />
        </li>

        <li>
          Make sure your identity provider performs the following SAML attribute
          mapping. This will ensure the SAML integration works:
          <ol type="i">
            <li>{"email <-> Primary Email"}</li>
            <li>{"family_name <-> Last Name"}</li>
            <li>{"given_name <-> First Name"}</li>
          </ol>
        </li>
      </ol>
    </div>
  );
  const restrictLoginPanel = (
    <div className={orgSettingsStyles.switchesHeader}>
      <div
        className={clsx({
          [orgSettingsStyles.switches]: true,
          [orgSettingsStyles.switch]: true,
          [orgSettingsStyles.off]: !restrictLoginToSaml,
        })}
      >
        <Switch
          checked={restrictLoginToSaml}
          onChange={async () => {
            try {
              const { data } = await updateSamlConnection({
                variables: {
                  input: {
                    restrictLoginToSaml: !restrictLoginToSaml,
                  },
                },
                refetchQueries: ["SamlConnection"],
              });

              switch (data?.updateSamlConnection.__typename) {
                case "UpdateSamlConnectionResult":
                  setRestrictLoginToSaml(
                    data.updateSamlConnection.organizationSamlInfo
                      .restrictLoginToSaml
                  );
                  displaySuccessToast("Success: SAML settings updated");
                  break;
                default:
                  logError(new Error(`failed to update SAML settings`));
                  displayErrorToast("Error: failed to update SAML settings");
              }
            } catch (error) {
              logError(error, `failed to update SAML settings`);
              displayErrorToast(
                getModifiedErrorMessage(
                  "Error: failed to update SAML settings",
                  error
                )
              );
            }
          }}
        />
      </div>
      <div className={orgSettingsStyles.label}>
        {"Restrict logins to SAML only"}
      </div>
      <MoreInfo
        tooltipText={"Require that all logins to Opal be done via SAML."}
      />
    </div>
  );

  const deleteConnectionPanel = (
    <div
      className={clsx({
        [styles.shadedPanel]: true,
        [styles.shadedRed]: true,
      })}
    >
      <div
        className={clsx({
          [styles.shadedPanelText]: true,
          [styles.shadedTextRed]: true,
          [styles.indentedTextLeftSmall]: true,
        })}
      >
        <div className={styles.header}>{"Delete SAML Connection"}</div>
      </div>
      <div className={styles.shadedButton}>
        <Button
          type="error"
          leftIconName="trash"
          label="Delete Connection"
          onClick={async () => {
            setShowConfirmModal(true);
          }}
        />
      </div>
    </div>
  );

  const modifySamlBreakGlassUsersModal = (
    <SamlBreakGlassUsersModal
      title={"SAML breakglass users"}
      isModalOpen={showSamlBreakGlassUsersModal}
      onClose={() => {
        setShowSamlBreakGlassUsersModal(false);
        setErrorMessage("");
      }}
      onSubmit={async (samlBreakGlassUsers) => {
        try {
          const { data } = await updateSamlConnection({
            variables: {
              input: {
                samlBreakGlassUsers: samlBreakGlassUsers,
              },
            },
          });
          switch (data?.updateSamlConnection.__typename) {
            case "UpdateSamlConnectionResult": {
              displaySuccessToast("Success: SAML break-glass users updated");
              setSamlBreakGlassUsers(
                data.updateSamlConnection.organizationSamlInfo
                  .samlBreakGlassUsers
              );
              setShowSamlBreakGlassUsersModal(false);
              break;
            }
          }
        } catch (error) {
          logError(error, "failed to update saml break glass users");
          setErrorMessage(
            getModifiedErrorMessage(
              "Error: failed to update saml break glass users",
              error
            )
          );
        }
      }}
      entryInfos={samlBreakGlassUserModalEntries}
      loading={updateSamlConnectionLoading}
      errorMessage={errorMessage || ""}
    />
  );

  const samlBreakGlassUsersPanel = (
    <div className={orgSettingsStyles.switchesHeader}>
      <div className={orgSettingsStyles.switches}>
        <button
          onClick={() => {
            setShowSamlBreakGlassUsersModal(true);
          }}
          disabled={!restrictLoginToSaml}
          className={orgSettingsStyles.orgSettingOpenModalButton}
        >
          <div className={sprinkles({ display: "flex" })}>
            {samlBreakGlassUsers.length === 0
              ? "None"
              : pluralize("user", samlBreakGlassUsers.length, true)}
          </div>
        </button>
      </div>
      <div className={orgSettingsStyles.label}>
        Breakglass users allowed to login without SAML
        <MoreInfo
          tooltipText={
            "When logins are restricted to SAML only, all users must login via SAML. To allow some users to also login without SAML, configure this here."
          }
        />
      </div>
    </div>
  );

  const existingSamlConnectionInfo = (
    <>
      {deleteConnectionPanel}
      {confirmDeleteModal}
    </>
  );

  const samlSettingsModal = (
    <Modal
      isOpen={showSamlSettingsModal}
      onClose={() => {
        setErrorMessage("");
        setShowSamlSettingsModal(false);
        if (!orgSamlInfo) {
          setCertFile(null);
          setCertExpirationDate(null);
          setSignInEndpointURL("");
        }
      }}
      title={"Enable sign in to Opal via SAML SSO"}
    >
      <Modal.Body>
        {!!errorMessage && (
          <ModalErrorMessage errorMessage={errorMessage || ""} />
        )}

        {samlEndpointForm}
        {certificateUploadPanel}
        {identityProviderConfigurationPanel}
        {orgSamlInfo && existingSamlConnectionInfo}
      </Modal.Body>

      <Modal.Footer
        primaryButtonLabel={"Save"}
        primaryButtonDisabled={
          formHasNotChanged || (!orgSamlInfo && formIsNotFilledOut)
        }
        primaryButtonLoading={createSamlConnectionLoading}
        onPrimaryButtonClick={async () => {
          try {
            const { data } = await createSamlConnection({
              variables: {
                input: {
                  signInEndpointURL: signInEndpointURL,
                  certFile: certFile,
                },
              },
              update: (cache, { data }) => {
                switch (data?.createSamlConnection.__typename) {
                  case "CreateSamlConnectionResult": {
                    let cachedSamlConnectionQuery = cache.readQuery<SamlConnectionQuery>(
                      {
                        query: SamlConnectionDocument,
                        variables: {
                          input: {},
                        },
                      }
                    );
                    if (cachedSamlConnectionQuery) {
                      cache.writeQuery<SamlConnectionQuery>({
                        query: SamlConnectionDocument,
                        variables: {
                          input: {},
                        },
                        data: {
                          ...cachedSamlConnectionQuery,
                          samlConnection: {
                            ...cachedSamlConnectionQuery.samlConnection,
                            organizationSamlInfo: {
                              ...data.createSamlConnection.organizationSamlInfo,
                            },
                          },
                        },
                      });
                    }
                  }
                }
              },
            });

            switch (data?.createSamlConnection.__typename) {
              case "CreateSamlConnectionResult":
                setOrgSamlInfo(data.createSamlConnection.organizationSamlInfo);
                setSignInEndpointURL(
                  data.createSamlConnection.organizationSamlInfo.endpoint
                );
                setCertExpirationDate(
                  data.createSamlConnection.organizationSamlInfo.certExpiration
                );
                setEndpointHostname(
                  new URL(
                    data.createSamlConnection.organizationSamlInfo.endpoint
                  ).hostname
                );
                setRestrictLoginToSaml(
                  data.createSamlConnection.organizationSamlInfo
                    .restrictLoginToSaml
                );
                setCertFile(null);
                setShowSamlSettingsModal(false);
                displaySuccessToast("Success: SAML connection updated");
                setErrorMessage("");
                break;
              case "InvalidEmailError":
                setErrorMessage(data.createSamlConnection.message);
                break;
              default:
                logError(new Error(`failed to update SAML connection`));
                setErrorMessage("Error: failed to update SAML connection");
            }
          } catch (error) {
            logError(error, "failed to create SAML connection");
            setErrorMessage(
              getModifiedErrorMessage(
                "Error: failed to create SAML connection",
                error
              )
            );
          }
        }}
      />
    </Modal>
  );

  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const samlSettingsPanel = hasV3 ? (
    <div className={stylesV3.switchesHeader}>
      <div
        className={orgSettingsStyles.text}
        style={{ display: "flex-column" }}
      >
        <div className={sprinkles({ display: "flex" })}>
          <div className={stylesV3.label}>SAML SSO Settings</div>
          <MoreInfo
            tooltipText={"When on, users can log into Opal via SAML SSO."}
          />
        </div>
        {!!orgSamlInfo && (
          <div className={integrationsStyles.subtitle}>
            <div
              className={clsx({
                [integrationsStyles.subtitleStatus]: true,
                [integrationsStyles.statusDisconnected]: !orgSamlInfo,
                [integrationsStyles.statusConnected]: !!orgSamlInfo,
              })}
            />
            <div className={integrationsStyles.subtitleText}>
              {"SAML provider: " + endpointHostname}
            </div>
          </div>
        )}
      </div>
      <div className={stylesV3.switches}>
        <ButtonV3
          onClick={() => {
            setShowSamlSettingsModal(true);
          }}
          label={orgSamlInfo ? "Edit" : "Setup"}
          outline={true}
        />
      </div>
    </div>
  ) : (
    <div className={orgSettingsStyles.switchesHeader}>
      <div className={orgSettingsStyles.switches}>
        <button
          onClick={() => {
            setShowSamlSettingsModal(true);
          }}
          className={orgSettingsStyles.orgSettingOpenModalButton}
        >
          <div className={sprinkles({ display: "flex" })}>
            {orgSamlInfo ? "Edit" : "Setup"}
          </div>
        </button>
      </div>
      <div
        className={orgSettingsStyles.text}
        style={{ display: "flex-column" }}
      >
        <div className={sprinkles({ display: "flex" })}>
          <div className={styles.text}>SAML SSO Settings</div>
          <MoreInfo
            tooltipText={"When on, users can log into Opal via SAML SSO."}
          />
        </div>
        {!!orgSamlInfo && (
          <div className={integrationsStyles.subtitle}>
            <div
              className={clsx({
                [integrationsStyles.subtitleStatus]: true,
                [integrationsStyles.statusDisconnected]: !orgSamlInfo,
                [integrationsStyles.statusConnected]: !!orgSamlInfo,
              })}
            />
            <div className={integrationsStyles.subtitleText}>
              {"SAML provider: " + endpointHostname}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div>
      {samlSettingsPanel}
      {showSamlSettingsModal && samlSettingsModal}
      {orgSamlInfo && restrictLoginPanel}
      {orgSamlInfo && restrictLoginToSaml && samlBreakGlassUsersPanel}
      {showSamlBreakGlassUsersModal && modifySamlBreakGlassUsersModal}
    </div>
  );
};
