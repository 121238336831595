import {
  AccessOption,
  ResourceType,
  useUserMenuQuery,
} from "api/generated/graphql";
import OpalIcon from "assets/logos/opal-logo-standalone.svg";
import OpalLogo from "assets/logos/opal-security-logo.svg";
import AuthContext from "components/auth/AuthContext";
import { TooltipPlacement } from "components/label/Label";
import { Avatar, Badge, ContextMenu, Icon, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useContext, useState } from "react";
import { useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";
import useLogEvent from "utils/analytics";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useLocalStorage } from "utils/hooks";
import AppContext from "views/app/AppContext";
import {
  ACCESS_OPTION_URL_KEY,
  ITEM_TYPE_URL_KEY,
} from "views/apps/AppsContext";
import { ConditionalWrapper } from "views/Common";

import * as styles from "./LeftSidebarV3.css";
import useLeftSidebarSectionInfos, {
  LeftSidebarOptionInfo,
  LeftSidebarSectionInfo,
} from "./useLeftSidebarRoutesV3";

const LeftSidebar = () => {
  const history = useHistory();
  const leftSidebarSectionInfos = useLeftSidebarSectionInfos();
  const [collapsed, setCollapsed] = useLocalStorage("collapsedSidebar", false);
  const [showCollapseButton, setShowCollapseButton] = useState(false);
  const hasEndUserXP = useFeatureFlag(FeatureFlag.EndUserExperience);

  return (
    <div
      className={styles.container({ collapsed: collapsed })}
      onMouseEnter={_.debounce(() => {
        setShowCollapseButton(true);
      }, 100)}
      onMouseLeave={_.debounce(() => {
        setShowCollapseButton(false);
      }, 100)}
    >
      <nav className={styles.menu}>
        <button
          className={styles.logo({ collapsed })}
          onClick={() => {
            history.push("/");
          }}
          type="button"
        >
          <img
            className={sprinkles({ overflow: "auto" })}
            src={collapsed ? OpalIcon : OpalLogo}
            alt="Opal logo"
            onClick={() => {
              history.push("/");
            }}
          />
        </button>
        <div
          className={styles.collapseButtonContainer({
            active: showCollapseButton,
          })}
          onClick={() => setCollapsed(!collapsed)}
        >
          <Tooltip
            tooltipText={collapsed ? "Expand Sidebar" : "Collapse Sidebar"}
          >
            <div className={styles.collapseButton}>
              <Icon name="layout-left" size="xs" />
            </div>
          </Tooltip>
        </div>
        {leftSidebarSectionInfos
          .filter((itemInfo) => {
            if ("options" in itemInfo) {
              return itemInfo.options.some((opt) => !opt.hidden);
            } else {
              return !itemInfo.hidden;
            }
          })
          .map((itemInfo, i) =>
            "options" in itemInfo ? (
              <LeftSidebarNavSection
                key={`${itemInfo.title}-${i}`}
                sectionInfo={itemInfo}
                collapsed={collapsed}
              />
            ) : (
              <LeftSidebarNavLink
                key={`${itemInfo.title}-${i}`}
                optionInfo={itemInfo}
                collapsed={collapsed}
              />
            )
          )}
      </nav>
      {hasEndUserXP && (
        // TODO: Remove feature flag and only use v3 feature flag
        <ConditionalWrapper
          wrapper={(children) => (
            <Tooltip
              tooltipText={"Make a request"}
              placement={TooltipPlacement.BottomStart}
            >
              {children}
            </Tooltip>
          )}
          useWrapper={collapsed}
        >
          <Link
            key={"request-button"}
            className={styles.menuItem({
              fullWidth: true,
              main: true,
              center: true,
            })}
            to={"/request-access"}
            title={"Make a request"}
          >
            <div className={styles.menuItemLeft}>
              <Icon name="raised-hand" size="xs" />
              {!collapsed && (
                <div className={styles.menuItemTitle}>Make a request</div>
              )}
            </div>
          </Link>
        </ConditionalWrapper>
      )}
      <Footer collapsed={collapsed} />
    </div>
  );
};

const LeftSidebarNavLink = ({
  optionInfo,
  collapsed = false,
}: {
  optionInfo: LeftSidebarOptionInfo;
  collapsed: boolean;
}) => {
  const logEvent = useLogEvent();
  const location = useLocation();

  if (optionInfo.hidden) {
    return null;
  }

  const active = [
    optionInfo.route,
    ...(optionInfo.extraRoutes ?? []),
  ].some((route) => location.pathname.startsWith(route));
  const badgeCount = optionInfo?.badgeCount;

  return (
    <ConditionalWrapper
      wrapper={(children) => (
        <Tooltip
          tooltipText={optionInfo.title}
          placement={TooltipPlacement.BottomStart}
        >
          {Boolean(badgeCount && badgeCount > 0) && collapsed ? (
            <div className={styles.notification}>
              <div className={styles.notificationIcon({ active })}>•</div>
              <div className={styles.notificationSubItems}>{children}</div>
            </div>
          ) : (
            children
          )}
        </Tooltip>
      )}
      useWrapper={collapsed}
    >
      <Link
        key={optionInfo.route}
        className={styles.menuItem({ active })}
        to={optionInfo.route}
        onClick={() =>
          logEvent({
            name: "left_sidebar_tab_click",
            properties: { tabName: optionInfo.title },
          })
        }
        title={optionInfo.title}
      >
        <div className={styles.menuItemLeft}>
          <Icon name={optionInfo.iconName} size="xs" />
          {!collapsed && (
            <div className={styles.menuItemTitle}>
              {optionInfo.title}
              {Boolean(badgeCount && badgeCount > 0) && (
                <Badge
                  count={optionInfo.badgeCount ?? 0}
                  color="white"
                  size="sm"
                />
              )}
            </div>
          )}
        </div>
      </Link>
    </ConditionalWrapper>
  );
};

const LeftSidebarNavSection = ({
  sectionInfo,
  collapsed = false,
}: {
  sectionInfo: LeftSidebarSectionInfo;
  collapsed: boolean;
}) => {
  const logEvent = useLogEvent();
  const location = useLocation();
  const history = useHistory();
  const hasActiveOption = sectionInfo.options.some((optionInfo) => {
    let active = location.pathname.startsWith(optionInfo.route);
    optionInfo.extraRoutes?.forEach((route) => {
      if (location.pathname.startsWith(route)) {
        active = true;
      }
    });
    return active;
  });
  const [open, setOpen] = useState(hasActiveOption);
  const configActive = sectionInfo.options
    .map((optionInfo) => optionInfo.route)
    .some((route) => location.pathname.startsWith(route));

  const renderMenuItem = (optionInfo: LeftSidebarOptionInfo) => {
    if (optionInfo.hidden) {
      return null;
    }
    let active = location.pathname.startsWith(optionInfo.route);
    optionInfo.extraRoutes?.forEach((route) => {
      if (location.pathname.startsWith(route)) {
        active = true;
      }
    });
    return (
      <Link
        key={optionInfo.route}
        className={styles.menuItem({ active, nested: !collapsed })}
        to={optionInfo.route}
        onClick={() =>
          logEvent({
            name: "left_sidebar_tab_click",
            properties: { tabName: optionInfo.title },
          })
        }
        title={optionInfo.title}
      >
        {!collapsed && (
          <div className={styles.menuItemTitle}>{optionInfo.title}</div>
        )}
      </Link>
    );
  };

  const renderPopoverMenuItem = (optionInfo: LeftSidebarOptionInfo) => {
    return {
      label: optionInfo.title,
      onClick: () => {
        history.push(optionInfo.route);
      },
      active: location.pathname.startsWith(optionInfo.route),
    };
  };

  if (collapsed) {
    return (
      <ContextMenu
        block
        options={sectionInfo.options.map((optionInfo) =>
          renderPopoverMenuItem(optionInfo)
        )}
        renderButton={(onClick) => (
          <Tooltip
            tooltipText={sectionInfo.title}
            placement={TooltipPlacement.BottomStart}
          >
            <button
              className={styles.menuItem({ active: hasActiveOption })}
              onClick={onClick}
            >
              <Icon name={sectionInfo.iconName} size="xs" />
            </button>
          </Tooltip>
        )}
        header={<div className={styles.userName}>{sectionInfo.title}</div>}
        collapsed={collapsed}
        rightAligned
        startAligned
      />
    );
  }

  return (
    <>
      <Link
        key={sectionInfo.title}
        className={styles.section}
        to={
          open || configActive
            ? location.pathname
            : sectionInfo.options[0].route
        }
        title={sectionInfo.title}
        onClick={() => {
          setOpen((prev) => !prev);
          logEvent({
            name: "left_sidebar_tab_click",
            properties: { tabName: sectionInfo.title },
          });
        }}
      >
        <div className={styles.menuItemLeft}>
          <Icon name={sectionInfo.iconName} size="xs" />
          <div className={styles.sectionTitle}>{sectionInfo.title}</div>
          <Icon name={open ? "chevron-down" : "chevron-right"} size="xs" />
        </div>
      </Link>
      {open &&
        sectionInfo.options.map((optionInfo) => renderMenuItem(optionInfo))}
    </>
  );
};

const Footer = ({ collapsed = false }: { collapsed: boolean }) => {
  const menuItems = [
    {
      label: "Opal Docs",
      onClick: () => {
        window.open("https://docs.opal.dev/docs");
      },
    },
    {
      label: "Customer Support",
      onClick: () => {
        window.open("https://opaldev.zendesk.com/hc/en-us");
      },
    },
  ];

  return (
    <div>
      <ConditionalWrapper
        wrapper={(children) => (
          <Tooltip
            tooltipText={"Get Support"}
            placement={TooltipPlacement.BottomStart}
          >
            {children}
          </Tooltip>
        )}
        useWrapper={collapsed}
      >
        <ContextMenu
          block
          options={menuItems}
          renderButton={(onClick) => (
            <button
              className={styles.menuItem({ active: false, fullWidth: true })}
              onClick={onClick}
            >
              {!collapsed ? (
                <div className={styles.menuItemTitle}>Get Support</div>
              ) : (
                <Icon name="help-circle" size="xs" />
              )}
            </button>
          )}
          collapsed={collapsed}
        />
      </ConditionalWrapper>
      <ConditionalWrapper
        wrapper={(children) => (
          <Tooltip
            tooltipText={"What's New"}
            placement={TooltipPlacement.BottomStart}
          >
            {children}
          </Tooltip>
        )}
        useWrapper={collapsed}
      >
        <button
          className={styles.menuItem({ active: false, fullWidth: true })}
          onClick={() => {
            window.open("https://docs.opal.dev/changelog");
          }}
        >
          <div className={styles.menuItemLeft}>
            {!collapsed ? (
              <div className={styles.menuItemTitle}>What's New</div>
            ) : (
              <Icon name="stars" size="xs" />
            )}
          </div>
        </button>
      </ConditionalWrapper>
      <div className={styles.divider} />
      <UserMenu collapsed={collapsed} />
    </div>
  );
};

const UserMenu = ({ collapsed }: { collapsed: boolean }) => {
  const { authState } = useContext(AuthContext);
  const { appState } = useContext(AppContext);
  const history = useHistory();

  const { data } = useUserMenuQuery();

  const user = authState.user;
  const opalConnectionId =
    data?.connections.connections.length === 1
      ? data?.connections.connections[0].id
      : null;

  const renderUserMenu = (onClick: (event: React.MouseEvent) => void) => {
    return (
      <button className={styles.userMenu({ collapsed })} onClick={onClick}>
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
            alignItems: "center",
          })}
        >
          <Avatar url={user?.user.avatarUrl} size="medium" />
          {!collapsed && (
            <div>
              <div className={styles.userName}>{user?.user.fullName}</div>
              <div className={styles.orgName}>
                {user?.user.organization.name}
              </div>
            </div>
          )}
        </div>
      </button>
    );
  };

  const menuItems: PropsFor<typeof ContextMenu>["options"] = [
    {
      label: "My Opal Roles",
      icon: { type: "name", icon: "role" },
      onClick: () =>
        history.push(
          `/apps/${opalConnectionId}?${ITEM_TYPE_URL_KEY}=${ResourceType.OpalRole}&${ACCESS_OPTION_URL_KEY}=${AccessOption.Mine}`
        ),
    },
    {
      label: "Account Settings",
      icon: { type: "name", icon: "user" },
      onClick: () => history.push("/user/settings"),
    },
    {
      label: "Log Out",
      icon: { type: "name", icon: "log-out" },
      onClick: () => history.push("/sign-out"),
    },
    {
      label: "",
      onClick: () => {},
      renderOption: () => {
        return (
          <div className={styles.version}>
            <a href="https://docs.opal.dev/changelog" target="_blank">
              {appState.version}
            </a>
          </div>
        );
      },
    },
  ];

  return (
    <ContextMenu
      block
      options={menuItems}
      renderButton={renderUserMenu}
      offsetLeft={4}
      padding="lg"
      collapsed={collapsed}
      header={
        collapsed && (
          <>
            <div className={styles.userName}>{user?.user.fullName}</div>
            <div className={styles.orgName}>{user?.user.organization.name}</div>
          </>
        )
      }
    />
  );
};

export default LeftSidebar;
