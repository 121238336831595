import { useVirtualizer } from "@tanstack/react-virtual";
import { Icon, Skeleton } from "components/ui";
import { IconName } from "components/ui/icon/Icon";
import sprinkles from "css/sprinkles.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as styles from "./Masonry.css";

// TODO: Header is propbably not the appropriate term for a card layout, but we
// want to define the renderer and the id
export type Header<Data> = {
  id: keyof Data & string;
};

type Props<Data> = {
  items: Data[];
  totalNumItems: number;
  renderItem: (data: Data, index: number) => JSX.Element;
  getItemKey: (data: Data, index: number) => string;
  emptyState?: {
    title: string;
    subtitle: string;
    icon?: IconName;
  };

  // Infinite scrolling/pagination
  onLoadMoreItems?: (id?: string) => Promise<void>;
  loadingItems?: boolean;

  checkedRowIds?: Set<string>;
  onCheckedRowsChange?: (ids: string[], checked: boolean) => void;

  columnGap?: "xs" | "sm" | "md" | "lg";
  rowGap?: "xs" | "sm" | "md" | "lg";
  breakpointCols?: { [key: string]: number } | number;

  allowHorizontalScroll?: boolean;
};

const gapMap = {
  xs: 4,
  sm: 8,
  md: 12,
  lg: 20,
};

const DEFAULT_COLUMN_COUNT = 4;

const maxColumnCount = (
  breakpointCols: { [key: string]: number } | number | undefined
) => {
  if (!breakpointCols) {
    return DEFAULT_COLUMN_COUNT;
  }
  if (typeof breakpointCols === "number") {
    return breakpointCols;
  }

  return Math.max(...Object.values(breakpointCols));
};

const computeColumnCount = (
  width: number,
  breakpointCols: { [key: string]: number } | number
) => {
  if (typeof breakpointCols === "number") {
    return breakpointCols;
  }
  const sortedBreakpoints = Object.entries(breakpointCols).sort(
    ([a], [b]) => parseInt(a) - parseInt(b)
  );
  for (const [breakpoint, cols] of sortedBreakpoints) {
    if (width < parseInt(breakpoint)) {
      return cols;
    }
  }
  return sortedBreakpoints[sortedBreakpoints.length - 1][1];
};

// CardGrid component: A grid layout for displaying cards, sorting needs to be
// handled by the parent component, preferably server side.
const Masonry = <Data,>(props: Props<Data>) => {
  const {
    items: propItems,
    totalNumItems,
    renderItem,
    emptyState,
    onLoadMoreItems,
    loadingItems,
    // checkedRowIds,
    // onCheckedRowsChange,
    columnGap,
    breakpointCols,
    allowHorizontalScroll,
  } = props;

  const [width, setWidth] = useState(0);
  const [columnCount, setColumnCount] = useState(
    maxColumnCount(props.breakpointCols)
  );
  const containerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count:
      totalNumItems === propItems.length
        ? propItems.length + 1
        : propItems.length,
    getItemKey: (index) => {
      if (index >= propItems.length) {
        return "loading";
      }
      return props.getItemKey(propItems[index], index);
    },
    getScrollElement: () => containerRef.current,
    // estimateSize is just a placeholder, the height is calculated dynamically
    estimateSize: () => 200,
    overscan: 10,
    lanes: columnCount,
    gap: gapMap[props.rowGap ?? "md"],
  });

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement && onLoadMoreItems) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        // Once the user has scrolled within 50px of the bottom of the table, fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight < 10 &&
          !loadingItems &&
          propItems.length < totalNumItems
        ) {
          onLoadMoreItems();
        }
      }
    },
    [onLoadMoreItems, loadingItems, propItems, totalNumItems]
  );

  // Check on mount and after a fetch to see if the table is already scrolled
  // to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(containerRef.current);
  }, [fetchMoreOnBottomReached]);

  useEffect(() => {
    if (!containerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (!containerRef.current) return;

      const width = Math.min(
        containerRef.current.scrollWidth,
        containerRef.current.clientWidth,
        containerRef.current.offsetWidth
      );
      setWidth(width);

      setColumnCount(
        computeColumnCount(width, breakpointCols ?? DEFAULT_COLUMN_COUNT)
      );
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [width, breakpointCols]);

  const virtualItems = rowVirtualizer.getVirtualItems();

  const columnPos = useMemo(() => {
    let pxVal = gapMap[columnGap ?? "md"];

    const columnWidth = Math.floor(
      (width - pxVal * (columnCount - 1)) / columnCount
    );
    return {
      columnWidth: columnWidth,
      left: (index: number) => index * (columnWidth + pxVal),
    };
  }, [width, columnGap, columnCount]);

  const loadingContent = (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        gap: "sm",
      })}
    >
      <div
        className={sprinkles({
          display: "flex",
          justifyContent: "space-between",
        })}
      >
        <Skeleton variant="rect" width={"80px"} height={"32px"} />
        <Skeleton variant="circle" width={"48px"} height={"48px"} />
      </div>
      <Skeleton variant="rect" height={"60px"} />
      <Skeleton variant="rect" height={"60px"} />
    </div>
  );

  return (
    <div
      className={styles.container({ allowHorizontalScroll })}
      ref={containerRef}
      onScroll={(e) => fetchMoreOnBottomReached(e.currentTarget)}
    >
      {props.totalNumItems === 0 && !props.loadingItems && (
        <div className={styles.emptyStateContainer}>
          <div>
            {emptyState?.icon && <Icon name={emptyState.icon} size="lg" />}
          </div>
          <div className={styles.emptyStateTitle}>
            {emptyState?.title ?? "No rows"}
          </div>
          <div>{emptyState?.subtitle}</div>
        </div>
      )}
      <div
        style={{
          height: rowVirtualizer.getTotalSize(),
          position: "relative",
          width: "100%",
        }}
      >
        {virtualItems.map((virtualRow) => {
          const item = propItems[virtualRow.index];

          return (
            <div
              key={virtualRow.key}
              data-index={virtualRow.index}
              ref={rowVirtualizer.measureElement}
              style={{
                position: "absolute",
                top: 0,
                transform: `translateY(${
                  virtualRow.start - rowVirtualizer.options.scrollMargin
                }px)`,
                left: columnPos.left(virtualRow.lane),
                width: columnPos.columnWidth,
                maxWidth: columnPos.columnWidth,
                display: "block",
              }}
            >
              {item && renderItem(item, virtualRow.index)}
              {!item && loadingItems && loadingContent}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Masonry;
