import { EntityType } from "api/generated/graphql";
import { PillV3, TagPillV3 } from "components/pills/PillsV3";
import {
  ButtonV3,
  ContextMenu,
  EntityIcon,
  Icon,
  Modal,
  Skeleton,
} from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";

import * as styles from "./ItemDetailsCard.css";

type ItemDetailsCardProps = {
  icon?: IconData;
  title?: string;
  subtitle?: string;
  preMenuLayout?: JSX.Element;
  rightActions?: JSX.Element;
  extraMenuOptions?: PropsFor<typeof ContextMenu>["options"];
  bodyFields?: Record<string, JSX.Element | string>;
  footerFields?: Record<string, string>;
  tags?: { id: string; key: string; value: string }[];
  messageChannels?: string[];
  titleAccessory?: JSX.Element;
  footerElement?: JSX.Element;
  url?: string;
};

export const ItemDetailsCard = (props: ItemDetailsCardProps) => {
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showChannelsModal, setShowChannelsModal] = useState(false);

  const {
    bodyFields = {},
    footerFields = {},
    tags = [],
    messageChannels = [],
  } = props;

  const itemTags: JSX.Element[] = tags.map((tag) => {
    return (
      <TagPillV3
        key={tag.key}
        icon={{ type: "name", icon: "tag" }}
        pillColor="LightGreen"
        keyText={tag.key}
        valueText={tag.value}
        entityId={{
          entityId: tag.id,
          entityType: EntityType.Tag,
        }}
      />
    );
  });

  const itemMessageChannels: JSX.Element[] = messageChannels.map((channel) => {
    return (
      <PillV3
        key={channel}
        icon={{ type: "name", icon: "slack" }}
        pillColor="Purple"
        keyText={channel}
      />
    );
  });

  const hasIcon = Boolean(props.icon);
  const renderIcon = () => {
    const iconData = props.icon;
    if (!iconData) return null;

    if (iconData.type === "src") {
      return <Icon data={iconData} size="xxl" iconStyle="rounded" />;
    } else if (iconData.type === "name") {
      return <Icon name={iconData.icon} size="xxl" iconStyle="rounded" />;
    } else {
      return (
        <EntityIcon type={iconData.entityType} size="xxl" iconStyle="rounded" />
      );
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={sprinkles({ display: "flex", gap: "mdlg" })}>
          {renderIcon()}
          <div
            className={sprinkles({ display: "flex", flexDirection: "column" })}
          >
            <div className={styles.title}>
              {props.title}
              {props.titleAccessory && (
                <div className={styles.titleAccessory}>
                  {props.titleAccessory}
                </div>
              )}
            </div>
            <div className={styles.subtitle}>{props.subtitle}</div>
          </div>
        </div>
        <div className={styles.actions}>
          {props.preMenuLayout}
          {props.extraMenuOptions && props.extraMenuOptions.length > 0 ? (
            <ContextMenu
              rightAligned
              options={props.extraMenuOptions}
              renderButton={(onClick) => (
                <div
                  className={sprinkles({ marginRight: "sm" })}
                  onClick={onClick}
                >
                  <ButtonV3 leftIconName="dots-horizontal" size="md" />
                </div>
              )}
            />
          ) : (
            <div className={sprinkles({ marginRight: "md" })}></div>
          )}
          {props.rightActions}
        </div>
      </div>
      <div className={styles.cardBody({ hasIcon })}>
        {itemTags.length > 0 && (
          <div className={styles.tags}>
            {itemTags.slice(0, 3)}
            {itemTags.length > 3 ? (
              <div
                className={styles.showMoreLink}
                onClick={() => setShowTagsModal(true)}
              >
                {"+ " + String(itemTags.length - 3) + " more"}
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        {itemMessageChannels.length > 0 && (
          <div className={styles.messageChannels}>
            {itemMessageChannels.slice(0, 3)}
            {itemMessageChannels.length > 3 ? (
              <div
                className={styles.showMoreLink}
                onClick={() => setShowChannelsModal(true)}
              >
                {"+ " + String(itemMessageChannels.length - 3) + " more"}
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        {bodyFields &&
          Object.entries(bodyFields).map(([fieldName, fieldValue]) => {
            return (
              <div className={styles.bodyField} key={fieldName}>
                <div>{fieldName}</div>
                <div>{fieldValue}</div>
              </div>
            );
          })}
      </div>
      {(Object.keys(footerFields).length > 0 || props.footerElement) && (
        <div className={styles.cardFooter}>
          {Object.keys(footerFields).length > 0 && (
            <div
              className={sprinkles({
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              })}
            >
              {Object.entries(footerFields)
                .filter(([, fieldValue]) => fieldValue.length > 0)
                .map(([fieldName, fieldValue]) => {
                  return (
                    <div
                      key={fieldName}
                      className={sprinkles({
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      })}
                    >
                      <div className={sprinkles({ fontSize: "bodyMd" })}>
                        {fieldValue}
                      </div>
                      <div
                        className={sprinkles({
                          fontSize: "bodySm",
                          color: "gray700",
                        })}
                      >
                        {fieldName}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          {props.footerElement}
        </div>
      )}
      {showTagsModal && (
        <Modal
          isOpen={showTagsModal}
          onClose={() => {
            setShowTagsModal(false);
          }}
          title={`Tags on ${props.title}`}
          maxWidth={"sm"}
        >
          <Modal.Body>
            <div
              className={sprinkles({
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                gap: "md",
              })}
            >
              {itemTags}
            </div>
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Close"
            onPrimaryButtonClick={() => {
              setShowTagsModal(false);
            }}
          />
        </Modal>
      )}
      {showChannelsModal && (
        <Modal
          isOpen={showChannelsModal}
          onClose={() => {
            setShowChannelsModal(false);
          }}
          title={`Message Channels Linked to ${props.title}`}
          maxWidth={"sm"}
        >
          <Modal.Body>
            <div
              className={sprinkles({
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                gap: "md",
              })}
            >
              {itemMessageChannels}
            </div>
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Close"
            onPrimaryButtonClick={() => {
              setShowChannelsModal(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export const ItemDetailsCardSkeleton = () => {
  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={sprinkles({ width: "100%" })}>
          <Skeleton height="100px" width="60%" />
          <Skeleton height="40px" width="60%" />
          <Skeleton height="40px" width="100%" />
          <Skeleton height="40px" width="100%" />
        </div>
      </div>
    </div>
  );
};
