import { Popper, PopperPlacementType } from "@material-ui/core";
import { Button, EntityIcon, Icon, Label } from "components/ui";
import { IconData } from "components/ui/utils";
import React from "react";
import { useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import * as stylesV2 from "./ContextMenu.css";
import * as stylesV3 from "./ContextMenuV3.css";

interface MenuDivider {
  type: "divider";
  hide?: boolean;
}

interface MenuOption {
  label: string;
  sublabel?: string;
  onClick: React.MouseEventHandler;
  type?: "warning" | "danger" | "success";
  disabled?: boolean;
  hide?: boolean;
  icon?: IconData;
  renderOption?: () => JSX.Element;
  active?: boolean;
}

interface Props {
  options: (MenuOption | MenuDivider)[];
  renderButton?: (onClick: React.MouseEventHandler) => JSX.Element;
  offsetLeft?: number;
  rightAligned?: boolean;
  startAligned?: boolean;
  block?: boolean;
  horizontalDots?: boolean;
  padding?: "md" | "lg";
  collapsed?: boolean;
  includeUserDetails?: boolean;
  header?: React.ReactNode;
}

const ContextMenu = ({
  options,
  renderButton,
  offsetLeft = 0,
  rightAligned = false,
  startAligned = false,
  block = false,
  horizontalDots = false,
  padding = "md",
  collapsed = false,
  header,
}: Props) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);
  const styles = hasV3Nav ? stylesV3 : stylesV2;

  const visibleMenuOptions = options.filter((option) => !option.hide);

  const renderMenuOption = (
    menuOption: MenuOption | MenuDivider,
    index: number
  ) => {
    if (menuOption.type === "divider") {
      return <hr key={`${index}-divider`} className={styles.divider} />;
    }
    if (menuOption.renderOption) {
      return (
        <React.Fragment key={`${index}-${menuOption.label}`}>
          {menuOption.renderOption()}
        </React.Fragment>
      );
    }

    const commonProps = {
      disabled: menuOption.disabled,
      type: menuOption.type,
      padding: padding,
    };

    // TODO: Remove catch for V3 after V2 is removed, V2 styling sheet does not support the active property resulting in typing error
    const optionItemStyles = hasV3Nav
      ? { ...commonProps, active: menuOption.active }
      : commonProps;

    const optionItemStyle = styles.menuItem(optionItemStyles);

    return (
      <li
        key={`${index}-${menuOption.label}`}
        className={optionItemStyle}
        onClick={
          menuOption.disabled
            ? undefined
            : (event) => {
                event.stopPropagation();
                menuOption.onClick(event);
                setShowMenu(false);
              }
        }
      >
        {menuOption.icon ? (
          <span className={styles.iconContainer}>
            {menuOption.icon.type === "entity" ? (
              <EntityIcon type={menuOption.icon.entityType} size="sm" />
            ) : (
              <Icon data={menuOption.icon} size="xs" />
            )}
          </span>
        ) : null}
        <div>
          <Label label={menuOption.label} oneLine />
          {menuOption.sublabel ? (
            <div className={styles.sublabel({ type: menuOption.type })}>
              {menuOption.sublabel}
            </div>
          ) : null}
        </div>
      </li>
    );
  };

  const content = (
    <ul className={styles.menuContainer}>
      {visibleMenuOptions.map(renderMenuOption)}
    </ul>
  );

  const button = renderButton ? (
    renderButton((event) => {
      event.stopPropagation();
      setShowMenu((prev) => !prev);
    })
  ) : (
    <Button
      leftIconName={horizontalDots ? "more-horizontal" : "more-vertical"}
      borderless
      onClick={(event) => {
        event.stopPropagation();
        setShowMenu((prev) => !prev);
      }}
      round
    />
  );

  let placement: PopperPlacementType;

  if (startAligned) {
    placement = "right-start";
  } else if (collapsed) {
    placement = "right-end";
  } else if (rightAligned) {
    placement = "bottom-end";
  } else {
    placement = "bottom-start";
  }

  return (
    <>
      <div
        className={styles.buttonContainer({ block: Boolean(block) })}
        ref={buttonRef}
      >
        {button}
      </div>
      <Popper
        placement={placement}
        open={showMenu}
        anchorEl={buttonRef.current}
        className={styles.overlayContainer}
      >
        <OutsideClickHandler
          onOutsideClick={(e) => {
            if (
              e.target instanceof Element &&
              buttonRef.current?.contains(e.target) === true
            ) {
              // Do not handle this if it is a click on our button
              return;
            }
            setShowMenu(false);
          }}
        >
          <div
            className={styles.overlay}
            style={{ marginLeft: offsetLeft ?? 0 }}
          >
            {header && (
              <>
                <div className={stylesV3.headerContainer}>{header}</div>
                <hr className={stylesV3.fullDivider} />
              </>
            )}
            {content}
          </div>
        </OutsideClickHandler>
      </Popper>
    </>
  );
};

export default ContextMenu;
