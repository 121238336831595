import { refetchQueries } from "api/ApiContext";
import {
  AccessReviewFragment,
  ConnectionPreviewSmallFragment,
  ConnectionType,
  GroupType,
  ResourcePreviewSmallFragment,
  ResourceType,
  SyncErrorFragment,
  SyncTaskFragment,
  SyncTaskStatus,
  SyncType,
  useStartSyncMutation,
  useSyncStatusQuery,
  useSyncTaskQuery,
} from "api/generated/graphql";
import { ActionIcon } from "components/column/ColumnHeaderV3";
import {
  CustomCloseButton,
  ToastStyle,
  useToast,
} from "components/toast/Toast";
import moment from "moment";
import { useContext, useRef, useState } from "react";
import useLogEvent from "utils/analytics";
import {
  isNativeGroupType,
  isNativeResourceType,
} from "utils/directory/resources";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";
import OrgContext from "views/settings/OrgContext";

interface Props {
  syncType: SyncType;
  queriesToRefetch?: Parameters<typeof refetchQueries>[0]["include"];

  connection?: ConnectionPreviewSmallFragment;
  resource?: ResourcePreviewSmallFragment;
  group?: {
    id: string;
    name: string;
    groupType: GroupType;
    isOnCallSynced: boolean;
  };
  accessReview?: AccessReviewFragment;
  loadingEntity?: boolean;
  label?: string;
  adminOnly?: boolean;
}

export const getSyncLabel = (
  lastSuccessfulSyncTask: SyncTaskFragment | null,
  syncErrors: SyncErrorFragment[]
) => {
  let label: string;
  if (syncErrors.length > 0) {
    const sortedErrors = syncErrors
      .slice()
      .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt));
    let newestSyncError = sortedErrors[0];

    label = moment(newestSyncError.createdAt).fromNow();
  } else if (lastSuccessfulSyncTask) {
    label = moment(lastSuccessfulSyncTask.updatedAt).fromNow();
  } else {
    label = "Not yet synced";
  }
  return label;
};

const useSyncActionIcon = (props: Props): ActionIcon | undefined => {
  const hasQueryOktaCache = useFeatureFlag(
    FeatureFlag.QueryOktaRoleAssignmentsCache
  );
  const { orgState } = useContext(OrgContext);
  const [syncInProgress, setSyncInProgress] = useState(false);
  const isCachedOktaRoleSync =
    hasQueryOktaCache &&
    props.syncType === SyncType.PullConnectionsSingleResource &&
    props.resource?.resourceType === ResourceType.OktaRole;
  const isHrIdpUnlinked =
    props.syncType == SyncType.PullHrIdpData && !orgState.isIdpEnabled;
  const isDisabled = syncInProgress || isCachedOktaRoleSync || isHrIdpUnlinked;
  const logEvent = useLogEvent();

  const displayToast = useRef(false);
  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();

  const [startSyncMutation] = useStartSyncMutation();
  const { refetch } = useSyncTaskQuery({
    skip: true,
  });
  const { refetch: refetchOngoing } = useSyncStatusQuery({
    skip: true,
  });

  const closeToastButton = CustomCloseButton(ToastStyle.Default, () => {
    displayToast.current = false;
  });

  useMountEffect(() => {
    const fetchOngoingSync = async (
      syncType: SyncType,
      syncedEntityName: string
    ) => {
      const ongoingResult = await refetchOngoing({
        input: {
          syncType: syncType,
          connectionId: props.connection?.id,
        },
      });
      const data = ongoingResult.data;
      if (data && data.syncStatus.__typename === "SyncStatusResult") {
        const taskID = data.syncStatus.ongoingSyncTask
          ? data.syncStatus.ongoingSyncTask.id
          : null;
        if (taskID != null) {
          setSyncInProgress(true);
          setTimeout(() => {
            checkSyncStatus(taskID, syncedEntityName, 500, 250);
          }, 250);
        }
      }
    };
    fetchOngoingSync(props.syncType, syncedEntityName);
  });

  let syncedEntityName: string;
  let connectionId: string | undefined;
  let resourceId: string | undefined;
  let groupId: string | undefined;
  let accessReviewId: string | undefined;
  switch (props.syncType) {
    case SyncType.PullConnectionsAll:
      syncedEntityName = "all resources and groups";
      break;
    case SyncType.PullConnectionsAllResources:
      syncedEntityName = "all resources";
      break;
    case SyncType.PullConnectionsAllGroups:
      syncedEntityName = "all groups";
      break;
    case SyncType.PullConnectionsSingleConnection:
    case SyncType.PullConnectionsSingleConnectionFast:
      if (props.connection) {
        syncedEntityName = `app "${props.connection.name}"`;
        connectionId = props.connection.id;
      } else if (!props.loadingEntity) {
        logError(new Error(`useSyncActionIcon app must not be undefined`));
        return;
      }
      break;
    case SyncType.PullConnectionsSingleResource:
      if (props.resource) {
        if (isNativeResourceType(props.resource.resourceType)) {
          return;
        }
        syncedEntityName = `resource "${props.resource.name}"`;
        resourceId = props.resource.id;
      } else if (!props.loadingEntity) {
        logError(new Error(`useSyncActionIcon resource must not be undefined`));
        return;
      }
      break;
    case SyncType.PullConnectionsSingleGroup:
      if (props.group) {
        if (
          isNativeGroupType(props.group.groupType) &&
          // Allow on-call team reviewers sync
          !props.group.isOnCallSynced
        ) {
          return;
        }
        syncedEntityName = `group "${props.group.name}"`;
        groupId = props.group.id;
      } else if (!props.loadingEntity) {
        logError(new Error(`useSyncActionIcon group must not be undefined`));
        return;
      }
      break;
    case SyncType.PullHrIdpData:
      syncedEntityName = "all HR and IDP data";
      break;
    case SyncType.PullUarRemoteTickets:
      accessReviewId = props.accessReview?.id;
      syncedEntityName = "all linked tickets";
      break;
    case SyncType.PullPropagationTickets:
      if (
        props.resource &&
        props.resource.resourceType === ResourceType.Custom
      ) {
        syncedEntityName = `resource "${props.resource.name}"`;
        resourceId = props.resource.id;
      } else if (
        props.connection &&
        props.connection.connectionType === ConnectionType.Custom
      ) {
        syncedEntityName = `app "${props.connection.name}"`;
        connectionId = props.connection.id;
      } else if (!props.loadingEntity) {
        logError(
          new Error(
            `useSyncActionIcon resource must not be undefined for custom resources`
          )
        );
        return;
      }
      break;
    default:
      logError(
        new Error(
          `unsupported SyncType in useSyncActionIcon: ${props.syncType}`
        )
      );
      return;
  }

  const handleSyncFinished = async (status: SyncTaskStatus) => {
    // If finished, refetch the relevant data
    if (props.queriesToRefetch) {
      try {
        await refetchQueries({
          include: props.queriesToRefetch,
        });
      } catch (error) {
        logError(error, `failed to refresh data after sync`);
      }
    }

    try {
      await refetchQueries({ include: ["SyncStatus"] });
    } catch (error) {
      logError(error, `failed to refresh data after sync`);
    }

    if (status === SyncTaskStatus.Completed && displayToast.current) {
      displaySuccessToast(`Success: finished syncing ${syncedEntityName}`);
    } else if (displayToast.current) {
      displayErrorToast(`Finished syncing ${syncedEntityName} with errors`);
    }
    setSyncInProgress(false);
  };

  const checkSyncStatus = async (
    syncTaskId: string,
    syncedEntityName: string,
    waitInterval: number,
    totalWaited: number
  ) => {
    const syncTaskErrorText = `Error: could not get sync status`;
    try {
      const result = await refetch({
        input: {
          id: syncTaskId,
        },
      });
      const { data: syncTaskData } = result;
      let status: SyncTaskStatus;

      if (syncTaskData) {
        switch (syncTaskData?.syncTask.__typename) {
          case "SyncTaskResult":
            status = syncTaskData.syncTask.syncTask.status;
            if (status === SyncTaskStatus.Started) {
              // If in progress, keep polling to check for sync task completion
              if (displayToast.current) {
                displayLoadingToast(
                  `Syncing ${syncedEntityName}...`,
                  false,
                  closeToastButton
                );
              }
              setTimeout(() => {
                checkSyncStatus(
                  syncTaskId,
                  syncedEntityName,
                  Math.min(2000, waitInterval * 2),
                  totalWaited + waitInterval
                );
              }, waitInterval);
            } else {
              await handleSyncFinished(status);
            }

            break;
          case "SyncTaskNotFoundError":
          default:
            // Upon error, stop polling
            logError(new Error(syncTaskErrorText));
            displayErrorToast(syncTaskErrorText);
            setSyncInProgress(false);
        }
      }
    } catch (error) {
      logError(error, syncTaskErrorText);
      if (displayToast.current) {
        displayErrorToast(syncTaskErrorText);
      }
      setSyncInProgress(false);
    }
  };

  const handleStartSync = async () => {
    displayToast.current = true;
    setSyncInProgress(true);
    const startSyncErrorText = `Error: failed to sync ${syncedEntityName}`;
    logEvent({
      name: "apps_sync_click",
      properties: { syncType: props.syncType },
    });
    try {
      const { data } = await startSyncMutation({
        variables: {
          input: {
            syncType: props.syncType,
            connectionId: connectionId,
            resourceId: resourceId,
            groupId: groupId,
            accessReviewId: accessReviewId,
          },
        },
      });
      let syncTaskId: string;
      switch (data?.startSync.__typename) {
        case "StartSyncResult":
          // Poll to check for sync task completion
          syncTaskId = data.startSync.syncTask.id;
          setTimeout(() => {
            checkSyncStatus(syncTaskId, syncedEntityName, 500, 250);
          }, 250);
          break;
        case "InvalidSyncTypeError":
        case "ConnectionNotFoundError":
        case "ResourceNotFoundError":
        case "GroupNotFoundError":
        case "AccessReviewNotFoundError":
        default:
          logError(new Error(startSyncErrorText));
          displayErrorToast(startSyncErrorText);
          setSyncInProgress(false);
      }
    } catch (error) {
      logError(error, startSyncErrorText);
      displayErrorToast(startSyncErrorText);
      setSyncInProgress(false);
    }
  };

  const getSyncLabel = () => {
    if (syncInProgress) return "Syncing...";
    if (props.label) {
      return props.label;
    }
    if (
      props.syncType === SyncType.PullConnectionsSingleGroup ||
      props.syncType === SyncType.PullConnectionsSingleResource
    ) {
      return "Sync item";
    }
    return "Start sync";
  };

  return {
    label: getSyncLabel(),
    iconName: "refresh",
    onClick: handleStartSync,
    adminOnly: props.adminOnly ?? true,
    disabled: isDisabled,
  };
};

export default useSyncActionIcon;
