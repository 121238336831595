import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Button, ButtonV3, Icon, PopoverV3 } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { isNil } from "lodash";
import React from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import * as styles from "./Modal.css";

const ModalBody: React.FC<{}> = (props) => {
  return <DialogContent>{props.children}</DialogContent>;
};
// TODO: @andrewsy-opal consolidate with RightAction type
export type ExtraActions = {
  type?: PropsFor<typeof ButtonV3>["type"];
  label?: string;
  onClick: () => void;
  iconName?: PropsFor<typeof Icon>["name"];
  disabledTooltip?: string;
};

type ModalFooterProps = {
  primaryButtonLabel?: string;
  onPrimaryButtonClick?: () => void;
  primaryButtonDisabled?: boolean;
  primaryButtonLoading?: boolean;
  primaryButtonType?: PropsFor<typeof Button>["type"];
  primaryButtonTypeV3?: PropsFor<typeof ButtonV3>["type"];

  secondaryButtonLabel?: string;
  onSecondaryButtonClick?: () => void;
  secondaryButtonDisabled?: boolean;

  extraActions?: ExtraActions[];
  leftComponent?: React.ReactNode;
  primaryButtonDisabledPopover?: string;
  secondaryButtonDisabledPopover?: string;
};

const ModalFooter: React.FC<ModalFooterProps> = (props) => {
  const {
    primaryButtonLabel,
    onPrimaryButtonClick,
    secondaryButtonLabel,
    onSecondaryButtonClick,
    extraActions,
    leftComponent,
  } = props;
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  return (
    <DialogActions>
      <div className={hasV3 ? styles.footerV3 : styles.footer}>
        {leftComponent && (
          <div
            className={sprinkles({
              display: "flex",
              alignItems: "center",
              marginRight: "auto",
            })}
          >
            {leftComponent}
          </div>
        )}
        {props.children && <>{props.children}</>}
        {extraActions &&
          extraActions.map((action, index) => (
            <ButtonV3
              key={index}
              label={action.label}
              onClick={action.onClick}
              type={action.type}
              leftIconName={action.iconName}
              disabledTooltip={action.disabledTooltip}
              size="sm"
            />
          ))}
        {secondaryButtonLabel &&
          onSecondaryButtonClick &&
          (hasV3 ? (
            <PopoverV3
              content={
                <div className={styles.tooltip}>
                  {props.secondaryButtonDisabledPopover}
                </div>
              }
              enabled={!isNil(props.secondaryButtonDisabledPopover)}
            >
              <ButtonV3
                key={secondaryButtonLabel}
                label={secondaryButtonLabel}
                onClick={onSecondaryButtonClick}
                disabled={props.secondaryButtonDisabled}
                type="defaultSecondary"
                size="sm"
              />
            </PopoverV3>
          ) : (
            <Button
              label={secondaryButtonLabel}
              onClick={onSecondaryButtonClick}
              disabled={props.secondaryButtonDisabled}
              borderless
            />
          ))}
        {primaryButtonLabel &&
          onPrimaryButtonClick &&
          (hasV3 ? (
            <PopoverV3
              content={
                <div className={styles.tooltip}>
                  {props.primaryButtonDisabledPopover}
                </div>
              }
              enabled={!isNil(props.primaryButtonDisabledPopover)}
            >
              <ButtonV3
                key={primaryButtonLabel}
                label={primaryButtonLabel}
                onClick={onPrimaryButtonClick}
                disabled={props.primaryButtonDisabled}
                loading={props.primaryButtonLoading}
                type={props.primaryButtonTypeV3 || "main"}
                size="sm"
              />
            </PopoverV3>
          ) : (
            <Button
              type={props.primaryButtonType || "primary"}
              label={primaryButtonLabel}
              onClick={onPrimaryButtonClick}
              disabled={props.primaryButtonDisabled}
              loading={props.primaryButtonLoading}
            />
          ))}
      </div>
    </DialogActions>
  );
};

interface ModalProps {
  /** Boolean describing if the modal should be shown or not. */
  isOpen: boolean;
  /** Function that will be run when the modal is requested to be closed. */
  onClose: (event: React.MouseEvent | React.KeyboardEvent) => void;
  /** Title of the modal. */
  title: string;
  subtitle?: string;
  /**
   * If `true`, clicking the backdrop will not fire the `onClose` callback.
   * @default false
   */
  disableBackdropClick?: boolean;
  /** `sm` is the default.
   *  `false` doesn't set the max width of the modal.
   *  The width of the modal will never exceed the width of the page. */
  maxWidth?: "sm" | "md" | "lg" | false;
  /**
   * If `true`, the dialog stretches to `maxWidth`.
   * Notice that the dialog width grow is limited by the default margin.
   * @default false
   */
  fullWidth?: boolean;
}

const Modal: React.FC<ModalProps> = (props) => {
  const { maxWidth = "sm", fullWidth = false } = props;
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      disableBackdropClick={props.disableBackdropClick ?? false}
      scroll="paper"
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      // Prevent scrolling modals on viz from panning
      onWheel={(e) => e.stopPropagation()}
    >
      <DialogTitle>
        <div className={styles.header}>
          <div className={hasV3 ? styles.headerTitleV3 : styles.headerTitle}>
            {props.title}
          </div>
          <div className={styles.closeButton} onClick={props.onClose}>
            <Icon name="x" size="sm" />
          </div>
        </div>
        {props.subtitle ? (
          <div className={styles.subtitle}>{props.subtitle}</div>
        ) : null}
      </DialogTitle>
      {props.children}
    </Dialog>
  );
};

export default Object.assign(Modal, {
  Body: ModalBody,
  Footer: ModalFooter,
});
