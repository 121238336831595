import { EntityType } from "api/generated/graphql";
import clsx from "clsx";
import { EntityIcon, Icon, Tooltip } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { colorVars, fontWeightVars } from "css/vars.css";
import React from "react";
import { Link } from "react-router-dom";
import { truncate } from "utils/text";

import * as styles from "./Label.css";

type LabelProps = {
  label: string;
  /** Used to determine the default icon if no icon is passed in. */
  entityType?: EntityType.User | EntityType.Resource | EntityType.Group;
  icon?: IconData;
  iconSize?: PropsFor<typeof Icon>["size"];
  /** Extra detail to add after the label text */
  detail?: string;
  linkTo?: string;
  onClick?: () => void;
  /** The number of characters to truncate to. Defaults to 50.
   *  Set to null to not truncate.
   */
  truncateLength?: number | null;
  oneLine?: boolean;
  color?: colorVars;
  iconColor?: colorVars;
  inactive?: boolean;
  inline?: boolean;
  tooltipText?: PropsFor<typeof Tooltip>["tooltipText"];
  fontWeight?: fontWeightVars;
};

type IconName = PropsFor<typeof Icon>["name"];

const Label: React.FC<LabelProps> = (props) => {
  const {
    truncateLength = 50,
    entityType,
    inactive = false,
    inline = false,
    oneLine = false,
  } = props;

  let iconStyle: "rounded" | "default" =
    entityType === EntityType.User ? "rounded" : "default";

  const renderIcon = () => {
    if (props.icon) {
      if (props.icon.type === "entity") {
        return (
          <div className={styles.icon({ inline })}>
            <EntityIcon
              type={props.icon.entityType}
              includeBrand={props.icon.includeBrand ?? true}
              size={props.iconSize ?? "md"}
              iconStyle={props.icon?.style || iconStyle}
            />
          </div>
        );
      }
      let iconData: IconData = props.icon;
      if (iconData.icon === "" && entityType === EntityType.User) {
        iconData.icon = defaultAvatarURL;
      }
      return (
        <div className={styles.icon({ inactive, inline })}>
          <Icon
            data={iconData}
            size={props.iconSize ?? "sm"}
            iconStyle={iconStyle}
            color={props.iconColor ?? props.color}
          />
        </div>
      );
    }

    // Try to use a default icon based on entityType.
    let iconName: IconName;
    switch (props.entityType) {
      case EntityType.User:
        iconName = "default-avatar";
        break;
      case EntityType.Group:
        iconName = "users";
        break;
      case EntityType.Resource:
        iconName = "cube";
        break;
    }
    if (iconName) {
      return (
        <div className={styles.icon({ inactive, inline })}>
          <Icon
            name={iconName}
            size={props.iconSize ?? "sm"}
            iconStyle={iconStyle}
            color={props.iconColor ?? props.color}
          />
        </div>
      );
    }
    return null;
  };

  const renderMainLabel = () => {
    let mainLabel: React.ReactNode = (
      <span
        className={styles.text({
          oneLine,
          inactive,
        })}
      >
        {props.label}
      </span>
    );
    if (props.tooltipText) {
      // If a tooltip is provided, wrap the label in a Tooltip component, prefer this over the truncated label
      mainLabel = (
        <Tooltip tooltipText={props.tooltipText}>
          <span className={styles.text({ oneLine, inactive })}>
            {mainLabel}
          </span>
        </Tooltip>
      );
    } else if (truncateLength) {
      const truncatedLabel = truncate(props.label, truncateLength);
      if (truncatedLabel.length !== props.label.length) {
        mainLabel = (
          <Tooltip tooltipText={props.label}>
            <span className={styles.text({ oneLine, inactive })}>
              {truncatedLabel}
            </span>
          </Tooltip>
        );
      }
    } else if (oneLine) {
      // Fallback if truncateLength is null and we opt for the CSS truncation, we want to still show a Tooltip on hover
      // https://stackoverflow.com/questions/56588625/react-show-material-ui-tooltip-only-for-text-that-has-ellipsis
      // TODO/FIXME(giulio) this mainLabel used to be wrapped with <Tooltip dynamicTooltip, but unfortunately
      // the performance were horrifying. Figure out how to memoize the component when that feature is used.
      mainLabel = (
        <span className={styles.text({ oneLine, inactive })}>
          {props.label}
        </span>
      );
    }

    if (props.linkTo) {
      return (
        <Link to={props.linkTo} className={styles.link}>
          {mainLabel}
        </Link>
      );
    } else if (props.onClick) {
      return (
        <span className={styles.link} onClick={props.onClick}>
          {mainLabel}
        </span>
      );
    }

    return mainLabel;
  };

  const labelStyle = clsx(
    styles.label({ inline, oneLine }),
    sprinkles({ color: props.color, fontWeight: props.fontWeight })
  );

  return (
    <div className={labelStyle}>
      {renderIcon()}
      {renderMainLabel()}
      {props.detail ? (
        <span className={styles.detail}>{props.detail}</span>
      ) : null}
    </div>
  );
};

export default Label;
