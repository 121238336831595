import { RecommendationsSubscoreType } from "api/generated/graphql";
import { PillV3 } from "components/pills/PillsV3";
import { PopoverV3 } from "components/ui";
import { IconData } from "components/ui/utils";

interface Props {
  type: RecommendationsSubscoreType;
  hidePopover?: boolean;
  inverse?: boolean;
  compact?: boolean;
}

import * as styles from "./ThreatPill.css";

export const permanentAccessIcon = {
  type: "name",
  icon: "alarm-clock",
  style: "rounded",
} as IconData;

export const unusedAccessIcon = {
  type: "name",
  icon: "cube-outline",
  style: "rounded",
} as IconData;

export const resourceSensitivityIcon = {
  type: "name",
  icon: "alert-triangle",
  style: "rounded",
} as IconData;

export const outsideAccessIcon = {
  type: "name",
  icon: "target",
  style: "rounded",
} as IconData;

export const unusedAccessRiskFactorText = "Unused for over 30 days";
export const perpetualAccessRiskFactorText = "Permanent Access";
export const outsideAccessRiskFactorText = "Outside Access";
export const resourceVulnerabilityRiskFactorText = "Resource Sensitivity";

const typeToThreatContext = (type: RecommendationsSubscoreType) => {
  switch (type) {
    case RecommendationsSubscoreType.UnusedAccess:
      return "Unused access to sensitive digital resources risks unauthorized entry.";
    case RecommendationsSubscoreType.PerpetualAccess:
      return "Permanent access causes over-provisioning, while timed permissions reduce attack surface.";
    case RecommendationsSubscoreType.UnmanagedAccess:
      return "Access to sensitive digital resources outside Opal's perimeter poses security risks.";
    case RecommendationsSubscoreType.ResourceVulnerability:
      return "Unsecured access to sensitive resources jeopardizes privacy.";
  }
};
const delay = 100;

const ThreatPill = (props: Props) => {
  const { type, hidePopover, inverse, compact } = props;
  var keyText = undefined;
  var icon = undefined;

  switch (type) {
    case RecommendationsSubscoreType.UnusedAccess:
      keyText = unusedAccessRiskFactorText;
      icon = unusedAccessIcon;
      break;
    case RecommendationsSubscoreType.PerpetualAccess:
      keyText = perpetualAccessRiskFactorText;
      icon = permanentAccessIcon;
      break;
    case RecommendationsSubscoreType.UnmanagedAccess:
      keyText = outsideAccessRiskFactorText;
      icon = outsideAccessIcon;
      break;
    case RecommendationsSubscoreType.ResourceVulnerability:
      keyText = resourceVulnerabilityRiskFactorText;
      icon = resourceSensitivityIcon;
      break;
  }

  if (compact) {
    keyText = null;
  }

  if (hidePopover) {
    return (
      <PillV3
        keyText={keyText}
        pillColor={inverse ? "RedInverse" : "Red"}
        icon={icon}
      />
    );
  } else {
    return (
      <PopoverV3
        content={
          <div className={styles.tooltip}>{typeToThreatContext(type)}</div>
        }
        position={"right"}
        delay={delay}
      >
        <PillV3
          keyText={keyText}
          pillColor={inverse ? "RedInverse" : "Red"}
          icon={icon}
        />
      </PopoverV3>
    );
  }
};

export default ThreatPill;
