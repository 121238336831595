import { getModifiedErrorMessage } from "api/ApiContext";
import {
  ConnectionPreviewTinyFragment,
  EntityType,
  GroupGroupFragment,
  GroupType,
  Maybe,
  useRemoveGroupGroupsMutation,
} from "api/generated/graphql";
import { ResourceLabel, TimeLabel } from "components/label/Label";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { EntityIcon, Input, Modal } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import { compareAccessPaths } from "components/ui/table/utils";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import { useState } from "react";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import {
  GroupGroupAccessPathsInfo,
  GroupGroupAccessPointsLabel,
} from "views/resources/GroupGroupAccessPointsLabel";
import { dropNothings } from "views/utils";

import { groupTypeInfoByType } from "../../components/label/GroupTypeLabel";
import { useToast } from "../../components/toast/Toast";
import { usePushTaskLoader } from "../../utils/sync/usePushTaskLoader";
import * as styles from "./MemberGroupsTableV3.css";

interface MemberGroupRow {
  id: string;
  memberGroupId: string;
  groupGroup: GroupGroupFragment;
  name?: string;
  connection?: ConnectionPreviewTinyFragment | null;
  role: string;
  accessPaths?: GroupGroupAccessPathsInfo;
  expiry?: string | null;
  // syncStatus?: PropagationStatusCode | null;
  authorizedActions?: string[] | null;
}

type Props = {
  group: {
    id: string;
    memberGroups: (GroupGroupFragment & {
      memberGroup?: {
        name: string;
        connection?: ConnectionPreviewTinyFragment | null;
        authorizedActions?: string[] | null;
      } | null;
    })[];
    authorizedActions?: string[] | null;
  };
};

export const MemberGroupsTableV3 = (props: Props) => {
  const transitionTo = useTransitionTo();
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [removeGroupsErrorMessage, setRemoveGroupsErrorMessage] = useState<
    Maybe<string>
  >(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const startPushTaskPoll = usePushTaskLoader();
  const logEvent = useLogEvent();
  const { displaySuccessToast } = useToast();

  const [
    removeGroupGroups,
    { loading: removeLoading },
  ] = useRemoveGroupGroupsMutation();

  const canManage = props.group.authorizedActions?.includes(
    AuthorizedActionManage
  );

  const GROUP_GROUP_COLUMNS: Header<MemberGroupRow>[] = dropNothings([
    {
      id: "name",
      label: "Name",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <div
            className={styles.groupNameField}
            onClick={(event) => {
              transitionTo(
                {
                  pathname: getResourceUrlNew({
                    entityId: row.groupGroup.memberGroupId,
                    entityType: EntityType.Group,
                  }),
                },
                event
              );
            }}
          >
            <ResourceLabel
              text={row.name}
              bold={true}
              pointerCursor={true}
              maxChars="auto"
            />
          </div>
        );
      },
      width: 130,
    },
    {
      id: "memberGroupId",
      label: "Type",
      customCellRenderer: (row) => {
        const entityType: GroupType | undefined =
          row.groupGroup.memberGroup?.groupType;
        const label =
          groupTypeInfoByType[row.groupGroup.memberGroup?.groupType!].name;

        if (!entityType) return <></>;
        if (entityType) {
          return (
            <span
              className={sprinkles({
                display: "flex",
                gap: "sm",
                alignItems: "center",
              })}
            >
              <EntityIcon type={entityType} />
              {label}
            </span>
          );
        }
        return <div>{label}</div>;
      },
    },
    {
      id: "role",
      label: "Role",
    },
    {
      id: "accessPaths",
      label: "Source Of Access",
      customCellRenderer: (row) => {
        if (!row.groupGroup.quickAccess) {
          return "Direct";
        }

        return (
          <GroupGroupAccessPointsLabel
            containingGroup={row.groupGroup.containingGroup}
            memberGroup={row.groupGroup.memberGroup}
            role={row.groupGroup.accessLevel}
            groupGroupId={row.id}
            quickAccess={row.groupGroup.quickAccess}
          />
        );
      },
      sortingFn: (rowA, rowB): number => {
        return compareAccessPaths(
          rowA.getValue("accessPaths"),
          rowB.getValue("accessPaths")
        );
      },
      width: 110,
    },
    {
      id: "connection",
      label: "App",
      customCellRenderer: (row) => {
        if (!row.connection) return <></>;
        const connection = row.connection;
        return (
          <div
            className={sprinkles({
              display: "flex",
              gap: "sm",
              alignItems: "center",
            })}
            onClick={(event) => {
              transitionTo(
                {
                  pathname: getResourceUrlNew({
                    entityId: connection.id,
                    entityType: EntityType.Connection,
                  }),
                },
                event
              );
            }}
          >
            <ResourceLabel
              text={connection.name}
              icon={
                <EntityIcon
                  type={connection.connectionType}
                  iconStyle="rounded"
                />
              }
              iconLarge
              bold={true}
              pointerCursor={true}
              maxChars="auto"
            />
          </div>
        );
      },
    },
    {
      id: "expiry",
      label: "Expires",
      customCellRenderer: (row) => {
        const expirationTime = row.groupGroup.quickAccess?.expiration
          ? moment(new Date(row.groupGroup.quickAccess?.expiration))
          : null;
        return (
          <TimeLabel
            time={expirationTime}
            supportTicket={row.groupGroup.quickAccess?.supportTicket}
            useExpiringLabel
            indefiniteLablel="Permanent Access"
          />
        );
      },
      width: 125,
    },
  ]);

  let memberGroups = props.group.memberGroups;
  if (searchQuery) {
    memberGroups = memberGroups.filter((groupGroup) =>
      groupGroup.memberGroup?.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  }
  const rowsById: Record<string, MemberGroupRow> = {};
  const rows: MemberGroupRow[] = memberGroups.map((memberGroup) => {
    const row = {
      id: memberGroup.id,
      memberGroupId: memberGroup.memberGroupId,
      name: memberGroup.memberGroup?.name,
      groupGroup: memberGroup,
      connection: memberGroup.memberGroup?.connection,
      role: memberGroup.accessLevel.accessLevelName || "\u2014",
      authorizedActions: memberGroup.memberGroup?.authorizedActions,
      // syncStatus: groupGroup.propagationStatus?.statusCode,
    };
    rowsById[row.id] = row;
    return row;
  });

  const bulkRightActions: PropsFor<
    typeof TableHeader
  >["bulkRightActions"] = dropNothings([
    canManage
      ? {
          label: "Remove",
          type: "danger",
          onClick: () => setShowRemoveModal(true),
          iconName: "trash",
        }
      : null,
  ]);

  const submitRemoval = async (groupGroupIdsToRemove: string[]) => {
    logEvent({
      name: "apps_remove_groups_from_group",
      properties: {
        numGroupsRemoved: _.uniqBy(groupGroupIdsToRemove, "id").length,
      },
    });
    try {
      const { data } = await removeGroupGroups({
        variables: {
          input: {
            groupGroupsIds: groupGroupIdsToRemove,
          },
        },
        refetchQueries: ["Group"],
      });
      switch (data?.removeGroupGroups.__typename) {
        case "RemoveGroupGroupsResult":
          if (data.removeGroupGroups.taskId) {
            startPushTaskPoll(data.removeGroupGroups.taskId, {
              refetchOnComplete: { groupId: props.group.id },
            });
          } else {
            displaySuccessToast(
              `Successfully removed ${pluralize(
                "groups",
                groupGroupIdsToRemove.length,
                true
              )}`
            );
          }
          setShowRemoveModal(false);
          setRemoveGroupsErrorMessage(null);
          setSelectedItemIds([]);
          setSearchQuery("");
          break;
        case "UserFacingError":
          setRemoveGroupsErrorMessage(data.removeGroupGroups.message);
          break;
        default:
          logError(new Error(`failed to remove member groups from group`));
          setRemoveGroupsErrorMessage(
            "Error: failed to remove member groups from group"
          );
      }
    } catch (error) {
      logError(error, "failed to remove member groups from group");
      setRemoveGroupsErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to remove member groups from group",
          error
        )
      );
    }
  };

  const getCheckboxDisabledReason = (row: MemberGroupRow) => {
    const canManageResource = row.authorizedActions?.includes(
      AuthorizedActionManage
    );
    if (!canManageResource) {
      return "You can only remove groups which you administer. Please ask your Opal admin to remove it.";
    } else if (!row.groupGroup.quickAccess?.hasDirectAccess) {
      return "The group has no direct access to remove.";
    }
    return undefined;
  };

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        height: "100%",
      })}
    >
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          gap: "sm",
          marginBottom: "md",
        })}
      >
        <div className={styles.searchInput}>
          <Input
            leftIconName="search"
            type="search"
            style="search"
            placeholder="Filter by name"
            value={searchQuery}
            onChange={setSearchQuery}
          />
        </div>
      </div>
      <TableHeader
        entityType={EntityType.Group}
        totalNumRows={new Set(rows.map((row) => row.memberGroupId)).size}
        selectedNumRows={selectedItemIds.length}
        defaultRightActions={
          canManage
            ? [
                {
                  label: "Add Groups",
                  type: "mainSecondary",
                  onClick: () => {
                    transitionTo({
                      pathname: `/groups/${props.group.id}/add-groups`,
                    });
                  },
                  iconName: "plus",
                },
              ]
            : []
        }
        bulkRightActions={bulkRightActions}
      />
      <Table
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        columns={GROUP_GROUP_COLUMNS}
        defaultSortBy="name"
        checkedRowIds={new Set(selectedItemIds)}
        onCheckedRowsChange={
          canManage
            ? (checkedRowIds, checked) => {
                if (checked) {
                  setSelectedItemIds((prev) => [...prev, ...checkedRowIds]);
                  return;
                } else {
                  setSelectedItemIds((prev) =>
                    prev.filter((id) => !checkedRowIds.includes(id))
                  );
                }
              }
            : undefined
        }
        selectAllChecked={
          selectedItemIds.length > 0 &&
          selectedItemIds.length ===
            rows.filter((row) => !getCheckboxDisabledReason(row)).length
        }
        onSelectAll={(checked) =>
          checked
            ? setSelectedItemIds(
                rows
                  .filter((row) => !getCheckboxDisabledReason(row))
                  .map((row) => row.id)
              )
            : setSelectedItemIds([])
        }
        getCheckboxDisabledReason={getCheckboxDisabledReason}
      />
      {showRemoveModal && (
        <Modal
          isOpen
          title="Remove Group Assignment"
          onClose={() => setShowRemoveModal(false)}
        >
          <Modal.Body>
            {removeGroupsErrorMessage && (
              <ModalErrorMessage errorMessage={removeGroupsErrorMessage} />
            )}
            Are you sure you want to remove{" "}
            {pluralize("direct group assignment", selectedItemIds.length, true)}{" "}
            from this group?
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Remove"
            primaryButtonDisabled={selectedItemIds.length === 0}
            primaryButtonLoading={removeLoading}
            onPrimaryButtonClick={() => {
              const groupGroupIdsToRemove: string[] = [];
              for (const rowId of selectedItemIds) {
                const row = rowsById[rowId];
                groupGroupIdsToRemove.push(row.id);
              }
              submitRemoval(groupGroupIdsToRemove);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default MemberGroupsTableV3;
