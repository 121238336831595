import {
  AwsIdentityCenterImportSetting,
  BulkReviewerStagesInput,
  BulkUpdateItemsInput,
  BundleDetailFragment,
  ConfigurationTemplateFragment,
  ConnectionMetadataInput,
  ConnectionPreviewLargeFragment,
  ConnectionType,
  ConnectionVisibilityGroupFragment,
  CreateConfigurationTemplateInput,
  GeneralSettingType,
  GroupTagPreviewFragment,
  GroupType,
  ImportSetting,
  MessageChannelFragment,
  OwnerFragment,
  OwnerPreviewSmallFragment,
  RequestConfigInput,
  RequestConfigurationFragment,
  ResourceTagPreviewFragment,
  ResourceTicketPropagationFragment,
  ResourceType,
  ReviewStageOperator,
  RiskSensitivityDetails,
  ThirdPartyProvider,
  UpdateConfigurationTemplateInput,
  UpdateConnectionInput,
  UserDropdownPreviewFragment,
  UserOverviewFragment,
  UserTagFragment,
  Visibility,
} from "api/generated/graphql";
import { GenericReviewer } from "components/approvals_editor/generic_reviewers";
import { getNumApproversInStage } from "components/approvals_editor/utils";
import { generateUniqueId } from "components/ui/utils";
import _ from "lodash";
import { resourceTypeCanBeAccessed } from "utils/directory/resources";
import { OrgContextState } from "views/settings/OrgContext";

import {
  BundleConfig,
  CLIENT_REQUEST_CONFIG_ID_PREFIX,
  ConnectionConfig,
  OwnerConfig,
  ResourceConfig,
  ResourceRequestConfig,
  ResourceTicketPropagation,
  ReviewerStage,
  UserConfig,
} from "./common";

type ResourceForConfig = {
  id: string;
  name: string;
  remoteName: string;
  remoteId: string;
  resourceType: ResourceType;
  description: string;
  connectionId: string;
  connection?: {
    connectionType: ConnectionType;
    name: string;
    visibility: Visibility;
    visibilityGroups: { visibilityGroupId: string }[];
  } | null;
  parentResource?: {
    name: string;
    resourceType: ResourceType;
    visibility: Visibility;
    visibilityGroups: { visibilityGroupId: string }[];
    configTemplate?: {
      id: string;
      name: string;
    } | null;
  } | null;
  parentResourceId?: string | null;
  useParentConfig: boolean;
  adminOwner?: OwnerPreviewSmallFragment | null;
  visibility: Visibility;
  visibilityGroups: {
    visibilityGroupId: string;
  }[];
  auditMessageChannels?: MessageChannelFragment[] | null;
  requireMfaToApprove: boolean;
  requireMfaToConnect: boolean;
  tags: ResourceTagPreviewFragment[];
  commonMetadata: {
    matchRemoteName: boolean;
    matchRemoteDescription: boolean;
  };
  requestConfigs: RequestConfigurationFragment[];
  childrenDefaultConfigTemplate?: {
    id: string;
    name: string;
  } | null;
  ticketPropagation?: ResourceTicketPropagationFragment | null;
  riskSensitivity?: RiskSensitivityDetails | null;
  customRequestNotification?: string | null;
};

export function makeConfigForResource(
  resource: ResourceForConfig,
  configTemplate?: ResourceConfig["configurationTemplate"]
): ResourceConfig {
  return {
    entityId: resource.id,
    name: resource.name,
    remoteName: resource.remoteName,
    remoteId: resource.remoteId,
    entityType: resource.resourceType,
    connectionId: resource.connectionId,
    connectionType: resource.connection?.connectionType,
    connectionName: resource.connection?.name,
    connectionVisibility: resource.connection?.visibility,
    connectionVisibilityGroups: resource.connection?.visibilityGroups.map(
      (g) => g.visibilityGroupId
    ),
    parentResourceId: resource.parentResourceId ?? undefined,
    parentResourceName: resource.parentResource?.name ?? undefined,
    parentResourceType: resource.parentResource?.resourceType ?? undefined,
    // TODO: make this a multi-level look-up once we have more than 1 level of parent resource
    parentResourceVisibility: resource.parentResource?.visibility ?? undefined,
    parentResourceVisibilityGroups: resource.parentResource?.visibilityGroups.map(
      (g) => g.visibilityGroupId
    ),
    parentConfigurationTemplateId: resource.parentResource?.configTemplate?.id,
    parentConfigurationTemplateName:
      resource.parentResource?.configTemplate?.name,
    useParentConfig: resource.useParentConfig,
    description: resource.description,
    adminOwner: resource.adminOwner ?? null,
    visibility: resource.visibility,
    visibilityGroups: resource.visibilityGroups.map((g) => g.visibilityGroupId),
    messageChannels: resource.auditMessageChannels ?? [],
    requireMfaToApprove: resource.requireMfaToApprove,
    requireMfaToConnect: resource.requireMfaToConnect,
    tagIds: resource.tags.map((tag) => tag.tagId),
    tagsWithSource: resource.tags,
    commonMetadata: resource.commonMetadata,
    requestConfigs: resource.requestConfigs.map(makeRequestConfig),
    configurationTemplate: configTemplate,
    childrenDefaultConfigTemplate:
      resource.childrenDefaultConfigTemplate ?? undefined,
    ticketPropagation: makeResourceTicketPropagation(
      resource.ticketPropagation ?? undefined
    ),
    riskSensitivity: resource.riskSensitivity ?? undefined,
    customRequestNotification: resource.customRequestNotification || undefined,
  };
}

function makeResourceTicketPropagation(
  ticketPropagation: ResourceTicketPropagationFragment | undefined
): ResourceTicketPropagation | undefined {
  if (!ticketPropagation) {
    return undefined;
  }

  return {
    onGrant: ticketPropagation.onGrant,
    onRevocation: ticketPropagation.onRevocation,
    ticketProvider: ticketPropagation.ticketProvider
      ? {
          ticketProvider: ticketPropagation.ticketProvider.ticketProvider,
          ticketProjectId: ticketPropagation.ticketProvider.ticketProjectId,
          ticketProjectName: ticketPropagation.ticketProvider.ticketProjectName,
        }
      : undefined,
  };
}

function makeRequestConfig(
  config: RequestConfigurationFragment
): ResourceRequestConfig {
  const groupIds = [];
  if (config.groupId !== undefined && config.groupId !== null) {
    groupIds.push(config.groupId);
  }
  if (config.groupIds !== undefined && config.groupIds !== null) {
    groupIds.push(...config.groupIds);
  }
  const roleIds = [];
  if (config.roleIds !== undefined && config.roleIds !== null) {
    roleIds.push(...config.roleIds);
  }
  return {
    id: config.id,
    groupIds,
    roleIds,
    priority: config.priority,
    isRequestable: config.isRequestable,
    autoApprove: config.autoApproval,
    reasonOptional: config.reasonOptional,
    stageData: [...config.reviewerStages]
      .sort((a, b) => a.stage - b.stage)
      .map((stage) => ({
        ...stage,
        ownerIds: stage.owners.map((owner) => owner.id),
      })),
    recommendedDurationMin: config.recommendedDurationInMinutes ?? null,
    maxDurationMin: config.maxDurationInMinutes ?? null,
    requireSupportTicket: config.requireSupportTicket,
    requireMfaToRequest: config.requireMfaToRequest,
    requestTemplateId: config.requestTemplateId ?? null,
  };
}

type GroupForConfig = {
  id: string;
  name: string;
  remoteName: string;
  remoteId?: string | null;
  groupType: GroupType;
  connectionId: string;
  connection?: {
    connectionType: ConnectionType;
    name: string;
    visibility: Visibility;
    visibilityGroups: { visibilityGroupId: string }[];
  } | null;
  description: string;
  visibility: Visibility;
  visibilityGroups: {
    visibilityGroupId: string;
  }[];
  breakGlassUsers?: {
    user?: UserDropdownPreviewFragment | null;
  }[];
  groupLeaders?: UserDropdownPreviewFragment[] | null;
  adminOwner?: OwnerPreviewSmallFragment | null;
  auditMessageChannels?: MessageChannelFragment[] | null;
  onCallSchedules?:
    | {
        onCallSchedule: {
          name: string;
          remoteId: string;
          thirdPartyProvider: ThirdPartyProvider;
          existsInRemote: boolean;
        };
      }[]
    | null;
  requireMfaToApprove: boolean;
  tags: GroupTagPreviewFragment[];
  commonMetadata: {
    matchRemoteName: boolean;
    matchRemoteDescription: boolean;
  };
  requestConfigs: RequestConfigurationFragment[];
  groupBinding?: {
    sourceGroupId: string;
    sourceGroup?: {
      name: string;
      groupType: GroupType;
    } | null;
  } | null;
  customRequestNotification?: string | null;
};

export function makeConfigForGroup(
  group: GroupForConfig,
  configTemplate?: ResourceConfig["configurationTemplate"]
): ResourceConfig {
  const breakGlassUsers: UserDropdownPreviewFragment[] = [];
  group.breakGlassUsers?.forEach((user) => {
    if (user.user) {
      breakGlassUsers.push(user.user);
    }
  });

  const groupLeaderUsers: UserDropdownPreviewFragment[] = [];
  group.groupLeaders?.forEach((user) => {
    if (user) {
      groupLeaderUsers.push(user);
    }
  });

  return {
    entityId: group.id,
    name: group.name,
    remoteName: group.remoteName,
    remoteId: group.remoteId ?? "",
    entityType: group.groupType,
    connectionId: group.connectionId ?? "",
    connectionType: group.connection?.connectionType,
    connectionName: group.connection?.name,
    connectionVisibility: group.connection?.visibility,
    connectionVisibilityGroups: group.connection?.visibilityGroups.map(
      (g) => g.visibilityGroupId
    ),
    customRequestNotification: group.customRequestNotification || undefined,
    description: group.description,
    adminOwner: group.adminOwner ?? null,
    visibility: group.visibility,
    visibilityGroups: group.visibilityGroups.map((g) => g.visibilityGroupId),
    messageChannels: group.auditMessageChannels ?? [],
    requireMfaToApprove: group.requireMfaToApprove,
    requireMfaToConnect: false,
    onCallSchedules: group.onCallSchedules?.map((s) => s.onCallSchedule),
    breakGlassUsers,
    tagIds: group.tags.map((tag) => tag.tagId),
    tagsWithSource: group.tags,
    commonMetadata: group.commonMetadata,
    requestConfigs: group.requestConfigs.map(makeRequestConfig),
    configurationTemplate: configTemplate,
    groupBinding: group.groupBinding
      ? {
          sourceGroupId: group.groupBinding?.sourceGroupId,
          sourceGroupName:
            group.groupBinding?.sourceGroup?.name ?? "Hidden group",
          sourceGroupType: group.groupBinding?.sourceGroup?.groupType,
        }
      : undefined,
    groupLeaderUsers,
  };
}

export function makeDefaultRequestConfig(): ResourceRequestConfig {
  return {
    id: generateUniqueId(CLIENT_REQUEST_CONFIG_ID_PREFIX),
    priority: 0,
    isRequestable: false,
    autoApprove: false,
    reasonOptional: false,
    stageData: [],
    maxDurationMin: null,
    recommendedDurationMin: null,
    requestTemplateId: null,
    requireMfaToRequest: false,
    requireSupportTicket: false,
  };
}

export function makeConfigForOwner(owner: OwnerFragment): OwnerConfig {
  return {
    name: owner.name,
    description: owner.description,
    escalationDurationMin: owner.accessRequestEscalationPeriodInMinutes ?? null,
    reviewerMessageChannel: owner.reviewerMessageChannel,
    sourceGroup: owner.sourceGroup,
    enabledSourceGroup: Boolean(owner.sourceGroup),
    users: owner.ownerUsers ?? [],
  };
}

export function makeConfigForTemplate(
  template: ConfigurationTemplateFragment
): ResourceConfig {
  return {
    adminOwner: template.adminOwner ?? null,
    visibility: template.visibility,
    visibilityGroups: template.visibilityGroups.map((g) => g.id),
    messageChannels: template.auditMessageChannels,
    requireMfaToApprove: template.requireMfaToApprove,
    requireMfaToConnect: template.requireMfaToConnect,
    onCallSchedules: template.onCallSchedules,
    breakGlassUsers: template.breakGlassUsers,
    requestConfigs: template.requestConfigs.map(makeRequestConfig),
    ticketPropagation: makeResourceTicketPropagation(
      template.ticketPropagation ?? undefined
    ),
    customRequestNotification: template.customRequestNotification || undefined,
  };
}

export function makeUpdateInputForResource(
  config: Partial<ResourceConfig>,
  resource: ResourceForConfig
): BulkUpdateItemsInput {
  if (config.configurationTemplate) {
    // Ignore any other settings since they were hidden
    return {
      name: config.name,
      description: config.description,
      tagIds: config.tagIds,
      commonMetadata: config.commonMetadata,
      configurationId: {
        configurationId: config.configurationTemplate.id,
      },
      useParentConfig: config.useParentConfig,
      riskSensitivity: config.riskSensitivity?.value,
    };
  }
  return {
    name: config.name,
    description:
      resource.resourceType === ResourceType.OpalRole
        ? undefined
        : config.description,
    tagIds: config.tagIds,
    commonMetadata: config.commonMetadata,

    adminOwnerId: config.adminOwner?.id,
    visibility: config.visibility,
    visibilityGroupsIds: config.visibilityGroups,
    messageChannelIds: config.messageChannels?.map((channel) => channel.id),
    requireMfaToApprove: config.requireMfaToApprove,
    requireMfaToConnect: config.requireMfaToConnect,
    requestConfigs: config.requestConfigs
      ? makeRequestConfigsInput(config.requestConfigs)
      : undefined,
    childrenDefaultConfigTemplateId: config.childrenDefaultConfigTemplate
      ? {
          configurationId: config.childrenDefaultConfigTemplate?.id,
        }
      : undefined,
    forkConfigurationTemplates: config.forkConfigurationTemplates,
    useParentConfig: config.useParentConfig,
    ticketPropagation: config.ticketPropagation
      ? {
          onGrant: config.ticketPropagation.onGrant,
          onRevocation: config.ticketPropagation.onRevocation,
          ticketProvider:
            config.ticketPropagation.onGrant ||
            config.ticketPropagation.onRevocation
              ? config.ticketPropagation.ticketProvider
              : undefined,
        }
      : undefined,
    riskSensitivity: config.riskSensitivity?.value,
    customRequestNotification: Object.keys(config).includes(
      "customRequestNotification"
    )
      ? {
          string: config.customRequestNotification,
        }
      : undefined,
  };
}

export function makeUpdateInputForGroup(
  config: Partial<ResourceConfig>
): BulkUpdateItemsInput {
  const customRequestNotification = Object.keys(config).includes(
    "customRequestNotification"
  )
    ? {
        string: config.customRequestNotification,
      }
    : undefined;
  if (config.configurationTemplate?.id) {
    // Ignore any other settings since they were hidden
    return {
      name: config.name,
      description: config.description,
      tagIds: config.tagIds,
      commonMetadata: config.commonMetadata,
      configurationId: {
        configurationId: config.configurationTemplate.id,
      },
      customRequestNotification: customRequestNotification,
    };
  }
  return {
    name: config.name,
    description: config.description,
    tagIds: config.tagIds,
    commonMetadata: config.commonMetadata,

    adminOwnerId: config.adminOwner?.id,
    visibility: config.visibility,
    visibilityGroupsIds: config.visibilityGroups,
    messageChannelIds: config.messageChannels?.map((channel) => channel.id),
    requireMfaToApprove: config.requireMfaToApprove,
    breakGlassUsersIds: config.breakGlassUsers?.map((user) => user.id),
    onCallSchedules: config.onCallSchedules?.map((schedule) => ({
      scheduleName: schedule.name,
      remoteId: schedule.remoteId,
      thirdPartyProvider: schedule.thirdPartyProvider,
    })),
    requestConfigs: config.requestConfigs
      ? makeRequestConfigsInput(config.requestConfigs)
      : undefined,
    forkConfigurationTemplates: config.forkConfigurationTemplates,
    groupLeaderUserIds: config.groupLeaderUsers?.map((user) => user.id),
    customRequestNotification: customRequestNotification,
  };
}

export function makeUpdateInputForConnection(
  config: Partial<ConnectionConfig>,
  connection: ConnectionPreviewLargeFragment
): UpdateConnectionInput {
  let metadataInput: ConnectionMetadataInput | undefined = undefined;
  if (
    connection.connectionType === ConnectionType.AwsSso &&
    connection.metadata?.__typename === "AWSSSOConnectionMetadata" &&
    (config.awsIdentityCenterImportSetting ||
      config.awsOrganizationImportSetting)
  ) {
    metadataInput = {
      connectionType: ConnectionType.AwsSso,
      awsSso: {
        ...connection.metadata, // PUT-style update, so we need to send the existing metadata
        ...(config.awsIdentityCenterImportSetting
          ? {
              awsIdentityCenterImportSetting:
                config.awsIdentityCenterImportSetting,
            }
          : {}),
        ...(config.awsOrganizationImportSetting
          ? {
              awsOrganizationImportSetting: config.awsOrganizationImportSetting,
            }
          : {}),
      },
    };
  } else if (connection.connectionType === ConnectionType.Custom) {
    metadataInput = {
      connectionType: ConnectionType.Custom,
      propagationTicket: {
        enableTicketPropagation: Boolean(config.ticketProviderEnabled),
        ticketProvider:
          config.ticketProvider && config.ticketProviderEnabled
            ? {
                ticketProvider: config.ticketProvider,
                ticketProjectId: config.ticketProjectId ?? "",
                ticketProjectName: config.ticketProjectName ?? "",
              }
            : undefined,
      },
    };
  }

  let importNotificationOwnerId;
  if (config.importNotification && !config.importNotification.shouldNotify) {
    importNotificationOwnerId = null;
  } else if (
    config.importNotification &&
    config.importNotification.shouldNotify &&
    !config.importNotification.recipientOwnerId
  ) {
    // Default to admin owner if no recipient specified
    importNotificationOwnerId = config.adminOwnerId
      ? config.adminOwnerId
      : connection.adminOwner?.id;
  } else {
    importNotificationOwnerId = config.importNotification?.recipientOwnerId;
  }

  return {
    id: connection.id,
    name: config.name,
    metadata: metadataInput,
    description: config.description,
    adminOwnerId: config.adminOwnerId,
    webhookEnabled: config.webhookEnabled,
    importSetting: config.importSetting,
    autoImportGroupResources: config.autoImportGroupResources,
    visibility: config.visibility,
    visibilityGroupIds: config.visibilityGroupIds,
    importVisibility: config.importVisibility,
    importVisibilityGroupsIds: config.importVisibilityGroups,
    childrenDefaultConfigTemplateId: config.childrenDefaultConfigTemplate
      ? {
          configurationId: config.childrenDefaultConfigTemplate?.id,
        }
      : connection.childrenDefaultConfigTemplate &&
        !config.childrenDefaultConfigTemplate
      ? {
          configurationId: null,
        }
      : undefined,
    importNotification: config.importNotification
      ? {
          recipientOwnerId: importNotificationOwnerId,
        }
      : undefined,
  };
}

export function makeUpdateInputForTemplate(
  id: string,
  config: Partial<ResourceConfig>
): UpdateConfigurationTemplateInput {
  return {
    id,
    name: config.name,
    adminOwnerId: config.adminOwner?.id,
    visibility: config.visibility,
    visibilityGroupsIds: config.visibilityGroups,
    messageChannelIds: config.messageChannels?.map((channel) => channel.id),
    requireMfaToApprove: config.requireMfaToApprove,
    requireMfaToConnect: config.requireMfaToConnect,
    breakGlassUsersIds: config.breakGlassUsers?.map((user) => user.id),
    onCallSchedules: config.onCallSchedules?.map((schedule) => ({
      scheduleName: schedule.name,
      remoteId: schedule.remoteId,
      thirdPartyProvider: schedule.thirdPartyProvider,
    })),
    requestConfigs: config.requestConfigs
      ? makeRequestConfigsInput(config.requestConfigs)
      : undefined,
    ticketPropagation: makeResourceTicketPropagation(
      config.ticketPropagation ?? undefined
    ),
    customRequestNotification: Object.keys(config).includes(
      "customRequestNotification"
    )
      ? {
          string: config.customRequestNotification,
        }
      : undefined,
  };
}

export function makeCreateInputForTemplate(
  name: string,
  config: Partial<ResourceConfig>
): CreateConfigurationTemplateInput {
  return {
    name,
    adminOwnerId: config.adminOwner!.id,
    visibility: config.visibility ?? Visibility.Global,
    visibilityGroupsIds: config.visibilityGroups ?? [],
    messageChannelIds:
      config.messageChannels?.map((channel) => channel.id) ?? [],
    requireMfaToConnect: config.requireMfaToConnect ?? false,
    requireMfaToApprove: config.requireMfaToApprove ?? false,
    breakGlassUsersIds: config.breakGlassUsers?.map((user) => user.id) ?? [],
    onCallSchedules:
      config.onCallSchedules?.map((schedule) => ({
        scheduleName: schedule.name,
        remoteId: schedule.remoteId,
        thirdPartyProvider: schedule.thirdPartyProvider,
      })) ?? [],
    requestConfigs: config.requestConfigs
      ? makeRequestConfigsInput(config.requestConfigs)
      : [],
    ticketPropagation: config.ticketPropagation
      ? {
          onGrant: config.ticketPropagation.onGrant,
          onRevocation: config.ticketPropagation.onRevocation,
          ticketProvider:
            config.ticketPropagation.onGrant ||
            config.ticketPropagation.onRevocation
              ? config.ticketPropagation.ticketProvider
              : undefined,
        }
      : { onGrant: false, onRevocation: false },
    customRequestNotification: {
      string: config.customRequestNotification,
    },
  };
}

export function getResourceConfigChangedFields(
  initialConfig: Partial<ResourceConfig>,
  updatedConfig: Partial<ResourceConfig>
): Partial<ResourceConfig> {
  const result: Partial<ResourceConfig> = {};
  for (let key of Object.keys(updatedConfig)) {
    const configKey = key as keyof ResourceConfig;
    const updatedValue = updatedConfig[configKey];
    const initialValue = initialConfig[configKey];
    if (!_.isEqual(updatedValue, initialValue)) {
      // This any is safe because we're only setting keys that exist in ResourceConfig,
      // and result is typed.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      result[configKey] = updatedValue as any;
    }
  }

  return result;
}

export function getConnectionConfigChangedFields(
  initialConfig: Partial<ConnectionConfig>,
  updatedConfig: Partial<ConnectionConfig>
): Partial<ConnectionConfig> {
  const result: Partial<ConnectionConfig> = {};
  for (let key of Object.keys(updatedConfig)) {
    const configKey = key as keyof ConnectionConfig;
    const updatedValue = updatedConfig[configKey];
    const initialValue = initialConfig[configKey];
    if (!_.isEqual(updatedValue, initialValue)) {
      // This any is safe because we're only setting keys that exist in ConnectionConfig,
      // and result is typed.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      result[configKey] = updatedValue as any;
    }
  }

  return result;
}

export function makeConfigForConnection(
  connection: ConnectionPreviewLargeFragment & {
    importVisibilityGroups: ConnectionVisibilityGroupFragment[];
  } & {
    visibilityGroups: ConnectionVisibilityGroupFragment[];
  }
): ConnectionConfig {
  const ticketProvider =
    connection.metadata?.__typename === "PropagationTicketConnectionMetadata" &&
    connection.metadata.ticketProvider
      ? connection.metadata.ticketProvider
      : null;

  let awsIdentityCenterImportSetting:
    | AwsIdentityCenterImportSetting
    | undefined = undefined;
  if (connection.metadata?.__typename === "AWSSSOConnectionMetadata") {
    awsIdentityCenterImportSetting =
      connection.metadata.awsIdentityCenterImportSetting || undefined;
  }

  let awsOrganizationImportSetting: ImportSetting | undefined = undefined;
  if (connection.metadata?.__typename === "AWSSSOConnectionMetadata") {
    awsOrganizationImportSetting =
      connection.metadata.awsOrganizationImportSetting || undefined;
  }

  return {
    name: connection.name,
    description: connection.description,
    adminOwnerId: connection.adminOwner?.id,
    adminOwnerName: connection.adminOwner?.name,
    webhookEnabled: connection.webhookEnabled,
    ticketProviderEnabled: Boolean(ticketProvider),
    ticketProvider: ticketProvider?.ticketProvider,
    ticketProjectId: ticketProvider?.ticketProjectId,
    ticketProjectName: ticketProvider?.ticketProjectName,
    importSetting: connection.importSetting,
    awsIdentityCenterImportSetting: awsIdentityCenterImportSetting,
    awsOrganizationImportSetting: awsOrganizationImportSetting,
    autoImportGroupResources: connection.autoImportGroupResources,
    visibility: connection.visibility,
    visibilityGroupIds:
      connection.visibility === Visibility.Team
        ? connection.visibilityGroups.map((g) => g.visibilityGroupId)
        : undefined,
    importVisibility: connection.importVisibility,
    importVisibilityGroups:
      connection.importVisibility === Visibility.Team
        ? connection.importVisibilityGroups.map((g) => g.visibilityGroupId)
        : undefined,
    childrenDefaultConfigTemplate:
      connection.childrenDefaultConfigTemplate ?? undefined,
    importNotification: connection.importNotificationOwner
      ? {
          shouldNotify: true,
          recipientOwnerId: connection.importNotificationOwner?.id,
          recipientOwnerName: connection.importNotificationOwner?.name,
        }
      : undefined,
  };
}

export const makeRequestConfigsInput = (
  requestConfigs: ResourceRequestConfig[]
): RequestConfigInput[] => {
  return requestConfigs.map((requestConfig) => {
    const isClientId = requestConfig.id.startsWith(
      CLIENT_REQUEST_CONFIG_ID_PREFIX
    );
    return {
      id: isClientId ? undefined : requestConfig.id,
      priority: requestConfig.priority,
      groupIds:
        requestConfig.groupIds && requestConfig.groupIds.length
          ? requestConfig.groupIds
          : undefined,
      roleIds:
        requestConfig.roleIds && requestConfig.roleIds.length
          ? requestConfig.roleIds
          : undefined,
      isRequestable: requestConfig.isRequestable,
      autoApprove: requestConfig.autoApprove,
      reasonOptional: requestConfig.reasonOptional,
      reviewerStages: requestConfig.stageData.map((stage) => ({
        requireAdminApproval: stage.requireAdminApproval,
        requireManagerApproval: stage.requireManagerApproval,
        operator: stage.operator,
        ownerIds: stage.ownerIds,
      })),
      recommendedDurationInMinutes: {
        int: requestConfig.recommendedDurationMin,
      },
      maxDurationInMinutes: {
        int: requestConfig.maxDurationMin,
      },
      requireMfaToRequest: requestConfig.requireMfaToRequest,
      requireSupportTicket: requestConfig.requireSupportTicket,
      requestTemplateId: {
        requestTemplateId: requestConfig.requestTemplateId,
      },
    };
  });
};

export const makeReviewerStagesInput = (
  reviewerStageData: {
    isRequestable?: boolean;
    autoApprove?: boolean;
    reasonOptional: boolean;
    stageData?: ReviewerStage[];
  },
  initialStageData: {
    isRequestable: boolean;
    autoApprove: boolean;
    reasonOptional: boolean;
    stageData: ReviewerStage[];
  }
): BulkReviewerStagesInput | undefined => {
  const {
    isRequestable,
    autoApprove,
    reasonOptional,
    stageData,
  } = reviewerStageData;
  if (
    isRequestable === undefined &&
    autoApprove === undefined &&
    reasonOptional === undefined &&
    stageData === undefined
  ) {
    return undefined;
  }

  let autoApproveToSave = autoApprove;
  let reasonOptionalToSave = reasonOptional;
  let stageDataToSave = stageData;
  if (isRequestable === false) {
    // If changing to not requestable, set auto-approve and reason optional to false and save stage as empty if not already.
    if (initialStageData.autoApprove) {
      autoApproveToSave = false;
    }
    if (initialStageData.reasonOptional) {
      reasonOptionalToSave = false;
    }
    if (initialStageData.stageData.length > 0 || stageDataToSave?.length) {
      stageDataToSave = [];
    }
  } else if (autoApprove === true) {
    // If changing to auto-approve, save stage as empty if not already.
    if (initialStageData.stageData.length > 0 || stageDataToSave?.length) {
      stageDataToSave = [];
    }
  }

  // If there's <= 1 approver in a stage, make sure the operator is AND.
  stageDataToSave = stageDataToSave?.map((stage) => {
    if (
      getNumApproversInStage(stage.ownerIds, {
        [GenericReviewer.Manager]: stage.requireManagerApproval,
        [GenericReviewer.Admin]: stage.requireAdminApproval,
      }) <= 1
    ) {
      return { ...stage, operator: ReviewStageOperator.And };
    }
    return stage;
  });

  return {
    isRequestable,
    autoApprove: autoApproveToSave,
    reasonOptional: reasonOptionalToSave,
    reviewerStages: stageDataToSave?.map((stage) => ({
      requireAdminApproval: stage.requireAdminApproval,
      requireManagerApproval: stage.requireManagerApproval,
      operator: stage.operator,
      ownerIds: stage.ownerIds,
    })),
  };
};

export const validateResourceConfig = (
  config: Partial<ResourceConfig>,
  orgState: OrgContextState
): string[] => {
  const errors: string[] = [];

  // if resource can't be accessed, we don't need to validate request configurations
  if (config.entityType && !resourceTypeCanBeAccessed(config.entityType)) {
    return errors;
  }

  for (let requestConfig of config.requestConfigs ?? []) {
    const isDefaultConfig = requestConfig.priority === 0;
    const hasSelectedGroupsOrRoles =
      (requestConfig.groupIds && requestConfig.groupIds?.length > 0) ||
      (requestConfig.roleIds && requestConfig.roleIds?.length > 0);
    if (!isDefaultConfig && !hasSelectedGroupsOrRoles) {
      errors.push(
        "Non default request configurations must have a selected group or role."
      );
    }

    if (
      requestConfig.isRequestable &&
      !requestConfig.autoApprove &&
      !requestConfig.stageData.length
    ) {
      errors.push(
        "At least one reviewer stage is required for non auto-approved requestable items."
      );
    }

    const everyStageHasOwners = requestConfig.stageData.every((stage) => {
      return (
        getNumApproversInStage(stage.ownerIds, {
          [GenericReviewer.Manager]: stage.requireManagerApproval,
          [GenericReviewer.Admin]: stage.requireAdminApproval,
        }) >= 1
      );
    });
    if (
      requestConfig.isRequestable &&
      !requestConfig.autoApprove &&
      !everyStageHasOwners
    ) {
      errors.push("Every stage must have at least one owner or manager.");
    }

    // If require manager is on globally, check that an AND stage requires manager.
    const isManagerCCRequiredGlobal =
      orgState.orgSettings?.generalSettings.some(
        (setting) => setting === GeneralSettingType.RequireManagerCc
      ) || false;
    const hasANDManagerApproval = requestConfig.stageData.some(
      (d) => d.requireManagerApproval && d.operator === "AND"
    );
    if (isManagerCCRequiredGlobal && !hasANDManagerApproval) {
      errors.push(
        "Manager approval is required globally. Add manager approval to an AND stage."
      );
    }
  }

  if (config.ticketPropagation) {
    const enabled =
      config.ticketPropagation.onRevocation || config.ticketPropagation.onGrant;
    const isTicketProviderSet =
      config.ticketPropagation.ticketProvider?.ticketProvider &&
      config.ticketPropagation.ticketProvider?.ticketProjectId;
    if (enabled && !isTicketProviderSet) {
      errors.push(
        "Ticket provider and project are required when enabling ticket propagation"
      );
    }
  }

  if (config.adminOwner && !config.adminOwner?.id && !config.adminOwner?.name) {
    errors.push("Select an admin owner");
  }

  if (
    config.configurationTemplate &&
    !config.configurationTemplate?.id &&
    !config.configurationTemplate?.name
  ) {
    errors.push("Select a configuration template");
  }

  return errors;
};

export function validateOwnerConfig(config: Partial<OwnerConfig>): string[] {
  const errors: string[] = [];

  if (!config.name || !config.name.trim()) {
    errors.push("Owner name is required.");
  }

  if (config.enabledSourceGroup && !config.sourceGroup) {
    errors.push("Source group is required if option is enabled.");
  }
  if (
    config.escalationDurationMin != null &&
    config.escalationDurationMin <= 0
  ) {
    errors.push("Please enter a valid escalation period");
  }

  return errors;
}

export function makeConfigForUser(
  user: UserOverviewFragment,
  userAtrributes: UserTagFragment[] | null,
  canEditManager: boolean
): UserConfig {
  return {
    editableManager: canEditManager,
    email: user.email,
    secondaryEmails: user.secondaryEmails,
    manager: user.manager,
    position: user.position,
    team: user.teamAttr ?? null,
    hrIdpStatus: user.hrIdpStatus,
    userAttributeTags: userAtrributes ?? [],
    userAttributeTagIds: userAtrributes?.map((tag) => tag.tagId),
    identities: user.identities ?? undefined,
  };
}

export const validateConnectionConfig = (
  config: Partial<ConnectionConfig>
): string[] => {
  const errors: string[] = [];
  if (config.ticketProviderEnabled) {
    if (!config.ticketProvider) {
      errors.push("Ticket provider is required to create tickets.");
    }
    if (!config.ticketProjectId) {
      errors.push("Ticket project is required if ticket provider is enabled.");
    }
  }
  return errors;
};

export const sortTagWithOktaStandardAttributes = (
  userTags: UserTagFragment[]
): UserTagFragment[] => {
  var oktaStandardAttributes = [
    "email",
    "firstName",
    "lastName",
    "honorificPrefix",
    "honorificSuffix",
    "title",
    "displayName",
    "nickName",
    "primaryPhone",
    "mobilePhone",
    "city",
    "state",
    "timezone",
    "organization",
    "division",
    "department",
    "manager",
    "secondEmail",
  ];
  const idxByKeys: Record<string, number> = {};
  oktaStandardAttributes.forEach((elt, index) => {
    idxByKeys[elt] = index;
  });
  var userTagsSorted = [...userTags];
  userTagsSorted.sort((a, b) => {
    if (
      idxByKeys[a.tag?.key || ""] === undefined ||
      idxByKeys[b.tag?.key || ""] === undefined
    ) {
      return -2;
    } else {
      if (idxByKeys[a.tag?.key || ""] === idxByKeys[b.tag?.key || ""]) {
        return 0;
      } else if (idxByKeys[a.tag?.key || ""] < idxByKeys[b.tag?.key || ""]) {
        return -1;
      } else {
        return 1;
      }
    }
  });
  return userTagsSorted;
};

export function makeBundleConfig(
  bundleDetail: BundleDetailFragment
): BundleConfig {
  return {
    adminOwner: bundleDetail.adminOwner,
    description: bundleDetail.description || "",
    name: bundleDetail.name,
    visibility: bundleDetail.visibility,
    visibilityGroupsIds:
      bundleDetail.visibilityGroups?.map(
        (bundleVisGroup) => bundleVisGroup.visibilityGroupId
      ) ?? undefined,
  };
}
