import {
  AccessType,
  GroupPreviewSmallFragment,
  ResourcePreviewLargeFragment,
  UserPreviewSmallFragment,
} from "api/generated/graphql";
import PaginatedGroupDropdown from "components/dropdown/PaginatedGroupDropdown";
import PaginatedResourceDropdown from "components/dropdown/PaginatedResourceDropdown";
import { PaginatedUserDropdown } from "components/dropdown/PaginatedUserDropdown";
import { PillV3 } from "components/pills/PillsV3";
import { Banner } from "components/ui";
import {
  Checkbox,
  DataElementList,
  FormGroup,
  RadioGroup,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import { isEqual } from "lodash";
import { useContext } from "react";
import useLogEvent from "utils/analytics";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { getUserAvatarIcon } from "views/users/utils";

import { UsageRange } from "./common";
import { useFilterDispatch, useFilterState } from "./contexts/FilterContext";
import { GraphContext } from "./contexts/GraphContext";
import * as styles from "./FiltersSidebar.css";

const usageOptions: Array<{
  label: string;
  value: UsageRange | undefined;
}> = [
  {
    label: "Any usage",
    value: undefined,
  },
  {
    label: "No use for over 90 days",
    value: {
      minDays: 90,
    },
  },
  {
    label: "No use for over 60 days",
    value: {
      minDays: 60,
    },
  },
  {
    label: "No use for over 30 days",
    value: {
      minDays: 30,
    },
  },
  {
    label: "Used within the last 90 days",
    value: {
      maxDays: 90,
    },
  },
  {
    label: "Used within the last 60 days",
    value: {
      maxDays: 60,
    },
  },
  {
    label: "Used within the last 30 days",
    value: {
      maxDays: 30,
    },
  },
  {
    label: "No usage data",
    value: {
      noData: true,
    },
  },
];

const FiltersSidebar = () => {
  const logEvent = useLogEvent();
  const filterState = useFilterState();
  const dispatch = useFilterDispatch();
  const { selection, accessTypes, usage } = filterState;
  const { graphState } = useContext(GraphContext);
  const hasUsageFilter = useFeatureFlag(FeatureFlag.VisualizationUsageFilter);

  const vizData = graphState.data;

  const userById: { [userId: string]: UserPreviewSmallFragment } = {};
  const resourceById: {
    [resourceId: string]: ResourcePreviewLargeFragment;
  } = {};
  const groupById: { [groupId: string]: GroupPreviewSmallFragment } = {};
  vizData?.users.forEach((user) => (userById[user.id] = user));
  vizData?.resources.forEach(
    (resource) => (resourceById[resource.id] = resource)
  );
  vizData?.groups.forEach((group) => (groupById[group.id] = group));

  const handleFilterChange = (accessType: AccessType) => (
    selected: boolean
  ) => {
    dispatch({
      type: "UPDATE_ACCESS_TYPE_FILTER",
      payload: {
        accessType,
        selected,
      },
    });
    logEvent({
      name: "viz_access_type_highlight_toggle",
      properties: {
        accessType,
        checked: selected,
      },
    });
  };

  const handleUsageChange = (usage?: UsageRange) => {
    dispatch({
      type: "UPDATE_USAGE_FILTER",
      payload: {
        value: usage,
      },
    });
  };

  return (
    <div className={styles.sidebar}>
      <div
        className={sprinkles({
          fontWeight: "medium",
          fontSize: "textLg",
          marginBottom: "xs",
        })}
      >
        Visualize Access
      </div>
      <div
        className={sprinkles({
          color: "gray700",
          fontSize: "textXs",
          marginBottom: "mdlg",
        })}
      >
        Add any number of users, groups, or resources below to add them to the
        graph on the right.
      </div>
      <FormGroup label="Users">
        <PaginatedUserDropdown
          selectOnly
          placeholder="Add Users"
          onChange={(user) => {
            if (user) {
              dispatch({
                type: "ADD_USER",
                payload: {
                  id: user.id,
                },
              });
            }
          }}
        />
        <div className={sprinkles({ marginTop: "md" })}>
          <DataElementList>
            {selection.userIds?.map((userId) => {
              const user = userById[userId];
              if (!user) {
                return null;
              }
              return (
                <PillV3
                  key={user.id}
                  keyText={user.fullName}
                  pillColor="DeepOrange"
                  icon={getUserAvatarIcon(user)}
                  onRemove={() => {
                    dispatch({
                      type: "REMOVE_USER",
                      payload: {
                        id: user.id,
                      },
                    });
                  }}
                />
              );
            })}
          </DataElementList>
        </div>
      </FormGroup>
      <FormGroup label="Groups">
        <PaginatedGroupDropdown
          selectOnly
          placeholder="Add Groups"
          onChange={(group) => {
            if (group) {
              dispatch({
                type: "ADD_GROUP",
                payload: {
                  id: group.id,
                },
              });
            }
          }}
        />
        <div className={sprinkles({ marginTop: "md" })}>
          <DataElementList>
            {selection.groupIds?.map((groupId) => {
              const group = groupById[groupId];
              if (!group) {
                return null;
              }
              return (
                <PillV3
                  key={group.id}
                  keyText={group.name}
                  pillColor="Teal"
                  icon={{ type: "entity", entityType: group.groupType }}
                  onRemove={() => {
                    dispatch({
                      type: "REMOVE_GROUP",
                      payload: {
                        id: group.id,
                      },
                    });
                  }}
                />
              );
            })}
          </DataElementList>
        </div>
      </FormGroup>
      <FormGroup label="Resources">
        <PaginatedResourceDropdown
          selectOnly
          placeholder="Add Resources"
          onChange={(resource) => {
            if (resource) {
              dispatch({
                type: "ADD_RESOURCE",
                payload: {
                  id: resource.id,
                },
              });
            }
          }}
        />
        <div className={sprinkles({ marginTop: "md" })}>
          <DataElementList>
            {selection.resourceIds?.map((resourceId) => {
              const resource = resourceById[resourceId];
              if (!resource) {
                return null;
              }
              return (
                <PillV3
                  key={resource.id}
                  keyText={resource.name}
                  pillColor="Pink"
                  icon={{ type: "entity", entityType: resource.resourceType }}
                  onRemove={() => {
                    dispatch({
                      type: "REMOVE_RESOURCE",
                      payload: {
                        id: resource.id,
                      },
                    });
                  }}
                />
              );
            })}
          </DataElementList>
        </div>
      </FormGroup>

      <div
        className={sprinkles({
          fontWeight: "medium",
          fontSize: "textLg",
          marginBottom: "md",
        })}
      >
        Filter Graph
      </div>
      <FormGroup label="Access Type">
        <div
          className={sprinkles({
            color: "gray700",
            fontSize: "textXs",
            marginBottom: "sm",
          })}
        >
          How users access resources and groups
        </div>
        <div className={styles.checkboxRow}>
          <Checkbox
            label="Expiring Access"
            checked={accessTypes.includes(AccessType.Expiring)}
            onChange={handleFilterChange(AccessType.Expiring)}
          />
          <span className={styles.textBadge({ color: "green" })}>BEST</span>
        </div>
        <div className={styles.checkboxRow}>
          <Checkbox
            label="Group-Based Access"
            checked={accessTypes.includes(AccessType.Birthright)}
            onChange={handleFilterChange(AccessType.Birthright)}
          />
          <span className={styles.textBadge({ color: "yellow" })}>OK</span>
        </div>
        <div className={styles.checkboxRow}>
          <Checkbox
            label="Longstanding Access"
            checked={accessTypes.includes(AccessType.Longstanding)}
            onChange={handleFilterChange(AccessType.Longstanding)}
          />
          <span className={styles.textBadge({ color: "red" })}>BAD</span>
        </div>
      </FormGroup>

      {hasUsageFilter && (
        <FormGroup label="Usage">
          <div
            className={sprinkles({
              color: "gray700",
              fontSize: "textXs",
              marginBottom: "sm",
            })}
          >
            How often resources are used
          </div>
          <div
            className={sprinkles({ marginBottom: "sm", fontSize: "bodySm" })}
          >
            <Banner
              message="We currently only show usage data for Okta apps, initiated AWS sessions, and AWS IDC Roles and Groups. More data coming soon!"
              type="info"
              noIcon
            />
          </div>
          <RadioGroup
            options={usageOptions}
            value={usageOptions.find((opt) => isEqual(opt.value, usage))}
            getOptionKey={(opt) => opt.label}
            getOptionLabel={(opt) => opt.label}
            onSelectValue={(opt) => handleUsageChange(opt.value)}
            marginBottom="lg"
          />
        </FormGroup>
      )}
    </div>
  );
};

export default FiltersSidebar;
