import {
  ActiveRequestConfigurationFragment,
  ConnectionType,
  EntityType,
  GroupAccessLevel,
  GroupDropdownPreviewFragment,
  RequestCustomMetadataInput,
  ResourceAccessLevel,
  ResourceType,
  SupportTicketPreviewFragment,
  UserDropdownPreviewFragment,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { produce } from "immer";
import _ from "lodash";
import { Dispatch, useContext, useEffect, useReducer } from "react";
import { useHistory, useLocation } from "react-router";
import { logError } from "utils/logging";
import { MfaCustomParams } from "utils/mfa/mfa";
import { useTransitionTo } from "utils/router/hooks";
import { NULLABLE_DURATION_INDEFINITE } from "views/requests/utils";

export type AccessRequestEntity = {
  type: EntityType.Group | EntityType.Resource | ResourceType.OktaApp;
  id: string;
};

export type Role = ResourceAccessLevel | GroupAccessLevel;

export const MAX_REQUESTABLE_ENTITIES = 20;

type AccessRequestConfiguration = {
  entityId: AccessRequestEntity["id"];
  roleRemoteId?: Role["accessLevelRemoteId"] | null;
  requestConfiguration: ActiveRequestConfigurationFragment;
};

type AccessRequestStateKeys = keyof AccessRequestState;
export type AccessRequestState = {
  appId?: string;
  appType?: ConnectionType | ResourceType.OktaApp;
  bundleId?: string;
  appNotRequestable?: boolean;

  readonly requesterUserId: string;

  targetUserId?: string;
  targetGroupId?: string;
  target?: UserDropdownPreviewFragment | GroupDropdownPreviewFragment;
  reason?: string;

  isExtension: boolean;

  durationInMinutes?: number;
  // hasSetDuration is set when a user has interacted at least once with either
  // the dropdown or the custom duration picker, in case they haven't we can
  // update the duration from the request configuration
  hasSetDuration: boolean;

  supportTicket?: SupportTicketPreviewFragment;

  selectedEntities: AccessRequestEntity[];
  // selectedEntityByEntityId is a map of entity id (eg. Okta App) to another
  // entity such as an Okta group or itself, which is the actual target for
  // this request
  selectedEntityByEntityId: Partial<Record<string, AccessRequestEntity>>;
  // isRoleRequiredByEntityId is a map of entity id (eg. GitHub resource) to a
  // boolean, which indicates if the resource needs at least a role to be
  // selected. In the future this value should come from requestDefaults: OPAL-11518
  isRoleRequiredByEntityId: Partial<Record<string, boolean>>;
  selectedRolesByEntityId: Partial<Record<string, Role[]>>;
  requestConfigsByEntityId: Partial<
    Record<string, AccessRequestConfiguration[]>
  >;
  customMetadata: Record<string, RequestCustomMetadataInput>;

  hasChanges: boolean;
  notRequestableBundleEntityCount?: number;
  skippedEntityCount?: number;
  hideAppDropdown?: boolean;
  errors: string[];
};

export enum AccessRequestStateType {
  App,
  Target,
  Reason,
  AppNotRequestable,
  // Duration will always be set by user input
  Duration,
  // Default duration will only be set by the request configuration and ignored
  // if the user has already set a duration
  DefaultDuration,
  ToggleCustomDurationPicker,
  SupportTicket,
  AddEntitiesToRequest,
  RemoveEntitiesFromRequest,
  SetRolesForEntity,
  SetIsRoleRequiredForEntity,
  // SetEntityForEntity is for an entity (eg. okta app) that requires another
  // entity (eg. okta group/app) to actually be requested in its place
  SetEntityForEntity,
  RequestConfigurations,
  CustomMetadata,
  ValidationError,
  RestoreFromMfaCustomParams,
}

type AccessRequestAction =
  | {
      type: AccessRequestStateType.App;
      value: {
        appId: string;
        appType: ConnectionType | ResourceType.OktaApp;
        isUserAction?: boolean;
      };
    }
  | {
      type: AccessRequestStateType.AppNotRequestable;
      value: {
        isNotRequestable: boolean;
      };
    }
  | {
      type: AccessRequestStateType.Target;
      value:
        | { targetGroup: GroupDropdownPreviewFragment }
        | { targetUser: UserDropdownPreviewFragment };
    }
  | {
      type: AccessRequestStateType.Reason;
      value: string;
    }
  | { type: AccessRequestStateType.Duration; value: number }
  | {
      type: AccessRequestStateType.DefaultDuration;
      value?: number;
    }
  | {
      type: AccessRequestStateType.SupportTicket;
      value: SupportTicketPreviewFragment | undefined;
    }
  | {
      type:
        | AccessRequestStateType.AddEntitiesToRequest
        | AccessRequestStateType.RemoveEntitiesFromRequest;
      value: AccessRequestEntity[];
    }
  | {
      type: AccessRequestStateType.SetRolesForEntity;
      value: {
        id: string;
        roles: Role[];
        isUserAction?: boolean;
      };
    }
  | {
      type: AccessRequestStateType.SetIsRoleRequiredForEntity;
      value: {
        id: string;
        isRoleRequired: boolean;
      };
    }
  | {
      type: AccessRequestStateType.SetEntityForEntity;
      value: {
        id: string;
        entity: AccessRequestEntity;
        isUserAction?: boolean;
      };
    }
  | {
      type: AccessRequestStateType.RequestConfigurations;
      value: AccessRequestConfiguration[];
    }
  | {
      type: AccessRequestStateType.CustomMetadata;
      value: {
        fieldName: string;
        fieldValue: RequestCustomMetadataInput;
      };
    }
  | {
      type: AccessRequestStateType.ValidationError;
      value: string;
    }
  | {
      type: AccessRequestStateType.RestoreFromMfaCustomParams;
      value: MfaCustomParams;
    };

function accessRequestReducer(
  state: AccessRequestState,
  action: AccessRequestAction
): AccessRequestState {
  switch (action.type) {
    case AccessRequestStateType.App: {
      return produce(state, (draft: AccessRequestState) => {
        draft.hasChanges =
          draft.hasChanges || Boolean(action.value.isUserAction);
        if (action.value.isUserAction) {
          draft.hideAppDropdown = false;
        }
        draft.errors = [];
        draft.appNotRequestable = false;

        const { appId, appType } = action.value;
        draft.appId = appId;
        draft.appType = appType;

        if (
          appType === ResourceType.OktaApp &&
          !draft.selectedEntities.find((entity) => entity.id === appId)
        ) {
          draft.selectedEntities.unshift({
            type: appType,
            id: appId,
          });
        }
      });
    }
    case AccessRequestStateType.AppNotRequestable: {
      return produce(state, (draft: AccessRequestState) => {
        draft.appNotRequestable = action.value.isNotRequestable;
      });
    }
    case AccessRequestStateType.Target:
      return produce(state, (draft: AccessRequestState) => {
        draft.hasChanges = true;
        draft.errors = [];

        if ("targetUser" in action.value) {
          draft.targetUserId = action.value.targetUser.id;
          draft.target = action.value.targetUser;
          delete draft.targetGroupId;
        } else {
          draft.targetGroupId = action.value.targetGroup.id;
          draft.target = action.value.targetGroup;
          delete draft.targetUserId;
        }
      });

    case AccessRequestStateType.Reason:
      return produce(state, (draft: AccessRequestState) => {
        draft.hasChanges = true;
        draft.errors = [];

        draft.reason = action.value;
      });
    case AccessRequestStateType.Duration:
      return produce(state, (draft: AccessRequestState) => {
        draft.hasChanges = true;
        draft.errors = [];
        draft.hasSetDuration = true;

        draft.durationInMinutes = action.value;
        if (draft.durationInMinutes === NULLABLE_DURATION_INDEFINITE) {
          draft.durationInMinutes = undefined;
        }
      });
    case AccessRequestStateType.DefaultDuration:
      return produce(state, (draft: AccessRequestState) => {
        if (!draft.hasSetDuration) {
          draft.durationInMinutes = action.value;
        }
      });
    case AccessRequestStateType.SupportTicket:
      return produce(state, (draft: AccessRequestState) => {
        draft.errors = [];
        if (!_.isEqual(draft.supportTicket, action.value)) {
          draft.supportTicket = action.value;
          draft.hasChanges = true;
        }
      });
    case AccessRequestStateType.AddEntitiesToRequest:
      return produce(state, (draft: AccessRequestState) => {
        draft.hasChanges = true;
        draft.errors = [];

        draft.selectedEntities = _.uniqBy(
          [...action.value, ...draft.selectedEntities],
          "id"
        );
      });
    case AccessRequestStateType.RemoveEntitiesFromRequest: {
      return produce(state, (draft: AccessRequestState) => {
        draft.hasChanges = true;
        draft.errors = [];
        draft.hideAppDropdown = false;

        const entityIdsToRemove = action.value.map((entity) => entity.id);
        draft.selectedEntities = _.filter(
          draft.selectedEntities,
          (entity) => !entityIdsToRemove.includes(entity.id)
        );
        // remove all the roles that were assigned to each entity
        for (const entityId of entityIdsToRemove) {
          delete draft.selectedRolesByEntityId[entityId];
          delete draft.isRoleRequiredByEntityId[entityId];
          delete draft.selectedEntityByEntityId[entityId];
          // Note that we don't delete request configurations here, otherwise
          // we might have some race issues if the user were to re-add the ID
          // that they just removed
        }
      });
    }
    case AccessRequestStateType.SetRolesForEntity: {
      return produce(state, (draft: AccessRequestState) => {
        // This is shouldn't be available, state should garantee that if a
        // resource or group only allows one role to be requested at a time, we
        // update the selected value in AddRoleForEntity
        if (
          // check that we are trying to set a role for an entity that is selected
          !draft.selectedEntities.find(
            (entity) => entity.id === action.value.id
          )
        ) {
          return;
        }
        draft.hasChanges =
          draft.hasChanges || Boolean(action.value.isUserAction);
        draft.errors = [];

        draft.selectedRolesByEntityId[action.value.id] = action.value.roles;
        if (
          action.value.roles &&
          draft.requestConfigsByEntityId[action.value.id]
        ) {
          draft.requestConfigsByEntityId[
            action.value.id
          ] = draft.requestConfigsByEntityId[
            action.value.id
          ]?.filter((config) =>
            _.some(
              action.value.roles,
              (role) => role.accessLevelRemoteId === config.roleRemoteId
            )
          );
        }
      });
    }
    case AccessRequestStateType.SetEntityForEntity:
      return produce(state, (draft: AccessRequestState) => {
        draft.hasChanges =
          draft.hasChanges || Boolean(action.value.isUserAction);
        draft.errors = [];
        draft.selectedEntityByEntityId[action.value.id] = action.value.entity;
      });
    case AccessRequestStateType.SetIsRoleRequiredForEntity:
      return produce(state, (draft: AccessRequestState) => {
        draft.errors = [];
        draft.isRoleRequiredByEntityId[action.value.id] =
          action.value.isRoleRequired;
      });
    case AccessRequestStateType.RequestConfigurations:
      return produce(state, (draft: AccessRequestState) => {
        for (const config of action.value) {
          draft.requestConfigsByEntityId[config.entityId] = _.uniqBy(
            [
              ...(draft.requestConfigsByEntityId[config.entityId] ?? []),
              config,
            ],
            "roleRemoteId"
          );
        }
      });
    case AccessRequestStateType.CustomMetadata: {
      return produce(state, (draft: AccessRequestState) => {
        draft.hasChanges = true;
        draft.errors = [];

        const { fieldName, fieldValue } = action.value;
        draft.customMetadata[fieldName] = fieldValue;
      });
    }
    case AccessRequestStateType.ValidationError:
      return produce(state, (draft: AccessRequestState) => {
        // TODO: we'll want to tie errors to their specific field, for now just append them together
        draft.errors = _.uniq([...draft.errors, action.value]);
      });
    case AccessRequestStateType.RestoreFromMfaCustomParams: {
      const { requestState } = action.value;
      if (requestState) {
        return requestState;
      }
      logError(new Error("No request state found in MFA custom params"));
      break;
    }
    default:
      logError(new Error(`Unknown action type: ${action}`));
      return state;
  }

  return state;
}

type encoder<T, S> = (v: T, s: S) => string | undefined;
type decoder<T> = (v: string) => T;
export type SerializerType<T> = {
  [key in keyof Partial<T>]: {
    encode: encoder<T[key], T>;
    decode: decoder<T[key]>;
  };
};

// For convience, we're going to use a serializer to encode and decode the
// state in a slightly less canonical way, for example arrays in URL params are
// usually encoded as: `key=value1&key=value2` instead of `key=[value1,value2].
// This will allow us to encode more complex objects if needed in the future
const serializer: SerializerType<AccessRequestState> = {
  durationInMinutes: {
    encode: (value) => value?.toString(),
    decode: (value) => parseInt(value),
  },
  appId: {
    encode: (value) => value,
    decode: (value) => value,
  },
  reason: {
    encode: (value) => (value && value.length > 0 ? value : undefined),
    decode: (value) => value,
  },
  selectedEntities: {
    encode: (value) =>
      value.length > 0
        ? JSON.stringify(value.map((v) => _.pick(v, "id", "type")))
        : undefined,
    decode: (value) =>
      _.uniqBy(JSON.parse(value) as AccessRequestEntity[], "id"),
  },
  selectedRolesByEntityId: {
    encode: (value, config) => {
      // We want to remove any entity that has no roles, just to keep the payload as small as possible
      const cleanedRolesByEntityId = config.selectedEntities.reduce(
        (acc, entity) => {
          const roles = value[entity.id];
          if (roles && roles.length > 0) {
            acc[entity.id] = roles;
          }
          return acc;
        },
        {} as Record<string, Role[]>
      );
      return Object.keys(cleanedRolesByEntityId).length > 0
        ? JSON.stringify(cleanedRolesByEntityId)
        : undefined;
    },
    decode: (value) => {
      const rolesByEntityId = JSON.parse(value);
      return Object.keys(rolesByEntityId).reduce((acc, entityId) => {
        const roles = rolesByEntityId[entityId];
        if (roles && roles.length > 0) {
          acc[entityId] = _.uniqWith(
            roles,
            (a, b) => a.accessLevelRemoteId === b.accessLevelRemoteId
          );
        }
        return acc;
      }, {} as Record<string, Role[]>);
    },
  },
  selectedEntityByEntityId: {
    encode: (value, config) => {
      // We want to remove any entity that has no target entity, just to keep the payload as small as possible
      const cleanedEntityByEntityId = config.selectedEntities.reduce(
        (acc, entity) => {
          const targetEntity = value[entity.id];
          if (targetEntity) {
            acc[entity.id] = targetEntity;
          }
          return acc;
        },
        {} as Record<string, AccessRequestEntity>
      );
      return Object.keys(cleanedEntityByEntityId).length > 0
        ? JSON.stringify(cleanedEntityByEntityId)
        : undefined;
    },
    decode: (value) => JSON.parse(value),
  },
  customMetadata: {
    encode: (value) => {
      const cleanedMetadata = Object.keys(value).reduce((acc, key) => {
        const metadataValue = value[key].metadataValue;
        const val =
          metadataValue.booleanValue?.value ??
          metadataValue.longTextValue?.value ??
          metadataValue.shortTextValue?.value ??
          metadataValue.multiChoiceValue?.value;
        if (val && val.toString().length > 0) {
          acc[key] = value[key];
        }
        return acc;
      }, {} as Record<string, RequestCustomMetadataInput>);
      return Object.keys(cleanedMetadata).length > 0
        ? JSON.stringify(cleanedMetadata)
        : undefined;
    },
    decode: (value) => JSON.parse(value),
  },
  skippedEntityCount: {
    encode: (value) => value?.toString(),
    decode: (value) => parseInt(value),
  },
  notRequestableBundleEntityCount: {
    encode: (value) => value?.toString(),
    decode: (value) => parseInt(value),
  },
  hideAppDropdown: {
    encode: (value) => (value ? "true" : undefined),
    decode: (value) => value === "true",
  },
};

function initAccessRequestState(
  targetUser?: UserDropdownPreviewFragment
): (searchParams: URLSearchParams) => AccessRequestState {
  return (searchParams: URLSearchParams) => {
    const state: AccessRequestState = {
      requesterUserId: targetUser?.id ?? "user not found",

      hasSetDuration: false,

      hasChanges: false,
      errors: [],
      isExtension: false,

      selectedEntities: [],
      selectedEntityByEntityId: {},
      selectedRolesByEntityId: {},
      isRoleRequiredByEntityId: {},
      requestConfigsByEntityId: {},
      customMetadata: {},
    };
    for (const key of Object.keys(serializer) as AccessRequestStateKeys[]) {
      const decode = serializer[key]?.decode;
      const value = searchParams.get(key);
      if (!value || !decode) continue;
      try {
        const decodedValue = decode(value);
        if (decodedValue) Object.assign(state, { [key]: decodedValue });
      } catch (e) {
        // JSON.parse may throw an exception, so we want to just ignore that value
        continue;
      }
    }
    if (targetUser) {
      state.target = targetUser;
      state.targetUserId = targetUser.id;
    }
    if (state.durationInMinutes !== undefined) {
      state.hasSetDuration = true;
    }
    // TODO: validate that the generated state is valid
    return state;
  };
}

function serializeAccessRequestState(
  state: Partial<AccessRequestState>
): URLSearchParams {
  const searchParams = new URLSearchParams();
  for (const key of Object.keys(state) as AccessRequestStateKeys[]) {
    const value = state[key];
    const encode = serializer[key]?.encode;
    if (!encode || value === undefined) continue;
    // This next casting is just to make typescript happy, we all know that our
    // encode has the right type, but we need to remind TS that the type is not
    // "never"
    const encodedValue = (encode as encoder<typeof value, typeof state>)(
      value,
      state
    );
    if (encodedValue) {
      searchParams.set(key, encodedValue);
    }
  }
  return searchParams;
}

export function useAccessRequestStateReducer(): [
  AccessRequestState,
  Dispatch<AccessRequestAction>
] {
  const history = useHistory();
  const location = useLocation();
  const { authState } = useContext(AuthContext);

  const searchParams = new URLSearchParams(location.search);
  const targetUser = authState?.user?.user;

  const [config, dispatch] = useReducer(
    accessRequestReducer,
    searchParams,
    initAccessRequestState(targetUser)
  );
  useEffect(() => {
    const handler = setTimeout(() => {
      const searchParams = serializeAccessRequestState(config);
      if (`?${searchParams.toString()}` === location.search) return;
      history.replace({
        ...location,
        search: searchParams.toString(),
      });
    }, 1000);
    return () => clearTimeout(handler);
    // We're going to ignore location and history in the array dependency list
    // to avoid triggering this useEffect on every history change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return [config, dispatch];
}

export function useAccessRequestTransition() {
  const transitionTo = useTransitionTo();

  const transitionToAccessRequest = (
    state: Partial<AccessRequestState>,
    event?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (state.selectedEntities?.length === 1) state.hideAppDropdown = true;
    const searchParams = serializeAccessRequestState(state);
    transitionTo(
      {
        pathname: `/request-access`,
        search: searchParams.toString(),
      },
      event
    );
  };

  return transitionToAccessRequest;
}
