import {
  EntityType,
  GroupAccessLevel,
  GroupGroupAccessFragment,
  GroupGroupQuickAccessFragment,
  GroupPreviewSmallFragment,
  Maybe,
  useGroupGroupAccessQuery,
} from "api/generated/graphql";
import AccessPathLabel from "components/label/AccessPathLabel";
import AccessPointsLabel from "components/label/AccessPointsLabel";
import EventLabel from "components/label/item_labels/EventLabel";
import { ResourceLabel, TimeLabel } from "components/label/Label";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import React, { useState } from "react";

import ModalErrorMessage from "../../components/modals/ModalErrorMessage";
import { logError } from "../../utils/logging";

interface GroupGroupAccessPointRow {
  event: string;
  accessPath: string;
  expires: Maybe<Date>;
}

type GroupGroupAccessPointsLabelProps = {
  containingGroup: Maybe<GroupPreviewSmallFragment> | undefined;
  access?: GroupGroupAccessFragment | undefined;
  quickAccess?: Maybe<GroupGroupQuickAccessFragment> | undefined;
  memberGroup: Maybe<GroupPreviewSmallFragment> | undefined;
  groupGroupId: string;
  role?: GroupAccessLevel;
};

const accessPathColMinWidth = "400px";
const accessPathColMaxWidth = "800px";

export const GroupGroupAccessPointsLabel = (
  props: GroupGroupAccessPointsLabelProps
) => {
  const { refetch } = useGroupGroupAccessQuery({
    // We only want to make this query when calling refetch.
    skip: true,
  });

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [accessPathsInfo, setAccessPathsInfo] = useState<
    GroupGroupAccessPathsInfo | undefined
  >(undefined);

  const headers: Header<GroupGroupAccessPointRow>[] = [
    { id: "expires", label: "Expires" },
    { id: "event", label: "Event" },
    { id: "accessPath", label: "Access Path" },
  ];

  let numAccessPoints = 0;
  if (props.quickAccess?.hasDirectAccess) {
    numAccessPoints++;
  }
  numAccessPoints =
    numAccessPoints + (props.quickAccess?.numInheritedAccess || 0);
  if (numAccessPoints === 0) {
    return <div>{"--"}</div>;
  }

  return (
    <AccessPointsLabel
      subject={props.memberGroup}
      object={props.containingGroup}
      roleName={props.role?.accessLevelName}
      hasDirectAccess={props.quickAccess?.hasDirectAccess}
      numAccessPoints={numAccessPoints || 0}
      numInheritedAccessPoints={props.quickAccess?.numInheritedAccess || 0}
      numGroupAccessPoints={0}
      onClick={async () => {
        try {
          if (!props.containingGroup || !props.memberGroup) {
            return;
          }
          const result = await refetch({
            groupGroupId: props.groupGroupId,
          });

          const { data: groupGroupAccessData } = result;
          if (groupGroupAccessData) {
            switch (groupGroupAccessData.groupGroupAccess.__typename) {
              case "GroupGroupAccessResult": {
                const access = groupGroupAccessData.groupGroupAccess.access;

                setAccessPathsInfo(
                  getGroupGroupAccessPathsInfo({
                    containingGroup: props.containingGroup,
                    memberGroup: props.memberGroup,
                    access: access,
                  })
                );
                setErrorMessage(null);
                break;
              }
              default:
                setErrorMessage("Error: failed to fetch access details");
                logError("failed to fetch access details");
            }
          }
        } catch (error) {
          setErrorMessage("Error: failed to fetch access details");
          logError(error, "failed to fetch access details");
        }
      }}
      accessPathContent={
        <div className={sprinkles({ padding: "sm" })}>
          <MaterialTable<GroupGroupAccessPointRow>
            headers={headers}
            rows={accessPathsInfo?.accessPointRows || []}
            denseFormatting={true}
            tableContainerStyles={{ maxHeight: 300 }}
          />
          {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
        </div>
      }
    />
  );
};

export type GroupGroupAccessPathsInfo = {
  accessPointRows: CellRow<GroupGroupAccessPointRow>[];
  hasDirectAccess: boolean;
};

type GroupGroupAccessPathsInfoParams = {
  containingGroup: Maybe<GroupPreviewSmallFragment> | undefined;
  access: Maybe<GroupGroupAccessFragment> | undefined;
  memberGroup: Maybe<GroupPreviewSmallFragment> | undefined;
};

export const getGroupGroupAccessPathsInfo = (
  params: GroupGroupAccessPathsInfoParams
) => {
  const accessPointRows: CellRow<GroupGroupAccessPointRow>[] = [];
  const directGroupAccess = params.access?.directAccessPoint;

  if (directGroupAccess) {
    const expirationTime = directGroupAccess.expiration
      ? moment(new Date(directGroupAccess.expiration))
      : null;

    accessPointRows.push({
      id: directGroupAccess.memberGroupId,
      data: {
        expires: {
          value: expirationTime?.unix() ?? "Permanent Access",
          element: (
            <TimeLabel
              time={expirationTime}
              useExpiringLabel
              indefiniteLablel="Permanent Access"
            />
          ),
        },
        event: {
          value: directGroupAccess.eventId,
          element: <EventLabel eventId={directGroupAccess.eventId} />,
        },
        accessPath: {
          value: `${directGroupAccess.memberGroupId}-access-path`,
          element: (
            <AccessPathLabel
              accessLabels={[
                <ResourceLabel
                  entityTypeNew={EntityType.Group}
                  entityId={params.memberGroup?.id}
                  text={params.memberGroup?.name}
                  groupType={params.memberGroup?.groupType}
                  maxChars={24}
                />,
                <ResourceLabel
                  entityTypeNew={EntityType.Group}
                  entityId={params.containingGroup?.id}
                  text={params.containingGroup?.name}
                  groupType={params.containingGroup?.groupType}
                  maxChars={24}
                />,
              ]}
              maxWidth={accessPathColMaxWidth}
            />
          ),
          style: { minWidth: accessPathColMinWidth },
        },
      },
    });
  }

  params.access?.indirectAccessPointPaths.forEach((indirectAccessPointPath) => {
    const resolvedAccessPoint = indirectAccessPointPath.resolvedAccessPoint;

    let accessPathLabels = [
      <ResourceLabel
        entityTypeNew={EntityType.Group}
        entityId={params.containingGroup?.id}
        text={params.containingGroup?.name}
        groupType={params.containingGroup?.groupType}
        maxChars={32}
      />,
    ];

    accessPathLabels = [
      ...accessPathLabels,
      ...indirectAccessPointPath.indirectAccessPointPath.map(
        (indirectAccessPoint) => {
          return (
            <ResourceLabel
              entityTypeNew={EntityType.Group}
              entityId={indirectAccessPoint.groupId}
              text={indirectAccessPoint.groupName}
              groupType={indirectAccessPoint.groupType}
              maxChars={32}
            />
          );
        }
      ),
    ].reverse(); // reverse because we want to show the order from the group that grants access to the group we're looking at right now
    const expirationTime = indirectAccessPointPath.expiration
      ? moment(new Date(indirectAccessPointPath.expiration))
      : null;
    accessPointRows.push({
      id: resolvedAccessPoint.containingGroupId,
      data: {
        expires: {
          value: expirationTime?.unix() ?? "Permanent Access",
          element: (
            <TimeLabel
              time={expirationTime}
              useExpiringLabel
              indefiniteLablel="Permanent Access"
            />
          ),
        },
        event: {
          value: resolvedAccessPoint.eventId,
          element: <EventLabel eventId={resolvedAccessPoint.eventId} />,
        },
        accessPath: {
          value: `${params.containingGroup?.id}-access-path`,
          element: (
            <AccessPathLabel
              accessLabels={accessPathLabels}
              maxWidth={accessPathColMaxWidth}
            />
          ),
          style: { minWidth: accessPathColMinWidth },
        },
      },
    });
  });

  return {
    accessPointRows,
    hasDirectAccess: !!directGroupAccess,
  };
};
