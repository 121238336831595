import {
  Identify,
  identify,
  init,
  setUserId,
  track,
} from "@amplitude/analytics-browser";
import { datadogRum } from "@datadog/browser-rum";
import AuthContext, { AuthContextState } from "components/auth/AuthContext";
import { useContext } from "react";
import { useLocation } from "react-router";
import AppContext from "views/app/AppContext";

import { Event, UserProperties } from "./constants";

const AMPLITUDE_API_KEY = "772138dcac0dbfcd48662dad39f2de39";
init(AMPLITUDE_API_KEY);

// Used to set and update user properties
const identifyObj = new Identify();
identify(identifyObj);

/**
 * setUserProperties updates the current user's Amplitude user properties.
 * @param properties user properties to set
 */
export function setUserProperties(properties: UserProperties) {
  Object.entries(properties).forEach(([propertyName, value]) => {
    identifyObj.set(propertyName, value);
  });
  setUserId(properties.userEmail ?? "");
  identify(identifyObj);
}

/**
 * makeLogEvent is our internal wrapper around amplitude's track function
 * that returns a logEvent function to use.
 *
 * @param userProperties are the current values of user properties to update before
 * tracking the event
 * @param defaultEventProperties specifies some pre-computed set of default properties
 *  that we pass for each event (e.g. userID)
 * @returns a logEvent function that takes event data (name, properties)
 */
const makeLogEvent = (
  userProperties: UserProperties,
  defaultEventProperties: Record<string, string>,
  skipLogEvent: boolean
) => (event: Event) => {
  if (skipLogEvent) return;

  // Update user properties
  setUserProperties(userProperties);

  const { name, properties } = event;
  track(name, {
    ...userProperties,
    ...defaultEventProperties,
    ...properties,
  });

  datadogRum.addAction(name, {
    ...defaultEventProperties,
    ...properties,
  });
};

export function useUserProperties(): UserProperties {
  const { authState } = useContext(AuthContext);
  const { appState } = useContext(AppContext);

  const userProperties: UserProperties = {
    userID: authState.user?.user.id,
    userEmail: authState.user?.user.email,
    userName: authState.user?.user.fullName,
    userRoles: getUserRoles(authState),
    orgID: authState.user?.user.organizationId,
    orgName: authState.user?.user.organization.name,
    environment: appState.environment,
  };

  return userProperties;
}

export default function useLogEvent() {
  const location = useLocation();
  const userProperties = useUserProperties();
  const { authState } = useContext(AuthContext);

  // Skip logging events when impersonating a user.
  const skipLogEvent = Boolean(authState.impersonatingUser?.id);

  return makeLogEvent(
    userProperties,
    {
      pageURL: location.pathname,
    },
    skipLogEvent
  );
}

export function getUserRoles(authState: AuthContextState) {
  const roles = [];
  if (authState.user?.isAdmin) {
    roles.push("Admin");
  }
  if (authState.user?.isAuditor) {
    roles.push("Auditor");
  }
  if (authState.user?.isReadOnlyAdmin) {
    roles.push("Read-only Admin");
  }

  if (roles.length === 0) {
    roles.push("Member");
  }
  return roles;
}
