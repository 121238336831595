import {
  useLeftSidebarRoutesQuery,
  useNumRequestsToReviewQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Icon } from "components/ui";
import { useContext } from "react";
import { hasBasicPermissions } from "utils/auth/auth";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import AccessReviews from "views/access_reviews/AccessReviews";
import Items from "views/apps/ItemsPage";
import Events from "views/events/Events";
import GroupBindings from "views/group_bindings/GroupBindings";
import MetricsDashboardView from "views/metrics_dashboard/MetricsDashboardView";
import NonHumanIdentities from "views/non-human-identities/NonHumanIdentities";
import Owners from "views/owners/Owners";
import RecommendationsView from "views/recommendations/RecommendationsView";
import Requests from "views/requests/Requests";
import Settings from "views/settings/Settings";
import Tags from "views/tags/Tags";
import Templates from "views/templates/Templates";
import Users from "views/users/Users";
import InsightsRouteWrapper from "views/viz/Insights";

export type LeftSidebarSectionInfo = {
  title: string;
  options: LeftSidebarOptionInfo[];
  iconName: PropsFor<typeof Icon>["name"];
};

export type LeftSidebarOptionInfo = {
  iconName?: PropsFor<typeof Icon>["name"];
  route: string;
  extraRoutes?: string[];
  title: string;
  // Component to redirect the main page to when this sidebar element is clicked
  component: React.ComponentType;
  // This allows the route to exist without it needing to appear in the sidebar
  hidden?: boolean;
  badgeCount?: number;
};

// Each nav sidebar item is either an exandable section or a link to a page
export type LeftSidebarItemInfo =
  | LeftSidebarOptionInfo
  | LeftSidebarSectionInfo;

const useLeftSidebarSectionInfos = (): LeftSidebarItemInfo[] => {
  const hasGroupBindings = useFeatureFlag(FeatureFlag.GroupBindings);
  const hasHomepage = useFeatureFlag(FeatureFlag.LPPMShowHomepage);
  const hasNonHumanIdentities = useFeatureFlag(FeatureFlag.NonHumanIdentities);
  const { authState } = useContext(AuthContext);

  const isAdmin = authState.user?.isAdmin;
  const isReadOnlyAdmin = authState.user?.isReadOnlyAdmin;
  const isAuditor = authState.user?.isAuditor;
  const isMember = hasBasicPermissions(authState.user);

  const { data } = useLeftSidebarRoutesQuery();
  const { data: numRequestsToReviewData } = useNumRequestsToReviewQuery();

  const sections: LeftSidebarItemInfo[] = [];
  if ((isAdmin || isReadOnlyAdmin) && hasHomepage) {
    sections.push({
      iconName: "home-2",
      route: "/home",
      title: "Home",
      component: RecommendationsView,
    });
  }
  sections.push(
    {
      iconName: "apps",
      route: "/apps",
      extraRoutes: ["/resources", "/groups", "/bundles"],
      title: "Catalog",
      component: Items,
    },
    {
      iconName: "check-circle",
      route: "/requests",
      title: "Requests",
      component: Requests,
      badgeCount: numRequestsToReviewData?.numRequestsToReview,
    },
    {
      iconName: "edit",
      route: "/access-reviews",
      title: "Access Reviews",
      component: AccessReviews,
    }
  );

  if (!isMember) {
    sections.push({
      iconName: "events",
      route: "/events",
      title: "Events",
      component: Events,
      hidden: isMember,
    });
  }

  if (isAdmin || isReadOnlyAdmin) {
    sections.push({
      iconName: "line-chart-up",
      route: "/dashboard",
      title: "Dashboard",
      component: MetricsDashboardView,
    });
  }

  if (!isMember) {
    sections.push({
      iconName: "department",
      route: "/insights",
      title: "Explore (beta)",
      component: InsightsRouteWrapper,
    });
  }

  const configurationOptions: LeftSidebarOptionInfo[] = [];
  if (
    isAdmin ||
    isReadOnlyAdmin ||
    isAuditor ||
    data?.leftSidebarRoutes.hasOwners
  ) {
    configurationOptions.push({
      iconName: "user",
      route: "/users",
      title: "Users",
      component: Users,
    });
  }

  if (isAdmin || isReadOnlyAdmin || isAuditor) {
    if (hasNonHumanIdentities) {
      configurationOptions.push({
        iconName: "key",
        route: "/non-human-identities",
        title: "Non-human Identities",
        component: NonHumanIdentities,
      });
    }
  }

  if (data?.leftSidebarRoutes.hasOwners) {
    configurationOptions.push({
      iconName: "user-square",
      route: "/owners",
      title: "Owners",
      component: Owners,
    });
  }

  if (isAdmin || isReadOnlyAdmin || isAuditor) {
    configurationOptions.push({
      iconName: "tag",
      route: "/tags",
      title: "Tags",
      component: Tags,
    });
  }

  if (isAdmin || isReadOnlyAdmin) {
    configurationOptions.push({
      iconName: "template",
      route: "/templates",
      title: "Templates",
      component: Templates,
    });
  }

  if ((isAdmin || isReadOnlyAdmin) && hasGroupBindings) {
    configurationOptions.push({
      iconName: "link",
      route: "/linked-groups",
      title: "Linked Groups",
      component: GroupBindings,
    });
  }

  if (isAdmin || isReadOnlyAdmin) {
    configurationOptions.push({
      iconName: "settings",
      route: "/settings",
      title: "Settings",
      component: Settings,
    });
  }

  sections.push({
    iconName: "settings",
    title: "Configuration",
    options: configurationOptions,
  });

  return sections;
};

export default useLeftSidebarSectionInfos;
