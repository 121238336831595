import {
  BundleDetailFragment,
  BundleItemFragment,
  BundleItemsSortByField,
  EntityType,
  SortDirection,
  useBundleItemsQuery,
  useBundleQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeaderV3";
import AccessLabel from "components/enduser_exp/AccessLabel";
import TableControls from "components/enduser_exp/TableControls";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { GroupDetailsModal } from "components/modals/enduser_exp/GroupDetailsModal";
import { ResourceDetailsModal } from "components/modals/enduser_exp/ResourceDetailsModal";
import {
  ButtonV3,
  EntityIcon,
  InteractiveCard,
  Label,
  Masonry,
  Skeleton,
  TabsV3,
} from "components/ui";
import Table from "components/ui/table/Table";
import { Header } from "components/ui/table/Table";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useContext, useMemo, useState } from "react";
import { useParams } from "react-router";
import { groupHasForfeitableRole } from "utils/groups";
import { useDebouncedValue } from "utils/hooks";
import { formatResourceBreadcrumb } from "utils/resources";
import { resourceHasForfeitableRole } from "utils/resources";
import { useURLSearchParam } from "utils/router/hooks";
import {
  MAX_REQUESTABLE_ENTITIES,
  Role,
  useAccessRequestTransition,
} from "views/access_request/AccessRequestContext";
import { EndUserItemDetailsCard } from "views/common/EndUserItemDetailsCard";
import { ItemUsageDetails } from "views/common/ItemUsageDetails";
import {
  CurrentUserResourceAccessStatus,
  currentUserResourceAccessStatusFromResource,
} from "views/connect_sessions/utils";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { isGroupBindingRedirectRequired } from "views/group_bindings/common";
import { useGroupBindingRequestRedirectModal } from "views/group_bindings/modals/GroupBindingRequestRedirectModal";

import { AppsContext, ITEM_TYPE_URL_KEY } from "../AppsContext";
import { BREAKPOINT_COLUMNS } from "./constants";
import { NO_PERMISSION_TO_REQUEST } from "./constants";
import { RoleLabel } from "./RoleLabel";
import * as tableStyles from "./Table.css";
import { BundleItemRow, ItemWithAccessLevel } from "./types";
import {
  formatRequestDataForItems,
  getAppIcon,
  getGroupUserAccessInfo,
  getOwnerUserIconList,
  getResourceUserAccessInfo,
} from "./utils";

const ITEMS_VIEW_KEY = "items";
const GROUPS_VIEW_KEY = "groups";
const ALL_VIEW_KEY = "all";

type BundleEndUserViewKey = "all" | "items" | "groups";

interface BundleEndUserView {
  key: BundleEndUserViewKey;
  title: string;
  icon: IconData;
  count: number;
}

const NAME_COL_ID = BundleItemsSortByField.Name;
const TYPE_COL_ID = BundleItemsSortByField.Type;

type BundleSortValue = {
  field: BundleItemsSortByField;
  direction: SortDirection;
};

const BUNDLE_SORT_OPTIONS: {
  label: string;
  value: BundleSortValue;
}[] = [
  {
    label: "Name (A-Z)",
    value: {
      field: BundleItemsSortByField.Name,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Name (Z-A)",
    value: {
      field: BundleItemsSortByField.Name,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Type (A-Z)",
    value: {
      field: BundleItemsSortByField.Type,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Type (Z-A)",
    value: {
      field: BundleItemsSortByField.Type,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Newest first",
    value: {
      field: BundleItemsSortByField.CreatedAt,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Oldest first",
    value: {
      field: BundleItemsSortByField.CreatedAt,
      direction: SortDirection.Asc,
    },
  },
];

function isSortableField(str: string): str is BundleItemsSortByField {
  return Object.values<string>(BundleItemsSortByField).includes(str);
}

const RequestBundleButton = ({ bundleId }: { bundleId: string }) => {
  const transitionToAccessRequest = useAccessRequestTransition();

  const { data, error, loading } = useBundleItemsQuery({
    variables: {
      input: {
        bundleId,
      },
    },
  });
  const [requestableItems, notRequestableItems] = useMemo(() => {
    const requestableItems: BundleItemFragment[] = [];
    const notRequestableItems: BundleItemFragment[] = [];
    for (const item of data?.bundleItems.items ?? []) {
      if (item.resource?.isRequestable || item.group?.isRequestable) {
        requestableItems.push(item);
      } else {
        notRequestableItems.push(item);
      }
    }
    return [requestableItems, notRequestableItems];
  }, [data?.bundleItems]);

  return (
    <ButtonV3
      size="lg"
      type="main"
      label="Request All"
      loading={loading}
      onClick={(event) => {
        const hasMoreThanLimit =
          requestableItems.length > MAX_REQUESTABLE_ENTITIES;
        transitionToAccessRequest(
          {
            selectedEntities: requestableItems
              .slice(0, MAX_REQUESTABLE_ENTITIES)
              .map((item) => ({
                id: item.resource?.id ?? item.group?.id ?? "",
                type: item.resource ? EntityType.Resource : EntityType.Group,
              })),
            selectedRolesByEntityId: requestableItems.reduce((acc, item) => {
              const entityId = item.resource?.id ?? item.group?.id;
              if (!entityId) {
                return acc;
              }
              acc[entityId] = _.uniqWith(
                [
                  ...(acc[entityId] ?? []),
                  {
                    accessLevelRemoteId: item.accessLevelRemoteId,
                    accessLevelName: item.accessLevelName,
                  },
                ],
                (a, b) => a.accessLevelRemoteId === b.accessLevelRemoteId
              );
              return acc;
            }, {} as Partial<Record<string, Role[]>>),
            notRequestableBundleEntityCount: notRequestableItems.length,
            skippedEntityCount: hasMoreThanLimit
              ? requestableItems.length - MAX_REQUESTABLE_ENTITIES
              : undefined,
          },
          event
        );
      }}
      disabled={Boolean(error) || requestableItems.length === 0}
      disabledTooltip="No requestable items in bundle"
    />
  );
};

const BundleDetails = () => {
  const { bundleId } = useParams<Record<string, string>>();
  const { layoutOption } = useContext(AppsContext);

  const [searchQuery, setSearchQuery] = useURLSearchParam("search", "");
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 300);
  const [sortBy, setSortBy] = useState<BundleSortValue | undefined>(
    BUNDLE_SORT_OPTIONS[0].value
  );
  const transitionToAccessRequest = useAccessRequestTransition();
  const [openInfoModalRow, setOpenInfoModalRow] = useState<BundleItemRow>();
  const [checkedItems, setCheckedItems] = useState<BundleItemRow[]>([]);
  const [selectedItemType, setSelectedItemType] = useURLSearchParam(
    ITEM_TYPE_URL_KEY,
    ALL_VIEW_KEY
  );
  const {
    open: openGroupBindingRedirectModal,
    maybeRenderModal: maybeRenderGroupBindingRedirectModal,
  } = useGroupBindingRequestRedirectModal();

  const handleOpenRequest = (
    row: BundleItemRow,
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (
      isGroupBindingRedirectRequired({
        id: row.entityId,
        groupBinding: row.groupBinding,
      })
    ) {
      openGroupBindingRedirectModal(row.entityId, row.groupBinding!.id);
      return;
    }
    const selectedItems = checkedItems;
    checkedItems.push(row);

    transitionToAccessRequest(formatRequestDataForItems(selectedItems), event);
  };

  const BUNDLE_ITEM_COLUMNS: Header<BundleItemRow>[] = [
    {
      id: NAME_COL_ID,
      label: "Name",
      sortable: true,
      width: 200,
      customCellRenderer: (row) => {
        return (
          <div className={tableStyles.row}>
            <div className={tableStyles.name}>
              <EntityIcon
                type={row[TYPE_COL_ID]}
                onlyBrandIcon={true}
                size="lgr"
              />
              <div
                className={sprinkles({
                  display: "flex",
                  flexDirection: "column",
                })}
              >
                <div className={tableStyles.text({ bold: true })}>
                  <Label label={row[NAME_COL_ID]} truncateLength={50} oneLine />
                </div>
                {row.shortenedBreadcrumb && row.breadcrumb && (
                  <Label
                    label={row.shortenedBreadcrumb}
                    color="gray600"
                    oneLine
                    truncateLength={null}
                    tooltipText={
                      row.breadcrumb !== row.shortenedBreadcrumb
                        ? row.breadcrumb
                        : undefined
                    }
                  />
                )}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      id: "description",
      label: "Description",
      sortable: false,
      width: 180,
      customCellRenderer: (row) => {
        const description = row.description.length > 0 ? row.description : "";
        return (
          <div className={tableStyles.text()}>
            <Label label={description} truncateLength={null} oneLine />
          </div>
        );
      },
    },
    {
      id: TYPE_COL_ID,
      label: "Type",
      sortable: true,
      width: 80,
      customCellRenderer: (row) => {
        return (
          <div className={tableStyles.text()}>
            <Label label={row.typeFriendlyName} truncateLength={null} oneLine />
          </div>
        );
      },
    },
    {
      id: "roles",
      label: "Role",
      width: 100,
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <div className={tableStyles.text()}>
            <RoleLabel roles={row.roles ?? []} includeLabel={false} />
          </div>
        );
      },
    },
    {
      id: "accessInfo",
      label: "Access",
      sortable: false,
      width: 100,
      customCellRenderer: (row) => {
        return <AccessLabel {...row.accessInfo} />;
      },
    },
    {
      id: "cta",
      label: "",
      sortable: false,
      width: 110,
      customCellRenderer: (row) => {
        return (
          <>
            <div className={tableStyles.row}>
              <div className={tableStyles.cta}>
                <ButtonV3
                  label="More Info"
                  type="mainBorderless"
                  size="xs"
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpenInfoModalRow(row);
                  }}
                />
                <ButtonV3
                  label="Request"
                  type="main"
                  size="xs"
                  disabled={!row.requestable}
                  disabledTooltip={NO_PERMISSION_TO_REQUEST}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenRequest(row, event);
                  }}
                />
              </div>
            </div>
          </>
        );
      },
    },
  ];

  // Fetch the general bundle information
  const { data, error, loading } = useBundleQuery({
    variables: {
      input: {
        id: bundleId,
      },
    },
    skip: !bundleId,
    fetchPolicy: "cache-and-network",
  });

  // Fetch the bundle items
  // Note: We could paginate here, but we don't have a ton of bundle data, so
  //      punting for now
  const {
    data: bundleItemsData,
    error: bundleItemsError,
    loading: bundleItemsLoading,
  } = useBundleItemsQuery({
    variables: {
      input: {
        bundleId,
        searchQuery: debouncedSearchQuery,
        sortBy: sortBy,
      },
    },
    skip: !bundleId,
    fetchPolicy: "cache-and-network",
  });

  const allBundleItems = useMemo(
    () => bundleItemsData?.bundleItems.items ?? [],
    [bundleItemsData?.bundleItems.items]
  );

  // TODO: API to actually return based on entity type
  // TODO: Handle if there is a filter and you select other tabs
  const uniqueItemsWithRoleById: Record<string, ItemWithAccessLevel> = {};
  const requestableItems: BundleItemRow[] = [];

  // Build the bundle items with the appropriate information
  allBundleItems.forEach((item) => {
    if (item.resource) {
      if (item.resource.id in uniqueItemsWithRoleById) {
        let tempItem = uniqueItemsWithRoleById[item.resource.id];
        tempItem.roles.push({
          accessLevelRemoteId: item.accessLevelRemoteId,
          accessLevelName: item.accessLevelName,
        });
        uniqueItemsWithRoleById[item.resource.id] = tempItem;
      } else {
        uniqueItemsWithRoleById[item.resource.id] = {
          kind: EntityType.Resource,
          key: item.key,
          resource: item.resource,
          roles: [
            {
              accessLevelRemoteId: item.accessLevelRemoteId,
              accessLevelName: item.accessLevelName,
            },
          ],
        };
      }
    } else if (item.group) {
      if (item.group.id in uniqueItemsWithRoleById) {
        let tempItem = uniqueItemsWithRoleById[item.group.id];
        tempItem.roles.push({
          accessLevelRemoteId: item.accessLevelRemoteId,
          accessLevelName: item.accessLevelName,
        });
        uniqueItemsWithRoleById[item.group.id] = tempItem;
      } else {
        uniqueItemsWithRoleById[item.group.id] = {
          kind: EntityType.Group,
          key: item.key,
          group: item.group,
          roles: [
            {
              accessLevelRemoteId: item.accessLevelRemoteId,
              accessLevelName: item.accessLevelName,
            },
          ],
        };
      }
    }
  });

  const itemsWithRole = Object.values(uniqueItemsWithRoleById);
  const allRows: BundleItemRow[] = itemsWithRole.map((item) => {
    let itemRow: BundleItemRow;
    if (item.kind === EntityType.Group) {
      const { group, roles } = item;
      const groupTypeInfo = getGroupTypeInfo(group.groupType);
      itemRow = {
        id: item.key,
        [NAME_COL_ID]: group.name,
        description: group.description,
        connectionId: group.connectionId,
        [TYPE_COL_ID]: group.groupType,
        typeFriendlyName: groupTypeInfo?.name ?? "",
        roles: roles,
        requestable: group.isRequestable,
        entityType: EntityType.Group,
        entityId: group.id,
        hasVisibleChildren: false,
        accessInfo: getGroupUserAccessInfo(group.currentUserAccess),
        shortenedBreadcrumb: `${group.connection?.name ?? ""}/`,
        breadcrumb: `${group.connection?.name ?? ""}/`,
        forfeitable: groupHasForfeitableRole(group.currentUserAccess),
        connectable: false,
        // Any group is a source group, unless it's part of a binding and they're not a source group
        groupBinding: group.groupBinding?.id
          ? {
              id: group.groupBinding.id,
              sourceGroupId: group.groupBinding.sourceGroupId,
            }
          : undefined,
      };
    } else {
      const { resource, roles } = item;
      const resourceTypeInfo = getResourceTypeInfo(resource.resourceType);
      const mostRecentUserAccessStatus = currentUserResourceAccessStatusFromResource(
        resource
      );
      itemRow = {
        id: item.key,
        [NAME_COL_ID]: resource.name,
        description: resource.description,
        connectionId: resource.connectionId,
        [TYPE_COL_ID]: resource.resourceType,
        typeFriendlyName: resourceTypeInfo?.fullName ?? "",
        roles: roles,
        requestable: resource.isRequestable,
        entityType: EntityType.Resource,
        entityId: resource.id,
        hasVisibleChildren: false,
        accessInfo: getResourceUserAccessInfo(resource.currentUserAccess),
        shortenedBreadcrumb: formatResourceBreadcrumb(
          resource.ancestorPathToResource,
          50,
          resource.connection?.name
        ),
        breadcrumb: formatResourceBreadcrumb(
          resource.ancestorPathToResource,
          null,
          resource.connection?.name
        ),
        forfeitable: resourceHasForfeitableRole(resource.currentUserAccess),
        connectable:
          mostRecentUserAccessStatus ===
            CurrentUserResourceAccessStatus.AuthorizedSessionStarted ||
          mostRecentUserAccessStatus ===
            CurrentUserResourceAccessStatus.AuthorizedSessionNotStarted,
      };
    }
    if (itemRow.requestable) {
      requestableItems.push(itemRow);
    }

    return itemRow;
  });

  if (!bundleId) {
    return null;
  }

  if (error || bundleItemsError) {
    return (
      <Column isContent maxWidth="none">
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  if (loading) {
    return (
      <Column isContent maxWidth="none">
        <ColumnHeaderSkeleton includeCard />
      </Column>
    );
  }

  let bundle: BundleDetailFragment | undefined;
  switch (data?.bundle.__typename) {
    case "BundleResult":
      bundle = data.bundle.bundle;
      break;
    case "BundleNotFoundError":
      return (
        <Column isContent maxWidth="none">
          <NotFoundPage />
        </Column>
      );
  }

  if (!bundle) {
    return (
      <Column isContent maxWidth="none">
        <NotFoundPage />
      </Column>
    );
  }

  const ownerIconList = getOwnerUserIconList(bundle?.adminOwner?.ownerUsers);

  // Views we can have for bundles
  const bundleViews: BundleEndUserView[] = [
    {
      key: ALL_VIEW_KEY,
      title: "Browse All",
      icon: { type: "name", icon: "dots-grid" },
      count: allRows.length,
    },
    {
      key: ITEMS_VIEW_KEY,
      title: "Items",
      icon: { type: "name", icon: "resource-group" },
      count: allRows.filter((row) => row.entityType === EntityType.Resource)
        .length,
    },
    {
      key: GROUPS_VIEW_KEY,
      title: "Groups",
      icon: { type: "name", icon: "users" },
      count: allRows.filter((row) => row.entityType === EntityType.Group)
        .length,
    },
  ];

  const overviewButtons = (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      <RequestBundleButton bundleId={bundleId} />
    </div>
  );

  const currentView = bundleViews.find((view) => selectedItemType === view.key);
  if (!currentView) {
    setSelectedItemType(ALL_VIEW_KEY);
  }

  // The bundle item API doesn't return items based on entity type (yet), so we
  // are returning all the items and presenting the rows based on which tab is selected
  let presentedRows = allRows;
  if (currentView?.key === ITEMS_VIEW_KEY) {
    presentedRows = allRows.filter(
      (row) => row.entityType === EntityType.Resource
    );
  } else if (currentView?.key === GROUPS_VIEW_KEY) {
    presentedRows = allRows.filter(
      (row) => row.entityType === EntityType.Group
    );
  }

  const tabInfos: PropsFor<typeof TabsV3>["tabInfos"] = bundleViews.map(
    (view) => {
      return {
        title: view.title,
        onClick: () => setSelectedItemType(view.key),
        isSelected: selectedItemType === view.key,
        icon: view.icon,
        badgeCount: view.count,
      };
    }
  );

  return (
    <>
      <Column isContent maxWidth="none">
        <ColumnHeader
          breadcrumbs={[
            { name: "Catalog", to: "/apps" },
            { name: "Bundles", to: "/bundles" },
            {
              name: bundle.name,
              to: "",
            },
          ]}
          includeDefaultActions
        />
        <ColumnContent>
          <EndUserItemDetailsCard
            icon={{ type: "name", icon: "bundle" }}
            title={bundle.name}
            subtitle={bundle.description || "—"}
            actions={overviewButtons}
            rightContent={
              <ItemUsageDetails
                entityType={EntityType.Bundle}
                ownerIcons={ownerIconList}
              />
            }
          />
          <TableControls
            tabInfos={tabInfos}
            selectedItems={checkedItems}
            totalItemsCount={itemsWithRole.length}
            searchQuery={searchQuery?.toString()}
            setSearchQuery={(value: string) => {
              setSearchQuery(value);
            }}
            sortBy={sortBy}
            setSortBy={(value) =>
              setSortBy(value as BundleSortValue | undefined)
            }
            showSortBy={layoutOption === "grid"}
            sortByOptions={BUNDLE_SORT_OPTIONS}
          />
          {bundleItemsLoading && !allBundleItems ? (
            <Skeleton variant="text" width="100px" />
          ) : (
            <>
              {openInfoModalRow?.entityType === EntityType.Group ? (
                <GroupDetailsModal
                  groupId={openInfoModalRow.entityId}
                  showModal={!!openInfoModalRow}
                  closeModal={() => setOpenInfoModalRow(undefined)}
                />
              ) : openInfoModalRow?.entityType === EntityType.Resource ? (
                <ResourceDetailsModal
                  resourceId={openInfoModalRow.entityId}
                  showModal={!!openInfoModalRow}
                  closeModal={() => setOpenInfoModalRow(undefined)}
                />
              ) : null}
              {maybeRenderGroupBindingRedirectModal()}
              {layoutOption === "grid" ? (
                <Masonry
                  loadingItems={bundleItemsLoading}
                  totalNumItems={presentedRows.length}
                  items={presentedRows}
                  getItemKey={(row) => row.id}
                  breakpointCols={BREAKPOINT_COLUMNS}
                  renderItem={(row) => (
                    <InteractiveCard
                      title={row[NAME_COL_ID]}
                      description={row.description}
                      subtitle={row.shortenedBreadcrumb ?? undefined}
                      subtitleTooltip={
                        row.breadcrumb !== row.shortenedBreadcrumb &&
                        row.requestable
                          ? row.breadcrumb
                          : undefined
                      }
                      note={<RoleLabel roles={row.roles ?? []} />}
                      icon={getAppIcon(row)}
                      iconSize="md"
                      disabledReason={
                        !row.requestable ? NO_PERMISSION_TO_REQUEST : undefined
                      }
                      checked={checkedItems.some((item) => item.id === row.id)}
                      onCheckedChange={(checked) => {
                        if (checked) {
                          setCheckedItems((prev) => [...prev, row]);
                        } else {
                          setCheckedItems((prev) =>
                            prev.filter((i) => i.id !== row.id)
                          );
                        }
                      }}
                      checkboxLabel={
                        <Label
                          label={row.typeFriendlyName}
                          inline
                          oneLine
                          truncateLength={null}
                          icon={{
                            type: "entity",
                            entityType: row[TYPE_COL_ID],
                            includeBrand: false,
                          }}
                        />
                      }
                      onClick={(event) => {
                        if (row.requestable) {
                          handleOpenRequest(row, event);
                        }
                      }}
                      renderCTA={(isHovering) => {
                        if (isHovering) {
                          return (
                            <>
                              <ButtonV3
                                label="Request"
                                type="main"
                                disabled={!row.requestable}
                                disabledTooltip={NO_PERMISSION_TO_REQUEST}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleOpenRequest(row, event);
                                }}
                              />
                              <ButtonV3
                                label="More Info"
                                type="mainBorderless"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setOpenInfoModalRow(row);
                                }}
                              />
                            </>
                          );
                        } else {
                          return row.accessInfo.hasAccess ? (
                            <AccessLabel {...row.accessInfo} showRoleName />
                          ) : (
                            <></>
                          );
                        }
                      }}
                    />
                  )}
                />
              ) : (
                <Table
                  rows={presentedRows}
                  totalNumRows={presentedRows.length}
                  getRowId={(ru) => ru.id}
                  columns={BUNDLE_ITEM_COLUMNS}
                  defaultSortBy={NAME_COL_ID}
                  emptyState={{
                    title: "No Items",
                  }}
                  onRowClick={(row, event) => {
                    if (row.requestable) {
                      handleOpenRequest(row, event);
                    }
                  }}
                  getCheckboxDisabledReason={(row) => {
                    if (row.requestable) {
                      return;
                    }
                    return NO_PERMISSION_TO_REQUEST;
                  }}
                  checkedRowIds={new Set(checkedItems.map((item) => item.id))}
                  onSelectAll={(checked) => {
                    if (checked) {
                      setCheckedItems(requestableItems);
                    } else {
                      setCheckedItems([]);
                    }
                  }}
                  selectAllChecked={
                    checkedItems.length > 0 &&
                    checkedItems.length === requestableItems.length
                  }
                  onCheckedRowsChange={(ids, checked) => {
                    if (checked) {
                      const newIDsToAdd = ids.filter(
                        (id) => !checkedItems.some((i) => i.id === id)
                      );
                      const newItems = requestableItems.filter((item) =>
                        newIDsToAdd.some((i) => i === item.id)
                      );
                      setCheckedItems((prev) => [...prev, ...newItems]);
                    } else {
                      setCheckedItems((prev) =>
                        prev.filter((i) => !ids.some((id) => i.id === id))
                      );
                    }
                  }}
                  manualSortDirection={
                    sortBy && {
                      sortBy: sortBy.field,
                      sortDirection: sortBy.direction,
                    }
                  }
                  handleManualSort={(sortBy, sortDirection) => {
                    if (!sortDirection) {
                      setSortBy(undefined);
                      return;
                    }
                    const direction: SortDirection =
                      sortDirection === "DESC"
                        ? SortDirection.Desc
                        : SortDirection.Asc;
                    if (!isSortableField(sortBy)) {
                      return;
                    }

                    setSortBy({
                      field: sortBy,
                      direction: direction,
                    } as BundleSortValue | undefined);
                  }}
                />
              )}
            </>
          )}
        </ColumnContent>
      </Column>
    </>
  );
};

export default BundleDetails;
