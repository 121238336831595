import { formatTimestampForDisplay } from "api/common/common";
import {
  EntityType,
  EventFilterFragment,
  EventForTableFragment,
  EventsInput,
  useEventsTableQuery,
} from "api/generated/graphql";
import axios from "axios";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeaderV3";
import { useToast } from "components/toast/Toast";
import TableHeader from "components/ui/table/TableHeader";
import moment from "moment";
import { useState } from "react";
import { useLocation } from "react-router";
import { logError } from "utils/logging";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import EventFilterBarV3 from "./EventFilterBarV3";
import EventsTableV3 from "./EventsTableV3";
import SavedEventFilterDropdown from "./SavedEventFilterDropdown";
import { getEventFilterEndDateInput, useEventFilter } from "./utils";

const EVENTS_PER_PAGE = 50;

const EventsColumnV3 = () => {
  const location = useLocation();
  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();
  const eventFilter = useEventFilter();
  const [
    selectedEventFilter,
    setSelectedEventFilter,
  ] = useState<EventFilterFragment>();

  const input: EventsInput = {
    filter: {
      ...eventFilter,
      endDate: eventFilter.endDate?.date
        ? {
            date: getEventFilterEndDateInput(eventFilter.endDate.date) ?? "",
          }
        : undefined,
    },
    maxNumEntries: EVENTS_PER_PAGE,
  };
  const {
    data: eventsData,
    error: eventsError,
    loading: eventsLoading,
    fetchMore: fetchMoreEvents,
  } = useEventsTableQuery({
    variables: { input },
  });

  let events: EventForTableFragment[] = [];
  let cursor: string | null = null;
  let filterError: string | null = null;
  switch (eventsData?.events.__typename) {
    case "EventsResult":
      events = eventsData.events.events.map((event) => ({
        ...event,
        createdAt: formatTimestampForDisplay(event.createdAt),
      }));
      cursor = eventsData.events.cursor ?? null;
      break;
    case "InvalidObjectIDFilterError":
      filterError = "Error: Object ID is not a valid UUID";
  }
  if (eventsError) {
    logError(eventsError, `failed to list events`);
    return (
      <Column isContent>
        <UnexpectedErrorPage error={eventsError} />
      </Column>
    );
  }

  const loadMore = cursor
    ? async () => {
        await fetchMoreEvents({
          variables: {
            input: {
              ...input,
              cursor,
            },
          },
        });
      }
    : undefined;

  const handleRequestExport = () => {
    const urlParams = new URLSearchParams(location.search);
    let endDate = urlParams.get("endDate");
    if (endDate) {
      endDate = moment(endDate, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");
      urlParams.delete("endDate");
      urlParams.append("endDate", endDate);
    }
    const queryString = urlParams.toString();

    displayLoadingToast("Generating export...");
    axios({
      url: "/export/events?" + queryString,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_Events_" + moment().toISOString() + ".csv"
        );
        link.click();
        displaySuccessToast(`Success: downloaded events`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  return (
    <Column isContent maxWidth="none">
      <ColumnHeader
        title="Events"
        icon={{ type: "name", icon: "events" }}
        leftActions={SavedEventFilterDropdown({
          selectedEventFilter,
          setSelectedEventFilter,
        })}
        includeDefaultActions
      />
      <EventFilterBarV3
        onChange={() => {
          // Clear any selected save event filter if the user
          // manipulates the applied filters
          setSelectedEventFilter(undefined);
        }}
        enableSaveFilter={true}
      />
      <TableHeader
        entityType={EntityType.Event}
        totalNumRows={null}
        loading={eventsLoading}
        defaultRightActions={[
          {
            label: "Export",
            type: "main",
            iconName: "download",
            onClick: handleRequestExport,
          },
        ]}
      />

      <EventsTableV3
        events={events}
        loadingRows={eventsLoading}
        emptyState={filterError ? { title: filterError } : undefined}
        totalNumRows={Number.MAX_SAFE_INTEGER} // Hack: until events query returns total count
        onLoadMoreRows={loadMore}
      />
    </Column>
  );
};

export default EventsColumnV3;
