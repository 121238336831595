import { Column } from "components/column/Column";
import ColumnHeaderV3 from "components/column/ColumnHeaderV3";
import React from "react";

import SettingsColumn from "./SettingsColumn";
import SettingsContent from "./SettingsContent";
import * as styles from "./SettingsV3.css";

const SettingsV3 = () => {
  return (
    <Column isContent maxWidth="none">
      <ColumnHeaderV3
        title="Settings"
        icon={{ type: "name", icon: "settings" }}
        includeDefaultActions
      />
      <div className={styles.container}>
        <SettingsColumn />
        <SettingsContent />
      </div>
    </Column>
  );
};

export default SettingsV3;
