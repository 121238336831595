import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewAssignedStatus,
  AccessReviewGroupResourceAssignmentFragment,
  AccessReviewItemOutcome,
  AccessReviewItemStatus,
  AssignmentsSortByField,
  EntityType,
  ResourceType,
  ReviewerUserInput,
  SortDirection,
  useAccessReviewGroupResourceAssignmentsQuery,
  useUpdateAccessReviewReviewersMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import PaginatedGroupDropdown from "components/dropdown/PaginatedGroupDropdown";
import { PaginatedUserDropdown } from "components/dropdown/PaginatedUserDropdown";
import ItemTypeLabel from "components/label/ItemTypeLabel";
import { ResourceLabel } from "components/label/Label";
import AccessReviewerModal from "components/modals/AccessReviewerModal";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Input, Select } from "components/ui";
import ButtonGroup from "components/ui/buttongroup/ButtonGroupV3";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import { useContext, useState } from "react";
import { hasBasicPermissions } from "utils/auth/auth";
import {
  SearchParamValue,
  useURLSearchParam,
  useURLSearchParamAsEnum,
} from "utils/router/hooks";
import useDebounce from "utils/search/useDebounce";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { ForbiddenPage } from "views/error/ErrorCodePage";

import * as styles from "./AccessReviewGroupResourceAssignments.css";
import AccessReviewReviewersCell from "./AccessReviewReviewersCell";
import {
  AccessReviewOutcomeLabel,
  AccessReviewStatusLabel,
} from "./AccessReviewStatus";
import {
  ENTITY_NAME_COL_ID,
  isSortableField,
  PRINCIPAL_NAME_COL_ID,
  SortValue,
  TYPE_COL_ID,
} from "./AccessReviewUserAssignments";

interface AccessReviewGroupResourceRow {
  id: string;
  [PRINCIPAL_NAME_COL_ID]: string;
  [ENTITY_NAME_COL_ID]: string;
  [TYPE_COL_ID]: ResourceType;
  role?: string;
  status: string;
  outcome: string;
  reviewers: string;
  data: AccessReviewGroupResourceAssignmentFragment;
}

type AccessReviewResourcesProps = {
  accessReviewId: string;
};

const AccessReviewGroupResourceAssignments = (
  props: AccessReviewResourcesProps
) => {
  const { displaySuccessToast, displayErrorToast } = useToast();

  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [showBulkReviewerModal, setShowBulkReviewerModal] = useState(false);
  const [reviewerError, setReviewerError] = useState("");
  const [showReviewerModal, setShowReviewerModal] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState<
    AccessReviewGroupResourceAssignmentFragment | undefined
  >();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchQuery, 250);
  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: AssignmentsSortByField.PrincipalName,
    direction: SortDirection.Asc,
  });

  // Filter states
  const [assignedStatus, setAssignedStatus] = useURLSearchParamAsEnum(
    "assignedStatus",
    AccessReviewAssignedStatus,
    undefined
  );
  const [reviewerId, setReviewerId] = useURLSearchParam(
    "reviewerId",
    undefined
  );
  const [groupId, setGroupId] = useURLSearchParam("groupId", undefined);
  const [ownerId, setOwnerId] = useURLSearchParam("ownerId", undefined);
  const [outcome, setOutcome] = useURLSearchParamAsEnum(
    "outcome",
    AccessReviewItemOutcome,
    undefined
  );
  const [status, setStatus] = useURLSearchParamAsEnum(
    "reviewStatus",
    AccessReviewItemStatus,
    undefined
  );
  const { authState } = useContext(AuthContext);
  const isMember = hasBasicPermissions(authState.user);
  const canEditReviewers =
    authState.user?.isAdmin || authState.user?.isAuditor || false;

  const ACCESS_REVIEW_GROUP_RESOURCE_COLUMNS: Header<AccessReviewGroupResourceRow>[] = [
    {
      id: PRINCIPAL_NAME_COL_ID,
      label: "Group",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <ResourceLabel
            text={row[PRINCIPAL_NAME_COL_ID]}
            pointerCursor={true}
            groupType={row.data.groupType}
            entityId={row.data.groupId}
            entityTypeNew={EntityType.Group}
          />
        );
      },
    },
    {
      id: ENTITY_NAME_COL_ID,
      label: "Resource",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <ResourceLabel
            text={row[ENTITY_NAME_COL_ID]}
            pointerCursor={true}
            hideIcon
            resourceType={row.data.resourceType}
            entityId={row.data.resourceId}
            entityTypeNew={EntityType.Resource}
          />
        );
      },
    },
    {
      id: TYPE_COL_ID,
      label: "Resource Type",
      sortable: true,
      customCellRenderer: (row) => {
        if (!row[TYPE_COL_ID]) {
          return <></>;
        }
        return <ItemTypeLabel itemType={row[TYPE_COL_ID]} />;
      },
    },
    {
      id: "role",
      label: "Role",
      customCellRenderer: (row) => {
        return <div className={styles.truncated}>{row.role || "--"}</div>;
      },
    },
    {
      id: "status",
      label: "Status",
      sortable: false,
      width: 100,
      customCellRenderer: (row) => {
        return (
          <AccessReviewStatusLabel
            entityType={EntityType.Resource}
            status={row.data.status}
            accessReviewId={props.accessReviewId}
          />
        );
      },
    },
    {
      id: "outcome",
      label: "Outcome",
      sortable: false,
      width: 100,
      customCellRenderer: (row) => {
        return (
          <AccessReviewOutcomeLabel
            entityType={EntityType.Resource}
            outcome={row.data.outcome}
          />
        );
      },
    },
    {
      id: "reviewers",
      label: "Assigned Reviewers",
      sortable: false,
      customCellRenderer: (row) => {
        return (
          <AccessReviewReviewersCell
            itemType="resource"
            canEditReviewers={canEditReviewers}
            reviewerUsers={
              row.data.reviewerUsers?.map((ru) => ({
                name: ru.fullName,
                avatarUrl: ru.avatarUrl,
              })) ?? []
            }
            onClick={() => {
              setSelectedAssignment(row.data);
              setShowReviewerModal(true);
            }}
          />
        );
      },
    },
  ];

  const [
    updateReviewers,
    { loading: updateReviewersLoading },
  ] = useUpdateAccessReviewReviewersMutation();

  const {
    data,
    previousData,
    error,
    loading,
    fetchMore,
  } = useAccessReviewGroupResourceAssignmentsQuery({
    variables: {
      input: {
        accessReviewId: props.accessReviewId,
        adminOwnerId: ownerId,
        assignedStatus: assignedStatus,
        groupId: groupId,
        outcome,
        reviewerId: reviewerId,
        searchQuery: debouncedSearchQuery,
        status,
        sortBy: sortBy,
      },
    },
  });

  const assignments =
    data?.accessReviewGroupResourceAssignments
      .accessReviewGroupResourceAssignments ??
    previousData?.accessReviewGroupResourceAssignments
      .accessReviewGroupResourceAssignments ??
    [];
  const cursor = data?.accessReviewGroupResourceAssignments.cursor ?? null;
  const totalNumGroupAssignments =
    data?.accessReviewGroupResourceAssignments.totalNumGroupAssignments;

  if (isMember) {
    return <ForbiddenPage />;
  }

  if (error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const handleUpdateReviewers = (isBulkUpdate: boolean) => async (
    reviewers: ReviewerUserInput[]
  ) => {
    const accessReviewGroupResourceIds = [];

    if (isBulkUpdate) {
      if (selectedRowIds.length === 0) {
        return;
      }
      for (const assignment of assignments) {
        if (selectedRowIds.includes(assignment.id)) {
          accessReviewGroupResourceIds.push(assignment.id);
        }
      }
    } else {
      if (!selectedAssignment) {
        return;
      }
      accessReviewGroupResourceIds.push(selectedAssignment.id);
    }

    try {
      const { data } = await updateReviewers({
        variables: {
          input: {
            accessReviewId: props.accessReviewId,
            reviewers,
            accessReviewGroupResourceIds,
          },
        },
        refetchQueries: [
          "AccessReviewGroupResourceAssignments",
          "AccessReview",
        ],
      });
      if (
        data?.updateAccessReviewReviewers?.__typename ===
          "UpdateAccessReviewReviewersResult" &&
        data?.updateAccessReviewReviewers?.success
      ) {
        setReviewerError("");
        displaySuccessToast("Success: reviewers updated");
        if (isBulkUpdate) {
          setShowBulkReviewerModal(false);
          setSelectedRowIds([]);
        } else {
          setShowReviewerModal(false);
          setSelectedAssignment(undefined);
        }
        return;
      } else {
        displayErrorToast(
          "Failure: Group Reviewer assignments could not be set"
        );
        setReviewerError("Failed to assign reviewers");
      }
    } catch (error) {
      displayErrorToast("Failure: Group Reviewer assignments could not be set");
      setReviewerError(
        getModifiedErrorMessage(
          "Error: failed to update access reviewers",
          error
        )
      );
    }
  };

  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              accessReviewId: props.accessReviewId,
              adminOwnerId: ownerId,
              assignedStatus: assignedStatus,
              groupId: groupId,
              outcome,
              reviewerId: reviewerId,
              searchQuery: debouncedSearchQuery,
              status,
              cursor,
              sortBy: sortBy,
            },
          },
        });
      }
    : undefined;

  const rows: AccessReviewGroupResourceRow[] = assignments.map((assignment) => {
    return {
      id: assignment.id,
      [PRINCIPAL_NAME_COL_ID]: assignment.groupName,
      [ENTITY_NAME_COL_ID]: assignment.resourceName,
      [TYPE_COL_ID]: assignment.resourceType,
      role: assignment.accessLevelName ?? "--",
      status: assignment.status,
      outcome: assignment.outcome,
      reviewers: assignment.reviewerUsers?.join(", ") ?? "--",
      data: assignment,
    };
  });

  return (
    <div
      className={sprinkles({
        height: "100%",
      })}
    >
      <TableFilters>
        <TableFilters.Left>
          <div className={styles.searchInput}>
            <Input
              value={searchQuery}
              onChange={(value) => setSearchQuery(value)}
              placeholder="Filter"
              leftIconName="search"
              type="search"
              style="search"
            />
          </div>
          <ButtonGroup
            buttons={[
              {
                label: "All",
                selected: !assignedStatus,
                onClick: () => setAssignedStatus(undefined),
              },
              {
                label: "Assigned",
                selected:
                  assignedStatus === AccessReviewAssignedStatus.Assigned,
                onClick: () =>
                  setAssignedStatus(AccessReviewAssignedStatus.Assigned),
              },
              {
                label: "Unassigned",
                selected:
                  assignedStatus === AccessReviewAssignedStatus.Unassigned,
                onClick: () =>
                  setAssignedStatus(AccessReviewAssignedStatus.Unassigned),
              },
            ]}
          />
          <PaginatedUserDropdown
            key="reviewer-filter"
            placeholder="Reviewer"
            onChange={(reviewer) =>
              setReviewerId((reviewer?.id as SearchParamValue) || null)
            }
            valueId={reviewerId}
            selectOnly
            size="sm"
          />
          <PaginatedGroupDropdown
            key="group-filter"
            placeholder="Group"
            onChange={(group) =>
              setGroupId((group?.id as SearchParamValue) || null)
            }
            valueId={groupId}
            selectOnly
            size="sm"
          />
          <div className={styles.ownerDropdown}>
            <OwnerDropdown
              selectedOwnerId={ownerId ?? ""}
              onSelectOwner={(owner) => setOwnerId(owner ? owner.id : null)}
              placeholder="Resource Admin"
              size="sm"
              clearable
            />
          </div>
          <Select
            options={Object.values(AccessReviewItemOutcome)}
            value={outcome}
            onChange={setOutcome}
            getOptionLabel={(outcome) => {
              switch (outcome) {
                case AccessReviewItemOutcome.None:
                  return "None";
                case AccessReviewItemOutcome.Accepted:
                  return "Approved";
                case AccessReviewItemOutcome.Revoked:
                  return "Revoked";
                case AccessReviewItemOutcome.Updated:
                  return "Updated";
              }
            }}
            size="sm"
            clearable
            placeholder="Outcome"
          />
          <Select
            options={Object.values(AccessReviewItemStatus)}
            value={status}
            onChange={setStatus}
            getOptionLabel={(outcome) => {
              switch (outcome) {
                case AccessReviewItemStatus.Completed:
                  return "Completed";
                case AccessReviewItemStatus.NeedsAttention:
                  return "Needs Attention";
                case AccessReviewItemStatus.NeedsEndSystemRevocation:
                  return "Needs End System Revocation";
                case AccessReviewItemStatus.NeedsUpdateRequestApproval:
                  return "Needs Update Request Approval";
                case AccessReviewItemStatus.NoReviewNeeded:
                  return "No Review Needed";
                case AccessReviewItemStatus.NotStarted:
                  return "Not Started";
                case AccessReviewItemStatus.PartiallyCompleted:
                  return "Partially Completed";
              }
            }}
            size="sm"
            clearable
            placeholder="Status"
          />
        </TableFilters.Left>
      </TableFilters>

      <TableHeader
        entityName="Group Access Review"
        totalNumRows={cursor ? totalNumGroupAssignments || 0 : rows.length}
        selectedNumRows={selectedRowIds.length}
        bulkRightActions={[
          {
            label: "Assign Reviewers",
            onClick: () => {
              setShowBulkReviewerModal(true);
            },
            type: "mainSecondary",
          },
        ]}
      />
      <Table
        rows={rows}
        totalNumRows={cursor ? totalNumGroupAssignments || 0 : rows.length}
        loadingRows={loading}
        getRowId={(ru) => ru.id}
        columns={ACCESS_REVIEW_GROUP_RESOURCE_COLUMNS}
        checkedRowIds={new Set(selectedRowIds)}
        onCheckedRowsChange={
          canEditReviewers
            ? (rowIds, checked) => {
                if (checked) {
                  setSelectedRowIds([...selectedRowIds, ...rowIds]);
                } else {
                  setSelectedRowIds(
                    selectedRowIds.filter((id) => !rowIds.includes(id))
                  );
                }
              }
            : undefined
        }
        selectAllChecked={selectedRowIds.length === rows.length}
        onLoadMoreRows={loadMoreRows}
        onSelectAll={(checked) => {
          if (checked) {
            setSelectedRowIds(rows.map((row) => row.id));
          } else {
            setSelectedRowIds([]);
          }
        }}
        manualSortDirection={
          sortBy && {
            sortBy: sortBy.field,
            sortDirection: sortBy.direction,
          }
        }
        handleManualSort={(sortBy, sortDirection) => {
          if (!sortDirection) {
            setSortBy(undefined);
            return;
          }
          if (!isSortableField(sortBy)) {
            return;
          }
          const direction: SortDirection =
            sortDirection === "DESC" ? SortDirection.Desc : SortDirection.Asc;

          setSortBy({
            field: sortBy,
            direction,
          });
        }}
      />
      {selectedRowIds.length > 0 && showBulkReviewerModal && (
        <AccessReviewerModal
          title="Reviewers"
          isModalOpen={showBulkReviewerModal}
          onClose={() => {
            setShowBulkReviewerModal(false);
            setReviewerError("");
          }}
          loading={updateReviewersLoading}
          onSubmit={handleUpdateReviewers(true)}
          errorMessage={reviewerError}
          entryInfos={[]}
          canEditReviewers={canEditReviewers}
        />
      )}
      {selectedAssignment && showReviewerModal && (
        <AccessReviewerModal
          title="Reviewers"
          isModalOpen={showReviewerModal}
          onClose={() => {
            setShowReviewerModal(false);
            setReviewerError("");
          }}
          loading={updateReviewersLoading}
          onSubmit={handleUpdateReviewers(false)}
          errorMessage={reviewerError}
          entryInfos={
            selectedAssignment.reviewerUsers?.map((ru) => ({
              id: ru.id,
              name: ru.fullName,
              avatarUrl: ru.avatarUrl,
              canBeRemoved: true,
              entityIds: [],
            })) ?? []
          }
          canEditReviewers={canEditReviewers}
        />
      )}
    </div>
  );
};

export default AccessReviewGroupResourceAssignments;
