import { openInNewTab } from "api/common/common";
import {
  ConnectionType,
  EntityType,
  ResourceDetailViewFragment,
  ResourceType,
  ServiceType,
  SyncErrorFragment,
  SyncTaskFragment,
  SyncType,
  useResourceDetailViewQuery,
  useSyncStatusQuery,
  Visibility,
} from "api/generated/graphql";
import axios from "axios";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeaderV3";
import ResourcesConfigFormV3 from "components/forms/ResourcesConfigFormV3";
import { makeConfigForResource } from "components/forms/utils";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import SyncStatusModal from "components/label/SyncStatusModal";
import RequestModal from "components/modals/RequestModal";
import { PillV3 } from "components/pills/PillsV3";
import useSyncActionIcon, {
  getSyncLabel,
} from "components/sync/useSyncActionIcon";
import { useToast } from "components/toast/Toast";
import { ButtonV3, TabsV3 } from "components/ui";
import { IconData } from "components/ui/utils";
import { makeURLForEntityViz } from "components/viz/contexts/FilterContext";
import sprinkles from "css/sprinkles.css";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import useLogEvent from "utils/analytics";
import {
  AuthorizedActionExport,
  AuthorizedActionManage,
  hasBasicPermissions,
} from "utils/auth/auth";
import {
  OPAL_GLOBAL_IMPERSONATION_REMOTE_ID,
  OPAL_IMPERSONATION_REMOTE_ID,
} from "utils/constants";
import {
  connectionTypeHasNHIs,
  isSessionableType,
} from "utils/directory/connections";
import {
  isSnowflakeResource,
  resourceTypeCanBeAccessed,
  resourceTypeCanBePrincipal,
  resourceTypeSupportsChildResources,
  serviceTypeHasCustomRoles,
} from "utils/directory/resources";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useGetResourceBreadcrumbs, usePageTitle } from "utils/hooks";
import { logError } from "utils/logging";
import { useURLSearchParam } from "utils/router/hooks";
import { useAccessRequestTransition } from "views/access_request/AccessRequestContext";
import {
  formatRequestDataForItems,
  useHandleRedirectToEndUserExp,
} from "views/apps/enduser_exp/utils";
import { ItemDetailsCard } from "views/common/ItemDetailsCard";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import EventsTableV3Component from "views/events/EventsTableV3Component";
import ResourceDeleteModal from "views/resources/ResourceDeleteModal";
import ResourceGroupsTableV3 from "views/resources/ResourceGroupsTableV3";
import ResourceUsersTableV3 from "views/resources/ResourceUsersTableV3";
import ResourceCustomRolesRow from "views/resources/viewer/rows/ResourceCustomRolesRow";
import ResourceUsageRowV3 from "views/resources/viewer/rows/ResourceUsageRowV3";
import ResourceVaultSessionsRow from "views/resources/viewer/rows/ResourceVaultSessionsRow";

import AppResourcesTable from "./AppResourcesTable";
import { ResourceActionButtonsV3 } from "./ResourceActionButtonsV3";
import RoleAssignmentsTable from "./RoleAssignmentsTable";
import { getAppIcon } from "./utils";

interface ResourceView {
  key: string;
  title: string;
  content: JSX.Element;
  count?: number;
}

const ResourceDetailView = () => {
  const history = useHistory();
  const logEvent = useLogEvent();
  const { authState } = useContext(AuthContext);
  const { resourceId } = useParams<{ resourceId: string }>();
  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();
  const redirectToEndUserExp = useHandleRedirectToEndUserExp();
  const transitionToAccessRequest = useAccessRequestTransition();

  const [showSyncModal, setShowSyncModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showDeleteResourceModal, setShowDeleteResourceModal] = useState(false);
  const [showUnmanagedGroups, setShowUnmanagedGroups] = useState(false);
  const [forceRequestModal, setForceRequestModal] = useURLSearchParam(
    "request"
  );

  const hasNonHumanIdentities = useFeatureFlag(FeatureFlag.NonHumanIdentities);
  const hasEndUserXP = useFeatureFlag(FeatureFlag.EndUserExperience);

  const {
    data: resourceData,
    error: resourceError,
    loading: resourceLoading,
    refetch: refetchResource,
  } = useResourceDetailViewQuery({
    fetchPolicy: "cache-first",
    variables: {
      id: resourceId,
    },
  });

  let resource: ResourceDetailViewFragment | null = null;
  let resourceNotFound = true;
  let isOktaApp = false;
  if (resourceData) {
    switch (resourceData.resource.__typename) {
      case "ResourceResult": {
        resource = resourceData.resource.resource;
        resourceNotFound = false;
        isOktaApp = resource.resourceType === ResourceType.OktaApp;
        break;
      }
      case "ResourceNotFoundError":
        break;
      default:
        logError(new Error(`failed to get resource`));
    }
  } else if (resourceError) {
    logError(resourceError, `failed to get resource`);
  }

  // Redirect users to the correct path when they're browsing a resource but
  // they need to be shown the end user UX.
  useEffect(() => {
    if (!resource?.id || !resource?.connectionId) {
      return;
    }
    let redirected = false;
    if (resource.resourceType === ResourceType.OktaApp) {
      redirected = redirectToEndUserExp(resource.id);
    } else {
      redirected = redirectToEndUserExp(
        resource.connectionId,
        resource.id,
        EntityType.Resource
      );
    }
    if (!redirected) {
      if (forceRequestModal && resource?.isRequestable) {
        if (hasEndUserXP) {
          setForceRequestModal(null);
          transitionToAccessRequest({
            ...formatRequestDataForItems(
              !isOktaApp
                ? {
                    entityId: resource?.id || "",
                    entityType: EntityType.Resource,
                  }
                : []
            ),
            appId: !isOktaApp ? resource?.connection?.id : resource?.id,
          });
        } else {
          setShowRequestModal(true);
          setForceRequestModal(null);
        }
      }
    }
    // don't include redirectToEndUserExp as deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    resource?.id,
    resource?.connectionId,
    resource?.resourceType,
    resource?.isRequestable,
    forceRequestModal,
  ]);

  usePageTitle(resource?.name);

  const {
    data: syncData,
    error: syncError,
    loading: syncLoading,
  } = useSyncStatusQuery({
    variables: {
      input: {
        syncType: SyncType.PullConnectionsSingleResource,
        resourceId,
      },
    },
    skip: resourceNotFound,
  });

  const syncActionIcon = useSyncActionIcon({
    syncType:
      resource?.resourceType === ResourceType.Custom
        ? SyncType.PullPropagationTickets
        : SyncType.PullConnectionsSingleResource,
    resource: resource ?? undefined,
    queriesToRefetch: [
      "ResourceDetailView",
      "ResourceAccessLevels",
      "ResourceCustomAccessLevels",
      "ResourceTags",
    ],
    loadingEntity: (resourceLoading && !resourceData) || resourceNotFound,
    label: "Sync resource",
    adminOnly: false,
  });

  let lastSuccessfulSyncTask: SyncTaskFragment | null = null;
  let syncErrors: SyncErrorFragment[] = [];
  if (syncData) {
    switch (syncData.syncStatus.__typename) {
      case "SyncStatusResult":
        lastSuccessfulSyncTask = syncData.syncStatus.lastSuccessfulSyncTask
          ? syncData.syncStatus.lastSuccessfulSyncTask
          : null;
        syncErrors = syncData.syncStatus.syncErrors;
        break;
      case "InvalidSyncTypeError":
      case "ResourceNotFoundError":
        // we should not get this error if the resource doesn't exist as we're
        // skipping this query if resourceNotFound is true
        logError(syncData.syncStatus.message);
        break;
    }
  }
  let syncStatus: string;
  if (syncError) {
    syncStatus = "Unable to get status";
  } else if (syncLoading) {
    syncStatus = "Loading sync status";
  } else {
    syncStatus = getSyncLabel(lastSuccessfulSyncTask, syncErrors);
  }

  const {
    data: breadcrumbData,
    error: breadcrumbError,
    loading: breadcrumbLoading,
  } = useGetResourceBreadcrumbs(resource);

  if (breadcrumbError) {
    logError(breadcrumbError, "failed to get resource breadcrumbs");
  }

  if (resourceLoading && !resourceData) {
    return (
      <Column isContent maxWidth="none">
        <ColumnHeaderSkeleton includeCard />
      </Column>
    );
  }
  if (resourceNotFound) {
    return (
      <Column isContent maxWidth="none">
        <NotFoundPage entity="Resource" />
      </Column>
    );
  }
  if (!resource || resourceError) {
    return (
      <Column isContent maxWidth="none">
        <UnexpectedErrorPage error={resourceError} />
      </Column>
    );
  }

  const isGlobalImpersonationResource =
    resource.remoteId === OPAL_GLOBAL_IMPERSONATION_REMOTE_ID;
  const isImpersonationResource =
    resource.remoteId === OPAL_IMPERSONATION_REMOTE_ID;
  const canManage = resource.authorizedActions?.includes(
    AuthorizedActionManage
  );
  const canBeAccessed = resourceTypeCanBeAccessed(resource.resourceType);
  const resourceAdminTabsVisible =
    resource.authorizedActions?.includes(AuthorizedActionManage) ||
    resource.authorizedActions?.includes(AuthorizedActionExport);
  const isAdmin = authState.user?.isAdmin || authState.user?.isReadOnlyAdmin;
  const connectionType = resource.connection?.connectionType;
  const canViewUsage =
    resource.serviceType === ServiceType.Ssh &&
    (connectionType === ConnectionType.Aws ||
      connectionType === ConnectionType.AwsSso) &&
    resource.authorizedActions?.includes(AuthorizedActionManage);

  const handleRequestExport = () => {
    if (!resource) return;
    logEvent({
      name: "apps_export_users",
      properties: {
        exportType: "resource",
      },
    });
    displayLoadingToast("Generating export...");
    axios({
      url: "/export/resources/users?resourceID=" + resource.id,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        if (!resource) return;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_" + resource.name.replace(" ", "_") + "_Users.csv"
        );
        link.click();
        displaySuccessToast(`Success: downloaded users for resource`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  const handleRequestExportDebugInfo = () => {
    if (!resource) return;
    displayLoadingToast("Generating export...");
    axios({
      url: `/export/resources/${resource.id}/debug`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        if (!resource) return;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_" + resource.name.replace(" ", "_") + "_Debug.json"
        );
        link.click();
        displaySuccessToast(`Success: downloaded resource debug info`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  const views: ResourceView[] = [];
  if (
    resourceTypeSupportsChildResources(resource.resourceType) ||
    resource.resourceType === ResourceType.OktaApp
  ) {
    views.push({
      key: "resources",
      title: "Resources",
      content: (
        <AppResourcesTable
          appId={isOktaApp ? resource.id : resource.connection?.id}
          resourceType={resource.resourceType}
          totalResources={
            isOktaApp ? undefined : resource.numDescendantResources
          }
        />
      ),
    });
  } else if (resourceTypeCanBePrincipal(resource.resourceType)) {
    views.push({
      key: "resources",
      title: "Resources",
      content: (
        <RoleAssignmentsTable
          resourceId={resource.id}
          roleAssignments={resource.entityAssignmentsForPrincipal}
          canManage={canManage}
          mode="entityView"
        />
      ),
    });
  }
  if (
    resourceAdminTabsVisible &&
    (canBeAccessed || isSnowflakeResource(resource.resourceType))
  ) {
    views.push({
      key: "users",
      title: "User Access",
      content: <ResourceUsersTableV3 resource={resource} />,
      count: resource?.numResourceUsers || 0,
    });
    if (hasNonHumanIdentities && connectionTypeHasNHIs(connectionType)) {
      views.push({
        key: "nonhuman-identities",
        title: "Non-human Access",
        content: (
          <RoleAssignmentsTable
            resourceId={resource.id}
            roleAssignments={resource.principalAssignmentsForEntity}
            canManage={canManage}
            mode="principalView"
          />
        ),
        count: new Set(
          resource.principalAssignmentsForEntity.map((ra) => ra.principalID)
        ).size,
      });
    }
  }
  if (canBeAccessed || isSnowflakeResource(resource.resourceType)) {
    views.push({
      key: "groups",
      title: "Group Access",
      content: (
        <ResourceGroupsTableV3
          resource={resource}
          showUnmanagedGroups={showUnmanagedGroups}
          setShowUnmanagedGroups={setShowUnmanagedGroups}
        />
      ),
      count: new Set(
        (showUnmanagedGroups
          ? resource.containingGroups
          : resource.containingGroups.filter((group) => group.group?.isManaged)
        ).map((group) => group.groupId)
      ).size,
    });
  }
  if (resourceAdminTabsVisible) {
    views.push({
      key: "events",
      title: "Events",
      content: (
        <EventsTableV3Component
          eventFilter={{
            objects: {
              objectId: resource.id,
            },
          }}
          route={{
            pathname: `/resources/${resource.id}`,
            hash: "#events",
          }}
        />
      ),
    });
  }
  if (canViewUsage) {
    views.push({
      key: "usage",
      title: "Usage",
      content: <ResourceUsageRowV3 resource={resource} />,
    });
  }

  if (
    serviceTypeHasCustomRoles(
      resource.serviceType,
      resource.connection?.connectionType,
      resource.resourceType
    ) &&
    resourceAdminTabsVisible
  ) {
    views.push({
      key: "roles",
      title: "Roles",
      content: <ResourceCustomRolesRow resource={resource} />,
    });
  }
  if (
    resource.connection?.connectionType &&
    isSessionableType(resource.connection.connectionType, resource.remoteId) &&
    resourceAdminTabsVisible &&
    canBeAccessed
  ) {
    views.push({
      key: "sessions",
      title: "Sessions",
      content: <ResourceVaultSessionsRow resource={resource} />,
    });
  }
  views.push({
    key: "details",
    title: "Details",
    content: (
      <ResourcesConfigFormV3
        mode="view"
        config={makeConfigForResource(
          resource,
          resource.configTemplate ?? undefined
        )}
        onChange={() => {}}
        isViewingAsNonAdmin={!resourceAdminTabsVisible}
      />
    ),
  });
  const selectedView = location.hash.slice(1) || views[0].key;

  const tabInfos: PropsFor<typeof TabsV3>["tabInfos"] = views.map((view) => ({
    title: view.title,
    onClick: () => history.push({ hash: view.key }),
    isSelected: selectedView === view.key,
    badgeCount: view.count,
  }));

  const actionIcons: PropsFor<typeof ColumnHeader>["actionIcons"] = [];
  if (!hasBasicPermissions(authState.user) || canManage) {
    if (canManage && syncActionIcon) {
      actionIcons.push(syncActionIcon);
    }
    actionIcons.push({
      label: "View Sync Details",
      sublabel: syncStatus,
      onClick: () => {
        logEvent({
          name: "apps_view_sync_details",
          properties: {
            syncType: SyncType.PullConnectionsSingleConnection,
          },
        });
        setShowSyncModal(true);
      },
      adminOnly: false,
    });
  }

  const rightActions = (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      {isAdmin && (
        <ButtonV3
          type="mainSecondary"
          label="Explore"
          leftIconName="department"
          onClick={() => {
            const hash = makeURLForEntityViz(resourceId, EntityType.Resource);
            openInNewTab("/insights" + hash);
          }}
          size="sm"
        />
      )}
      {canManage && (
        <ButtonV3
          type="mainSecondary"
          label="Edit"
          leftIconName="edit"
          onClick={() => history.push(`/resources/${resourceId}/edit`)}
          size="sm"
        />
      )}
      <ResourceActionButtonsV3
        resource={resource}
        resourceId={resourceId}
        onNavigate={(viewKey) => {
          if (viewKey === "request") {
            if (hasEndUserXP) {
              transitionToAccessRequest({
                ...formatRequestDataForItems(
                  !isOktaApp
                    ? {
                        entityId: resource?.id || "",
                        entityType: EntityType.Resource,
                      }
                    : []
                ),
                appId: !isOktaApp ? resource?.connection?.id : resource?.id,
              });
            } else {
              setShowRequestModal(true);
            }
          }
        }}
        refetchResource={refetchResource}
      />
    </div>
  );

  const menuOptions: PropsFor<typeof ItemDetailsCard>["extraMenuOptions"] = [];
  if (resource.authorizedActions?.includes(AuthorizedActionExport)) {
    menuOptions.push(
      {
        label: "Export resource users",
        icon: { type: "name", icon: "users-right" },
        onClick: handleRequestExport,
      },
      {
        label: "Export debug info",
        icon: { type: "name", icon: "tool" },
        onClick: handleRequestExportDebugInfo,
      }
    );
  }
  if (canManage && resource.connection?.connectionType != ConnectionType.Opal) {
    menuOptions.push({
      label: "Remove from Opal",
      onClick: () => {
        setShowDeleteResourceModal(true);
      },
      type: "danger",
      icon: { type: "name", icon: "trash" },
    });
  }

  let iconData: IconData = {
    type: "entity",
    entityType: resource.connection?.connectionType || resource.resourceType,
  };
  if (isOktaApp) {
    iconData = getAppIcon({
      __typename: "OktaResourceApp",
      iconUrl: resource.iconUrl,
    });
  }

  const titleAccessory = !isOktaApp ? (
    <PillV3
      pillColor="Teal"
      icon={{ type: "entity", entityType: resource.resourceType }}
      keyText={getResourceTypeInfo(resource.resourceType)?.fullName}
    />
  ) : undefined;

  const footerFields: Record<string, string> = {
    Admin: resource.adminOwner?.name || "--",
    Source: isOktaApp ? "Okta" : "",
    Visibility:
      resource.visibility === Visibility.Global ? "Global" : "Limited",
    ["Configuration Template"]: resource.configTemplate?.name || "No Template",
    ["MFA to Approve"]: resource.requireMfaToApprove
      ? "Required"
      : "Not Required",
  };

  const selectedViewInfo =
    views.find((view) => view.key === selectedView) ?? views[0];
  const content = selectedViewInfo?.content;

  return (
    <>
      {showSyncModal && (
        <SyncStatusModal
          syncType={SyncType.PullConnectionsSingleResource}
          entity={resource}
          lastSuccessfulSyncTask={lastSuccessfulSyncTask}
          syncErrors={syncErrors}
          isModalOpen={showSyncModal}
          onClose={() => {
            setShowSyncModal(false);
          }}
        />
      )}
      {showRequestModal && (
        <RequestModal
          entity={resource}
          isGlobalImpersonationResource={isGlobalImpersonationResource}
          isImpersonationResource={isImpersonationResource}
          entityType={EntityType.Resource}
          containingResourceGroups={resource.containingGroups}
          isModalOpen
          onClose={() => setShowRequestModal(false)}
        />
      )}
      {showDeleteResourceModal && (
        <ResourceDeleteModal
          resource={resource}
          showModal={showDeleteResourceModal}
          setShowModal={setShowDeleteResourceModal}
        />
      )}
      <Column isContent maxWidth="none">
        <ColumnHeader
          breadcrumbs={!breadcrumbLoading ? breadcrumbData : undefined}
          actionIcons={actionIcons}
          includeDefaultActions
        />
        <ColumnContent>
          <ItemDetailsCard
            icon={iconData}
            title={resource.name}
            titleAccessory={titleAccessory}
            subtitle={resource.description || "--"}
            rightActions={rightActions}
            extraMenuOptions={menuOptions}
            footerFields={footerFields}
            tags={resource.tags.map((tag) => ({
              id: tag.tagId,
              key: tag.tag?.key || "",
              value: tag.tag?.value || "",
            }))}
            messageChannels={resource.auditMessageChannels?.map(
              (channel) => channel.name
            )}
          />
          <TabsV3 tabInfos={tabInfos} />
          {content}
        </ColumnContent>
      </Column>
    </>
  );
};

export default ResourceDetailView;
