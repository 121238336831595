import { Maybe, ResourceType } from "api/generated/graphql";
import bucketIcon from "assets/icons/bucket.svg";
import organizationIcon from "assets/icons/buildings.svg";
import computeInstanceIcon from "assets/icons/compute-instance.svg";
import customIcon from "assets/icons/custom.svg";
import databaseIcon from "assets/icons/database.svg";
import folderIcon from "assets/icons/folder.svg";
import kubernetesIcon from "assets/icons/kubernetes.svg";
import oktaLogo from "assets/icons/okta-app.svg";
import permissionSetIcon from "assets/icons/permission-set.svg";
import singleKeyIcon from "assets/icons/permission.svg";
import profileIcon from "assets/icons/profile.svg";
import projectsIcon from "assets/icons/project.svg";
import repoIcon from "assets/icons/repo.svg";
import roleIcon from "assets/icons/role.svg";
import serviceAccountIcon from "assets/icons/service-account.svg";
import tableIcon from "assets/icons/table.svg";
import Label from "components/label/Label";
import { IconName } from "components/ui/icon/Icon";
import sprinkles from "css/sprinkles.css";

export type ResourceTypeInfo = {
  name: string;
  fullName: string;
  icon: string;
  iconName: IconName;
};

export const resourceTypeInfoByType: Record<ResourceType, ResourceTypeInfo> = {
  [ResourceType.AwsEc2Instance]: {
    name: "EC2",
    fullName: "AWS EC2",
    icon: computeInstanceIcon,
    iconName: "server-3",
  },
  [ResourceType.AwsEksCluster]: {
    name: "EKS",
    fullName: "AWS EKS",
    icon: kubernetesIcon,
    iconName: "kubernetes",
  },
  [ResourceType.AwsIamRole]: {
    name: "IAM Role",
    fullName: "AWS IAM Role",
    icon: roleIcon,
    iconName: "role",
  },
  [ResourceType.AwsAccount]: {
    name: "Account",
    fullName: "AWS Account",
    icon: roleIcon,
    iconName: "account",
  },
  [ResourceType.AwsSsoPermissionSet]: {
    name: "Identity Center Role",
    fullName: "AWS Identity Center Role",
    icon: permissionSetIcon,
    iconName: "list",
  },
  [ResourceType.AwsRdsMysqlInstance]: {
    name: "RDS MySQL Instance",
    fullName: "AWS RDS MySQL Instance",
    icon: databaseIcon,
    iconName: "database",
  },
  [ResourceType.AwsRdsMysqlCluster]: {
    name: "RDS MySQL Cluster",
    fullName: "AWS RDS MySQL Cluster",
    icon: databaseIcon,
    iconName: "database",
  },
  [ResourceType.AwsRdsPostgresInstance]: {
    name: "RDS Postgres Instance",
    fullName: "AWS RDS Postgres Instance",
    icon: databaseIcon,
    iconName: "database",
  },
  [ResourceType.AwsRdsPostgresCluster]: {
    name: "RDS Postgres Cluster",
    fullName: "AWS RDS Postgres Cluster",
    icon: databaseIcon,
    iconName: "database",
  },
  [ResourceType.Custom]: {
    name: "Custom",
    fullName: "Custom",
    icon: customIcon,
    iconName: "cube",
  },
  [ResourceType.CustomConnector]: {
    name: "Connector Resource",
    fullName: "Connector Resource",
    icon: customIcon,
    iconName: "cube",
  },
  [ResourceType.GcpOrganization]: {
    name: "Organization",
    fullName: "GCP Organization",
    icon: organizationIcon,
    iconName: "buildings",
  },
  [ResourceType.GcpBucket]: {
    name: "Bucket",
    fullName: "GCP Bucket",
    icon: bucketIcon,
    iconName: "bucket",
  },
  [ResourceType.GcpFolder]: {
    name: "Folder",
    fullName: "GCP Folder",
    icon: folderIcon,
    iconName: "folder",
  },
  [ResourceType.GcpComputeInstance]: {
    name: "Compute",
    fullName: "GCP Compute",
    icon: computeInstanceIcon,
    iconName: "server-3",
  },
  [ResourceType.GcpCloudSqlMysqlInstance]: {
    name: "Cloud SQL MySQL",
    fullName: "GCP Cloud SQL MySQL",
    icon: databaseIcon,
    iconName: "database",
  },
  [ResourceType.GcpCloudSqlPostgresInstance]: {
    name: "Cloud SQL Postgres",
    fullName: "GCP Cloud SQL Postgres",
    icon: databaseIcon,
    iconName: "database",
  },
  [ResourceType.GcpGkeCluster]: {
    name: "GKE",
    fullName: "GCP GKE",
    icon: kubernetesIcon,
    iconName: "kubernetes",
  },
  [ResourceType.GcpProject]: {
    name: "Project",
    fullName: "GCP Project",
    icon: projectsIcon,
    iconName: "gcp-project",
  },
  [ResourceType.GcpBigQueryDataset]: {
    name: "Dataset",
    fullName: "GCP BigQuery Dataset",
    icon: databaseIcon,
    iconName: "database",
  },
  [ResourceType.GcpBigQueryTable]: {
    name: "Table",
    fullName: "GCP BigQuery Table",
    icon: tableIcon,
    iconName: "table",
  },
  [ResourceType.GcpServiceAccount]: {
    name: "Service Account",
    fullName: "GCP Service Account",
    icon: serviceAccountIcon,
    iconName: "service-account",
  },
  [ResourceType.GoogleWorkspaceRole]: {
    name: "Workspace Role",
    fullName: "Google Workspace Role",
    icon: roleIcon,
    iconName: "role",
  },
  [ResourceType.GitHubRepo]: {
    name: "Repo",
    fullName: "GitHub Repo",
    icon: repoIcon,
    iconName: "git-merge",
  },
  [ResourceType.GitLabProject]: {
    name: "Project",
    fullName: "GitLab Project",
    icon: repoIcon,
    iconName: "git-merge",
  },
  [ResourceType.MongoInstance]: {
    name: "Mongo",
    fullName: "Mongo",
    icon: databaseIcon,
    iconName: "database",
  },
  [ResourceType.MongoAtlasInstance]: {
    name: "Mongo Atlas",
    fullName: "Mongo Atlas",
    icon: databaseIcon,
    iconName: "database",
  },
  [ResourceType.OktaApp]: {
    name: "App",
    fullName: "Okta App",
    icon: oktaLogo,
    iconName: "apps",
  },
  [ResourceType.OktaRole]: {
    name: "Role",
    fullName: "Okta Role",
    icon: roleIcon,
    iconName: "role",
  },
  [ResourceType.OpalRole]: {
    name: "Role",
    fullName: "Opal Role",
    icon: roleIcon,
    iconName: "role",
  },
  [ResourceType.OpalScopedRole]: {
    name: "Opal Scoped Role",
    fullName: "Opal Scoped Role",
    icon: roleIcon,
    iconName: "role",
  },
  [ResourceType.PagerdutyRole]: {
    name: "Role",
    fullName: "PagerDuty Role",
    icon: roleIcon,
    iconName: "role",
  },
  [ResourceType.TailscaleSsh]: {
    name: "Tailscale",
    fullName: "Tailscale SSH",
    icon: roleIcon,
    iconName: "server-3",
  },
  [ResourceType.SalesforcePermissionSet]: {
    name: "Permission Set",
    fullName: "SFDC Permission Set",
    icon: permissionSetIcon,
    iconName: "list",
  },
  [ResourceType.SalesforceProfile]: {
    name: "Profile",
    fullName: "SFDC Profile",
    icon: profileIcon,
    iconName: "user-square",
  },
  [ResourceType.SalesforceRole]: {
    name: "Role",
    fullName: "SFDC Role",
    icon: roleIcon,
    iconName: "role",
  },
  [ResourceType.SnowflakeDatabase]: {
    name: "Database",
    fullName: "Snowflake Database",
    icon: databaseIcon,
    iconName: "database",
  },
  [ResourceType.SnowflakeSchema]: {
    name: "Schema",
    fullName: "Snowflake Schema",
    icon: databaseIcon,
    iconName: "dataflow",
  },
  [ResourceType.SnowflakeTable]: {
    name: "Table",
    fullName: "Snowflake Table",
    icon: tableIcon,
    iconName: "table",
  },
  [ResourceType.WorkdayRole]: {
    name: "Organization Role",
    fullName: "Workday Organization Role",
    icon: roleIcon,
    iconName: "cube",
  },
  [ResourceType.MysqlInstance]: {
    name: "MySQL",
    fullName: "MySQL",
    icon: databaseIcon,
    iconName: "database",
  },
  [ResourceType.MariadbInstance]: {
    name: "MariaDB",
    fullName: "MariaDB",
    icon: databaseIcon,
    iconName: "database",
  },
  [ResourceType.PostgresInstance]: {
    name: "PostgreSQL",
    fullName: "Postgres",
    icon: databaseIcon,
    iconName: "database",
  },
  [ResourceType.TeleportRole]: {
    name: "Teleport Role",
    fullName: "Teleport Role",
    icon: roleIcon,
    iconName: "role",
  },
  [ResourceType.AzureManagementGroup]: {
    name: "Management Group",
    fullName: "Azure Management Group",
    icon: organizationIcon,
    iconName: "management-group",
  },
  [ResourceType.AzureResourceGroup]: {
    name: "Resource Group",
    fullName: "Azure Resource Group",
    icon: roleIcon,
    iconName: "resource-group",
  },
  [ResourceType.AzureSubscription]: {
    name: "Subscription",
    fullName: "Azure Subscription",
    icon: singleKeyIcon,
    iconName: "key",
  },
  [ResourceType.AzureVirtualMachine]: {
    name: "Virtual Machine",
    fullName: "Azure Virtual Machine",
    icon: computeInstanceIcon,
    iconName: "key",
  },
  [ResourceType.AzureStorageAccount]: {
    name: "Storage Account",
    fullName: "Azure Storage Account",
    icon: bucketIcon,
    iconName: "bucket",
  },
  [ResourceType.AzureStorageContainer]: {
    name: "Storage Container",
    fullName: "Azure Storage Container",
    icon: bucketIcon,
    iconName: "bucket",
  },
  [ResourceType.AzureSqlServer]: {
    name: "SQL Server",
    fullName: "Azure SQL Server",
    icon: databaseIcon,
    iconName: "database",
  },
  [ResourceType.AzureSqlManagedInstance]: {
    name: "SQL Managed Instance",
    fullName: "Azure SQL Managed Instance",
    icon: databaseIcon,
    iconName: "database",
  },
  [ResourceType.AzureSqlDatabase]: {
    name: "SQL Database",
    fullName: "Azure SQL Database",
    icon: databaseIcon,
    iconName: "database",
  },
  [ResourceType.AzureUserAssignedManagedIdentity]: {
    name: "User-Assigned Managed Identity",
    fullName: "Azure User-Assigned Managed Identity",
    icon: serviceAccountIcon,
    iconName: "service-account",
  },
};

export const getResourceTypeInfo = (resourceType?: Maybe<ResourceType>) => {
  return resourceType ? resourceTypeInfoByType[resourceType] : null;
};

type ResourceTypeLabelProps = {
  resourceType?: Maybe<ResourceType>;
  pointerCursor?: boolean;
  maxChars?: number;
  fullName?: boolean;
};

export const ResourceTypeLabel = (props: ResourceTypeLabelProps) => {
  const resourceTypeInfo = getResourceTypeInfo(props.resourceType);

  let name = resourceTypeInfo?.name;
  if (props.fullName) {
    name = resourceTypeInfo?.fullName;
  }

  return (
    <div className={sprinkles({ display: "flex" })}>
      <Label
        icon={resourceTypeInfo?.icon}
        text={name || "--"}
        pointerCursor={props.pointerCursor}
        maxChars={props.maxChars}
      />
    </div>
  );
};

export default ResourceTypeLabel;
