import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewAssignedStatus,
  AccessReviewItemOutcome,
  AccessReviewItemStatus,
  AccessReviewResourcePrincipalAssignmentFragment,
  AssignmentsSortByField,
  EntityType,
  GroupType,
  ResourceType,
  ReviewerUserInput,
  SortDirection,
  useAccessReviewResourcePrincipalAssignmentsQuery,
  useUpdateAccessReviewReviewersMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import PaginatedResourceDropdown from "components/dropdown/PaginatedResourceDropdown";
import { PaginatedUserDropdown } from "components/dropdown/PaginatedUserDropdown";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import { ResourceLabel } from "components/label/Label";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import AccessReviewerModal from "components/modals/AccessReviewerModal";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { EntityIcon, Input, Select } from "components/ui";
import ButtonGroup from "components/ui/buttongroup/ButtonGroupV3";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import { useContext, useState } from "react";
import { hasBasicPermissions } from "utils/auth/auth";
import { useDebouncedValue } from "utils/hooks";
import {
  SearchParamValue,
  useURLSearchParam,
  useURLSearchParamAsEnum,
} from "utils/router/hooks";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { ForbiddenPage } from "views/error/ErrorCodePage";

import * as styles from "./AccessReviewResourcePrincipalAssignments.css";
import AccessReviewReviewersCell from "./AccessReviewReviewersCell";
import {
  AccessReviewOutcomeLabel,
  AccessReviewStatusLabel,
} from "./AccessReviewStatus";
import {
  ENTITY_NAME_COL_ID,
  isSortableField,
  PRINCIPAL_NAME_COL_ID,
  SortValue,
  TYPE_COL_ID,
} from "./AccessReviewUserAssignments";

interface AssignmentRow {
  id: string;
  [PRINCIPAL_NAME_COL_ID]: string;
  [ENTITY_NAME_COL_ID]: string;
  [TYPE_COL_ID]?: ResourceType | GroupType;
  role?: string;
  status: string;
  outcome: string;
  reviewers: string;
  data: AccessReviewResourcePrincipalAssignmentFragment;
}

const AccessReviewResourcePrincipalAssignments = ({
  accessReviewId,
}: {
  accessReviewId: string;
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedAssignment, setSelectedAssignment] = useState<
    AccessReviewResourcePrincipalAssignmentFragment | undefined
  >();
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [showReviewerModal, setShowReviewerModal] = useState(false);
  const [showBulkReviewerModal, setShowBulkReviewerModal] = useState(false);
  const [reviewerError, setReviewerError] = useState("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 250);
  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: AssignmentsSortByField.PrincipalName,
    direction: SortDirection.Asc,
  });

  // Filter states
  const [assignedStatus, setAssignedStatus] = useURLSearchParamAsEnum(
    "assignedStatus",
    AccessReviewAssignedStatus,
    undefined
  );
  const [reviewerId, setReviewerId] = useURLSearchParam(
    "reviewerId",
    undefined
  );
  const [
    resourcePrincipalFilterId,
    setResourcePrincipalFilterId,
  ] = useURLSearchParam("resourcePrincipalId", undefined);
  const [ownerId, setOwnerId] = useURLSearchParam("ownerId", undefined);
  const [outcome, setOutcome] = useURLSearchParamAsEnum(
    "outcome",
    AccessReviewItemOutcome,
    undefined
  );
  const [status, setStatus] = useURLSearchParamAsEnum(
    "reviewStatus",
    AccessReviewItemStatus,
    undefined
  );
  const { displaySuccessToast, displayErrorToast } = useToast();

  const {
    data,
    previousData,
    error,
    loading,
    fetchMore,
  } = useAccessReviewResourcePrincipalAssignmentsQuery({
    variables: {
      input: {
        accessReviewId,
        searchQuery: debouncedSearchQuery,
        assignedStatus,
        reviewerId: reviewerId,
        resourcePrincipalID: resourcePrincipalFilterId,
        adminOwnerId: ownerId,
        outcome,
        status,
        sortBy: sortBy,
      },
    },
  });

  const [
    updateReviewers,
    { loading: updateReviewersLoading },
  ] = useUpdateAccessReviewReviewersMutation();

  const { authState } = useContext(AuthContext);
  const isMember = hasBasicPermissions(authState.user);
  const canEditReviewers =
    authState.user?.isAdmin || authState.user?.isAuditor || false;

  let assignments =
    data?.accessReviewResourcePrincipalAssignments
      .accessReviewResourcePrincipalAssignments ??
    previousData?.accessReviewResourcePrincipalAssignments
      .accessReviewResourcePrincipalAssignments ??
    [];
  let cursor = data?.accessReviewResourcePrincipalAssignments.cursor ?? null;
  const totalNumResourcePrincipalAssignments =
    data?.accessReviewResourcePrincipalAssignments
      .totalNumResourcePrincipalAssignments;

  if (isMember) {
    return <ForbiddenPage />;
  }

  if (error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const handleUpdateReviewers = (isBulkUpdate: boolean) => async (
    reviewers: ReviewerUserInput[]
  ) => {
    const accessReviewResourceResourceIds = [];

    if (isBulkUpdate) {
      if (selectedRowIds.length === 0) {
        return;
      }
      for (const assignment of assignments) {
        if (selectedRowIds.includes(assignment.id)) {
          accessReviewResourceResourceIds.push(assignment.id);
        }
      }
    } else {
      if (!selectedAssignment) {
        return;
      }
      accessReviewResourceResourceIds.push(selectedAssignment.id);
    }

    try {
      const { data } = await updateReviewers({
        variables: {
          input: {
            accessReviewId: accessReviewId,
            reviewers,
            accessReviewResourceResourceIds: accessReviewResourceResourceIds,
          },
        },
        refetchQueries: [
          "AccessReviewResourcePrincipalAssignments",
          "AccessReview",
        ],
      });
      if (
        data?.updateAccessReviewReviewers?.__typename ===
          "UpdateAccessReviewReviewersResult" &&
        data?.updateAccessReviewReviewers?.success
      ) {
        setReviewerError("");
        displaySuccessToast("Success: reviewers updated");
        if (isBulkUpdate) {
          setShowBulkReviewerModal(false);
          setSelectedRowIds([]);
        } else {
          setShowReviewerModal(false);
          setSelectedAssignment(undefined);
        }
        return;
      } else {
        displayErrorToast(
          "Failure: Resource Reviewer assignments could not be set"
        );
        setReviewerError("Failed to assign reviewers");
      }
    } catch (error) {
      displayErrorToast(
        "Failure: Resource Reviewer assignments could not be set"
      );
      setReviewerError(
        getModifiedErrorMessage(
          "Error: failed to update access reviewers",
          error
        )
      );
    }
  };

  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              cursor,
              accessReviewId,
              searchQuery: debouncedSearchQuery,
              assignedStatus,
              reviewerId: reviewerId,
              resourcePrincipalID: resourcePrincipalFilterId,
              adminOwnerId: ownerId,
              outcome,
              status,
              sortBy: sortBy,
            },
          },
        });
      }
    : undefined;

  const rows: AssignmentRow[] = assignments.map((assignment) => {
    return {
      id: assignment.id,
      [PRINCIPAL_NAME_COL_ID]: assignment.resourcePrincipalName,
      [ENTITY_NAME_COL_ID]: assignment.entityName,
      [TYPE_COL_ID]: assignment.entityItemType as ResourceType | GroupType,
      role: assignment.accessLevelName ?? "--",
      status: assignment.status,
      outcome: assignment.outcome,
      reviewers: assignment.reviewerUsers?.join(", ") ?? "--",
      data: assignment,
    };
  });

  const columns: Header<AssignmentRow>[] = [
    {
      id: PRINCIPAL_NAME_COL_ID,
      label: "Principal",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <div className={styles.principalCell}>
            <ResourceLabel
              text={row[PRINCIPAL_NAME_COL_ID]}
              pointerCursor={true}
              entityId={row.data.resourcePrincipalId}
              entityTypeNew={EntityType.Resource}
              resourceType={row.data.resourcePrincipalType}
            />
          </div>
        );
      },
    },
    {
      id: ENTITY_NAME_COL_ID,
      label: "Resource",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <ResourceLabel
            text={row[ENTITY_NAME_COL_ID]}
            pointerCursor={true}
            hideIcon
            entityId={row.data.entityId}
            entityTypeNew={row.data.entityType}
            resourceType={
              row.data.entityType === EntityType.Resource
                ? (row.data.entityItemType as ResourceType)
                : undefined
            }
            groupType={
              row.data.entityType === EntityType.Group
                ? (row.data.entityItemType as GroupType)
                : undefined
            }
          />
        );
      },
    },
    {
      id: TYPE_COL_ID,
      label: "Resource Type",
      sortable: true,
      customCellRenderer: (row) => {
        let entityType: ResourceType | GroupType | undefined;
        let label = "";
        if (row.data.entityType === EntityType.Resource) {
          entityType = row[TYPE_COL_ID];
          label =
            resourceTypeInfoByType[row[TYPE_COL_ID] as ResourceType].fullName;
        } else if (row.data.entityType === EntityType.Group) {
          entityType = row[TYPE_COL_ID];
          label = groupTypeInfoByType[row[TYPE_COL_ID] as GroupType].name;
        }
        if (!entityType) {
          return "--";
        }

        return (
          <div
            className={sprinkles({
              display: "flex",
              gap: "sm",
              alignItems: "center",
            })}
          >
            <EntityIcon iconStyle="rounded" type={entityType} />
            {label}
          </div>
        );
      },
    },
    {
      id: "role",
      label: "Role",
      sortable: false,
      customCellRenderer: (row) => {
        return <div className={styles.truncated}>{row.role || "--"}</div>;
      },
    },
    {
      id: "status",
      label: "Status",
      sortable: false,
      width: 100,
      customCellRenderer: (row) => {
        return (
          <AccessReviewStatusLabel
            entityType={EntityType.Resource}
            status={row.data.status}
            accessReviewId={accessReviewId}
          />
        );
      },
    },
    {
      id: "outcome",
      label: "Outcome",
      sortable: false,
      width: 100,
      customCellRenderer: (row) => {
        return (
          <AccessReviewOutcomeLabel
            entityType={EntityType.Resource}
            outcome={row.data.outcome}
          />
        );
      },
    },
    {
      id: "reviewers",
      label: "Assigned Reviewers",
      sortable: false,
      customCellRenderer: (row) => {
        return (
          <AccessReviewReviewersCell
            itemType="resource"
            canEditReviewers={canEditReviewers}
            reviewerUsers={
              row.data.reviewerUsers?.map((ru) => ({
                name: ru.fullName,
                avatarUrl: ru.avatarUrl,
              })) ?? []
            }
            onClick={() => {
              setSelectedAssignment(row.data);
              setShowReviewerModal(true);
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <div
        className={sprinkles({
          height: "100%",
        })}
      >
        <TableFilters>
          <TableFilters.Left>
            <div className={styles.searchInput}>
              <Input
                value={searchQuery}
                onChange={(value) => setSearchQuery(value)}
                placeholder="Filter"
                leftIconName="search"
                type="search"
                style="search"
              />
            </div>
            <ButtonGroup
              buttons={[
                {
                  label: "All",
                  selected: !assignedStatus,
                  onClick: () => setAssignedStatus(undefined),
                },
                {
                  label: "Assigned",
                  selected:
                    assignedStatus === AccessReviewAssignedStatus.Assigned,
                  onClick: () =>
                    setAssignedStatus(AccessReviewAssignedStatus.Assigned),
                },
                {
                  label: "Unassigned",
                  selected:
                    assignedStatus === AccessReviewAssignedStatus.Unassigned,
                  onClick: () =>
                    setAssignedStatus(AccessReviewAssignedStatus.Unassigned),
                },
              ]}
            />
            <PaginatedUserDropdown
              key="reviewer-filter"
              placeholder="Reviewer"
              onChange={(reviewer) =>
                setReviewerId((reviewer?.id as SearchParamValue) || null)
              }
              valueId={reviewerId}
              selectOnly
              size="sm"
            />
            <PaginatedResourceDropdown
              key="resource-principal-filter"
              placeholder="Principal"
              onChange={(resource) =>
                setResourcePrincipalFilterId(
                  (resource?.id as SearchParamValue) || null
                )
              }
              valueId={resourcePrincipalFilterId}
              selectOnly
              size="sm"
            />
            <div className={styles.ownerDropdown}>
              <OwnerDropdown
                selectedOwnerId={ownerId ?? ""}
                onSelectOwner={(owner) => setOwnerId(owner ? owner.id : null)}
                placeholder="Resource Admin"
                size="sm"
                clearable
              />
            </div>
            <Select
              options={Object.values(AccessReviewItemOutcome)}
              value={outcome}
              onChange={setOutcome}
              getOptionLabel={(outcome) => {
                switch (outcome) {
                  case AccessReviewItemOutcome.None:
                    return "None";
                  case AccessReviewItemOutcome.Accepted:
                    return "Approved";
                  case AccessReviewItemOutcome.Revoked:
                    return "Revoked";
                  case AccessReviewItemOutcome.Updated:
                    return "Updated";
                }
              }}
              size="sm"
              clearable
              placeholder="Outcome"
            />
            <Select
              options={Object.values(AccessReviewItemStatus)}
              value={status}
              onChange={setStatus}
              getOptionLabel={(outcome) => {
                switch (outcome) {
                  case AccessReviewItemStatus.Completed:
                    return "Completed";
                  case AccessReviewItemStatus.NeedsAttention:
                    return "Needs Attention";
                  case AccessReviewItemStatus.NeedsEndSystemRevocation:
                    return "Needs End System Revocation";
                  case AccessReviewItemStatus.NeedsUpdateRequestApproval:
                    return "Needs Update Request Approval";
                  case AccessReviewItemStatus.NoReviewNeeded:
                    return "No Review Needed";
                  case AccessReviewItemStatus.NotStarted:
                    return "Not Started";
                  case AccessReviewItemStatus.PartiallyCompleted:
                    return "Partially Completed";
                }
              }}
              size="sm"
              clearable
              placeholder="Status"
            />
          </TableFilters.Left>
        </TableFilters>

        <TableHeader
          entityName="Non-Human Identity Access Review"
          totalNumRows={
            cursor ? totalNumResourcePrincipalAssignments || 0 : rows.length
          }
          selectedNumRows={selectedRowIds.length}
          bulkRightActions={[
            {
              label: "Assign Reviewers",
              onClick: () => {
                setShowBulkReviewerModal(true);
              },
              type: "mainSecondary",
            },
          ]}
        />
        <Table
          rows={rows}
          totalNumRows={
            cursor ? totalNumResourcePrincipalAssignments || 0 : rows.length
          }
          loadingRows={loading}
          getRowId={(ru) => ru.id}
          columns={columns}
          checkedRowIds={new Set(selectedRowIds)}
          onCheckedRowsChange={
            canEditReviewers
              ? (rowIds, checked) => {
                  if (checked) {
                    setSelectedRowIds([...selectedRowIds, ...rowIds]);
                  } else {
                    setSelectedRowIds(
                      selectedRowIds.filter((id) => !rowIds.includes(id))
                    );
                  }
                }
              : undefined
          }
          selectAllChecked={selectedRowIds.length === rows.length}
          onSelectAll={(checked) => {
            if (checked) {
              setSelectedRowIds(rows.map((row) => row.id));
            } else {
              setSelectedRowIds([]);
            }
          }}
          onLoadMoreRows={loadMoreRows}
          manualSortDirection={
            sortBy && {
              sortBy: sortBy.field,
              sortDirection: sortBy.direction,
            }
          }
          handleManualSort={(sortBy, sortDirection) => {
            if (!sortDirection) {
              setSortBy(undefined);
              return;
            }
            if (!isSortableField(sortBy)) {
              return;
            }
            const direction: SortDirection =
              sortDirection === "DESC" ? SortDirection.Desc : SortDirection.Asc;

            setSortBy({
              field: sortBy,
              direction,
            });
          }}
        />
      </div>
      {selectedRowIds.length > 0 && showBulkReviewerModal && (
        <AccessReviewerModal
          title="Reviewers"
          isModalOpen={showBulkReviewerModal}
          onClose={() => {
            setShowBulkReviewerModal(false);
            setReviewerError("");
          }}
          loading={updateReviewersLoading}
          onSubmit={handleUpdateReviewers(true)}
          errorMessage={reviewerError}
          entryInfos={[]}
          canEditReviewers={canEditReviewers}
        />
      )}
      {selectedAssignment && showReviewerModal && (
        <AccessReviewerModal
          title="Reviewers"
          isModalOpen={showReviewerModal}
          onClose={() => {
            setShowReviewerModal(false);
            setReviewerError("");
          }}
          loading={updateReviewersLoading}
          onSubmit={handleUpdateReviewers(false)}
          errorMessage={reviewerError}
          entryInfos={
            selectedAssignment.reviewerUsers?.map((ru) => ({
              id: ru.id,
              name: ru.fullName,
              avatarUrl: ru.avatarUrl,
              canBeRemoved: true,
              entityIds: [],
            })) ?? []
          }
          canEditReviewers={canEditReviewers}
        />
      )}
    </>
  );
};

export default AccessReviewResourcePrincipalAssignments;
