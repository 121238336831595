import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EntityType,
  OwnerFragment,
  SortDirection,
  useOwnerQuery,
  UserPreviewSmallFragment,
  UsersSortByField,
  useUpdateOwnerUsersForOwnerMutation,
  useUsersQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import { ColumnHeaderSkeleton } from "components/column/ColumnHeaderV3";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import FullscreenViewTitle from "components/fullscreen_modals/FullscreenViewTitle";
import { ResourceLabel } from "components/label/Label";
import FullscreenView from "components/layout/FullscreenView";
import { useToast } from "components/toast/Toast";
import { Banner, ButtonV3, Divider, Icon, Input } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useState } from "react";
import { useParams } from "react-router";
import { useImmer } from "use-immer";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { getUserAvatarIcon } from "views/users/utils";

import * as styles from "./OwnerAddUsersView.css";

type SortValue = {
  field: UsersSortByField;
  direction: SortDirection;
};
function isSortableField(str: string): str is UsersSortByField {
  return Object.values<string>(UsersSortByField).includes(str);
}

interface UserItemRow {
  id: string;
  [UsersSortByField.FirstName]: string;
  [UsersSortByField.Email]: string;
  item: UserPreviewSmallFragment;
}
const columns: Header<UserItemRow>[] = [
  {
    id: UsersSortByField.FirstName,
    label: "Name",
    sortable: true,
    customCellRenderer: (row) => {
      let userFullname = row.item?.fullName;
      let icon = row.item ? getUserAvatarIcon(row.item) : null;
      if (!userFullname) {
        return <></>;
      }
      return (
        <div
          className={sprinkles({
            display: "flex",
            alignItems: "center",
            gap: "sm",
          })}
        >
          {icon && <Icon data={icon} />}
          <ResourceLabel
            text={userFullname}
            bold={true}
            pointerCursor={true}
            maxChars="auto"
          />
        </div>
      );
    },
    width: 230,
  },
  {
    id: UsersSortByField.Email,
    label: "Email",
    sortable: true,
    customCellRenderer: (row) => {
      let userEmail = row.item.email;
      if (!userEmail) {
        return <></>;
      }
      return (
        <ResourceLabel
          text={userEmail}
          bold={false}
          pointerCursor={true}
          maxChars="auto"
        />
      );
    },
  },
];
const OwnerAddUsersView = () => {
  const transitionBack = useTransitionBack();
  const { ownerId } = useParams<{ ownerId: string }>();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const [usersToAddByUserId, setUsersToAddByUserId] = useImmer<
    Record<string, UserPreviewSmallFragment>
  >({});
  const [addUsersErrorMessage, setAddUsersErrorMessage] = useState("");
  const { displaySuccessToast } = useToast();

  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: UsersSortByField.FirstName,
    direction: SortDirection.Asc,
  });

  const [
    updateOwnerUsersForOwnerMutation,
    { loading: addUsersLoading },
  ] = useUpdateOwnerUsersForOwnerMutation();

  const { data, error, loading } = useOwnerQuery({
    variables: { input: { id: ownerId } },
    skip: !ownerId,
  });

  let owner: OwnerFragment | undefined = undefined;
  let ownerNotFound = false;
  if (data) {
    switch (data.owner.__typename) {
      case "OwnerResult":
        owner = data.owner.owner;
        break;
      case "OwnerNotFoundError":
        ownerNotFound = true;
        break;
      default:
        logError(new Error(`failed to list owner`));
    }
  } else if (error) {
    logError(error, `failed to list owner`);
  }

  const {
    data: usersData,
    previousData: previousUsersData,
    loading: usersLoading,
    error: usersError,
    fetchMore: usersFetchMore,
  } = useUsersQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        maxNumEntries: 100,
        searchQuery: debouncedSearchQuery,
        sortBy: sortBy,
      },
    },
  });
  if (usersError) {
    logError(usersError, `failed to list users`);
  }

  if (loading || usersLoading) {
    return (
      <Column isContent maxWidth="none">
        <ColumnHeaderSkeleton />
      </Column>
    );
  }
  if (ownerNotFound) {
    return (
      <Column isContent maxWidth="none">
        <NotFoundPage entity="Owner" />
      </Column>
    );
  }
  if (!owner || error || usersError) {
    return (
      <Column isContent maxWidth="none">
        <UnexpectedErrorPage error={error || usersError} />
      </Column>
    );
  }
  const currentUsers = owner.ownerUsers.flatMap((user) =>
    user.user ? user.user.id : []
  );
  const allUsers =
    usersData?.users.users || previousUsersData?.users.users || [];
  const users = allUsers.filter((user) => !currentUsers.includes(user.id));
  const totalNumUsers = usersData?.users.totalNumUsers || 0;
  let cursor = usersData?.users.cursor || undefined;

  const handleClose = () => {
    transitionBack(`/owners/${ownerId}`);
  };

  const numUsersToAdd = Object.keys(usersToAddByUserId).length;

  const rows: UserItemRow[] = users.map((user) => {
    return {
      id: user.id,
      [UsersSortByField.FirstName]: user.firstName || "--",
      [UsersSortByField.Email]: user.email || "--",
      item: user,
    };
  });
  const loadMoreRows = async () => {
    if (!cursor) {
      return;
    }
    await usersFetchMore({
      variables: {
        input: {
          searchQuery: debouncedSearchQuery,
          cursor: cursor,
          sortBy: sortBy,
          maxNumEntries: 100,
        },
      },
    });
  };
  const handleAddUsers = async () => {
    if (!owner) return;
    try {
      const { data } = await updateOwnerUsersForOwnerMutation({
        variables: {
          input: {
            ownerId: owner.id,
            userIds: [...currentUsers, ...Object.keys(usersToAddByUserId)],
          },
        },
        refetchQueries: ["Owner"],
      });
      switch (data?.setOwnerUsers.__typename) {
        case "UpdateOwnerUsersForOwnerResult":
          displaySuccessToast("Success: owner users added");
          handleClose();
          break;
        case "CannotAddUserToSyncedOwnerError":
          setAddUsersErrorMessage(
            `Error: this owner's user list is link to a group: ${data.setOwnerUsers.group?.name}`
          );
          break;
        default:
          logError(new Error(`failed to add users to owner`));
          setAddUsersErrorMessage(`Error: failed to add users to owner`);
      }
    } catch (error) {
      logError(error, "failed to update owner");
      setAddUsersErrorMessage(
        getModifiedErrorMessage("Error: failed to add users to owner", error)
      );
    }
  };

  const handleUserClick = (user: UserPreviewSmallFragment) => {
    if (user.id in usersToAddByUserId) {
      // Remove user from usersToAddByUserId
      setUsersToAddByUserId((draft) => {
        delete draft[user.id];
      });
    } else {
      // Add user to usersToAddByUserId
      setUsersToAddByUserId((draft) => {
        draft[user.id] = user;
      });
    }
  };

  return (
    <FullscreenView
      title={
        <FullscreenViewTitle
          entityType={EntityType.Owner}
          entityName={owner.name}
          targetEntityName="Users"
          action="add"
        />
      }
      onCancel={handleClose}
      onPrimaryButtonClick={handleAddUsers}
      primaryButtonLabel={`Add ${
        numUsersToAdd > 0 ? numUsersToAdd : ""
      } ${pluralize("user", numUsersToAdd)}`}
      primaryButtonDisabled={numUsersToAdd === 0}
      primaryButtonLoading={addUsersLoading}
    >
      <FullscreenView.Content fullWidth>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "md",
            })}
          >
            Select users to add to the owner:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Filter by name or email"
            />
          </div>
          <Divider />
          {usersLoading ? (
            <ColumnListItemsSkeleton />
          ) : (
            <Table
              columns={columns}
              rows={rows}
              totalNumRows={totalNumUsers ?? 0}
              getRowId={(user) => user.id}
              onLoadMoreRows={loadMoreRows}
              checkedRowIds={new Set(Object.keys(usersToAddByUserId))}
              onCheckedRowsChange={(checkedRowIds) => {
                for (const userId of checkedRowIds) {
                  const user = users.find((user) => user.id === userId);
                  if (user) {
                    handleUserClick(user);
                  }
                }
              }}
              onRowClick={(user) => {
                handleUserClick(user.item);
              }}
              manualSortDirection={
                sortBy && {
                  sortBy: sortBy.field,
                  sortDirection: sortBy.direction,
                }
              }
              handleManualSort={(sortBy, sortDirection) => {
                // Investigate if there is more elegant solution
                if (!sortDirection) {
                  setSortBy(undefined);
                  return;
                }
                if (!isSortableField(sortBy)) {
                  return;
                }
                const direction: SortDirection =
                  sortDirection === "DESC"
                    ? SortDirection.Desc
                    : SortDirection.Asc;
                setSortBy({
                  field: sortBy,
                  direction,
                });
              }}
            />
          )}
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addUsersErrorMessage && (
          <Banner
            message={addUsersErrorMessage}
            type="error"
            marginBottom="lg"
          />
        )}
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "lg",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textLg",
              fontWeight: "medium",
              marginBottom: "lg",
            })}
          >
            Adding {numUsersToAdd} {pluralize("User", numUsersToAdd)}
          </div>

          {numUsersToAdd > 0 && (
            <ButtonV3
              leftIconName="x"
              label="Clear all"
              size="xs"
              type="dangerBorderless"
              onClick={() => setUsersToAddByUserId({})}
            />
          )}
        </div>
        {Object.keys(usersToAddByUserId).map((userId) => {
          const user = usersToAddByUserId[userId];
          if (!user) {
            return null;
          }

          return (
            <div key={userId} className={styles.userCard}>
              <div
                className={sprinkles({
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "sm",
                })}
              >
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon data={getUserAvatarIcon(user)} />
                </div>
                <div className={styles.userInfoSection}>
                  <div className={styles.userCardHeader}>{user.fullName}</div>
                  <div className={styles.userCardSubtitle}>{user.email}</div>
                </div>
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon
                    name="trash"
                    color="red600V3"
                    onClick={() => {
                      setUsersToAddByUserId((draft) => {
                        delete draft[userId];
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

export default OwnerAddUsersView;
