import {
  CurrentUserGroupAccessFragment,
  EntityType,
  GroupType,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { FormMode } from "components/forms/common";
import { GroupExpirationLabel } from "components/label/GroupExpirationLabel";
import {
  CurrentUserGroupAccessStatus,
  currentUserGroupAccessStatusFromGroup,
} from "components/label/Label";
import { Button, ButtonV3 } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { ForfeitGroupButton } from "views/groups/ForfeitGroupButton";
import {
  GroupBreakGlassButton,
  GroupBreakGlassModal,
} from "views/groups/GroupBreakGlassModal";

import { AppsContext } from "./AppsContext";
import { GroupViewKey } from "./utils";

type GroupActionButtonsProps = {
  group: {
    id: string;
    name: string;
    currentUserAccess: CurrentUserGroupAccessFragment;
    isRequestable: boolean;
    groupType: GroupType;
  };
  selectedGroupViewKey?: string;
  onNavigate: (groupViewKey: GroupViewKey) => void;
  mode?: FormMode;
  editButtons?: React.ReactNode;
  buttonSize?: "sm" | "md" | "lg";
};

export const GroupActionButtons = (props: GroupActionButtonsProps) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const history = useHistory();
  const logEvent = useLogEvent();

  const { authState } = useContext(AuthContext);
  const { isSelectMode } = useContext(AppsContext);

  const [isBreakGlassModalOpen, setIsBreakGlassModalOpen] = useState(false);

  const groupAccess = props.group.currentUserAccess.groupUser?.access;
  const mostRecentUserAccessStatus = currentUserGroupAccessStatusFromGroup(
    props.group
  );

  let expirationLabel = null;
  let forfeitButton = null;

  const breakGlassModal = (
    <GroupBreakGlassModal
      groupId={props.group.id}
      onClose={() => setIsBreakGlassModalOpen(false)}
      isOpen={isBreakGlassModalOpen}
    />
  );
  let breakGlassButton = props.group.currentUserAccess.hasBreakGlass ? (
    <GroupBreakGlassButton
      key="break-glass"
      onClick={() => {
        setIsBreakGlassModalOpen(true);
        logEvent({ name: "apps_breakglass_click" });
      }}
      hasV3={hasV3}
      buttonSize={props.buttonSize}
    />
  ) : undefined;

  const expirationTime = groupAccess?.latestExpiringAccessPoint?.expiration
    ? moment(new Date(groupAccess?.latestExpiringAccessPoint?.expiration))
    : null;

  const hasForfeitableAccess =
    groupAccess?.directAccessPoint && groupAccess?.groupId === props.group.id;
  let hasPendingButton = false;
  switch (mostRecentUserAccessStatus) {
    case CurrentUserGroupAccessStatus.Authorized:
      breakGlassButton = undefined; // Do not allow break-glass if the user already has access
      if (hasForfeitableAccess) {
        forfeitButton = (
          <ForfeitGroupButton
            group={props.group}
            key="forfeit-button-group"
            hasV3={hasV3}
            buttonSize={props.buttonSize}
          />
        );
      }

      expirationLabel = (
        <GroupExpirationLabel
          key="expiration-label"
          user={authState.user?.user}
          group={props.group}
          entityType={EntityType.Group}
          expiration={expirationTime}
          iconOnly={true}
          hasV3={hasV3}
          buttonSize={props.buttonSize}
        />
      );
      break;
    case CurrentUserGroupAccessStatus.Requested:
      hasPendingButton = true;
      break;
  }

  const buttons = [];
  buttons.push(props.editButtons);
  if (props.mode !== "edit") {
    if (props.group.isRequestable || !hasForfeitableAccess) {
      // Show a disabled request button if the user has no access to the group and is not allowed to request it.
      const notRequestableMessage =
        "This group cannot be requested, please see the group admin";
      const hasOtherPrimaryButton = Boolean(
        hasPendingButton || breakGlassButton || forfeitButton
      );
      buttons.push(
        hasV3 ? (
          <ButtonV3
            key="request"
            label="Request"
            leftIconName="raised-hand"
            type={hasOtherPrimaryButton ? "mainSecondary" : "main"}
            onClick={() => {
              props.onNavigate("request");
              logEvent({
                name: "apps_item_request_click",
                properties: {
                  itemType: props.group.groupType,
                },
              });
            }}
            disabled={!props.group.isRequestable}
            disabledTooltip={notRequestableMessage}
            size={props.buttonSize}
          />
        ) : (
          <Button
            label="Request"
            key="request"
            disabled={
              props.selectedGroupViewKey === "request" ||
              isSelectMode ||
              !props.group.isRequestable
            }
            leftIconName="lock-unlocked"
            size="md"
            type={hasOtherPrimaryButton ? "default" : "primary"}
            borderless={hasOtherPrimaryButton}
            disabledTooltip={
              isSelectMode
                ? "Exit bulk select mode to request"
                : props.selectedGroupViewKey !== "request"
                ? notRequestableMessage
                : undefined
            }
            onClick={() => {
              props.onNavigate("request");
              logEvent({
                name: "apps_item_request_click",
                properties: {
                  itemType: props.group.groupType,
                },
              });
            }}
          />
        )
      );
    }
    if (hasPendingButton) {
      const hasOtherPrimaryButton = Boolean(breakGlassButton || forfeitButton);
      buttons.push(
        hasV3 ? (
          <ButtonV3
            label="View Request"
            key="view-request"
            leftIconName="send"
            type="defaultSecondary"
            onClick={() => {
              logEvent({ name: "apps_pending_click" });
              history.push(
                `${getResourceUrl(
                  EntityTypeDeprecated.Request,
                  props.group.currentUserAccess.pendingRequest?.id
                )}?o=1`
              );
            }}
            size={props.buttonSize}
          />
        ) : (
          <Button
            label="View Request"
            key="view-request"
            leftIconName="send"
            size="md"
            borderless={hasOtherPrimaryButton}
            onClick={() => {
              logEvent({ name: "apps_pending_click" });
              history.push(
                `${getResourceUrl(
                  EntityTypeDeprecated.Request,
                  props.group.currentUserAccess.pendingRequest?.id
                )}?o=1`
              );
            }}
          />
        )
      );
    }
    if (breakGlassButton) {
      buttons.push(breakGlassButton);
    }
    if (expirationLabel) {
      buttons.push(expirationLabel);
    }
    if (forfeitButton) {
      buttons.push(forfeitButton);
    }
  }

  return (
    <>
      {breakGlassModal}
      <div
        className={sprinkles({
          display: "flex",
          flexDirection: "row",
          gap: "sm",
        })}
      >
        {buttons}
      </div>
    </>
  );
};
