import { EntityType } from "api/generated/graphql";

export const entityTypeToString = (entityType: EntityType) => {
  switch (entityType) {
    case EntityType.AccessReview:
      return "Access Review";
    case EntityType.Activity:
      return "Activity";
    case EntityType.AccessLevel:
      return "Role";
    case EntityType.Bundle:
      return "Bundle";
    case EntityType.Connection:
      return "App";
    case EntityType.Event:
      return "Event";
    case EntityType.Group:
      return "Group";
    case EntityType.GroupFolder:
      return "Group Folder";
    case EntityType.OnCallSchedule:
      return "On-call Schedule";
    case EntityType.Resource:
      return "Resource";
    case EntityType.ResourceFolder:
      return "Resource Folder";
    case EntityType.Request:
      return "Request";
    case EntityType.SearchQuery:
      return "Search Query";
    case EntityType.Session:
      return "Session";
    case EntityType.User:
      return "User";
    case EntityType.AccessReviewResourceUser:
      return "Resource User";
    case EntityType.AccessReviewGroupUser:
      return "Group User";
    case EntityType.AccessReviewGroupResource:
      return "Group Resource";
    case EntityType.AccessReviewTemplate:
      return "Access Review Schedule";
    case EntityType.OrgSetting:
      return "Organization Setting";
    case EntityType.EventFilter:
      return "Event Filter";
    case EntityType.GroupBinding:
      return "Linked Group";
    case EntityType.GroupBindingSuggestion:
      return "Linked Group Suggestion";
    case EntityType.EventStreamConnection:
      return "Event Streaming Connection";
    case EntityType.RequestTemplate:
      return "Custom Access Request";
    case EntityType.ConfigurationTemplate:
      return "Configuration Template";
    case EntityType.Tag:
      return "Tag";
    case EntityType.Owner:
      return "Owner";
  }
  return "";
};

// returns true if the entity type requires us to fetch and display a list of access levels
export const entityTypeHasAccessLevels = (entityType: EntityType): boolean => {
  switch (entityType) {
    case EntityType.Resource:
      return true;
    default:
      return false;
  }
};
