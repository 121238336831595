import { getModifiedErrorMessage } from "api/ApiContext";
import {
  DetailResourceUserFragment,
  EntityType,
  HrIdpStatus,
  Maybe,
  OidcProviderType,
  PaginatedResourceUsersFragment,
  PaginatedResourceUsersSortBy,
  PropagationStatusCode,
  RemoveResourceUserInput,
  ResourceDetailViewFragment,
  ResourceType,
  ResourceUserFiltersInput,
  ResourceUserSortByField,
  SortDirection,
  useInitOidcAuthFlowMutation,
  useRemoveResourceUsersMutation,
  useResourceDetailPaginatedResourceUsersQuery,
} from "api/generated/graphql";
import { ResourceLabel, TimeLabel } from "components/label/Label";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  Checkbox,
  Icon,
  Input,
  Modal,
  Select,
  Tooltip,
} from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import { useState } from "react";
import { useHistory } from "react-router";
import { useImmer } from "use-immer";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage, generateState } from "utils/auth/auth";
import { isSnowflakeResource } from "utils/directory/resources";
import { useDebouncedValue, useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";
import {
  clearOidcData,
  getOidcData,
  OidcPostAuthAction,
  setOidcData,
} from "utils/oidc/oidc";
import { useTransitionTo } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { PropagationType } from "utils/useRemediations";
import { AVAILABLE_PROPAGATION_STATUSES } from "views/Common";
import {
  getResourceUserAccessPathsInfo,
  ResourceUserAccessPathsInfo,
  ResourceUserAccessPointsLabel,
} from "views/resources/ResourceUserAccessPointsLabel";
import { formatHrIdpStatus } from "views/users/utils";
import { dropNothings } from "views/utils";

import * as styles from "./ResourceUsersTableV3.css";

type SortValue = PaginatedResourceUsersSortBy;

function isSortableField(str: string): str is ResourceUserSortByField {
  return Object.values<string>(ResourceUserSortByField).includes(str);
}

interface ResourceUserRow {
  id: string;
  userId: string;
  resourceUser: DetailResourceUserFragment;
  [ResourceUserSortByField.UserFullName]?: string;
  userAvatarUrl?: string;
  [ResourceUserSortByField.UserEmail]: string;
  [ResourceUserSortByField.AccessLevelName]: string;
  [ResourceUserSortByField.SourceOfAccess]: ResourceUserAccessPathsInfo;
  [ResourceUserSortByField.ExpiresAt]?: string | null;
  [ResourceUserSortByField.PropagationStatus]?: PropagationStatusCode | null;
  idpStatus?: HrIdpStatus;
}
type ResourceUsersTableV3Props = {
  resource: ResourceDetailViewFragment;
};

const DEFAULT_FILTERS: ResourceUserFiltersInput = {
  userFullName: undefined,
  propagationStatuses: [],
  accessLevelRemoteIds: [],
  directAccessOnly: false,
};

export const ResourceUsersTableV3 = (props: ResourceUsersTableV3Props) => {
  const transitionTo = useTransitionTo();
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [removeUsersErrorMessage, setRemoveUsersErrorMessage] = useState<
    Maybe<string>
  >(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: ResourceUserSortByField.UserFullName,
    direction: SortDirection.Asc,
  });

  const history = useHistory();
  const startPushTaskPoll = usePushTaskLoader();
  const logEvent = useLogEvent();
  const [initOidcAuthFlow] = useInitOidcAuthFlowMutation();
  const { displayErrorToast } = useToast();
  const [filters, setFilters] = useImmer<ResourceUserFiltersInput>(
    DEFAULT_FILTERS
  );
  const debouncedFilters = useDebouncedValue(filters, 250);

  const {
    data,
    error,
    loading,
    fetchMore,
    refetch,
  } = useResourceDetailPaginatedResourceUsersQuery({
    variables: {
      id: props.resource.id,
      resourceUsers: {
        sortBy,
        filters: debouncedFilters,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  let paginatedResourceUsers: PaginatedResourceUsersFragment | null = null;
  if (data) {
    switch (data.resource.__typename) {
      case "ResourceResult": {
        paginatedResourceUsers = data.resource.resource.paginatedResourceUsers;
        break;
      }
      case "ResourceNotFoundError":
        break;
      default:
        logError(new Error(`failed to get resource users`));
    }
  } else if (error) {
    logError(error, `failed to get resource`);
  }

  const hasMoreUsersToFetch = Boolean(
    paginatedResourceUsers?.cursor &&
      paginatedResourceUsers?.resourceUsers.length <
        paginatedResourceUsers?.totalNumResourceUsers
  );
  const availableAccessLevels = _.sortBy(
    paginatedResourceUsers?.uniqueAccessLevels ?? [],
    "accessLevelName"
  );

  const cursor = paginatedResourceUsers?.cursor;
  const loadMoreRows =
    hasMoreUsersToFetch && cursor && !loading
      ? async () => {
          await fetchMore({
            variables: {
              // NOTE: since this is a nested field, we need to pass _all_ the fields
              resourceUsers: {
                sortBy,
                filters: debouncedFilters,
                cursor,
              },
            },
          });
        }
      : undefined;

  const canManage = props.resource?.authorizedActions?.includes(
    AuthorizedActionManage
  );

  const RESOURCE_USER_COLUMNS: Header<ResourceUserRow>[] = [
    {
      id: ResourceUserSortByField.UserFullName,
      label: "User",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <div className={styles.userCellContainer}>
            <ResourceLabel
              text={row[ResourceUserSortByField.UserFullName]}
              avatar={row.userAvatarUrl}
              pointerCursor={true}
              entityId={row.userId}
              entityTypeNew={EntityType.User}
            />
          </div>
        );
      },
      width: 130,
    },
    {
      id: ResourceUserSortByField.UserEmail,
      label: "Email",
      customCellRenderer: (row) => {
        return (
          <div className={styles.emailCell}>
            {row[ResourceUserSortByField.UserEmail]}
          </div>
        );
      },
      width: 130,
    },
    {
      id: ResourceUserSortByField.AccessLevelName,
      label: "Role",
    },
    {
      id: ResourceUserSortByField.SourceOfAccess,
      label: "Source Of Access",
      customCellRenderer: (row) => {
        return (
          <ResourceUserAccessPointsLabel
            access={row.resourceUser.access}
            user={row.resourceUser.user}
            resource={props.resource}
          />
        );
      },
      width: 110,
    },
    {
      id: ResourceUserSortByField.ExpiresAt,
      label: "Expires",
      customCellRenderer: (row) => {
        const expirationTime = row.resourceUser.access
          ?.latestExpiringAccessPoint?.expiration
          ? moment(
              new Date(
                row.resourceUser.access.latestExpiringAccessPoint.expiration
              )
            )
          : null;
        return (
          <TimeLabel
            time={expirationTime}
            supportTicket={
              row.resourceUser.access?.latestExpiringAccessPoint.supportTicket
            }
            useExpiringLabel
            indefiniteLablel="Permanent Access"
          />
        );
      },
      width: 125,
    },
    {
      id: ResourceUserSortByField.PropagationStatus,
      label: "Status",
      customCellRenderer: (row) => {
        return (
          <PropagationStatusLabelWithModal
            // key is required to force re-render when a resource user has multiple roles and multiple tickets
            key={
              row.resourceUser.userId +
              row.resourceUser.resourceId +
              row.resourceUser.accessLevel.accessLevelRemoteId
            }
            propagationType={PropagationType.ResourceUser}
            propagationStatus={row.resourceUser.propagationStatus}
            isAccessReview={false}
            entityInfo={{
              user: row.resourceUser.user,
              resource: props.resource,
              role: row.resourceUser.accessLevel,
              lastExpiringAccessPointExpiration:
                row.resourceUser.access?.latestExpiringAccessPoint.expiration,
            }}
          />
        );
      },
      width: 65,
    },
    {
      id: "idpStatus",
      label: "",
      width: 32,
      customCellRenderer: (row) => {
        let iconName: PropsFor<typeof Icon>["name"] = "user";
        let color: PropsFor<typeof Icon>["color"] = "gray400";
        switch (row.idpStatus) {
          case HrIdpStatus.Active:
            iconName = "user-check";
            color = "gray400";
            break;
          default:
            iconName = "user-x";
            color = "red600";
            break;
        }
        return row.idpStatus ? (
          <Tooltip tooltipText={formatHrIdpStatus(row.idpStatus)}>
            <Icon name={iconName} size="xs" color={color} />
          </Tooltip>
        ) : (
          <></>
        );
      },
    },
  ];

  const [
    removeResourceUsers,
    { loading: removeUsersLoading },
  ] = useRemoveResourceUsersMutation();

  const submitRemoval = async (
    resourceUsersToRemove: RemoveResourceUserInput[]
  ) => {
    logEvent({
      name: "apps_remove_user",
      properties: {
        type: "resource",
        numUsers: resourceUsersToRemove.length,
      },
    });

    try {
      const { data } = await removeResourceUsers({
        variables: {
          input: {
            resourceUsers: resourceUsersToRemove,
          },
        },
      });
      switch (data?.removeResourceUsers.__typename) {
        case "RemoveResourceUsersResult":
          startPushTaskPoll(data.removeResourceUsers.taskId, {
            refetchOnComplete: { resourceId: props.resource.id },
            onComplete: () => {
              refetch();
            },
          });
          setRemoveUsersErrorMessage(null);
          setSelectedItemIds([]);
          setShowRemoveModal(false);
          break;
        case "OpalAdminRoleMustHaveAtLeastOneDirectUser":
          logError(new Error(data.removeResourceUsers.message));
          setRemoveUsersErrorMessage(data.removeResourceUsers.message);
          break;
        case "CallToWebhookFailedError":
          setRemoveUsersErrorMessage(data.removeResourceUsers.message);
          break;
        case "OidcIDTokenNotFoundError":
          try {
            const state = generateState();
            const { data } = await initOidcAuthFlow({
              variables: {
                input: {
                  state: state,
                  oidcProviderType: OidcProviderType.AwsSession,
                },
              },
            });
            switch (data?.initOidcAuthFlow.__typename) {
              case "InitOidcAuthFlowResult":
                setOidcData({
                  state: state,
                  oidcProviderType: OidcProviderType.AwsSession,
                  postAuthPath: window.location.pathname,
                  postAuthHash: window.location.hash,
                  params: {
                    action: OidcPostAuthAction.RemoveResourceUser,
                    resourceId: props.resource.id,
                    resourceUsersToRemove: resourceUsersToRemove,
                  },
                });
                window.location.replace(data?.initOidcAuthFlow.authorizeUrl);
                break;
              case "OidcProviderNotFoundError":
                displayErrorToast(
                  "Error: Failed to trigger MFA. Please contact your admin."
                );
                break;
              default:
                displayErrorToast(
                  "Error: Failed to trigger MFA. Please try again or contact support if the issue persists."
                );
            }
          } catch (e) {
            logError(e, "Error: resource forfeiture failed");
            setRemoveUsersErrorMessage(
              getModifiedErrorMessage("Error: resource forfeiture failed", e)
            );
          }
          break;
        default:
          logError(new Error(`failed to update resource user access`));
          setRemoveUsersErrorMessage(
            "Error: failed to update resource user access"
          );
      }
    } catch (error) {
      logError(error, "failed to update resource user access");
      setRemoveUsersErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to update resource user access",
          error
        )
      );
    }
  };

  useMountEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("oidc_auth")) {
      const oidcAuthStatus = queryParams.get("oidc_auth");
      if (oidcAuthStatus === "success") {
        const oidcData = getOidcData();
        if (
          oidcData &&
          oidcData.params?.action === OidcPostAuthAction.RemoveResourceUser &&
          oidcData.params.resourceId === props.resource.id &&
          oidcData.params.resourceUsersToRemove
        ) {
          clearOidcData();
          submitRemoval(oidcData.params.resourceUsersToRemove);
          // Clear oidc_auth parameter
          queryParams.delete("oidc_auth");
          history.replace({
            search: queryParams.toString(),
          });
        }
      } else {
        displayErrorToast(
          "Authentication failed. Please try again or contact support if the issue remains."
        );
      }
    }
  });

  let resourceUsers: DetailResourceUserFragment[] =
    paginatedResourceUsers?.resourceUsers ?? [];
  const rowsById: Record<string, ResourceUserRow> = {};
  const rows: ResourceUserRow[] = resourceUsers.map((resourceUser) => {
    const row = {
      id: resourceUser.userId + resourceUser.accessLevel.accessLevelName,
      userId: resourceUser.userId,
      resourceUser: resourceUser,
      [ResourceUserSortByField.UserFullName]: resourceUser.user?.fullName,
      userAvatarUrl: resourceUser.user?.avatarUrl,
      [ResourceUserSortByField.UserEmail]: resourceUser.user?.email ?? "\u2014",
      idpStatus: resourceUser.user?.hrIdpStatus ?? undefined,
      [ResourceUserSortByField.AccessLevelName]:
        resourceUser.accessLevel.accessLevelName || "\u2014",
      [ResourceUserSortByField.SourceOfAccess]: getResourceUserAccessPathsInfo({
        user: resourceUser.user,
        resource: props.resource,
        access: resourceUser.access,
      }),
      [ResourceUserSortByField.ExpiresAt]:
        resourceUser.access?.latestExpiringAccessPoint.expiration,
      [ResourceUserSortByField.PropagationStatus]:
        resourceUser.propagationStatus?.statusCode,
    };
    rowsById[row.id] = row;
    return row;
  });

  const bulkRightActions: PropsFor<
    typeof TableHeader
  >["bulkRightActions"] = dropNothings([
    canManage
      ? {
          label: "Remove",
          type: "danger",
          onClick: () => setShowRemoveModal(true),
          iconName: "trash",
        }
      : null,
  ]);

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      })}
    >
      {resourceUsers.length === 0 &&
        (props.resource.resourceType == ResourceType.GcpCloudSqlMysqlInstance ||
          props.resource.resourceType ==
            ResourceType.GcpCloudSqlPostgresInstance) && (
          <div className={sprinkles({ marginTop: "md", marginX: "md" })}>
            <Banner
              message={`Note that this instance will display no users if it is stopped.`}
            />
          </div>
        )}

      <TableFilters>
        <TableFilters.Left>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              placeholder="Filter by name"
              value={filters.userFullName ?? ""}
              onChange={(value) => {
                logEvent({
                  name: "apps_filter_users",
                  properties: {
                    type: "resource",
                    filter: "userFullName",
                  },
                });
                setFilters((draft) => {
                  draft.userFullName = value;
                });
              }}
            />
          </div>
          <div className={styles.dropdown}>
            <Select
              multiple
              placeholder="Filter by propagation status"
              size="sm"
              value={AVAILABLE_PROPAGATION_STATUSES.filter((status) => {
                if (!filters.propagationStatuses) return false;
                return status.values.some((value) => {
                  return filters?.propagationStatuses?.includes(value);
                });
              })}
              options={AVAILABLE_PROPAGATION_STATUSES}
              getOptionLabel={(option) => option.label}
              onSelectValue={(value, reason) => {
                logEvent({
                  name: "apps_filter_users",
                  properties: {
                    type: "resource",
                    filter: "propagationStatuses",
                  },
                });
                setFilters((draft) => {
                  if (!value) return;
                  switch (reason) {
                    case "select-option":
                      draft.propagationStatuses = _.uniq([
                        ...(draft.propagationStatuses ?? []),
                        ...value.values,
                      ]);
                      break;
                    case "remove-option":
                      draft.propagationStatuses = draft.propagationStatuses?.filter(
                        (status) =>
                          !value.values.some((value) => status === value)
                      );
                      break;
                  }
                });
              }}
            />
          </div>
          {availableAccessLevels && availableAccessLevels.length > 1 && (
            <div className={styles.dropdown}>
              <Select
                multiple
                placeholder="Filter by role"
                size="sm"
                value={availableAccessLevels.filter(
                  (accessLevel) =>
                    filters.accessLevelRemoteIds?.includes(
                      accessLevel.accessLevelRemoteId
                    ) ?? false
                )}
                options={availableAccessLevels}
                getOptionLabel={(option) => option.accessLevelName}
                onSelectValue={(value, reason) => {
                  logEvent({
                    name: "apps_filter_users",
                    properties: {
                      type: "resource",
                      filter: "roles",
                    },
                  });
                  setFilters((draft) => {
                    if (!value) return;
                    switch (reason) {
                      case "select-option":
                        draft.accessLevelRemoteIds = _.uniq([
                          ...(draft.accessLevelRemoteIds ?? []),
                          value.accessLevelRemoteId,
                        ]);
                        break;
                      case "remove-option":
                        draft.accessLevelRemoteIds = draft.accessLevelRemoteIds?.filter(
                          (accessLevel) =>
                            accessLevel !== value.accessLevelRemoteId
                        );
                        break;
                    }
                  });
                }}
              />
            </div>
          )}
          <Checkbox
            checked={filters.directAccessOnly ?? false}
            onChange={(checked) => {
              logEvent({
                name: "apps_filter_users",
                properties: {
                  type: "resource",
                  filter: "directAccessOnly",
                },
              });
              setFilters((draft) => {
                draft.directAccessOnly = checked;
              });
            }}
            label="Direct Access Only"
          />
        </TableFilters.Left>
      </TableFilters>

      <TableHeader
        entityType={EntityType.User}
        totalNumRows={paginatedResourceUsers?.totalNumUsers ?? 0}
        loading={loading}
        selectedNumRows={selectedItemIds.length}
        defaultRightActions={
          canManage && !isSnowflakeResource(props.resource.resourceType)
            ? [
                {
                  label: "Add Users",
                  type: "mainSecondary",
                  onClick: () => {
                    transitionTo({
                      pathname: `/resources/${props.resource.id}/add-users`,
                    });
                  },
                  iconName: "plus",
                },
              ]
            : []
        }
        bulkRightActions={bulkRightActions}
      />
      <Table
        rows={rows}
        loadingRows={loading}
        totalNumRows={paginatedResourceUsers?.totalNumResourceUsers ?? 0}
        getRowId={(row) => row.id}
        columns={RESOURCE_USER_COLUMNS}
        defaultSortBy={ResourceUserSortByField.UserFullName}
        onLoadMoreRows={loadMoreRows}
        checkedRowIds={new Set(selectedItemIds)}
        manualSortDirection={
          sortBy && {
            sortBy: sortBy.field,
            sortDirection: sortBy.direction,
          }
        }
        handleManualSort={(field, sortDirection) => {
          if (!sortDirection) {
            setSortBy(undefined);
            return;
          }
          if (!isSortableField(field)) {
            return;
          }
          const direction: SortDirection =
            sortDirection === "DESC" ? SortDirection.Desc : SortDirection.Asc;

          setSortBy({ field, direction });
        }}
        onCheckedRowsChange={
          canManage
            ? (checkedRowIds, checked) => {
                if (checked) {
                  setSelectedItemIds((prev) => [...prev, ...checkedRowIds]);
                  return;
                } else {
                  setSelectedItemIds((prev) =>
                    prev.filter((id) => !checkedRowIds.includes(id))
                  );
                }
              }
            : undefined
        }
        selectAllChecked={
          selectedItemIds.length > 0 &&
          selectedItemIds.length ===
            rows.filter((row) => row.resourceUser.access?.directAccessPoint)
              .length
        }
        onSelectAll={(checked) =>
          checked
            ? setSelectedItemIds(
                rows
                  .filter((row) => row.resourceUser.access?.directAccessPoint)
                  .map((row) => row.id)
              )
            : setSelectedItemIds([])
        }
        getCheckboxDisabledReason={(row) => {
          if (!row.resourceUser.access?.directAccessPoint) {
            return "The user has no direct access to remove";
          }
          return undefined;
        }}
      />
      {showRemoveModal && (
        <Modal
          isOpen
          title="Remove Resource Users"
          onClose={() => setShowRemoveModal(false)}
        >
          <Modal.Body>
            {removeUsersErrorMessage && (
              <ModalErrorMessage errorMessage={removeUsersErrorMessage} />
            )}
            Are you sure you want to remove{" "}
            {pluralize("direct role assignment", selectedItemIds.length, true)}{" "}
            from this resource?
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Remove"
            primaryButtonDisabled={selectedItemIds.length === 0}
            primaryButtonLoading={removeUsersLoading}
            onPrimaryButtonClick={() => {
              const resourceUsersToRemove: RemoveResourceUserInput[] = [];
              for (const rowId of selectedItemIds) {
                const row = rowsById[rowId];
                resourceUsersToRemove.push({
                  resourceId: props.resource.id,
                  userId: row.userId,
                  accessLevel: {
                    accessLevelName:
                      row.resourceUser.accessLevel.accessLevelName,
                    accessLevelRemoteId:
                      row.resourceUser.accessLevel.accessLevelRemoteId,
                  },
                });
              }
              submitRemoval(resourceUsersToRemove);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default ResourceUsersTableV3;
