import { NetworkStatus } from "@apollo/client";
import {
  BundleDetailForCatalogFragment,
  BundlesSortByField,
  EntityType,
  ServiceType,
  SortDirection,
  useBundleCatalogQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnHeaderV3 from "components/column/ColumnHeaderV3";
import LayoutToggle from "components/enduser_exp/LayoutToggle";
import { getServiceTypeInfo } from "components/label/ServiceTypeLabel";
import {
  Input,
  InteractiveCard,
  Label,
  Masonry,
  Select,
  Skeleton,
  TabsV3,
} from "components/ui";
import { IconGroup } from "components/ui/icon_group/IconGroup";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import TableHeader from "components/ui/table/TableHeader";
import _ from "lodash";
import pluralize from "pluralize";
import { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useDebouncedValue } from "utils/hooks";
import { usePageTitle } from "utils/hooks";
import { useTransitionTo, useURLSearchParam } from "utils/router/hooks";
import {
  ACCESS_OPTION_URL_KEY,
  AppsContext,
  ITEM_TYPE_URL_KEY,
} from "views/apps/AppsContext";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import * as styles from "./BundleCatalog.css";
import * as catalogStyles from "./Catalog.css";
import { BREAKPOINT_COLUMNS } from "./constants";
import * as tableStyles from "./Table.css";

const SORT_OPTIONS: { label: string; value: SortValue }[] = [
  {
    label: "Name (A-Z)",
    value: {
      field: BundlesSortByField.Name,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Name (Z-A)",
    value: {
      field: BundlesSortByField.Name,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Newest first",
    value: {
      field: BundlesSortByField.CreatedAt,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Oldest first",
    value: {
      field: BundlesSortByField.CreatedAt,
      direction: SortDirection.Asc,
    },
  },
];

type SortValue = {
  field: BundlesSortByField;
  direction: SortDirection;
};

interface BundleRow {
  id: string;
  name: string;
  description: string;
  resourceCount: number;
  groupCount: number;
  linkTo?: React.MouseEventHandler;
  serviceTypes: ServiceType[];
}

const BUNDLE_COLUMNS: Header<BundleRow>[] = [
  {
    id: "name",
    label: "Name",
    sortable: true,
    width: 250,
    customCellRenderer: (row) => {
      return (
        <div className={tableStyles.row}>
          <div className={tableStyles.nameCatalog}>
            <div className={tableStyles.text({ bold: true })}>
              <Label label={row.name} truncateLength={50} oneLine />
            </div>
          </div>
        </div>
      );
    },
  },
  {
    id: "description",
    label: "Description",
    sortable: false,
    minWidth: 250,
    customCellRenderer: (row) => {
      const description = row.description.length > 0 ? row.description : "—";
      return (
        <div className={tableStyles.row}>
          <div className={tableStyles.descriptionCatalog}>
            <div className={tableStyles.text()}>
              <Label label={description} truncateLength={70} oneLine />
            </div>
          </div>
        </div>
      );
    },
  },
  {
    id: "resourceCount",
    label: "Items",
    sortable: true,
    width: 102,
    minWidth: 50,
    customCellRenderer: (row) => {
      const resourceCountLabel = `${pluralize(
        "Items",
        row.resourceCount,
        true
      )}`;
      return (
        <div className={tableStyles.row}>
          <div className={tableStyles.resourceCount}>
            <div className={tableStyles.text()}>
              <Label label={resourceCountLabel} truncateLength={30} oneLine />
            </div>
          </div>
        </div>
      );
    },
  },
  {
    id: "groupCount",
    label: "Groups",
    sortable: true,
    width: 81,
    minWidth: 50,
    customCellRenderer: (row) => {
      const groupCountLabel = `${pluralize("Groups", row.groupCount, true)}`;
      return (
        <div className={tableStyles.row}>
          <div className={tableStyles.groupCount}>
            <div className={tableStyles.text()}>
              <Label label={groupCountLabel} truncateLength={30} oneLine />
            </div>
          </div>
        </div>
      );
    },
  },
];

function isSortableField(str: string): str is BundlesSortByField {
  return Object.values<string>(BundlesSortByField).includes(str);
}

function getAppIconForGroup(
  row: BundleRow
): PropsFor<typeof IconGroup>["items"] {
  return row.serviceTypes.map((type) => {
    const serviceTypeIcon = getServiceTypeInfo(type);
    return {
      key: row.id,
      icon: {
        type: "src",
        icon: serviceTypeIcon?.icon ?? "",
      },
    };
  });
}

const BundleCatalog = () => {
  const history = useHistory();
  const transitionTo = useTransitionTo({
    preserveQueries: [ACCESS_OPTION_URL_KEY, ITEM_TYPE_URL_KEY],
  });
  const { layoutOption } = useContext(AppsContext);

  const [searchQuery, setSearchQuery] = useURLSearchParam("search", "");
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 300);
  const [sortBy, setSortBy] = useState<SortValue | undefined>(
    SORT_OPTIONS[0].value
  );

  const {
    data,
    previousData,
    error,
    loading,
    fetchMore,
    networkStatus,
  } = useBundleCatalogQuery({
    variables: {
      input: {
        searchQuery: debouncedSearchQuery,
        sortBy: sortBy,
      },
    },
    fetchPolicy: "cache-and-network",
  });
  const bundlesFetchingMore = networkStatus === NetworkStatus.fetchMore;
  const bundlesList = bundlesFetchingMore ? data || previousData : data;
  const bundlesToDisplay = useMemo(() => data?.bundles.bundles ?? [], [
    data?.bundles.bundles,
  ]);
  const totalNumBundles = bundlesList?.bundles.totalNumBundles ?? 0;
  const cursor = data?.bundles.cursor;

  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              cursor,
              searchQuery: debouncedSearchQuery,
              sortBy: sortBy,
            },
          },
        });
      }
    : undefined;

  function getBundleTransitionTo(
    bundle: BundleDetailForCatalogFragment
  ): React.MouseEventHandler<HTMLElement> | undefined {
    return (event) =>
      transitionTo({ pathname: `/bundles/${bundle.id}` }, event);
  }

  const rows: BundleRow[] = useMemo(
    () =>
      bundlesToDisplay.map((bundle) => {
        return {
          id: bundle.id,
          name: bundle.name,
          description: bundle.description ?? "",
          groupCount: bundle.totalNumGroups,
          resourceCount: bundle.totalNumResources,
          linkTo: getBundleTransitionTo(bundle),
          serviceTypes: bundle.serviceTypes ?? [],
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bundlesToDisplay]
  );

  usePageTitle("Bundles");

  if (error) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  return (
    <>
      <Column isContent maxWidth="none">
        <ColumnHeaderV3
          title="Catalog"
          icon={{ type: "name", icon: "apps" }}
          includeDefaultActions
        />
        <TabsV3
          tabInfos={[
            {
              title: "Apps",
              isSelected: history.location.pathname.endsWith("/apps"),
              onClick: () => history.push("/apps"),
            },
            {
              title: "Bundles",
              isSelected: history.location.pathname.endsWith("/bundles"),
              onClick: () => history.push("/bundles"),
            },
          ]}
        />

        <TableFilters>
          <TableFilters.Left>
            <div className={catalogStyles.searchInput}>
              <Input
                leftIconName="search"
                type="search"
                style="search"
                value={searchQuery ?? ""}
                onChange={setSearchQuery}
                placeholder="Filter Bundles by name"
              />
            </div>
          </TableFilters.Left>
          <TableFilters.Right>
            {layoutOption === "grid" && (
              <div className={catalogStyles.sortBySelect}>
                <Select
                  value={SORT_OPTIONS.find((option) =>
                    _.isEqual(option.value, sortBy)
                  )}
                  searchable={false}
                  options={SORT_OPTIONS}
                  getOptionLabel={(option) => option.label}
                  onChange={(option) => {
                    if (option) setSortBy(option.value);
                  }}
                  size="sm"
                />
              </div>
            )}
            <LayoutToggle />
          </TableFilters.Right>
        </TableFilters>

        {loading && !bundlesList ? (
          <Skeleton variant="text" width="100px" />
        ) : (
          <>
            <TableHeader
              entityType={EntityType.Bundle}
              totalNumRows={bundlesToDisplay.length}
            />
            {layoutOption === "grid" ? (
              <Masonry
                loadingItems={loading}
                onLoadMoreItems={loadMoreRows}
                totalNumItems={totalNumBundles}
                items={rows}
                getItemKey={(row) => row.id}
                breakpointCols={BREAKPOINT_COLUMNS}
                renderItem={(row) => (
                  <InteractiveCard
                    title={row.name}
                    icons={getAppIconForGroup(row)}
                    iconSize="md"
                    description={row.description}
                    onClick={(event) => {
                      if (row.linkTo) {
                        row.linkTo(event);
                      }
                    }}
                    renderCTA={(isHovering) => {
                      if (isHovering) {
                        const resourceCountLabel = `${pluralize(
                          "Items",
                          row.resourceCount,
                          true
                        )}`;
                        const groupCountLabel = `${pluralize(
                          "Groups",
                          row.groupCount,
                          true
                        )}`;
                        return (
                          <div className={styles.hoverLabel}>
                            <Label
                              label={resourceCountLabel}
                              icon={{ type: "name", icon: "resource-group" }}
                            />
                            <Label
                              label={groupCountLabel}
                              icon={{ type: "name", icon: "users" }}
                            />
                          </div>
                        );
                      }
                    }}
                  />
                )}
              />
            ) : (
              <Table
                rows={rows}
                totalNumRows={rows.length}
                getRowId={(ru) => ru.id}
                columns={BUNDLE_COLUMNS}
                onLoadMoreRows={loadMoreRows}
                emptyState={{
                  title: "No Bundles",
                }}
                onRowClick={(row, event) => {
                  if (row.linkTo) {
                    row.linkTo(event);
                  }
                }}
                manualSortDirection={
                  sortBy && {
                    sortBy: sortBy.field,
                    sortDirection: sortBy.direction,
                  }
                }
                handleManualSort={(sortBy, sortDirection) => {
                  if (!sortDirection) {
                    setSortBy(undefined);
                    return;
                  }
                  if (!isSortableField(sortBy)) {
                    return;
                  }
                  const direction: SortDirection =
                    sortDirection === "DESC"
                      ? SortDirection.Desc
                      : SortDirection.Asc;

                  setSortBy({
                    field: sortBy,
                    direction,
                  });
                }}
              />
            )}
          </>
        )}
      </Column>
    </>
  );
};

export default BundleCatalog;
