import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AddGroupResourceInput,
  ConnectionType,
  GroupPreviewWithResourcesFragment,
  ResourceAccessLevel,
  ResourceDropdownPreviewFragment,
  ResourceType,
  useAddGroupResourcesMutation,
  useGroupAddResourcesQuery,
  useMultipleResourceAccessLevelsQuery,
  usePaginatedResourceDropdownLazyQuery,
  useResourcePreviewWithAccessLevelsQuery,
  useSearchResourcesQuery,
} from "api/generated/graphql";
import FullscreenViewTitle from "components/fullscreen_modals/FullscreenViewTitle";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import {
  Banner,
  Divider,
  EntityIcon,
  Icon,
  Input,
  Label,
  Loader,
  Select,
} from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import {
  resourceRequiresAtLeastOneRole,
  resourceTypeHasRoles,
  serviceTypeHasMaxOneRole,
} from "utils/directory/resources";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { formatResourceBreadcrumb } from "utils/resources";
import { useTransitionBack } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";

import * as styles from "./GroupAddResources.css";

const PAGE_SIZE = 100;

interface GroupResourceRow {
  id: string;
  icon?: IconData;
  name: string;
  sublabel?: string;
  connectionType?: ConnectionType;
  connectionId?: string;
  resourceType?: ResourceType;
  isEmpty?: boolean;
}

const GroupAddResourcesView = () => {
  const transitionBack = useTransitionBack();
  const logEvent = useLogEvent();
  const { groupId } = useParams<{ groupId: string }>();
  const startPushTaskPoll = usePushTaskLoader();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  // TODO: Convert this mess of useStates that are all connected to each other to a context.
  const [resourceById, setResourceById] = useState<{
    [resourceId: string]: ResourceDropdownPreviewFragment;
  }>({});
  const [resourcesByConnectionId, setResourcesByConnectionId] = useState<{
    [connectionId: string]: ResourceDropdownPreviewFragment[];
  }>({});
  const [resourcesByParentId, setResourcesByParentId] = useState<{
    [resourceId: string]: ResourceDropdownPreviewFragment[];
  }>({});
  const [roleByResourceIdToAdd, setRoleByResourceIdToAdd] = useState<
    Record<string, ResourceAccessLevel[]>
  >({});
  const [
    accessDurationByResourceIdToAdd,
    setAccessDurationByResourceIdToAdd,
  ] = useState<Record<string, ExpirationValue>>({});
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [addError, setAddError] = useState("");
  const rowsById: Record<string, GroupResourceRow> = {};
  const [itemsLoadingSubRows, setItemsLoadingSubRows] = useState<string[]>([]);

  // Get Group and Connection data
  const { data, loading, error } = useGroupAddResourcesQuery({
    variables: {
      groupId,
    },
  });
  let group: GroupPreviewWithResourcesFragment | undefined;
  if (data?.group.__typename === "GroupResult") {
    group = data.group.group;
  }
  const allConnections = data?.connections.connections ?? [];
  const connections = allConnections.filter((connection) =>
    Boolean(connection.numResources)
  );

  // Fetch all non-remote roles for resources that the user has access to
  // Used to determine if the user already has full-access to the resource
  const resourceIDs = new Set(group?.groupResources.map((gr) => gr.resourceId));
  const {
    data: rolesData,
    previousData: rolesPreviousData,
    error: rolesError,
  } = useMultipleResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceIds: Array.from(resourceIDs),
        // Don't query end systems for roles upfront to load page faster
        ignoreRemoteAccessLevels: true,
      },
    },
    skip: resourceIDs.size === 0,
  });
  const allNonRemoteRolesByResourceId: Record<
    string,
    ResourceAccessLevel[]
  > = {};
  switch (rolesPreviousData?.multipleAccessLevels.__typename) {
    case "MultipleResourceAccessLevelsResult":
      rolesPreviousData.multipleAccessLevels.results.forEach((role) => {
        allNonRemoteRolesByResourceId[role.resourceId] = role.accessLevels;
      });
  }
  switch (rolesData?.multipleAccessLevels.__typename) {
    case "MultipleResourceAccessLevelsResult":
      rolesData.multipleAccessLevels.results.forEach((role) => {
        allNonRemoteRolesByResourceId[role.resourceId] = role.accessLevels;
      });
  }

  // Allow searching directly for resources
  const {
    data: searchResourcesData,
    loading: searchResourcesLoading,
    error: searchResourcesError,
  } = useSearchResourcesQuery({
    variables: {
      query: debouncedSearchQuery,
      maxNumEntries: PAGE_SIZE,
    },
    skip: debouncedSearchQuery === "",
  });

  useEffect(() => {
    setResourceById((resourceById) => {
      return {
        ...resourceById,
        ...searchResourcesData?.resources.resources.reduce((acc, resource) => {
          acc[resource.id] = resource;
          return acc;
        }, {} as typeof resourceById),
      };
    });
  }, [searchResourcesData]);

  const [getResources] = usePaginatedResourceDropdownLazyQuery();
  const [
    addGroupResources,
    { loading: addLoading },
  ] = useAddGroupResourcesMutation();

  if (loading) {
    return <FullscreenSkeleton />;
  }
  if (!group?.authorizedActions?.includes(AuthorizedActionManage)) {
    return <ForbiddenPage />;
  }
  if (!group || error || rolesError) {
    return <UnexpectedErrorPage error={error} />;
  }

  // Get the roles that the user already has on each resource
  const directRolesByResourceId: Record<string, ResourceAccessLevel[]> = {};
  group.groupResources.forEach((groupResource) => {
    if (!groupResource.access.directAccessPoint) {
      return;
    }
    if (!directRolesByResourceId[groupResource.resourceId]) {
      directRolesByResourceId[groupResource.resourceId] = [];
    }
    directRolesByResourceId[groupResource.resourceId] = _.uniqBy(
      [
        ...directRolesByResourceId[groupResource.resourceId],
        groupResource.accessLevel,
      ],
      "accessLevelRemoteId"
    );
  });

  const handleClose = () => {
    transitionBack(`/groups/${groupId}/#resources`);
  };

  const handleFetchResources = async (
    connectionId: string,
    connectionType?: ConnectionType,
    parentResourceId?: string
  ) => {
    let resourceTypes: ResourceType[] | undefined;
    if (connectionType === ConnectionType.AwsSso) {
      resourceTypes = [ResourceType.AwsAccount];
    }
    try {
      setItemsLoadingSubRows((prev) => [
        ...prev,
        parentResourceId ?? connectionId,
      ]);
      const { data } = await getResources({
        variables: {
          input: {
            connectionIds: parentResourceId ? undefined : [connectionId],
            resourceTypes,
            parentResourceId: parentResourceId
              ? {
                  parentResourceId,
                }
              : undefined,
            maxNumEntries: PAGE_SIZE,
          },
        },
      });

      if (parentResourceId) {
        setResourcesByParentId((resourcesByParentId) => {
          return {
            ...resourcesByParentId,
            [parentResourceId]: data?.resources.resources ?? [],
          };
        });
      } else {
        setResourcesByConnectionId((resourcesByConnectionId) => {
          return {
            ...resourcesByConnectionId,
            [connectionId]: data?.resources.resources ?? [],
          };
        });
      }
      setResourceById((resourceById) => {
        return {
          ...resourceById,
          ...data?.resources.resources.reduce((acc, resource) => {
            acc[resource.id] = resource;
            return acc;
          }, {} as typeof resourceById),
        };
      });
      setItemsLoadingSubRows((prev) =>
        prev.filter((id) => id !== (parentResourceId ?? connectionId))
      );
      return data?.resources.resources ?? [];
    } catch (err) {
      logError(err, "Failed to fetch resources for connection " + connectionId);
    }
  };

  const numResourcesToAdd = Object.keys(roleByResourceIdToAdd).length;

  /*
    Disable any resources that the group already has full access to.
    If the group has no direct access then keep the resource enabled.
    Otherwise, if numNonRemoteRoles != 0 and numDirectRoles === numNonRemoteRoles
    then the group has all custom/static roles available on the resource so disable it.
    This will not disable a resource if a group has all remote roles, so a group
    can still select it, but will be shown it has no roles to add later.
    This is done because fetching remote roles upfront is expensive.
  */
  const disabledResourceIds = new Set();
  group.groupResources.forEach((groupResource) => {
    const numDirectRoles =
      directRolesByResourceId[groupResource.resourceId]?.length ?? 0;
    const numNonRemoteRoles =
      allNonRemoteRolesByResourceId[groupResource.resourceId]?.length ?? 0;
    if (numDirectRoles != 0 && numDirectRoles === numNonRemoteRoles) {
      disabledResourceIds.add(groupResource.resourceId);
    }
  });

  const hasNestedRows = (row: GroupResourceRow) => {
    return (
      Boolean(row.connectionType) ||
      row.resourceType === ResourceType.AwsAccount
    );
  };

  const getNestedRows = (row: GroupResourceRow) => {
    const resources = (row.connectionType
      ? resourcesByConnectionId[row.id]
      : resourcesByParentId[row.id]
    )?.filter((resource) => !disabledResourceIds.has(resource.id));
    if (resources && resources.length === 0) {
      return [
        {
          id: `${row.id}-empty`,
          name: "No resources",
          isEmpty: true,
        },
      ];
    }
    return resources?.map((resource) => {
      const iconData: IconData = {
        type: "entity",
        entityType: resource.resourceType,
      };
      const row: GroupResourceRow = {
        id: resource.id,
        icon: iconData,
        name: resource.name,
        resourceType: resource.resourceType,
        connectionId: resource?.connection?.id,
        sublabel: formatResourceBreadcrumb(
          resource.ancestorPathToResource,
          null
        ),
      };
      rowsById[row.id] = row;
      return row;
    });
  };

  const handleSubmit = async () => {
    if (!group) {
      return;
    }
    logEvent({
      name: "apps_add_resources_to_group",
      properties: {
        numResourcesAdded: Object.entries(roleByResourceIdToAdd).length,
      },
    });
    try {
      const groupResourcesToAdd: AddGroupResourceInput[] = [];
      for (const [resourceId, roles] of Object.entries(roleByResourceIdToAdd)) {
        const resource = resourceById[resourceId];
        if (!resource) {
          logError(
            "resourceById was missing a resourceId when submitting -- this should NOT happen"
          );
          setAddError("failed to add resources to group");
          continue;
        }
        // Convert expiration value to duration in minutes, default to undefined.
        const expirationVal =
          accessDurationByResourceIdToAdd[resourceId] ||
          ExpirationValue.Indefinite;
        const accessDurationInMinutes = expirationValueToDurationInMinutes(
          expirationVal
        )?.asMinutes();

        if (roles.length === 0) {
          // If resource requires a role , but none are selected,
          // show an error.
          if (
            resourceRequiresAtLeastOneRole(resource) ||
            allNonRemoteRolesByResourceId[resourceId]?.length > 0
          ) {
            setAddError(
              "Please select at least one role for resources that have roles."
            );
            return;
          } else {
            // If resource does not require roles,
            // add an empty role to add the resource directly.
            groupResourcesToAdd.push({
              groupId: group.id,
              resourceId,
              accessLevel: {
                accessLevelName: "",
                accessLevelRemoteId: "",
              },
              durationInMinutes: accessDurationInMinutes,
            });
          }
        }

        roles.forEach((role) => {
          groupResourcesToAdd.push({
            groupId: group?.id ?? "",
            resourceId,
            accessLevel: {
              accessLevelName: role.accessLevelName,
              accessLevelRemoteId: role.accessLevelRemoteId,
            },
            durationInMinutes: accessDurationInMinutes,
          });
        });
      }

      const { data } = await addGroupResources({
        variables: {
          input: {
            groupResources: groupResourcesToAdd,
          },
        },
        // TODO update query once we break up pagination for group users, group resources, etc
        refetchQueries: ["Group", "AppsListColumn"],
      });
      switch (data?.addGroupResources.__typename) {
        case "AddGroupResourcesResult":
          if (data.addGroupResources.taskId) {
            startPushTaskPoll(data.addGroupResources.taskId);
          }
          handleClose();
          break;
        case "GroupNotFoundError":
        case "GroupResourceAlreadyExists":
          setAddError(data.addGroupResources.message);
          break;
        default:
          logError(new Error(`failed to add resources to group`));
          setAddError("Error: failed to add resources to group");
      }
    } catch (error) {
      logError(error, "failed to add resources to group");
      setAddError(
        getModifiedErrorMessage(
          "Error: failed to add resources to group",
          error
        )
      );
    }
  };

  const COLUMNS: Header<GroupResourceRow>[] = [
    {
      id: "name",
      label: "Name",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <div
            className={sprinkles({
              display: "flex",
              alignItems: "center",
              gap: "md",
            })}
          >
            <Label label={row.name} icon={row.icon} detail={row.sublabel} />
            {itemsLoadingSubRows.includes(row.id) && <Loader size="xs" />}
          </div>
        );
      },
      width: 500,
    },
  ];

  const getCheckboxDisabledReason = (row: GroupResourceRow) => {
    if (row?.isEmpty) {
      return "No resources";
    }
    if (disabledResourceIds.has(row.id)) {
      return "Already in bundle";
    }
  };

  const onCheckedRowsChange = async (
    checkedRowIds: string[],
    checked: boolean
  ) => {
    if (checked) {
      setSelectedRowIds((prev) => [...prev, ...checkedRowIds]);
    } else {
      setSelectedRowIds((prev) =>
        prev.filter((id) => !checkedRowIds.includes(id))
      );
    }
    checkedRowIds.forEach((id) => {
      const row = rowsById[id];
      onCheckRow(row, checked);
    });
  };

  const updateChildRows = async (row: GroupResourceRow, checked: boolean) => {
    if (checked) {
      try {
        setSelectedRowIds((selectedRowIds) => [...selectedRowIds, row.id]);
        if (hasNestedRows(row)) {
          let children: ResourceDropdownPreviewFragment[] | undefined;
          if (row.connectionType) {
            if (resourcesByConnectionId[row.id]) {
              children = resourcesByConnectionId[row.id];
            } else {
              children = await handleFetchResources(row.id, row.connectionType);
            }
          } else if (row.resourceType === ResourceType.AwsAccount) {
            if (resourcesByParentId[row.id]) {
              children = resourcesByParentId[row.id];
            } else {
              children = await handleFetchResources(
                "",
                row.connectionType,
                row.id
              );
            }
          }
          ReactDOM.unstable_batchedUpdates(() => {
            children
              ?.filter((resource) => !disabledResourceIds.has(resource.id))
              .forEach((child) => {
                updateChildRows(child, checked);
              });
          });
        } else {
          setRoleByResourceIdToAdd((prev) => {
            return {
              ...prev,
              [row.id]: [],
            };
          });
        }
      } catch (err) {
        logError(err, "Failed to fetch resources for connection");
      }
    } else {
      setSelectedRowIds((selectedRowIds) =>
        selectedRowIds.filter((id) => id !== row.id)
      );
      if (hasNestedRows(row)) {
        const children = getNestedRows(row);
        children?.forEach((child) => {
          updateChildRows(child, checked);
        });
      } else {
        setRoleByResourceIdToAdd((prev) => {
          const newRoles = { ...prev };
          delete newRoles[row.id];
          return newRoles;
        });
      }
    }
  };

  const onCheckRow = async (row: GroupResourceRow, checked: boolean) => {
    if (checked) {
      setSelectedRowIds((selectedRowIds) => [...selectedRowIds, row.id]);
      if (!hasNestedRows(row)) {
        setRoleByResourceIdToAdd((prev) => {
          return {
            ...prev,
            [row.id]: [],
          };
        });
      }
    } else {
      setSelectedRowIds((selectedRowIds) =>
        selectedRowIds.filter((id) => id !== row.id)
      );
      if (!hasNestedRows(row)) {
        setRoleByResourceIdToAdd((prev) => {
          const newRoles = { ...prev };
          delete newRoles[row.id];
          return newRoles;
        });
      }
    }
    if (hasNestedRows(row)) {
      let children: ResourceDropdownPreviewFragment[] | undefined;
      if (row.connectionType) {
        if (resourcesByConnectionId[row.id]) {
          children = resourcesByConnectionId[row.id];
        } else {
          children = await handleFetchResources(row.id, row.connectionType);
        }
      } else if (row.resourceType === ResourceType.AwsAccount) {
        if (resourcesByParentId[row.id]) {
          children = resourcesByParentId[row.id];
        } else {
          children = await handleFetchResources("", row.connectionType, row.id);
        }
      }
      ReactDOM.unstable_batchedUpdates(() => {
        children
          ?.filter((resource) => !disabledResourceIds.has(resource.id))
          .forEach((child) => {
            updateChildRows(child, checked);
          });
      });
    }
  };

  const onRowClick = async (row: GroupResourceRow) => {
    if (row.isEmpty || disabledResourceIds.has(row.id)) {
      return;
    }
    if (hasNestedRows(row)) {
      if (row.connectionType && !resourcesByConnectionId[row.id]) {
        await handleFetchResources(row.id, row.connectionType);
      }
      if (
        row.resourceType === ResourceType.AwsAccount &&
        !resourcesByParentId[row.id]
      ) {
        await handleFetchResources("", row.connectionType, row.id);
      }
    } else {
      onCheckRow(row, !selectedRowIds.includes(row.id));
    }
  };

  const renderConnectionsList = () => {
    const rows: GroupResourceRow[] = connections.map((connection) => {
      const row: GroupResourceRow = {
        id: connection.id,
        icon: {
          type: "src",
          icon: getConnectionTypeInfo(connection.connectionType)?.icon,
        },
        name: connection.name,
        connectionType: connection.connectionType,
      };
      rowsById[row.id] = row;
      return row;
    });

    return (
      <Table
        columns={COLUMNS}
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        getRowCanExpand={(row) => hasNestedRows(row.original)}
        loadingRows={loading || searchResourcesLoading}
        defaultSortBy="name"
        checkedRowIds={new Set(selectedRowIds)}
        onCheckedRowsChange={onCheckedRowsChange}
        getCheckboxDisabledReason={getCheckboxDisabledReason}
        onRowClick={onRowClick}
        onExpandRow={(row) => {
          if (row.connectionType && !resourcesByConnectionId[row.id]) {
            handleFetchResources(row.id, row.connectionType);
          }
          if (
            row.resourceType === ResourceType.AwsAccount &&
            !resourcesByParentId[row.id]
          ) {
            handleFetchResources("", row.connectionType, row.id);
          }
        }}
        getChildRows={getNestedRows}
        expandOnChecked={true}
        expandOnRowClick={true}
      />
    );
  };

  const renderSearchList = () => {
    if (searchResourcesError) {
      return <ModalErrorMessage errorMessage={searchResourcesError.message} />;
    }

    const filteredResources = (
      searchResourcesData?.resources.resources ?? []
    ).filter((resource) => !disabledResourceIds.has(resource.id));

    const rows: GroupResourceRow[] = filteredResources.map((resource) => {
      const iconData: IconData = {
        type: "entity",
        entityType: resource.resourceType,
      };

      const row: GroupResourceRow = {
        id: resource.id,
        icon: iconData,
        name: resource.name,
        resourceType: resource.resourceType,
        connectionId: resource?.connection?.id,
        sublabel: formatResourceBreadcrumb(
          resource.ancestorPathToResource,
          null,
          resource.connection?.name
        ),
      };
      rowsById[row.id] = row;
      return row;
    });

    return (
      <Table
        columns={COLUMNS}
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        getRowCanExpand={(row) => hasNestedRows(row.original)}
        loadingRows={loading || searchResourcesLoading}
        defaultSortBy="name"
        checkedRowIds={new Set(selectedRowIds)}
        onCheckedRowsChange={onCheckedRowsChange}
        getCheckboxDisabledReason={getCheckboxDisabledReason}
        onRowClick={onRowClick}
        getChildRows={getNestedRows}
      />
    );
  };

  return (
    <FullscreenView
      title={
        <FullscreenViewTitle
          entityType={group.groupType}
          entityName={group.name}
          targetEntityName="Resources"
          action="add"
        />
      }
      onCancel={handleClose}
      onPrimaryButtonClick={handleSubmit}
      primaryButtonDisabled={numResourcesToAdd === 0}
      primaryButtonLabel={`Add ${
        numResourcesToAdd ? numResourcesToAdd : ""
      } ${pluralize("resource", numResourcesToAdd)}`}
      primaryButtonLoading={addLoading}
    >
      <FullscreenView.Content fullWidth>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "md",
            })}
          >
            Select resources to add to the group:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Search by name"
            />
          </div>
          <div className={sprinkles({ color: "gray600", fontSize: "textXs" })}>
            {debouncedSearchQuery === ""
              ? "Showing first 100 resources in each app. Use search to find more results."
              : "Showing first 100 search results. Refine your search to find more."}
          </div>
          <Divider />
          {debouncedSearchQuery === ""
            ? renderConnectionsList()
            : renderSearchList()}
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addError && (
          <Banner message={addError} type="error" marginBottom="lg" />
        )}
        <div
          className={sprinkles({
            fontSize: "textLg",
            fontWeight: "medium",
            marginBottom: "lg",
          })}
        >
          Adding {numResourcesToAdd} {pluralize("Resource", numResourcesToAdd)}
        </div>
        {Object.keys(roleByResourceIdToAdd).map((resourceId) => {
          const resource = resourceById[resourceId];
          if (!resource) {
            return null;
          }

          return (
            <ResourceCard
              key={resource.id}
              resource={resource}
              existingRoles={directRolesByResourceId[resourceId] ?? []}
              selectedRoles={roleByResourceIdToAdd[resourceId] ?? []}
              onRemove={() => {
                setRoleByResourceIdToAdd((prev) => {
                  const newRoles = { ...prev };
                  delete newRoles[resourceId];
                  return newRoles;
                });
                setAccessDurationByResourceIdToAdd((prev) => {
                  const newAccessDurations = { ...prev };
                  delete newAccessDurations[resourceId];
                  return newAccessDurations;
                });
                setSelectedRowIds((prev) =>
                  prev.filter(
                    (id) => id !== resourceId && id !== resource.connection?.id
                  )
                );
              }}
              onUpdateSelectedRoles={(roles) => {
                setRoleByResourceIdToAdd((prev) => {
                  return {
                    ...prev,
                    [resourceId]: roles,
                  };
                });
              }}
              onUpdateAllRoles={(roles) => {
                allNonRemoteRolesByResourceId[resource.id] = roles;
              }}
              accessDuration={
                accessDurationByResourceIdToAdd[resourceId] ??
                ExpirationValue.Indefinite
              }
              setAccessDuration={(access) => {
                setAccessDurationByResourceIdToAdd((prev) => {
                  return {
                    ...prev,
                    [resourceId]: access,
                  };
                });
              }}
            />
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

interface Props {
  resource: ResourceDropdownPreviewFragment;
  existingRoles: ResourceAccessLevel[];
  selectedRoles: ResourceAccessLevel[];
  onRemove: () => void;
  onUpdateSelectedRoles: (roles: ResourceAccessLevel[]) => void;
  onUpdateAllRoles?: (roles: ResourceAccessLevel[]) => void;
  accessDuration: ExpirationValue;
  setAccessDuration: (access: ExpirationValue) => void;
}

export const ResourceCard = (props: Props) => {
  const { resource } = props;

  const hasRoles = resourceTypeHasRoles(resource);
  const { data, loading, error } = useResourcePreviewWithAccessLevelsQuery({
    variables: {
      input: {
        id: resource.id,
      },
    },
    skip: !hasRoles,
  });

  let allRoles: ResourceAccessLevel[] = [];
  if (data?.resource.__typename === "ResourceResult") {
    allRoles =
      data.resource.resource.accessLevels?.filter(
        (role) => role.accessLevelRemoteId !== ""
      ) ?? [];
    props.onUpdateAllRoles && props.onUpdateAllRoles(allRoles);
  }

  if (!resource.connection) {
    return null;
  }

  const roleOptions = allRoles.filter((role) => {
    return ![...props.existingRoles, ...props.selectedRoles].some(
      (existingRole) =>
        existingRole.accessLevelRemoteId === role.accessLevelRemoteId
    );
  });

  const alreadyHasAllRoles =
    allRoles.length > 0 &&
    roleOptions.length === 0 &&
    props.selectedRoles.length === 0;

  return (
    <div key={resource.id} className={styles.resourceCard}>
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "flex-start",
          gap: "sm",
        })}
      >
        <div
          className={sprinkles({
            flexShrink: 0,
          })}
        >
          <EntityIcon
            type={resource.connection.connectionType}
            iconStyle="rounded"
          />
        </div>
        <div className={styles.resourceInfoSection}>
          <div className={styles.resourceCardHeader}>{resource.name}</div>
          <div className={styles.resourceCardSubtitle}>
            {resource.parentResource
              ? `${resource.parentResource.name} / `
              : ""}
            {resource.connection.name}
          </div>
          <div className={styles.resourceCardType}>
            <EntityIcon type={resource.resourceType} includeBrand={false} />
            {resourceTypeInfoByType[resource.resourceType].name}
          </div>
        </div>
        <div className={sprinkles({ flexShrink: 0 })}>
          <Icon name="trash" color="red600V3" onClick={props.onRemove} />
        </div>
      </div>
      <div className={sprinkles({ marginTop: "md" })}>
        <Select
          key={resource.id}
          options={Object.values(ExpirationValue)}
          value={props.accessDuration}
          onChange={(val) => {
            if (val) {
              props.setAccessDuration(val);
            }
          }}
          disableBuiltInFiltering
          getOptionLabel={(expirationVal) =>
            expirationVal === ExpirationValue.Indefinite
              ? "Indefinite access"
              : `Access for ${expirationVal}`
          }
        />
      </div>
      {alreadyHasAllRoles ? (
        <div
          className={sprinkles({
            paddingTop: "md",
            paddingLeft: "md",
            paddingRight: "md",
          })}
        >
          All roles on this resource have already been granted, remove this from
          selection.
        </div>
      ) : (
        (allRoles.length > 0 || loading) && (
          <div className={sprinkles({ marginTop: "md" })}>
            {error && <ModalErrorMessage errorMessage={error.message} />}
            <Select
              options={roleOptions}
              loading={loading}
              placeholder="Select role"
              getOptionLabel={(role) => role.accessLevelName}
              onChange={(role) => {
                if (role) {
                  if (serviceTypeHasMaxOneRole(resource?.serviceType)) {
                    props.onUpdateSelectedRoles([role]);
                  } else {
                    props.onUpdateSelectedRoles([...props.selectedRoles, role]);
                  }
                }
              }}
              selectOnly
            />
            {props.selectedRoles.map((role) => {
              return (
                <div
                  key={role.accessLevelRemoteId}
                  className={sprinkles({
                    paddingX: "sm",
                    marginTop: "sm",
                    fontSize: "textSm",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  })}
                >
                  {role.accessLevelName}
                  <Icon
                    name="x"
                    size="xs"
                    onClick={() => {
                      props.onUpdateSelectedRoles(
                        props.selectedRoles.filter(
                          (r) =>
                            r.accessLevelRemoteId !== role.accessLevelRemoteId
                        )
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
        )
      )}
    </div>
  );
};

export default GroupAddResourcesView;
