import {
  Maybe,
  useInviteUsersMutation,
  UserProductRole,
} from "api/generated/graphql";
import UploadCSVButton, {
  CSVColumns,
} from "components/buttons/UploadCSVButton";
import { ProductRoleDropdown } from "components/dropdown/ProductRoleDropdown";
import { ResourceLabel } from "components/label/Label";
import inviteUsersModalStyles from "components/modals/InviteTeammatesModal.module.scss";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Button, ButtonV3, FormGroup, Input, Modal } from "components/ui";
import pluralize from "pluralize";
import React, { useState } from "react";
import * as Icons from "react-feather";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";

export type UserInfo = {
  name: string;
  email: string;
  productRole: Maybe<UserProductRole>;
  roleRemoteId?: Maybe<string>;
};

enum UserProductRoleDisplayName {
  ProductMember = "Member",
  ProductAdmin = "Admin",
}

export const DisplayNameByProductRole: {
  [key in UserProductRole]: UserProductRoleDisplayName;
} = {
  [UserProductRole.Member]: UserProductRoleDisplayName.ProductMember,
  [UserProductRole.Admin]: UserProductRoleDisplayName.ProductAdmin,
};

type InviteTeammatesModalProps = {
  isModalOpen: boolean;
  onClose: () => void;
};

export const InviteTeammatesModal = (props: InviteTeammatesModalProps) => {
  const [userInfos, setUserInfos] = useState<UserInfo[]>([]);
  const [currentName, setCurrentName] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [productRole, setProductRole] = useState<Maybe<UserProductRole>>(null);

  const [inviteUsersMutation, { loading }] = useInviteUsersMutation();
  const { displaySuccessToast } = useToast();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const handleAddTeammate = () => {
    if (userInfos.map((info) => info.email).includes(currentEmail)) {
      setErrorMessage("Email already exists in invite list.");
      return;
    }
    if (!currentEmail.includes("@")) {
      setErrorMessage("Email incorrectly formatted. Please try again.");
      return;
    }
    if (currentName.trim() === "") {
      setErrorMessage("Name cannot be empty.");
      return;
    }
    setUserInfos([
      ...userInfos,
      {
        name: currentName.trim(),
        email: currentEmail.trim(),
        productRole: productRole,
      },
    ]);
    setCurrentName("");
    setCurrentEmail("");
    setProductRole(null);
    setErrorMessage("");
  };

  const modalReset = () => {
    props.onClose();
    setErrorMessage(null);
    setUserInfos([]);
    setCurrentName("");
    setCurrentEmail("");
    setErrorMessage("");
  };

  const handleSubmit = async () => {
    try {
      const { data } = await inviteUsersMutation({
        variables: {
          input: {
            invites: userInfos.map((userInfo) => {
              const nameParts = userInfo.name.trim().split(" ");
              return {
                email: userInfo.email,
                firstName: nameParts[0],
                lastName: nameParts.slice(1).join(" "),
                role: userInfo.productRole || UserProductRole.Member,
              };
            }),
          },
        },
        refetchQueries: ["Users", "UsersHome", "UsersTeam"],
      });
      switch (data?.inviteUsers.__typename) {
        case "InviteUsersResult":
          modalReset();
          displaySuccessToast(
            `Successfully invited ${pluralize(
              "user",
              data.inviteUsers.invitedUsers.length,
              true
            )}`
          );
          break;
        case "UserAlreadyExistsInAnotherOrg":
          logError(new Error(`user already exists in another organization`));
          setErrorMessage(`Error: user already exists in another organization`);
          break;
        default:
          logError(new Error(`failed to invite users`));
          setErrorMessage(
            `Error: failed to invite ${pluralize(
              "user",
              userInfos.length,
              true
            )}`
          );
      }
    } catch (error) {
      logError(error, `failed to invite users`);
      setErrorMessage(
        `Error: failed to invite ${pluralize("user", userInfos.length, true)}`
      );
    }
  };

  return (
    <Modal isOpen={props.isModalOpen} onClose={modalReset} title="Add Users">
      <Modal.Body>
        <UserNameEmailBlock
          currentName={currentName}
          setCurrentName={setCurrentName}
          currentEmail={currentEmail}
          setCurrentEmail={setCurrentEmail}
        />
        <ProductRoleBlock
          productRole={productRole}
          setProductRole={setProductRole}
        />
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
        <TeammatesButtonsBlock
          setUserInfos={setUserInfos}
          setErrorMessage={setErrorMessage}
          handleAddTeammate={handleAddTeammate}
          currentName={currentName}
          currentEmail={currentEmail}
        />
        <UserRowsBlock userInfos={userInfos} setUserInfos={setUserInfos} />
      </Modal.Body>
      <Modal.Footer
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={props.onClose}
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLabel={`Send ${pluralize(
          "invite",
          userInfos.length,
          userInfos.length > 0
        )}`}
        primaryButtonLoading={loading}
        primaryButtonDisabled={userInfos.length === 0}
      />
    </Modal>
  );
};

type UserNameEmailBlockProps = {
  currentName: string;
  setCurrentName: (name: string) => void;
  currentEmail: string;
  setCurrentEmail: (email: string) => void;
};

const UserNameEmailBlock = (props: UserNameEmailBlockProps) => {
  return (
    <>
      <FormGroup label="Name">
        <Input value={props.currentName} onChange={props.setCurrentName} />
      </FormGroup>
      <FormGroup label="Email">
        <Input value={props.currentEmail} onChange={props.setCurrentEmail} />
      </FormGroup>
    </>
  );
};

type ProductRoleBlockProps = {
  productRole: Maybe<UserProductRole>;
  setProductRole: (productRole: UserProductRole) => void;
};

const ProductRoleBlock = (props: ProductRoleBlockProps) => {
  return (
    <FormGroup
      label="Product Role"
      infoTooltip="Members can request and grant access to resources, as
                well as manage groups that they own. Admins have full read and write privileges, and can
                perform sensitive actions like adding new connections, and
                starting quarterly access reviews."
    >
      <ProductRoleDropdown
        productRole={props.productRole}
        setProductRole={props.setProductRole}
      />
    </FormGroup>
  );
};

type TeammatesButtonsBlockProps = {
  setUserInfos: (userInfos: UserInfo[]) => void;
  setErrorMessage: (error: string) => void;
  handleAddTeammate: () => void;
  currentName: string;
  currentEmail: string;
};

const TeammatesButtonsBlock = (props: TeammatesButtonsBlockProps) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  return (
    <div className={inviteUsersModalStyles.teammatesButtonsContainer}>
      <div className={inviteUsersModalStyles.teammatesButtons}>
        <UploadCSVButton
          onChangeUserInfos={props.setUserInfos}
          onChangeErrorMessage={props.setErrorMessage}
          requiredColumns={[CSVColumns.Name, CSVColumns.Email]}
          trackName="invite_teammates"
        />
        {hasV3 ? (
          <ButtonV3
            label="Add User"
            type="main"
            onClick={props.handleAddTeammate}
            disabled={props.currentName === "" || props.currentEmail === ""}
            size="sm"
          />
        ) : (
          <Button
            type="primary"
            label="Add User"
            onClick={props.handleAddTeammate}
            disabled={props.currentName === "" || props.currentEmail === ""}
          />
        )}
      </div>
    </div>
  );
};

type UserRowsBlockProps = {
  userInfos: UserInfo[];
  setUserInfos: (userInfos: UserInfo[]) => void;
};

const UserRowsBlock = (props: UserRowsBlockProps) => {
  return (
    <div>
      {props.userInfos.length > 0 ? (
        <div className={inviteUsersModalStyles.userRows}>
          {props.userInfos.map((info) => (
            <UserRow
              key={info.email}
              userInfo={info}
              onRemove={(userEmail) => {
                props.setUserInfos(
                  props.userInfos.filter((info) => info.email !== userEmail)
                );
              }}
            />
          ))}
        </div>
      ) : (
        <div className={inviteUsersModalStyles.teammatesPlaceholder}>
          <div className={inviteUsersModalStyles.placeholderIcon}>
            <Icons.UserPlus strokeWidth={2} />
          </div>
          <div className={inviteUsersModalStyles.placeholderText}>
            {`Please add at least one user to continue`}
          </div>
        </div>
      )}
    </div>
  );
};

type UserRowProps = {
  userInfo: UserInfo;
  onRemove: (userEmail: string) => void;
};

const UserRow = (props: UserRowProps) => {
  return (
    <div className={inviteUsersModalStyles.reviewerRow}>
      <div className={inviteUsersModalStyles.reviewerRowContent}>
        <ResourceLabel
          text={props.userInfo.name.trim()}
          entityType={EntityTypeDeprecated.User}
          entityId={props.userInfo.email}
          email={props.userInfo.email}
        />
        <div
          className={inviteUsersModalStyles.removeReviewerButton}
          onClick={() => {
            props.onRemove(props.userInfo.email);
          }}
        >
          <Icons.X strokeWidth={4} size={16} />
        </div>
      </div>
      <ProductRoleDropdown
        productRole={props.userInfo.productRole}
        setProductRole={(productRole) => {
          props.userInfo.productRole = productRole;
        }}
      />
    </div>
  );
};

export default InviteTeammatesModal;
