import {
  Maybe,
  ResourceAccessLevel,
  useResourceAccessLevelsQuery,
} from "api/generated/graphql";
import ResourceLabel from "components/label/item_labels/ResourceLabel";
import { TooltipPlacement } from "components/label/Label";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { FormGroup, Modal, Select, Tooltip } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import { useState } from "react";
import { logError } from "utils/logging";
import { by } from "utils/search/sort";
import { SessionWithExpiration } from "views/connect_sessions/utils";

type RoleModalProps = {
  isModalOpen: boolean;
  onClose: () => void;
  onSubmit: () => Promise<Boolean>;
  loading?: boolean;
  isImpersonationResource?: boolean;
  resource: {
    id: string;
    name: string;
  };
  setSelectedRole: (role: ResourceAccessLevel) => void;
  selectedRole: ResourceAccessLevel | null;
  getCurrentSession: (remoteId: string) => SessionWithExpiration | null;
  errorMessage?: Maybe<string>;
};

export const RoleModal = (props: RoleModalProps) => {
  const [roles, setRoles] = useState<ResourceAccessLevel[]>([]);

  useResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceId: props.resource.id,
        onlyMine: true,
      },
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      switch (data?.accessLevels.__typename) {
        case "ResourceAccessLevelsResult": {
          if (data.accessLevels.accessLevels.length === 1) {
            const role = data.accessLevels.accessLevels[0];
            props.setSelectedRole(role);
          }
          setRoles(data.accessLevels.accessLevels);
          break;
        }
        case "ResourceNotFoundError": {
          logError(new Error(`Error: failed to list resource roles`));
          break;
        }
      }
    },
  });

  const sortedOptions = roles.slice().sort(by((role) => role.accessLevelName));

  const getOptionLabel = (role: ResourceAccessLevel) =>
    props.getCurrentSession(role.accessLevelRemoteId)
      ? `${role.accessLevelName} (Active)`
      : role.accessLevelName;

  const getRoleSelect = () => {
    const select = (
      <Select
        options={sortedOptions}
        value={props.selectedRole ?? undefined}
        getOptionLabel={getOptionLabel}
        onChange={(role) => {
          if (role) {
            props.setSelectedRole(role);
          }
        }}
        getOptionSelected={(a, b) => a.accessLevelName === b.accessLevelName}
        disabled={roles.length === 1}
      />
    );
    if (roles.length === 1) {
      return (
        <Tooltip
          tooltipText="Only one role is available for this resource."
          placement={TooltipPlacement.Top}
        >
          {select}
        </Tooltip>
      );
    }
    return select;
  };

  const requiresRole =
    props.isImpersonationResource ||
    (roles.length > 0 &&
      roles.every((role) => role.accessLevelRemoteId !== ""));

  return (
    <Modal
      title={
        props.isImpersonationResource
          ? "Impersonate a user"
          : "Connect to resource"
      }
      isOpen={props.isModalOpen}
      onClose={props.onClose}
    >
      <Modal.Body>
        <FormGroup label="Resource:">
          <ResourceLabel text={props.resource.name} />
        </FormGroup>
        {props.isImpersonationResource && (
          <FormGroup
            label="Select a user to impersonate:"
            infoTooltip="Once a user is selected, you will see the Opal product from the same perspective as that user. Only read-only actions can be performed."
          >
            <Select
              options={sortedOptions}
              value={props.selectedRole ?? undefined}
              getOptionLabel={getOptionLabel}
              getIcon={(option) => ({
                type: "src",
                style: "rounded",
                icon: option.accessLevelMetadata?.avatarUrl || defaultAvatarURL,
                fallbackIcon: defaultAvatarURL,
              })}
              onChange={(role) => {
                if (role) {
                  props.setSelectedRole(role);
                }
              }}
              getOptionSelected={(a, b) =>
                a.accessLevelName === b.accessLevelName
              }
            />
          </FormGroup>
        )}
        {!props.isImpersonationResource && requiresRole && (
          <FormGroup label="Role:">{getRoleSelect()}</FormGroup>
        )}
        {props.errorMessage && (
          <ModalErrorMessage errorMessage={props.errorMessage} />
        )}
      </Modal.Body>
      <Modal.Footer
        onPrimaryButtonClick={async () => {
          const success = await props.onSubmit();
          if (success) {
            props.onClose();
          }
        }}
        primaryButtonLabel={
          props.isImpersonationResource ? "Impersonate" : "Connect"
        }
        primaryButtonDisabled={
          !props.selectedRole ||
          (requiresRole && props.selectedRole.accessLevelRemoteId === "")
        }
        primaryButtonLoading={props.loading}
      />
    </Modal>
  );
};

export default RoleModal;
