import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  ServerError,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import generatedIntrospection from "api/generated/fragment_matcher";
import {
  AccessReviewGroupResourceAssignmentsResult,
  AccessReviewItemsResult,
  AccessReviewResourcePrincipalAssignmentsResult,
  AccessReviewsResult,
  AccessReviewUserAssignmentsResult,
  App,
  AppItemsOutput,
  AppsOutput,
  BundleItemsOutput,
  BundlesOutput,
  EventsResult,
  FilteredGroupsResult,
  FilteredResourcesResult,
  GroupsResult,
  ListEventStreamMessagesResult,
  Maybe,
  namedOperations,
  OwnersResult,
  RequestsResult,
  ResourcesResult,
  TagsResult,
  TeamsResult,
  TitlesResult,
  UsersResult,
} from "api/generated/graphql";
import { createUploadLink } from "apollo-upload-client";
import { ToastStyle } from "components/toast/Toast";
import { getToastUrl, MessageCode } from "components/toast/ToastUrlParser";
import _ from "lodash";
import { PropsWithChildren } from "react";
import { getPresignedAuthentication } from "utils/auth/auth";
import { RequestState } from "utils/common";

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...(window.csrfToken !== undefined
        ? { "X-CSRF-Token": window.csrfToken }
        : {}),
    },
  };
});

let httpLink = createUploadLink({
  uri: (operationName) => `/query?o=${operationName.operationName}`,
});

// global error handling
const errorLink = onError(({ networkError }) => {
  if (networkError && "statusCode" in networkError) {
    switch (networkError.statusCode) {
      case 401:
        if (
          !window.location.pathname.includes("/sign-in") &&
          !window.location.pathname.includes("/sign-out") &&
          // Do not redirect on HTTP 401 for pages with presigned
          // authentication. Let the page handle the error.
          getPresignedAuthentication() === null
        ) {
          window.location.href = getToastUrl(
            "/sign-out",
            RequestState.Warning,
            MessageCode.ErrorAuthSessionInvalid,
            ToastStyle.Banner
          );
        }
        break;
      case 500:
        // When client cannot reach server, we get a ServerParseError that tries to parse
        // the HTML response as JSON, which fails and gives the "Unexpected token < in JSON" error message.
        // We want to replace message with the actual error message instead, which is in bodyText.
        // https://github.com/apollographql/apollo-feature-requests/issues/153
        if (
          networkError.name === "ServerParseError" &&
          "bodyText" in networkError
        ) {
          networkError.message = networkError.bodyText;
        }
        break;
      case 403:
        if ((networkError as ServerError).name === "ServerError") {
          const p = networkError as ServerError;
          if (
            p.result &&
            p.result["errors"] &&
            p.result["errors"][0] &&
            p.result["errors"][0].message ===
              "using a read-only session, but attempting a write"
            //Copied from https://github.com/opalsecurity/opal/blob/edd15bcc617a95c56c95fe7c746cf3860c54beeb/web/backend/router/handlers/directives/auth.go/#L108-L109
          ) {
            alert("Write actions are not allowed during User Impersonation.");
          }
        }
    }
  }
});

const link = ApolloLink.from([errorLink, authLink, httpLink]);

let client = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: {
      ConnectionUser: {
        keyFields: ["userId", "connectionId"],
      },
      Event: {
        fields: {
          paginatedSubEvents: {
            keyArgs: ["input", ["sortBy"]],
            merge(existing, incoming) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time

              const existingSubEvents = existing.subEvents || [];
              const incomingSubEvents = incoming.subEvents || [];
              return {
                ...existing,
                ...incoming,
                subEvents: _.uniqBy(
                  [...existingSubEvents, ...incomingSubEvents],
                  "__ref"
                ),
              };
            },
          },
        },
      },
      ConnectionUserPropagationStatus: {
        keyFields: ["connectionId", "userId"],
      },
      GroupUserAccess: {
        keyFields: ["groupId", "userId"],
      },
      GroupUserAccessPoint: {
        keyFields: ["groupId", "userId"],
      },
      CurrentUserGroupAccess: {
        keyFields: ["groupId"],
      },
      GroupResourceAccess: {
        keyFields: [
          "groupId",
          "resourceId",
          "accessLevel",
          ["accessLevelRemoteId"],
        ],
      },
      GroupResourceAccessPoint: {
        keyFields: [
          "groupId",
          "resourceId",
          "accessLevel",
          ["accessLevelRemoteId"],
        ],
      },
      GroupGroupAccess: {
        keyFields: [
          "containingGroupId",
          "memberGroupId",
          "accessLevel",
          ["accessLevelRemoteId"],
        ],
      },
      GroupGroupAccessPoint: {
        keyFields: [
          "containingGroupId",
          "memberGroupId",
          "accessLevel",
          ["accessLevelRemoteId"],
        ],
      },
      IndirectGroupUserAccessPointPath: {
        // there's no good way to key the path
        keyFields: false,
      },
      IndirectGroupResourceAccessPointPath: {
        // there's no good way to key the path
        keyFields: false,
      },
      IndirectGroupGroupAccessPointPath: {
        // there's no good way to key the path
        keyFields: false,
      },
      IndirectGroupAccessPoint: {
        keyFields: ["groupId", "eventId"],
      },
      GroupReviewer: {
        keyFields: ["groupId", "owner"],
      },
      GroupPropagationStatus: {
        keyFields: ["groupId", "userId"],
      },
      GroupResourcePropagationStatus: {
        keyFields: ["groupId", "resourceId", "accessLevelRemoteId"],
      },
      GroupResource: {
        keyFields: [
          "groupId",
          "resourceId",
          "accessLevel",
          ["accessLevelRemoteId"],
        ],
      },
      GroupUser: {
        keyFields: ["groupId", "userId"],
      },
      GroupBreakGlassUser: {
        keyFields: ["groupId", "userId"],
      },
      GroupBinding: {
        fields: {
          groups: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Group: {
        fields: {
          paginatedGroupUsers: {
            keyArgs: ["input", ["sortBy", "filters"]],
            merge(existing, incoming) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              const existingGroupUsers = existing.groupUsers || [];
              const incomingGroupUsers = incoming.groupUsers || [];
              return {
                ...existing,
                ...incoming,
                groupUsers: _.uniqBy(
                  [...existingGroupUsers, ...incomingGroupUsers],
                  "__ref"
                ),
              };
            },
          },
        },
      },
      Resource: {
        fields: {
          paginatedResourceUsers: {
            keyArgs: ["input", ["sortBy", "filters"]],
            merge(existing, incoming) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time

              const existingResourceUsers = existing.resourceUsers || [];
              const incomingResourceUsers = incoming.resourceUsers || [];
              return {
                ...existing,
                ...incoming,
                resourceUsers: _.uniqBy(
                  [...existingResourceUsers, ...incomingResourceUsers],
                  "__ref"
                ),
              };
            },
          },
          resourceUsers: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      CurrentUserResourceAccess: {
        keyFields: ["resourceId"],
      },
      ResourceUserAccess: {
        keyFields: [
          "resourceId",
          "userId",
          "accessLevel",
          ["accessLevelRemoteId"],
        ],
      },
      ResourceUserAccessPoint: {
        keyFields: [
          "resourceId",
          "userId",
          "accessLevel",
          ["accessLevelRemoteId"],
        ],
      },
      ResourceCustomAccessLevel: {
        keyFields: ["resourceId", "accessLevel", ["accessLevelRemoteId"]],
      },
      ResourcePropagationStatus: {
        keyFields: ["resourceId", "userId", "accessLevelRemoteId"],
      },
      PropagationStatus: {
        keyFields: ["roleAssignmentId"],
      },
      ResourceReviewer: {
        keyFields: ["resourceId", "owner"],
      },
      ResourceUser: {
        keyFields: [
          "resourceId",
          "userId",
          "accessLevel",
          ["accessLevelRemoteId"],
        ],
      },
      ReviewerUser: {
        keyFields: ["entityId", "userId"],
      },
      User: {
        fields: {
          userResources: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      SupportTicket: {
        keyFields: ["id", "remoteId"],
      },
      AppItem: {
        keyFields: ["key"],
      },
      BundleItem: {
        keyFields: ["key"],
      },
      AccessReviewItem: {
        keyFields: ["key"],
      },
      Team: {
        keyFields: ["name"],
      },
      Title: {
        keyFields: ["name"],
      },
      Bundle: {
        keyFields: ["id"],
      },
      App: {
        fields: {
          userAccessCount: {
            merge(existing, incoming) {
              return existing ?? incoming;
            },
          },
          groupAccessCount: {
            merge(existing, incoming) {
              return existing ?? incoming;
            },
          },
          items: {
            keyArgs: (args) => {
              const keyArgs = [
                "itemType",
                "access",
                "searchQuery",
                "sortBy",
                "ancestorResourceId",
                ["resourceId"],
                "includeOnlyUnmanaged",
                "includeGroups",
                "hasV3",
              ];
              if (args && !args.input.hasV3) {
                keyArgs.push("parentResourceId");
              }
              return ["input", keyArgs];
            },
            merge(existing: AppItemsOutput, incoming: AppItemsOutput) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...existing,
                ...incoming,
                items: _.uniqBy(
                  [...(existing.items || []), ...(incoming.items || [])],
                  "__ref"
                ),
              };
            },
          },
        },
      },
      OktaResourceApp: {
        keyFields: ["resourceId"],
      },
      Query: {
        fields: {
          accessReviews: {
            keyArgs: ["input", ["ongoingOnly"]],
            merge(
              existing: AccessReviewsResult,
              incoming: AccessReviewsResult
            ) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                requests: _.uniqBy(
                  [...existing.accessReviews, ...incoming.accessReviews],
                  "__ref"
                ),
              };
            },
          },
          accessReviewItems: {
            keyArgs: [
              "input",
              ["accessReviewId", "searchQuery", "sortBy", "reviewerFilter"],
            ],
            merge(
              existing: AccessReviewItemsResult,
              incoming: AccessReviewItemsResult
            ) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                items: _.uniqBy(
                  [...(existing.items || []), ...(incoming.items || [])],
                  "__ref"
                ),
              };
            },
          },
          events: {
            // keyArgs notation from https://github.com/apollographql/apollo-client/issues/7314 hard to find
            // filter in keyArgs ensures we have separate caches for different filters
            keyArgs: ["input", ["filter"]],
            merge(existing: EventsResult, incoming: EventsResult) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                events: _.uniqBy(
                  [...existing.events, ...incoming.events],
                  "__ref"
                ),
              };
            },
          },
          bundles: {
            keyArgs: ["input", ["searchQuery", "sortBy"]],
            merge(existing: BundlesOutput, incoming: BundlesOutput) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                items: _.uniqBy(
                  [...(existing.bundles || []), ...(incoming.bundles || [])],
                  "__ref"
                ),
              };
            },
          },
          bundleItems: {
            keyArgs: [
              "input",
              ["bundleId", "itemType", "searchQuery", "sortBy"],
            ],
            merge(existing: BundleItemsOutput, incoming: BundleItemsOutput) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                items: _.uniqBy(
                  [...(existing.items || []), ...(incoming.items || [])],
                  "__ref"
                ),
              };
            },
          },
          users: {
            keyArgs: [
              "input",
              [
                "userIds",
                "teamId",
                "userEmails",
                "managerFilter",
                "teamFilter",
                "titleFilter",
                "searchQuery",
                "includeSystemUser",
                "sortBy",
                "idpStatusFilter",
              ],
            ],
            merge(existing: UsersResult, incoming: UsersResult) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                users: _.uniqBy(
                  [...existing.users, ...incoming.users],
                  "__ref"
                ),
              };
            },
          },
          resources: {
            keyArgs: [
              "input",
              [
                "serviceType",
                "tag",
                "connectionType",
                "connectionIds",
                "resourceTypes",
                "searchQuery",
                "parentResourceId",
                "resourceIds",
                "unmanagedOnly",
                "nonHumanIdentitiesOnly",
              ],
            ],
            merge(existing: ResourcesResult, incoming: ResourcesResult) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                resources: _.uniqBy(
                  [...existing.resources, ...incoming.resources],
                  "__ref"
                ),
              };
            },
          },
          resourceTypesWithCounts: {
            keyArgs: ["input", ["connectionIds", "parentResourceId", "query"]],
          },
          groups: {
            keyArgs: [
              "input",
              [
                "serviceType",
                "tag",
                "connectionType",
                "connectionIds",
                "searchQuery",
                "groupIds",
                "unmanagedOnly",
                "groupType",
              ],
            ],
            merge(existing: GroupsResult, incoming: GroupsResult) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                groups: _.uniqBy(
                  [...existing.groups, ...incoming.groups],
                  "__ref"
                ),
              };
            },
          },
          requests: {
            keyArgs: [
              "input",
              [
                "requestType",
                "maxNumEntries",
                "sortBy",
                "searchQuery",
                "showPendingOnly",
              ],
            ],
            merge(existing: RequestsResult, incoming: RequestsResult) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                requests: _.uniqBy(
                  [...existing.requests, ...incoming.requests],
                  "__ref"
                ),
              };
            },
          },
          app: {
            keyArgs: ["id"],
            merge(existing: App, incoming: App) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
              };
            },
          },
          apps: {
            keyArgs: ["access", "searchQuery", "appCategory", "sortBy"],
            merge(existing: AppsOutput, incoming: AppsOutput) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                apps: _.uniqBy([...existing.apps, ...incoming.apps], "__ref"),
              };
            },
          },
          filteredResources: {
            // We need to have "sort" here to fix behavior on lazy-loading tables when the user changes
            // the sorting direction
            keyArgs: ["input", ["filters", "sort"]],
            merge(
              existing: FilteredResourcesResult,
              incoming: FilteredResourcesResult
            ) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                resources: _.uniqBy(
                  [...existing.resources, ...incoming.resources],
                  "__ref"
                ),
              };
            },
          },
          filteredGroups: {
            // We need to have "sort" here to fix behavior on lazy-loading tables when the user changes
            // the sorting direction
            keyArgs: ["input", ["filters", "sort"]],
            merge(
              existing: FilteredGroupsResult,
              incoming: FilteredGroupsResult
            ) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                groups: _.uniqBy(
                  [...existing.groups, ...incoming.groups],
                  "__ref"
                ),
              };
            },
          },
          owners: {
            keyArgs: ["input", ["searchQuery", "sortBy", "includeAll"]],
            merge(existing: OwnersResult, incoming: OwnersResult) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                owners: _.uniqBy(
                  [...existing.owners, ...incoming.owners],
                  "__ref"
                ),
              };
            },
          },
          tags: {
            keyArgs: ["input", ["searchQuery", "sortBy"]],
            merge(existing: TagsResult, incoming: TagsResult) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                tags: _.uniqBy([...existing.tags, ...incoming.tags], "__ref"),
              };
            },
          },
          accessReviewUserAssignments: {
            keyArgs: [
              "input",
              [
                "accessReviewId",
                "searchQuery",
                "assignedStatus",
                "userId",
                "reviewerId",
                "adminOwnerId",
                "outcome",
                "status",
                "sortBy",
              ],
            ],
            merge(
              existing: AccessReviewUserAssignmentsResult,
              incoming: AccessReviewUserAssignmentsResult
            ) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                accessReviewUserAssignments: _.uniqBy(
                  [
                    ...existing.accessReviewUserAssignments,
                    ...incoming.accessReviewUserAssignments,
                  ],
                  "__ref"
                ),
              };
            },
          },
          accessReviewGroupResourceAssignments: {
            keyArgs: [
              "input",
              [
                "accessReviewId",
                "groupId",
                "searchQuery",
                "assignedStatus",
                "reviewerId",
                "adminOwnerId",
                "outcome",
                "status",
                "sortBy",
              ],
            ],
            merge(
              existing: AccessReviewGroupResourceAssignmentsResult,
              incoming: AccessReviewGroupResourceAssignmentsResult
            ) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                accessReviewGroupResourceAssignments: _.uniqBy(
                  [
                    ...existing.accessReviewGroupResourceAssignments,
                    ...incoming.accessReviewGroupResourceAssignments,
                  ],
                  "__ref"
                ),
              };
            },
          },
          accessReviewResourcePrincipalAssignments: {
            keyArgs: [
              "input",
              [
                "accessReviewId",
                "searchQuery",
                "assignedStatus",
                "resourcePrincipalID",
                "reviewerId",
                "adminOwnerId",
                "outcome",
                "status",
                "sortBy",
              ],
            ],
            merge(
              existing: AccessReviewResourcePrincipalAssignmentsResult,
              incoming: AccessReviewResourcePrincipalAssignmentsResult
            ) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                accessReviewResourcePrincipalAssignments: _.uniqBy(
                  [
                    ...existing.accessReviewResourcePrincipalAssignments,
                    ...incoming.accessReviewResourcePrincipalAssignments,
                  ],
                  "__ref"
                ),
              };
            },
          },
          teams: {
            keyArgs: ["input", ["searchQuery"]],
            merge(existing: TeamsResult, incoming: TeamsResult) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                teams: _.uniqBy(
                  [...existing.teams, ...incoming.teams],
                  "__ref"
                ),
              };
            },
          },
          titles: {
            keyArgs: ["input", ["searchQuery"]],
            merge(existing: TitlesResult, incoming: TitlesResult) {
              if (!incoming) return existing;
              if (!existing) return incoming; // existing will be empty the first time
              return {
                ...incoming,
                titles: _.uniqBy(
                  [...existing.titles, ...incoming.titles],
                  "__ref"
                ),
              };
            },
          },
          listEventStreamMessages: {
            keyArgs: ["input", ["eventStreamId"]],
            merge(
              existing: ListEventStreamMessagesResult,
              incoming: ListEventStreamMessagesResult
            ) {
              if (!incoming) return existing;
              if (!existing) return incoming;
              return {
                ...incoming,
                messages: _.uniqBy(
                  [...existing.messages, ...incoming.messages],
                  "__ref"
                ),
              };
            },
          },
        },
      },
    },
  }),
  link: link,
});

export const ApiContextProvider = (props: PropsWithChildren<{}>) => {
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export const isAuthError = (e: unknown) => {
  const statusCode = getApolloStatusCode(e);
  return statusCode === 403 || statusCode === 401;
};

export const getAuthErrorMessage = (e: unknown): string => {
  if (isAuthError(e)) {
    const apolloError = e as ApolloError;
    if (
      apolloError.networkError &&
      "result" in apolloError.networkError &&
      "errors" in apolloError.networkError.result &&
      apolloError.networkError.result["errors"].length > 0 &&
      "message" in apolloError.networkError.result["errors"][0]
    ) {
      return apolloError.networkError.result["errors"][0].message;
    } else {
      return "Error: you are not authorized to perform this action";
    }
  }

  return "Error: could not parse error message";
};

/**
 * Return `defaultMessage` unless `error` has a type that we want to display a custom message for.
 */
export const getModifiedErrorMessage = (
  defaultMessage: string,
  error: unknown
) => {
  if (isAuthError(error)) {
    return getAuthErrorMessage(error);
  } else if (isValidationError(error)) {
    return getValidationErrorMessage(error);
  } else {
    return defaultMessage;
  }
};

export const isValidationError = (e: unknown) => {
  const statusCode = getApolloStatusCode(e);
  return statusCode === 422;
};

export const getValidationErrorMessage = (e: unknown): string => {
  if (isValidationError(e)) {
    const apolloError = e as ApolloError;
    if (
      apolloError.networkError &&
      "result" in apolloError.networkError &&
      "errors" in apolloError.networkError.result &&
      apolloError.networkError.result["errors"].length > 0 &&
      "message" in apolloError.networkError.result["errors"][0]
    ) {
      return apolloError.networkError.result["errors"][0].message;
    } else {
      return "Error: your input is invalid for this action";
    }
  }

  return "Error: could not parse error message";
};

const NETWORK_FETCH_ERROR_MESSAGES = [
  "TypeError: Failed to fetch",
  "AbortError: Fetch is aborted",
];

export const isNetworkFetchError = (e: unknown): boolean => {
  if (
    e instanceof ApolloError &&
    "networkError" in e &&
    e.networkError &&
    "stack" in e.networkError
  ) {
    return NETWORK_FETCH_ERROR_MESSAGES.some((message) =>
      e.networkError?.stack?.includes(message)
    );
  }
  return false;
};

// These errors seem to be correlated with deploys. We should probably
// investigate this further to figure out where the HTML comes from, but for
// now downgrade these to warnings.
export const isUnexpectedHTMLError = (e: unknown): boolean => {
  if (
    e instanceof ApolloError &&
    "message" in e &&
    typeof e.message === "string"
  ) {
    const message = e.message as string;
    return (
      message.toLowerCase().includes("json parse error") ||
      message.toLowerCase().includes("unexpected token '<'")
    );
  }
  return false;
};

export const getApolloStatusCode = (e: unknown): Maybe<Number> => {
  const statusCode =
    e instanceof ApolloError &&
    e.networkError &&
    "statusCode" in e.networkError &&
    e.networkError.statusCode;

  if (statusCode) {
    return statusCode;
  }

  return null;
};

export const readCachedQuery = client.readQuery.bind(client);

export async function refetchQueries({
  include,
}: {
  include: Array<keyof typeof namedOperations["Query"]>;
}) {
  await client.refetchQueries({ include });
}
