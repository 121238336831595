import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EntityType,
  HrIdpStatus,
  OwnerFragment,
  useUpdateOwnerUsersForOwnerMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ResourceLabel } from "components/label/Label";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { List, Modal } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import { formatHrIdpStatus } from "views/users/utils";

interface OwnerUsersTableV3Props {
  owner: OwnerFragment;
}

interface OwnerUserRow {
  name: string;
  hrIdpStatus?: HrIdpStatus | null;
  position: string;
  order: number;
  data: {
    avatarURL: string;
    userId: string;
  };
}

const OwnerUsersTableV3 = (props: OwnerUsersTableV3Props) => {
  const { authState } = useContext(AuthContext);
  const transitionTo = useTransitionTo();
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);

  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showReorderModal, setShowReorderModal] = useState(false);
  const [updateUsersErrorMessage, setUpdateUsersErrorMessage] = useState("");
  const { displaySuccessToast } = useToast();

  const isAdmin = authState.user?.isAdmin ?? false;

  const orderedOwnerUsers = props.owner.ownerUsers.flatMap((ou) => {
    if (!ou.user) return [];
    return [
      {
        id: ou.user.id,
        fullName: ou.user.fullName,
        avatarUrl: ou.user.avatarUrl,
        position: ou.user.position,
        hrIdpStatus: ou.user.hrIdpStatus,
      },
    ];
  });
  const [orderedUsers, setOrderedUsers] = useState(orderedOwnerUsers);

  const [
    updateOwnerUsersForOwnerMutation,
    { loading: updateUsersLoading },
  ] = useUpdateOwnerUsersForOwnerMutation();

  const OWNER_USER_COLUMNS: Header<OwnerUserRow>[] = [
    {
      id: "name",
      label: "User",
      customCellRenderer: (row) => {
        return (
          <div className={sprinkles({ width: "fit-content" })}>
            <ResourceLabel
              text={row.name}
              avatar={row.data.avatarURL}
              pointerCursor={true}
              entityId={row.data.userId}
              entityTypeNew={EntityType.User}
            />
          </div>
        );
      },
    },
    {
      id: "position",
      label: "Title",
    },
    {
      id: "hrIdpStatus",
      label: "HRIS/IDP Status",
      customCellRenderer: (row) =>
        row.hrIdpStatus ? formatHrIdpStatus(row.hrIdpStatus) : "Not Found",
    },
  ];
  if (props.owner.accessRequestEscalationPeriodInMinutes) {
    OWNER_USER_COLUMNS.push({
      id: "order",
      label: "Escalation Priority",
    });
  }

  const ownerUsers = [...props.owner.ownerUsers];
  ownerUsers.sort((a, b) => a.reviewerPriority - b.reviewerPriority);
  const rows: OwnerUserRow[] = ownerUsers.flatMap((ownerUser, index) => {
    if (!ownerUser.user) return [];
    const row = {
      name: ownerUser.user.fullName,
      hrIdpStatus: ownerUser.user.hrIdpStatus,
      position:
        ownerUser.user.position.length > 0 ? ownerUser.user.position : "--",
      order: index + 1,
      data: {
        avatarURL: ownerUser.user.avatarUrl,
        userId: ownerUser.user.id,
      },
    };
    return [row];
  });

  const handleUsersUpdate = async (updatedOwnerIDs: string[]) => {
    try {
      const { data } = await updateOwnerUsersForOwnerMutation({
        variables: {
          input: {
            ownerId: props.owner.id,
            userIds: updatedOwnerIDs,
          },
        },
        refetchQueries: ["Owner"],
      });
      switch (data?.setOwnerUsers.__typename) {
        case "UpdateOwnerUsersForOwnerResult":
          displaySuccessToast("Success: owner users updated");
          setSelectedItemIds([]);
          setShowRemoveModal(false);
          break;
        case "CannotAddUserToSyncedOwnerError":
          setUpdateUsersErrorMessage(
            `Error: this owner's user list is link to a group: ${data.setOwnerUsers.group?.name}`
          );
          break;
        default:
          logError(new Error(`failed to update owner users`));
          setUpdateUsersErrorMessage(`Error: failed to update owner users`);
      }
    } catch (error) {
      logError(error, "failed to update owner");
      setUpdateUsersErrorMessage(
        getModifiedErrorMessage("Error: failed update owner users", error)
      );
    }
  };

  const bulkRightActions: PropsFor<typeof TableHeader>["bulkRightActions"] = [];
  if (!props.owner.sourceGroup && isAdmin) {
    bulkRightActions.push({
      label: "Remove",
      type: "danger",
      onClick: () => setShowRemoveModal(true),
      iconName: "trash",
    });
  }

  const defaultRightActions: PropsFor<
    typeof TableHeader
  >["defaultRightActions"] = [];
  if (props.owner.accessRequestEscalationPeriodInMinutes && isAdmin) {
    defaultRightActions.push({
      label: "Reorder Users",
      type: "mainSecondary",
      onClick: () => {
        setShowReorderModal(true);
      },
      iconName: "list",
    });
  }
  if (!props.owner.sourceGroup && isAdmin) {
    defaultRightActions.push({
      label: "Add Users",
      type: "mainSecondary",
      onClick: () => {
        transitionTo({
          pathname: `/owners/${props.owner.id}/add-users`,
        });
      },
      iconName: "plus",
    });
  }

  return (
    <div>
      <TableHeader
        entityType={EntityType.User}
        totalNumRows={new Set(rows.map((row) => row.data.userId)).size}
        selectedNumRows={selectedItemIds.length}
        defaultRightActions={defaultRightActions}
        bulkRightActions={bulkRightActions}
      />
      <Table
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.data.userId}
        columns={OWNER_USER_COLUMNS}
        defaultSortBy={
          props.owner.accessRequestEscalationPeriodInMinutes ? "order" : "name"
        }
        checkedRowIds={new Set(selectedItemIds)}
        onCheckedRowsChange={
          bulkRightActions.length > 0
            ? (checkedRowIds, checked) => {
                if (checked) {
                  setSelectedItemIds((prev) => [...prev, ...checkedRowIds]);
                  return;
                } else {
                  setSelectedItemIds((prev) =>
                    prev.filter((id) => !checkedRowIds.includes(id))
                  );
                }
              }
            : undefined
        }
        selectAllChecked={
          selectedItemIds.length > 0 && selectedItemIds.length === rows.length
        }
        onSelectAll={
          bulkRightActions.length > 0
            ? (checked) =>
                checked
                  ? setSelectedItemIds(rows.map((row) => row.data.userId))
                  : setSelectedItemIds([])
            : undefined
        }
      />
      {showRemoveModal && (
        <Modal
          isOpen
          title="Remove Resource Users"
          onClose={() => setShowRemoveModal(false)}
        >
          <Modal.Body>
            {updateUsersErrorMessage && (
              <ModalErrorMessage errorMessage={updateUsersErrorMessage} />
            )}
            Are you sure you want to remove{" "}
            {pluralize("user", selectedItemIds.length, true)} from this owner?
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Remove"
            primaryButtonDisabled={selectedItemIds.length === 0}
            primaryButtonLoading={updateUsersLoading}
            onPrimaryButtonClick={() => {
              const newOwnerUserIDs = ownerUsers
                .filter(
                  (user) => user.user && !selectedItemIds.includes(user.user.id)
                )
                .flatMap((user) => (user.user ? user.user.id : []));
              handleUsersUpdate(newOwnerUserIDs);
            }}
          />
        </Modal>
      )}
      <Modal
        isOpen={showReorderModal}
        onClose={() => {
          setShowReorderModal(false);
        }}
        title="Reorder Users"
      >
        <Modal.Body>
          {updateUsersErrorMessage ? (
            <ModalErrorMessage errorMessage={updateUsersErrorMessage} />
          ) : null}
          <div
            className={sprinkles({
              display: "flex",
              flexDirection: "column",
              gap: "xl",
            })}
          >
            <div
              className={sprinkles({
                fontSize: "textMd",
                color: "gray1000V3",
              })}
            >
              User order determines the order in which users are notified within
              an escalation policy
            </div>
            <List
              items={orderedUsers}
              getItemLabel={(option) => option.fullName}
              getItemKey={(option) => option.id}
              getIcon={(option) => ({
                type: "src",
                style: "rounded",
                icon: option.avatarUrl || defaultAvatarURL,
                fallbackIcon: defaultAvatarURL,
              })}
              onReorder={(newUsers) => setOrderedUsers(newUsers)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer
          onPrimaryButtonClick={async () => {
            if (orderedUsers.length !== ownerUsers.length) return;
            await handleUsersUpdate(orderedUsers.map((user) => user.id));
            setShowReorderModal(false);
          }}
          primaryButtonLabel="Update"
          primaryButtonLoading={updateUsersLoading}
          primaryButtonDisabled={orderedUsers.length === 0}
        />
      </Modal>
    </div>
  );
};

export default OwnerUsersTableV3;
