import { Column } from "components/column/Column";
import { useContext } from "react";
import { useLocation } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { usePageTitle } from "utils/hooks";
import BrowseIdpServices from "views/idp/create/BrowseIdpServices";
import CreateIdpConnection from "views/idp/CreateIdpConnection";
import { EventStreaming } from "views/settings/event_streaming/EventStreaming";

import Api from "./api/Api";
import ApiV3 from "./api/ApiV3";
import { DangerZone } from "./DangerZone";
import { DangerZoneV3 } from "./DangerZoneV3";
import OrgContext from "./OrgContext";
import OrgIntegrations from "./OrgIntegrations";
import OrgIntegrationsV3 from "./OrgIntegrationsV3";
import {
  AccessRequestsOrgSettings,
  AdvancedOrgSettings,
  AuthOrgSettings,
  AWSOrgSettings,
  IDPAndHRSettings,
} from "./OrgSettings";
import {
  AccessRequestsOrgSettingsV3,
  AdvancedOrgSettingsV3,
  AuthOrgSettingsV3,
  AWSOrgSettingsV3,
  IDPAndHRSettingsV3,
} from "./OrgSettingsV3";
import Webhooks from "./Webhooks";
import WebhooksV3 from "./WebhooksV3";

interface CategoryInfo {
  content?: JSX.Element;
  pageTitle: string;
}

const SettingsContent = () => {
  const location = useLocation();
  const { orgState } = useContext(OrgContext);

  const integrations = orgState.orgThirdPartyIntegrations || [];
  const currentCategory = location.hash.slice(1);
  const orgSettings = orgState.orgSettings;
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  let categoryInfo: CategoryInfo = {
    pageTitle: "",
  };
  switch (currentCategory) {
    case "general": {
      categoryInfo.pageTitle = "General";
      categoryInfo.content = hasV3 ? (
        <AdvancedOrgSettingsV3 title="General" orgSettings={orgSettings} />
      ) : (
        <AdvancedOrgSettings title="General" orgSettings={orgSettings} />
      );
      break;
    }
    case "access-requests": {
      categoryInfo.pageTitle = "Access Requests";
      categoryInfo.content = hasV3 ? (
        <AccessRequestsOrgSettingsV3
          title="Access Requests"
          orgSettings={orgSettings}
        />
      ) : (
        <AccessRequestsOrgSettings
          title="Access Requests"
          orgSettings={orgSettings}
        />
      );
      break;
    }
    case "api": {
      categoryInfo.pageTitle = "API Access Tokens";
      categoryInfo.content = hasV3 ? (
        <ApiV3 title="API Access Tokens" />
      ) : (
        <Api title="API Access Tokens" />
      );
      break;
    }
    case "authentication": {
      categoryInfo.pageTitle = "Authentication";
      categoryInfo.content = hasV3 ? (
        <AuthOrgSettingsV3 title="Authentication" orgSettings={orgSettings} />
      ) : (
        <AuthOrgSettings title="Authentication" orgSettings={orgSettings} />
      );
      break;
    }
    case "advanced": {
      categoryInfo.pageTitle = "Advanced";
      categoryInfo.content = hasV3 ? (
        <AdvancedOrgSettingsV3 title="Advanced" orgSettings={orgSettings} />
      ) : (
        <AdvancedOrgSettings title="Advanced" orgSettings={orgSettings} />
      );
      break;
    }
    case "aws-settings": {
      categoryInfo.pageTitle = "AWS Settings";
      categoryInfo.content = hasV3 ? (
        <AWSOrgSettingsV3 title="AWS Settings" orgSettings={orgSettings} />
      ) : (
        <AWSOrgSettings title="AWS Settings" orgSettings={orgSettings} />
      );
      break;
    }
    case "idp-and-hr": {
      categoryInfo.pageTitle = "IDP & HR Integrations";
      categoryInfo.content = hasV3 ? (
        <IDPAndHRSettingsV3
          title="IDP & HR Integrations"
          orgSettings={orgSettings}
        />
      ) : (
        <IDPAndHRSettings
          title="IDP & HR Integrations"
          orgSettings={orgSettings}
        />
      );
      break;
    }
    case "productivity-integrations": {
      categoryInfo.pageTitle = "Productivity Integrations";
      categoryInfo.content = hasV3 ? (
        <OrgIntegrationsV3
          title="Productivity Integrations"
          integrations={integrations}
        />
      ) : (
        <OrgIntegrations
          title="Productivity Integrations"
          integrations={integrations}
        />
      );
      break;
    }
    case "webhooks": {
      categoryInfo.pageTitle = "Webhooks";
      categoryInfo.content = hasV3 ? (
        <WebhooksV3 title="Webhooks" />
      ) : (
        <Webhooks title="Webhooks" />
      );
      break;
    }
    case "danger-zone": {
      categoryInfo.pageTitle = "Danger Zone";
      categoryInfo.content = hasV3 ? (
        <DangerZoneV3 orgSettings={orgSettings} />
      ) : (
        <DangerZone orgSettings={orgSettings} />
      );
      break;
    }
    case "event-streaming": {
      categoryInfo.pageTitle = "Event Streaming";
      categoryInfo.content = <EventStreaming title="Event Streaming" />;
      break;
    }
  }

  if (location.pathname.includes("/idp/browse")) {
    categoryInfo.pageTitle = "Browse Providers";
    categoryInfo.content = <BrowseIdpServices />;
  } else if (location.pathname.includes("/idp/create")) {
    categoryInfo.pageTitle = "Create Connection";
    categoryInfo.content = <CreateIdpConnection />;
  }

  const separator = categoryInfo.pageTitle.length > 0 ? " - " : "";
  usePageTitle(`Settings${separator}${categoryInfo.pageTitle}`);

  if (!categoryInfo.content) {
    return null;
  }

  return (
    <Column isContent maxWidth="xxl">
      {categoryInfo.content}
    </Column>
  );
};

export default SettingsContent;
