import {
  AddUserTagInput,
  EntityType,
  Maybe,
  RemoveUserTagInput,
  TagFragment,
  useAddUserTagsMutation,
  useRemoveUserTagsMutation,
  UserTagFragment,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import UserLabel from "components/label/item_labels/UserLabel";
import { ResourceLabel } from "components/label/Label";
import SelectItemsModal, {
  SelectType,
} from "components/modals/update/SelectItemsModal";
import { useToast } from "components/toast/Toast";
import { Modal } from "components/ui";
import ButtonV3 from "components/ui/buttonV3/ButtonV3";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { useAllUsersQuery } from "utils/hooks";
import { logError, logWarning } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";

type TagResourcesTableProps = {
  tag: TagFragment;
};

interface UserRow {
  id: string;
  user?: {
    id?: string;
    fullName?: string;
    avatarUrl?: string;
  };
  tagSource: string;
  createdAt: string;
}

const USERS_COLUMNS: Header<UserRow>[] = [
  {
    id: "user",
    label: "Name",
    sortable: false,
    customCellRenderer: (user) => (
      <ResourceLabel
        text={user.user?.fullName}
        entityTypeNew={EntityType.User}
        avatar={user?.user?.avatarUrl}
        entityId={user?.id}
      />
    ),
  },
  {
    id: "tagSource",
    label: "Tag Source",
    sortable: false,
  },
  {
    id: "createdAt",
    label: "Created",
    sortable: false,
    customCellRenderer: (row) => moment(new Date(row.createdAt)).fromNow(),
  },
];

type AddUsersToTagModalProps = {
  tag: TagFragment;
  userTags: UserTagFragment[];
  setShowModal: (show: boolean) => void;
};

const AddUsersToTagModal = ({
  tag,
  userTags,
  setShowModal,
}: AddUsersToTagModalProps) => {
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [addUserTags, { loading }] = useAddUserTagsMutation();
  const [idsToAdd, setIdsToAdd] = useState<string[]>([]);

  const [searchQuery, setSearchQuery] = useState<string>("");

  const modalReset = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const onSubmit = async () => {
    const userTagsToAdd: AddUserTagInput[] = [];
    for (const userId of idsToAdd) {
      userTagsToAdd.push({
        tagId: tag.id,
        userId: userId,
      });
    }

    try {
      const { data } = await addUserTags({
        variables: {
          input: {
            userTags: userTagsToAdd,
          },
        },
        refetchQueries: ["UserTags", "Tag"],
      });
      switch (data?.addUserTags.__typename) {
        case "AddUserTagsResult": {
          let userTags = [];
          data.addUserTags.entries.forEach((entry) => {
            switch (entry.__typename) {
              case "AddUserTagsEntryResult":
                userTags.push(entry.userTag);
            }
          });
          if (userTags.length !== userTagsToAdd.length) {
            setErrorMessage(
              `Error: tag was not added to selected users successfully`
            );
          } else {
            modalReset();
            displaySuccessToast(
              `Success: tag added to ${pluralize(
                "users",
                userTagsToAdd.length,
                true
              )}`
            );
          }
          break;
        }
        case "UserNotFoundError":
        case "TagNotFoundError": {
          logWarning(new Error(data.addUserTags.message));
          setErrorMessage(data.addUserTags.message);
          break;
        }
        default:
          logError(new Error(`failed to add tag to users`));
          setErrorMessage("Error: failed to add tag to users");
      }
    } catch (error) {
      logError(error, `failed to add tag to users`);
      setErrorMessage("Error: failed to add tag to users");
    }
  };

  // Fetch all users
  const existingUserIds = new Set(userTags.map((userTag) => userTag.userId));

  const { data: usersData, error: usersError } = useAllUsersQuery();
  if (usersError) {
    logError(usersError, `failed to list users`);
  }

  const users = usersData?.users.users ?? [];

  return (
    <SelectItemsModal
      key={"users_remove"}
      title={"Add tag to users"}
      selectType={SelectType.Add}
      entryInfos={users
        ?.filter(
          (user) =>
            !existingUserIds.has(user.id) &&
            user.fullName
              .toLowerCase()
              .includes(searchQuery.toLocaleLowerCase())
        )
        .sort((a, b) => {
          if (a && b) {
            const aSortString = a.fullName || "";
            const bSortString = b.fullName || "";
            return aSortString.localeCompare(bSortString);
          }
          return 0;
        })
        .map((user) => ({
          entityId: {
            entityId: user.id,
            entityType: EntityType.User,
          },
          label: (
            <UserLabel
              avatar={user.avatarUrl}
              name={user.fullName}
              large={true}
              bold={true}
            />
          ),
          isBold: false,
          isBreadcrumb: false,
        }))}
      itemName={"user"}
      isModalOpen
      errorMessage={errorMessage}
      submitDisabled={idsToAdd.length === 0}
      onClose={modalReset}
      onSubmit={onSubmit}
      loading={loading}
      idsToUpdate={idsToAdd}
      setIdsToUpdate={setIdsToAdd}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  );
};

type RemoveUsersFromTagModalProps = {
  idsToRemove: string[];
  setShowModal: (show: boolean) => void;
  setSelectedItemIds: (ids: string[]) => void;
};

const RemoveUsersFromTagModal = ({
  idsToRemove,
  setShowModal,
  setSelectedItemIds,
}: RemoveUsersFromTagModalProps) => {
  const [removeTagUsers, { loading }] = useRemoveUserTagsMutation();
  const { displaySuccessToast, displayErrorToast } = useToast();

  const removeSelectedUsers = async () => {
    const userTagsToRemove: RemoveUserTagInput[] = idsToRemove.map(
      (userTagId) => ({ userTagId: userTagId })
    );

    try {
      const { data } = await removeTagUsers({
        variables: {
          input: {
            userTags: userTagsToRemove,
          },
        },
        refetchQueries: ["UserTags", "Tag"],
      });

      switch (data?.removeUserTags.__typename) {
        case "RemoveUserTagsResult": {
          let userTags: string[] = [];
          data.removeUserTags.entries.forEach((entry) => {
            switch (entry.__typename) {
              case "RemoveUserTagsEntryResult":
                userTags.push(entry.userTag.userId);
                break;
            }
          });
          if (userTags.length !== idsToRemove.length) {
            logError(new Error(`removed tag to some, but not all users`));
            displayErrorToast(`Error: removed tag to some, but not all users`);
            setSelectedItemIds(_.difference(idsToRemove, userTags));
          } else {
            displaySuccessToast(
              `Success: removed tag from ${pluralize(
                "user",
                idsToRemove.length,
                true
              )}`
            );
            setSelectedItemIds([]);
          }
          break;
        }
        case "UserTagNotFoundError": {
          logError(new Error(data.removeUserTags.message));
          displayErrorToast(data.removeUserTags.message);
          break;
        }
        default:
          logError(new Error(`failed to remove tag from selected users`));
          displayErrorToast("Error: failed to remove tag from selected users");
      }
    } catch (error) {
      logError(error, `failed to remove group tag from selected users`);
      displayErrorToast("Error: failed to remove tag from selected users");
    }

    setShowModal(false);
  };

  return (
    <Modal isOpen onClose={() => setShowModal(false)} title="Remove Tag Users">
      <Modal.Body>
        Are you sure you want to remove{" "}
        {pluralize("user", idsToRemove.length, true)} from this tag?
      </Modal.Body>
      <Modal.Footer>
        <ButtonV3
          label="Remove"
          onClick={() => {
            removeSelectedUsers();
            setShowModal(false);
          }}
          type="danger"
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

const TagUsersTable = ({ tag }: TagResourcesTableProps) => {
  const transitionTo = useTransitionTo();
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  const [showRemoveUsersModal, setShowRemoveUsersModal] = useState(false);
  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user?.isAdmin;
  const history = useHistory();

  const rows: UserRow[] =
    tag?.tagUsers.map((user) => {
      return {
        id: user.id,
        user: {
          id: user.user?.id,
          fullName: user.user?.fullName,
          avatarUrl: user.user?.avatarUrl,
        },
        tagSource: user.source,
        createdAt: user.createdAt,
      };
    }) || [];

  const allSelected = rows.every((row) => selectedItemIds.includes(row?.id));

  const table = (
    <Table
      rows={rows}
      getRowId={(row) => row.id}
      columns={USERS_COLUMNS}
      totalNumRows={rows.length}
      defaultSortBy="user"
      emptyState={{
        title: "No Users",
      }}
      onRowClick={(row, event) => {
        transitionTo(
          {
            pathname: `/users/${row.user?.id}`,
          },
          event
        );
      }}
      checkedRowIds={new Set(selectedItemIds)}
      onCheckedRowsChange={(checkedRowIds, checked) => {
        if (checked) {
          setSelectedItemIds((prev) => [...prev, ...checkedRowIds]);
          return;
        } else {
          setSelectedItemIds((prev) =>
            prev.filter((id) => !checkedRowIds.includes(id))
          );
        }
      }}
      selectAllChecked={allSelected}
      onSelectAll={(checked) => {
        if (checked) {
          const allIds = rows.map((row) => row.id);
          setSelectedItemIds(allIds);
        } else {
          setSelectedItemIds([]);
        }
      }}
    />
  );

  return (
    <div
      className={sprinkles({
        padding: "sm",
      })}
    >
      <div
        className={sprinkles({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "md",
          fontSize: "textXl",
          fontWeight: "medium",
        })}
      >
        {`${rows.length} ${pluralize("User", rows.length)}`}
        {isAdmin && (
          <ButtonV3
            label="User"
            type="mainSecondary"
            leftIconName="plus"
            size="sm"
            onClick={() => history.push(`/tags/${tag.id}/add-users`)}
          />
        )}
      </div>
      {selectedItemIds.length > 0 && (
        <TableHeader
          totalNumRows={rows.length}
          selectedNumRows={selectedItemIds.length}
          rightElement={
            <ButtonV3
              label="Remove"
              type="danger"
              leftIconName="trash"
              onClick={() => setShowRemoveUsersModal((prev) => !prev)}
            />
          }
        />
      )}
      {table}
      {showRemoveUsersModal && (
        <RemoveUsersFromTagModal
          key={`remove-users-from-tag-modal`}
          idsToRemove={selectedItemIds}
          setShowModal={setShowRemoveUsersModal}
          setSelectedItemIds={setSelectedItemIds}
        />
      )}
      {showAddUsersModal && (
        <AddUsersToTagModal
          key={`add-users-to-tag-modal`}
          tag={tag}
          userTags={tag?.tagUsers || []}
          setShowModal={setShowAddUsersModal}
        />
      )}
    </div>
  );
};

export default TagUsersTable;
