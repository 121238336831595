import {
  EntityType,
  OwnerFragment,
  useOwnerQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeaderV3";
import { formatDuration } from "components/label/Label";
import { PillV3 } from "components/pills/PillsV3";
import { ButtonV3, TabsV3, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { duration } from "moment";
import { useContext, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { logError } from "utils/logging";
import { ItemDetailsCard } from "views/common/ItemDetailsCard";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import EventsTableV3Component from "views/events/EventsTableV3Component";

import OwnerDeleteModal from "./owner_viewer/OwnerDeleteModal";
import OwnerGroupsTableV3 from "./owner_viewer/OwnerGroupsTableV3";
import OwnerResourcesTableV3 from "./owner_viewer/OwnerResourcesTableV3";
import OwnerUsersTableV3 from "./owner_viewer/OwnerUsersTableV3";
import OwnerEditModal from "./OwnerEditModal";

interface OwnerView {
  key: string;
  title: string;
  content: JSX.Element;
  count?: number;
}

const OwnerDetailV3 = () => {
  const location = useLocation();
  const history = useHistory();
  const { ownerId } = useParams<Record<string, string>>();
  const { authState } = useContext(AuthContext);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const isAdmin = authState.user?.isAdmin ?? false;
  const selectedView = location.hash.slice(1) || "users";

  const { data, error, loading } = useOwnerQuery({
    fetchPolicy: "cache-and-network",
    variables: { input: { id: ownerId } },
    skip: !ownerId,
  });

  let owner: OwnerFragment | undefined = undefined;
  let ownerNotFound = false;
  if (data) {
    switch (data.owner.__typename) {
      case "OwnerResult":
        owner = data.owner.owner;
        break;
      case "OwnerNotFoundError":
        ownerNotFound = true;
        break;
      default:
        logError(new Error(`failed to list owner`));
    }
  } else if (error) {
    logError(error, `failed to list owner`);
  }

  if (loading && !data) {
    return (
      <Column isContent maxWidth="none">
        <ColumnHeaderSkeleton includeCard />
      </Column>
    );
  }
  if (ownerNotFound) {
    return (
      <Column isContent maxWidth="none">
        <NotFoundPage entity="Owner" />
      </Column>
    );
  }
  if (!owner || error) {
    return (
      <Column isContent maxWidth="none">
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  let durationString;
  if (
    owner.reviewerMessageChannel &&
    !owner.accessRequestEscalationPeriodInMinutes
  ) {
    durationString = "Notify only Slack channel";
  } else if (owner.accessRequestEscalationPeriodInMinutes != null) {
    durationString =
      "Escalate after " +
      formatDuration(
        duration(owner.accessRequestEscalationPeriodInMinutes, "m")
      );
  } else {
    durationString = "Notify all reviewers";
  }

  const hasOwnedItems =
    owner.ownedResources.length > 0 ||
    owner.ownedGroups.length > 0 ||
    owner.ownedConfigurationTemplates.length > 0;

  const detailsCard = (
    <ItemDetailsCard
      title={owner.name}
      subtitle={owner.description}
      bodyFields={{
        ["Reviewer escalation policy"]: (
          <PillV3
            pillColor="Blue"
            keyText={durationString}
            icon={{ type: "name", icon: "alarm-clock" }}
          />
        ),
        ["Source Group"]: (
          <PillV3
            pillColor="Teal"
            keyText={owner.sourceGroup?.name}
            icon={
              owner.sourceGroup
                ? { type: "entity", entityType: owner.sourceGroup.groupType }
                : undefined
            }
            entityId={
              owner.sourceGroup
                ? {
                    entityId: owner.sourceGroup.id,
                    entityType: EntityType.Group,
                  }
                : undefined
            }
          />
        ),
        ["Reviewer Slack channel"]: (
          <PillV3 keyText={owner.reviewerMessageChannel?.name} />
        ),
      }}
      rightActions={
        isAdmin ? (
          <div className={sprinkles({ display: "flex", gap: "sm" })}>
            {hasOwnedItems ? (
              <Tooltip
                tooltipText={`Cannot delete ${owner.name} while it is still the admin or reviewer of any resources, groups, or templates.`}
              >
                <ButtonV3
                  type="danger"
                  label="Delete Owner"
                  size="sm"
                  disabled
                />
              </Tooltip>
            ) : (
              <ButtonV3
                type="danger"
                label="Delete Owner"
                size="sm"
                onClick={() => setShowDeleteModal(true)}
              />
            )}
            <ButtonV3
              type="main"
              label="Edit"
              size="sm"
              leftIconName="edit"
              onClick={() => setShowEditModal(true)}
            />
          </div>
        ) : undefined
      }
    />
  );

  const ownerViews: OwnerView[] = [
    {
      key: "users",
      title: "Users",
      count: owner.ownerUsers.length,
      content: <OwnerUsersTableV3 owner={owner} />,
    },
    {
      key: "resources",
      title: "Resources",
      count: owner.ownedResources.length,
      content: <OwnerResourcesTableV3 ownedResources={owner.ownedResources} />,
    },
    {
      key: "groups",
      title: "Groups",
      count: owner.ownedGroups.length,
      content: <OwnerGroupsTableV3 ownedGroups={owner.ownedGroups} />,
    },
    {
      key: "events",
      title: "Events",
      content: (
        <EventsTableV3Component
          eventFilter={{
            objects: {
              objectId: owner.id,
            },
          }}
          route={{
            pathname: `/owners/${owner.id}`,
            hash: "#events",
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Column isContent maxWidth="none">
        <ColumnHeader
          breadcrumbs={[
            {
              name: "Owners",
              to: "/owners",
            },
            {
              name: owner.name,
            },
          ]}
          includeDefaultActions
        />
        <ColumnContent>
          {detailsCard}
          <TabsV3
            tabInfos={ownerViews.map((view) => ({
              title: view.title,
              badgeCount: view.count,
              onClick: () => history.push({ hash: view.key }),
              isSelected: selectedView === view.key,
            }))}
          />
          {ownerViews.find((view) => view.key === selectedView)?.content}
        </ColumnContent>
      </Column>
      {showDeleteModal && (
        <OwnerDeleteModal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          owner={owner}
        />
      )}
      {showEditModal && (
        <OwnerEditModal owner={owner} onClose={() => setShowEditModal(false)} />
      )}
    </>
  );
};

export default OwnerDetailV3;
