import {
  ConnectionType,
  SyncErrorFragment,
  SyncTaskFragment,
  SyncType,
  useConnectionPreviewQuery,
  useSyncStatusQuery,
  Visibility,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeaderV3";
import { makeConfigForConnection } from "components/forms/utils";
import SyncStatusModal from "components/label/SyncStatusModal";
import useSyncActionIcon, {
  getSyncLabel,
} from "components/sync/useSyncActionIcon";
import { ButtonV3, TabsV3 } from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { useContext, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { hasBasicPermissions } from "utils/auth/auth";
import {
  connectionTypeAllowsCreateGroup,
  isConnectionTypeGroupsProvider,
  isConnectionTypeResourcesProvider,
  isConnectionTypeUsersProvider,
} from "utils/directory/connections";
import {
  isConfigurableConnectionType,
  isNativeConnectionType,
} from "utils/directory/resources";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { usePageTitle } from "utils/hooks";
import { logError } from "utils/logging";
import { useAccessRequestTransition } from "views/access_request/AccessRequestContext";
import { ItemDetailsCard } from "views/common/ItemDetailsCard";
import ConnectionDeleteModal from "views/connections/ConnectionDeleteModal";
import AwsConnectionMetadataSetup from "views/connections/viewer/rows/AwsConnectionMetadataSetup";
import ConnectionMetadataAndCredsSetup from "views/connections/viewer/rows/ConnectionMetadataAndCredsSetup";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import EventsTableV3Component from "views/events/EventsTableV3Component";
import { hasServiceTypes } from "views/resources/creation/utils";
import OrgContext from "views/settings/OrgContext";

import AppDetailsV3 from "./AppDetailsV3";
import AppResourcesTable from "./AppResourcesTable";
import AppUsersTable from "./AppUsersTable";

interface AppView {
  key: string;
  title: string;
  content: JSX.Element;
  count?: number;
}

const AppDetailView = () => {
  const location = useLocation();
  const history = useHistory();
  const logEvent = useLogEvent();
  const { appId } = useParams<Record<string, string>>();
  const { authState } = useContext(AuthContext);
  const { orgState } = useContext(OrgContext);
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);
  const hasEndUserXP = useFeatureFlag(FeatureFlag.EndUserExperience);
  const transitionToAccessRequest = useAccessRequestTransition();

  const [showSyncModal, setShowSyncModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data, error, loading } = useConnectionPreviewQuery({
    variables: {
      connectionId: appId,
      fetchCounts: true,
    },
    fetchPolicy: "cache-and-network",
  });

  const connection =
    data?.connection.__typename == "ConnectionResult"
      ? data.connection.connection
      : null;
  let connectionNotFound =
    data?.connection.__typename == "ConnectionNotFoundError" || !connection;

  usePageTitle(connection?.name);

  const {
    data: syncData,
    error: syncError,
    loading: syncLoading,
  } = useSyncStatusQuery({
    variables: {
      input: {
        syncType: SyncType.PullConnectionsSingleConnection,
        connectionId: appId,
      },
    },
    skip: connectionNotFound,
  });

  const syncActionIcon = useSyncActionIcon({
    syncType: SyncType.PullConnectionsSingleConnection,
    connection: connection ?? undefined,
    loadingEntity: loading || connectionNotFound,
    queriesToRefetch: ["ConnectionPreview", "AppResources"],
    label: "Sync app",
  });

  let lastSuccessfulSyncTask: SyncTaskFragment | null = null;
  let syncErrors: SyncErrorFragment[] = [];
  if (syncData) {
    switch (syncData.syncStatus.__typename) {
      case "SyncStatusResult":
        lastSuccessfulSyncTask = syncData.syncStatus.lastSuccessfulSyncTask
          ? syncData.syncStatus.lastSuccessfulSyncTask
          : null;
        syncErrors = syncData.syncStatus.syncErrors;
        break;
      case "InvalidSyncTypeError":
      case "ConnectionNotFoundError":
        // we should not get this error if the connection doesn't exist as we're
        // skipping this query if connectionNotFound is true
        logError(syncData.syncStatus.message);
        break;
    }
  }
  let syncStatus: string;
  if (syncError) {
    syncStatus = "Unable to get status";
  } else if (syncLoading) {
    syncStatus = "Loading sync status";
  } else {
    syncStatus = getSyncLabel(lastSuccessfulSyncTask, syncErrors);
  }

  if (loading) {
    return (
      <Column isContent maxWidth="none">
        <ColumnHeaderSkeleton includeCard />
      </Column>
    );
  }
  if (connectionNotFound) {
    return (
      <Column isContent maxWidth="none">
        <NotFoundPage entity="App" />
      </Column>
    );
  }
  if (!connection || error) {
    logError(error, `failed to get app`);
    return (
      <Column isContent maxWidth="none">
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  const selectedView = location.hash.slice(1) || "resources";

  const actionIcons: PropsFor<typeof ColumnHeader>["actionIcons"] = [];
  if (!hasBasicPermissions(authState.user)) {
    if (syncActionIcon) {
      actionIcons.push(syncActionIcon);
    }
    actionIcons.push({
      label: "View Sync Details",
      sublabel: syncStatus,
      onClick: () => {
        logEvent({
          name: "apps_view_sync_details",
          properties: {
            syncType: SyncType.PullConnectionsSingleConnection,
          },
        });
        setShowSyncModal(true);
      },
      adminOnly: false,
    });
  }

  const actionButtons = authState.user?.isAdmin ? (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      <ButtonV3
        label="Edit"
        leftIconName="edit"
        type="mainSecondary"
        onClick={() => history.push(`/apps/${appId}/edit`)}
        size="sm"
      />
      {hasEndUserXP && (
        <ButtonV3
          label="Request"
          leftIconName="raised-hand"
          type="main"
          onClick={(event) =>
            transitionToAccessRequest({ appId: appId }, event)
          }
          size="sm"
        />
      )}
    </div>
  ) : undefined;

  const menuOptions: PropsFor<typeof ItemDetailsCard>["extraMenuOptions"] = [];
  if (authState.user?.isAdmin) {
    const { connectionType } = connection;
    const hasAwsRoleCreation = Boolean(
      orgState.orgSettings?.roleCreationReviewerOwner
    );
    const isGroupsProvider = isConnectionTypeGroupsProvider(connectionType);
    const isResourcesProvider = isConnectionTypeResourcesProvider(
      connectionType
    );
    const canImport = !isNativeConnectionType(connectionType);

    if (canImport) {
      menuOptions.push({
        label: "Import items",
        onClick: () => {
          history.push(
            `/apps/${appId}/import` // TODO: import V3 is not implemented yet
          );
        },
      });
    }

    if (isGroupsProvider && connectionTypeAllowsCreateGroup(connectionType)) {
      menuOptions.push({
        label: "Create group",
        onClick: () => history.push(`/apps/${appId}/create-group`),
      });
    }

    if (
      isResourcesProvider &&
      connectionType !== ConnectionType.Opal &&
      !hasServiceTypes(connectionType)
    ) {
      menuOptions.push({
        label: "Create resource",
        onClick: () => history.push(`/apps/${appId}/create-resource`),
      });
    }

    if (connectionType === ConnectionType.Aws && hasAwsRoleCreation) {
      menuOptions.push({
        label: "Create new IAM role",
        onClick: () =>
          history.push({
            pathname: `/apps/${appId}/create-role`,
          }),
      });
    }

    menuOptions.push({
      label: "Remove from Opal",
      onClick: () => setShowDeleteModal(true),
      type: "danger",
    });
  }

  const isAdmin =
    authState.user?.isAdmin || authState.user?.isReadOnlyAdmin || false;
  const totalNumItems =
    (connection.numVisibleResources ?? 0) + (connection.numVisibleGroups ?? 0);
  const appViews: AppView[] = [
    {
      key: "resources",
      title: "Resources",
      content: <AppResourcesTable totalResources={totalNumItems} />,
    },
  ];
  if (isAdmin && isConnectionTypeUsersProvider(connection.connectionType)) {
    appViews.push({
      key: "users",
      title: "User Access",
      content: <AppUsersTable />,
      count: connection.numUsers,
    });
  }
  if (isAdmin) {
    appViews.push({
      key: "events",
      title: "Events",
      content: (
        <EventsTableV3Component
          eventFilter={{
            objects: {
              objectId: appId,
            },
          }}
          route={{
            pathname: `/apps/${appId}`,
            hash: "#events",
          }}
        />
      ),
    });
  }
  appViews.push({
    key: "details",
    title: "Details",
    content: (
      <AppDetailsV3
        connection={connection}
        config={makeConfigForConnection(connection)}
        setConfig={() => {}}
      />
    ),
  });
  if (isAdmin && isConfigurableConnectionType(connection.connectionType)) {
    appViews.push({
      key: "setup",
      title: "Setup",
      content:
        connection.connectionType === ConnectionType.AwsSso ? (
          <AwsConnectionMetadataSetup connection={connection} />
        ) : (
          <ConnectionMetadataAndCredsSetup connection={connection} />
        ),
    });
  }

  const tabInfos: PropsFor<typeof TabsV3>["tabInfos"] = appViews.map(
    (view) => ({
      title: view.title,
      onClick: () => history.push({ hash: view.key }),
      isSelected: selectedView === view.key,
      badgeCount: view.count,
    })
  );

  const selectedViewInfo =
    appViews.find((view) => view.key === selectedView) ?? appViews[0];
  const content = selectedViewInfo?.content;
  const footerFields: Record<string, string> = {
    Admin: connection.adminOwner?.name ?? "Unknown",
    Source: "Native",
  };
  if (hasAppLevelVisibility) {
    footerFields["Visibility"] =
      connection.visibility === Visibility.Global ? "Global" : "Limited";
  }
  let appData: IconData;
  if (connection?.iconUrl) {
    appData = {
      type: "src",
      icon: connection.iconUrl,
    };
  } else {
    appData = { type: "entity", entityType: connection.connectionType };
  }

  return (
    <>
      {showSyncModal && (
        <SyncStatusModal
          syncType={SyncType.PullConnectionsSingleConnection}
          entity={connection}
          lastSuccessfulSyncTask={lastSuccessfulSyncTask}
          syncErrors={syncErrors}
          isModalOpen={showSyncModal}
          onClose={() => {
            setShowSyncModal(false);
          }}
        />
      )}
      {showDeleteModal && (
        <ConnectionDeleteModal
          connection={connection}
          showModal
          setShowModal={setShowDeleteModal}
        />
      )}
      <Column isContent maxWidth="none">
        <ColumnHeader
          breadcrumbs={[
            { name: "Catalog", to: "/apps" },
            { name: "Apps", to: "/apps" },
            { name: connection.name },
          ]}
          actionIcons={actionIcons}
          includeDefaultActions
        />
        <ColumnContent>
          <ItemDetailsCard
            icon={appData}
            title={connection.name}
            subtitle={connection.description}
            rightActions={actionButtons}
            extraMenuOptions={menuOptions}
            footerFields={footerFields}
          />
          <TabsV3 tabInfos={tabInfos} />
          {content}
        </ColumnContent>
      </Column>
    </>
  );
};

export default AppDetailView;
