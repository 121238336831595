import {
  ConnectSessionViewQuery,
  useConnectSessionViewQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeaderV3";
import { useParams } from "react-router";
import { useMountEffect } from "utils/hooks";
import ConnectResourceSession from "views/connect_sessions/ConnectResourceSession";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import * as styles from "./ConnectSessionView.css";

const ConnectSessionView = () => {
  const { appId, resourceId } = useParams<Record<string, string>>();

  const {
    data,
    error,
    loading,
    previousData,
    refetch: refetchResource,
  } = useConnectSessionViewQuery({
    variables: {
      id: resourceId,
    },
    skip: !resourceId,
    fetchPolicy: "cache-and-network",
  });

  // Re-render component every 30s
  useMountEffect(() => {
    const interval = setInterval(() => {
      refetchResource();
    }, 30_000);
    return () => clearInterval(interval);
  });

  const getResource = (data: ConnectSessionViewQuery | undefined) => {
    return data?.resource.__typename === "ResourceResult"
      ? data.resource.resource
      : null;
  };

  const getAccessLevels = (data: ConnectSessionViewQuery | undefined) => {
    return data?.accessLevels.__typename === "ResourceAccessLevelsResult"
      ? data.accessLevels.accessLevels
      : null;
  };

  const resource = getResource(data) ?? getResource(previousData);
  const roles = getAccessLevels(data) ?? getAccessLevels(previousData) ?? [];
  const connection = resource?.connection;

  if (error || !resourceId) {
    return (
      <Column isContent maxWidth="none">
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  if (data?.resource.__typename === "ResourceNotFoundError")
    return (
      <Column isContent maxWidth="none">
        <NotFoundPage entity="Resource" />
      </Column>
    );

  if (!resource && loading) {
    return (
      <Column isContent maxWidth="none">
        <ColumnContentSkeleton />
      </Column>
    );
  }

  return (
    <>
      <Column isContent maxWidth="none">
        <ColumnHeader
          breadcrumbs={[
            { name: "Catalog", to: "/apps" },
            { name: "Apps", to: "/apps" },
            {
              name: connection?.name ?? "",
              to: `/apps/${appId}`,
            },
            ...(resource?.ancestorPathToResource
              ?.split(" / ")
              .map((ancestor) => ({
                name: ancestor,
                to: "",
              })) ?? []),
          ]}
          includeDefaultActions
        />
        <ColumnContent>
          <div className={styles.container}>
            {resource && (
              <ConnectResourceSession
                resource={resource}
                roles={roles}
                refetchResource={refetchResource}
              />
            )}
          </div>
        </ColumnContent>
      </Column>
    </>
  );
};

export default ConnectSessionView;
